import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { connect } from 'react-redux';
// import TopbarMenuLink from './TopbarMenuLink';
import alertActions from '../../../redux/actions/alertActions';
import userActions from '../../../redux/actions/userActions';

class TopbarProfile extends PureComponent {

  static propTypes = {
    authData: PropTypes.objectOf(PropTypes.any).isRequired,
    logout: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  logout = () => {
    const { logout } = this.props;
    logout();
  }

  render() {
    const { collapse } = this.state;
    const { authData: { user: { name, first_name: firstName, last_name: lastName } } } = this.props;
    let userName = name;
    if (firstName && lastName) {
      userName = `${firstName} ${lastName}`;
    }
    const avatar = userName.substring(0, 1).toUpperCase();

    return (
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={this.toggle}>
          <span className="topbar__avatar-img">{avatar}</span>
          <p className="topbar__avatar-name">{userName}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            {/* <TopbarMenuLink title="Log Out" icon="exit" path="/" onClick={this.logout} /> */}
            <div role="button" styling="link" className="topbar__link" 
              onClick={this.logout} tabIndex="-1" onKeyDown={this.handleClick}>
              <span className="topbar__link-icon lnr lnr-exit" />
              <p className="topbar__link-title">Log Out</p>
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ logout: state.logout, authData: state.authentication });

const actionCreators = {
  logout: userActions.logoutAction,
  successAlerts: alertActions.success
};

export default connect(mapStateToProps, actionCreators)(TopbarProfile);