import API from "../../api/API";
import { globalSearch } from "../../_constants/common.constants";
import alertActions from "./alertActions";
import preloaderActions from "./preloaderActions";
import { constructUrlParams } from "../../_helpers/CommonFunctions";

function getGlobalSearchAction(filters) {
  let custFilter = filters;

  function success(data) {
    return {
      type: globalSearch.GLOBAL_SEARCH,
      data,
      filter: {
        search: custFilter.search,
      },
    };
  }

  return (dispatch, getState) => {
    dispatch(preloaderActions.show());
    const { globalSearch } = getState();

    if (typeof filters === "undefined" || !filters) {
      custFilter = globalSearch.filter;
    }

    // console.log(filters);

    // const urlParams = constructUrlParams({
    //   search: custFilter.search,
    // });
    // console.log(urlParams);

    API.getGlobalSearch(custFilter.search).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

const globalSearchActions = {
  getGlobalSearchAction,
};

export default globalSearchActions;
