import API from '../../api/API';
import { rolesConstants } from '../../_constants/dashboard.constants';
// import history from '../../_helpers/history';
import alertActions from "./alertActions";
import preloaderActions from "./preloaderActions";
import { constructUrlParams} from "../../_helpers/CommonFunctions";

const getUserRolesAction = (filters) => {
    let custFilter = filters;

    const success = (data) => {
        return {
            type: rolesConstants.GET_USER_ROLES,
            data,
            filter: {
                currentPage: custFilter.currentPage,
                search: custFilter.search
            }
        }
    };

    return (dispatch, getState) => {
        dispatch(preloaderActions.show());
        const { roles } = getState();

        if (typeof filters === 'undefined') {
            custFilter = roles.filter;
        }

        if (custFilter.currentPage > 1) {
            custFilter.currentPage = (custFilter.currentPage > roles.last_page)
                ? roles.last_page
                : custFilter.currentPage;
        }

        const urlParams = constructUrlParams({
            page: custFilter.currentPage,
            search: custFilter.search
        });

        API.getAllUserRoles(urlParams).then(
            response => {
                if (response.data) {
                    dispatch(success(response.data));
                }
                dispatch(preloaderActions.hide());
            }, error => {
                dispatch(preloaderActions.hide());
                if (error && error.response && error.response.data && error.response.data.message) {
                    dispatch(alertActions.error(error.response.data.error.message));
                }
            });
    };
};

const getSingleUserRolesAction = (id) => {
    const success = (data) => {
        return {
            type: rolesConstants.GET_SINGLE_USER_ROLES,
            data
        }
    }

    return dispatch => {
        dispatch(preloaderActions.show());
        API.getSingleUserRoles(id).then(
            response => {
                if (response.data) {
                    dispatch(success(response.data));
                }
                dispatch(preloaderActions.hide());
            }, error => {
                dispatch(preloaderActions.hide());
                if (error && error.response && error.response.data && error.response.data.message) {
                    dispatch(alertActions.error(error.response.data.error.message));
                }
            });
    };
}

const updateSingleUserRolesAction = (params, id) => {
    const success = (res) => {
        return {
            type: rolesConstants.UPDATE_SINGLE_USER_ROLES,
            data: res.data.record.roles,
        };
    };

    return dispatch => {
        dispatch(preloaderActions.show());
        API.updateSingleUserRoles(params, id).then(
            response => {
                if (response.data) {
                    dispatch(success(response.data));
                    dispatch(alertActions.success(response.data.message));
                }
                dispatch(preloaderActions.hide());
            }, error => {
                dispatch(preloaderActions.hide());
                if (error && error.response && error.response.data && error.response.data.error) {
                    dispatch(alertActions.error(error.response.data.error.message));
                }
            });
    };
}

const clearUserRolesForm = () => {
    return {
        type: rolesConstants.CLEAR_SINGLE_USER_ROLES,
    }
}

const getPermissionsAction = () => {
    const success = (data) => {
        return {
            type: rolesConstants.GET_ROLES_PERMISSIONS,
            data
        }
    };

    return dispatch => {
        dispatch(preloaderActions.show());
        API.getAllPermissions().then(
            response => {
                if (response.data) {
                    dispatch(success(response.data));
                }
                dispatch(preloaderActions.hide());
            }, error => {
                dispatch(preloaderActions.hide());
                if (error && error.response && error.response.data && error.response.data.message) {
                    dispatch(alertActions.error(error.response.data.error.message));
                }
            });
    };
}

const UpdatePermissionsAction = (params, role) => {
    const success = (currentRole, currentPerms) => {
        return {
            type: rolesConstants.UPDATE_ROLES_PERMISSIONS,
            data: { currentRole, currentPerms }
        }
    }

    return dispatch => {
        dispatch(preloaderActions.show());
        API.UpdatePermissions(params, role).then(
            response => {
                if (response.data) {
                    dispatch(success(role, JSON.parse(params)));
                    dispatch(alertActions.success(response.data.message));
                }
                dispatch(preloaderActions.hide());
            }, error => {
                dispatch(preloaderActions.hide());
                if (error && error.response && error.response.data && error.response.data.error) {
                    dispatch(alertActions.error(error.response.data.error.message));
                }
            });
    };
}

const rolesActions = {
    getUserRolesAction,
    getSingleUserRolesAction,
    updateSingleUserRolesAction,
    clearUserRolesForm,
    getPermissionsAction,
    UpdatePermissionsAction,
}

export default rolesActions;