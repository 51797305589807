import API from '../../api/API';
import userConstants from '../../_constants/user.constants';
import history from '../../_helpers/history';
import alertActions from "./alertActions";
import preloaderActions from "./preloaderActions";


function getUserAction(dispatch, shouldNavigate) {
    function success(user) {
        return {
            type: userConstants.LOGIN_SUCCESS,
            user
        }
    }
    API.getUser().then(
        userDetails => {
            dispatch(success(userDetails.data));
            localStorage.setItem('user', JSON.stringify(userDetails.data));
            if (shouldNavigate) {
                history.push('/dashboard');
            }
        }
    ).catch(
        error => {
             console.log(error);
        }
    );
    
}

function authUser(username, password) {

    function request(user) {
        return {
            type: userConstants.LOGIN_REQUEST,
            user
        }
    }
    // function failure(user) {
    //     return {
    //         type: userConstants.LOGIN_FAILURE,
    //         user
    //     }
    // }
    
    return dispatch => {
        dispatch(preloaderActions.show());
        dispatch(request({ username }));

        API.login(username, password).then(
            userToken => {

                if (userToken.data.access_token) {
                    localStorage.setItem('authToken', JSON.stringify(userToken.data));
                    getUserAction(dispatch, true);
                }

                dispatch(preloaderActions.hide());
        }, error => {
            
            if (error && error.response && error.response.data) {
                dispatch(alertActions.error(error.response.data.error.message));
            } else {
                dispatch(alertActions.error("Something Went Wrong"));
            }

            dispatch(preloaderActions.hide());
        });
    };
}

function logoutAction() {
    history.push('/');
    API.logout();
    return dispatch => {
        dispatch(alertActions.success('Logged Out Successfully'));
        dispatch({ type: userConstants.LOGOUT });
    }
}

function resetPasswordAction(email) {

    return dispatch => {
        API.resetPassword(email).then(
            response => {
                // console.log(userToken.data);
                // history.push('/dashboard');
                // console.log(userToken);
                // dispatch(success(userToken));
                // console.log(history);
                dispatch(alertActions.success(response.data.message));
            }, error => {
                // console.log(error)
                // dispatch(failure(error.response.data.error.message.toString()));
                if (error && error.response && error.response.data && error.response.data) {
                    dispatch(alertActions.error(error.response.data.message));
                } else {
                    dispatch(alertActions.error("Something Went Wrong"));
                }
            });
    }

}

function getUserEmailFromTokenAction(token) {
    function success(data) {
        return {
            type: userConstants.VERIFY_PASSWORD_RESET,
            data
        }
    }

    return dispatch => {
        dispatch(preloaderActions.show());
        API.getUserEmailFromToken(token).then(
            response => {
                if (response.data) {
                    dispatch(success(response.data));
                }
                dispatch(preloaderActions.hide());
            }, error => {
                dispatch(preloaderActions.hide());
                if (error && error.response && error.response.data && error.response.data.message) {
                    dispatch(alertActions.error(error.response.data.message));
                }
            });
    };
}
function resetPasswordWithNewPasswordAction(params) {
    function success(data) {
        return {
            type: userConstants.RESET_NEW_PASSWORD,
            data
        }
    }

    return dispatch => {
        dispatch(preloaderActions.show());
        API.resetPasswordWithNewPassword(params).then(
            response => {
                if (response.data) {
                    dispatch(success(response.data));
                    response.data.user && dispatch(alertActions.success(response.data.user ? 'Password reset successfully' : ''));
                }
                dispatch(preloaderActions.hide());
            }, error => {
                dispatch(preloaderActions.hide());
                if (error && error.response && error.response.data && error.response.data.message) {
                    dispatch(alertActions.error(error.response.data.message));
                }
            });
    };
}


const userActions = {
    authUser,
    logoutAction,
    resetPasswordAction,
    getUserEmailFromTokenAction,
    resetPasswordWithNewPasswordAction
}

export default userActions;