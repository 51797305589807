import React, { PureComponent, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import classNames from "classnames";

import { connect } from "react-redux";
import PropTypes from "prop-types";

// import NotificationSystem from 'rc-notification';
import history from "../../_helpers/history";
import Layout from "../Layout/index";
import MainWrapper from "./MainWrapper";
import alertActions from "../../redux/actions/alertActions";
import commonActions from "../../redux/actions/commonActions";

import Testing from "../Testing/index";

import LogIn from "../LogIn/index";
import ForgotPassword from "../ForgotPassword/index";
import ResetPassword from "../ResetPassword/index";

import Search from "../Search/index";
import Dashboard from "../Dashboard/index";

import HorseListing from "../Horse/Horse Listing/index";
import HorseListByTrainer from "../Horse/Horse List By Trainer/index";

import HorseLocations from "../Horse/Locations/index";
import HorseSingle from "../Horse/Horse Single/index";
import LocationSingle from "../Horse/Location Single/index";

import TrainerListing from "../Trainer/Trainer Listing/index";
import TrainerSingle from "../Trainer/Trainer Single/index";

import MemberListing from "../Member/Member Listing/index";
import MemberSingle from "../Member/Member Single/index";
import Corporate from "../Member/Corporate/index";
import CorporateSingle from "../Member/Corporate Single/index";

import ManagerListing from "../Manager/Listing/index";
import ManagerSingle from "../Manager/Single/index";

import UserRoles from "../Roles/User/index";
import RolesPermissions from "../Roles/Permissions/index";

import ServiceSingleSeasonListing from "../Service/Service Single Season Listing/index";
import ServiceSingleSeasonListingSingle from "../Service/Service Single Season Listing Single/index";
import PackageListing from "../Service/Package Listing/index";
import PackageSingle from "../Service/Package Single/index";
import ActivityListing from "../Service/Activity Listing/index";
import ActivitySingle from "../Service/Activity Single/index";
import CategoryListing from "../Service/Category Listing/index";
import CategorySingle from "../Service/Category Single/index";

import BookingListing from "../Booking/Booking Listing/index";
import BookingCancelListing from "../Booking/Booking Cancel Listing/index";
import BookingNoShowListing from "../Booking/Booking Noshow Listing/index";

import BookingCancel from "../Booking/Booking Cancel/index";

import BookingSingle from "../Booking/Booking Single/index";

import Calendar from "../Calendar/index";
import TrainerCalendar from "../TrainerCalendar/index";
import Analytics from "../Reports/Analytics/index";
import ReportsList from "../Reports/Listing/index";
import ReportsChangeLog from "../Reports/ChangeLog/List/index";
import ReportsChangeLogView from "../Reports/ChangeLog/View/index";
import ReportHorse from "../Reports/Horse/Report/index";
import ReportHorseOwner from "../Reports/Horse/Owner/index";
import ReportMembership from "../Reports/Membership/index";
import ReportCorporate from "../Reports/Corporates/index";
import ReportSalesByService from "../Reports/Sales/Service/index";
import ReportSalesByActivity from "../Reports/Sales/Activity/index";
import ReportSalesByPackage from "../Reports/Sales/Package/index";
import ReportSalesReport from "../Reports/Sales/Report/index";
import ReportLiveryReport from "../Reports/Sales/Livery/index";

import ReportSalesByTrainer from "../Reports/Sales/Trainer/index";

import ReportView from "../Reports/View/index";
import PrivateRoute from "../../shared/components/PrivateRoute";

import { showNotification } from "../../_helpers/CommonFunctions";
import {
  BasicNotification,
  ImageNotification,
} from "../../shared/components/Notification";

const HorseRoutes = () => (
  <Switch>
    <PrivateRoute
      path="/horse/listing"
      component={HorseListing}
      permission="view-horses"
    />
    <PrivateRoute
      path="/horse/list-by-trainer/:id?"
      component={HorseListByTrainer}
      permission="view-horses"
    />
    {/* <Route path='/horse/listing-owner' component={HorseListingOwner} /> */}
    <PrivateRoute
      path="/horse/locations/:action/:id?"
      component={LocationSingle}
      permission="edit-horses"
    />
    <PrivateRoute
      path="/horse/locations"
      component={HorseLocations}
      permission="view-horses"
    />
    <PrivateRoute
      path="/horse/add"
      component={HorseSingle}
      permission="add-horses"
    />
    <PrivateRoute
      path="/horse/:action/:id?"
      component={HorseSingle}
      permission="edit-horses"
    />

    {/* <Route path='/horse/add' component={AddHorse} /> */}
    {/* <Route path='/horse/edit' component={EditHorse} /> */}
    {/* <Route path='/horse/add-location' component={AddHorseLocation} /> */}
    {/* <Route path='/horse/edit-location' component={EditHorseLocation} /> */}
  </Switch>
);
const TrainerRoutes = () => (
  <Switch>
    <PrivateRoute
      path="/trainer/listing"
      component={TrainerListing}
      permission="view-trainers"
    />
    <PrivateRoute
      path="/trainer/:action/:id?"
      component={TrainerSingle}
      permission="edit-trainers"
    />
  </Switch>
);
const MemberRoutes = () => (
  <Switch>
    <PrivateRoute
      path="/member/listing"
      component={MemberListing}
      permission="view-members"
    />
    <PrivateRoute
      path="/member/corporate/:action/:id?"
      component={CorporateSingle}
      permission="edit-members"
    />
    <PrivateRoute
      path="/member/corporate"
      component={Corporate}
      permission="view-members"
    />
    <PrivateRoute
      path="/member/:action/:id?"
      component={MemberSingle}
      permission="edit-members"
    />
  </Switch>
);
const ManagerRoutes = () => (
  <Switch>
    <PrivateRoute
      path="/manager/listing"
      component={ManagerListing}
      permission="view-managers"
    />
    <PrivateRoute
      path="/manager/:action/:id?"
      component={ManagerSingle}
      permission="edit-managers"
    />
  </Switch>
);
const RolesRoutes = () => (
  <Switch>
    <Route path="/roles/users" component={UserRoles} />
    <Route path="/roles/edit/:id" component={UserRoles} />
    <Route path="/roles/permissions" component={RolesPermissions} />
  </Switch>
);
const ServiceRoutes = () => (
  <Switch>
    <PrivateRoute
      path="/services/single/:action/:id?"
      component={ServiceSingleSeasonListingSingle}
      permission="edit-services"
    />
    <PrivateRoute
      path="/services/single"
      component={ServiceSingleSeasonListing}
      permission="view-services"
    />
    <PrivateRoute
      path="/services/packages/:action/:id?"
      component={PackageSingle}
      permission="edit-services"
    />
    <PrivateRoute
      path="/services/packages"
      component={PackageListing}
      permission="view-services"
    />

    <PrivateRoute
      path="/services/activity/:action/:id?"
      component={ActivitySingle}
      permission="edit-services"
    />
    <PrivateRoute
      path="/services/activity"
      component={ActivityListing}
      permission="view-services"
    />

    <PrivateRoute
      path="/services/categories/:action/:id?"
      component={CategorySingle}
      permission="edit-services"
    />
    <PrivateRoute
      path="/services/categories"
      component={CategoryListing}
      permission="view-services"
    />
  </Switch>
);
const BookingRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path="/booking/listing"
        component={BookingListing}
        permission="view-bookings"
      />
      <PrivateRoute
        path="/booking/cancelled-listing"
        component={BookingCancelListing}
        permission={"view-bookings"}
      />
      <PrivateRoute
        path="/booking/noshow-listing"
        component={BookingNoShowListing}
        permission={"view-bookings"}
      />
      <PrivateRoute path="/booking/cancel/:id?" component={BookingCancel} />

      <PrivateRoute
        path="/booking/:action/:id?"
        component={BookingSingle}
        permission="edit-bookings"
      />
      
    </Switch>
  );
};

const CalendarRoutes = () => (
  <Switch>
    <Route path="/calendar" component={Calendar} />
    <Route path="/trainer-calendar" component={TrainerCalendar} />
  </Switch>
);
const ReportRoutes = () => (
  <Switch>
    <Route path="/reports/analytics" component={Analytics} />
    <Route path="/reports/list" component={ReportsList} />
    <Route path="/reports/change-log" component={ReportsChangeLog} />
    <Route
      path="/reports/change-log-view/:id?"
      component={ReportsChangeLogView}
    />
  </Switch>
);
const ReportRoutesSub = () => (
  <Switch>
    <Route path="/report/horse" component={ReportHorse} />
    <Route path="/report/horse-by-owners" component={ReportHorseOwner} />
    <Route path="/report/membership" component={ReportMembership} />
    <Route path="/report/sales-by-service" component={ReportSalesByService} />
    <Route path="/report/sales-report" component={ReportSalesReport} />
    <Route path="/report/sales-by-livery" component={ReportLiveryReport} />
    <Route path="/report/sales-by-activity" component={ReportSalesByActivity} />
    <Route path="/report/sales-by-package" component={ReportSalesByPackage} />
    <Route path="/report/sales-by-trainer" component={ReportSalesByTrainer} />
    <Route path="/report/corporates" component={ReportCorporate} />
  </Switch>
);
const wrappedRoutes = () => {
  return (
    <div>
      <Layout />
      <div className="container__wrap">
        <Switch>
          <PrivateRoute
            path="/dashboard"
            component={Dashboard}
            permission="manage-dashboard"
          />
          <PrivateRoute
            path="/horse"
            component={HorseRoutes}
            permissionGroup="horses"
          />
          <PrivateRoute
            path="/trainer"
            component={TrainerRoutes}
            permissionGroup="trainers"
          />
          <PrivateRoute
            path="/member"
            component={MemberRoutes}
            permissionGroup="members"
          />
          <PrivateRoute
            path="/manager"
            component={ManagerRoutes}
            permissionGroup="managers"
          />
          <PrivateRoute
            path="/roles"
            component={RolesRoutes}
            permission="manage-roles"
          />
          <PrivateRoute
            path="/services"
            component={ServiceRoutes}
            permissionGroup="services"
          />
          <PrivateRoute
            path="/booking"
            component={BookingRoutes}
            permissionGroup="bookings"
          />
          <PrivateRoute
            path="/calendar"
            component={CalendarRoutes}
            permission="view-bookings"
          />
          <PrivateRoute
            path="/trainer-calendar"
            component={TrainerCalendar}
            permission="view-bookings"
          />
          <PrivateRoute
            path="/reports"
            component={ReportRoutes}
            permission="manage-reports"
          />
          <PrivateRoute
            path="/report"
            component={ReportRoutesSub}
            permission="manage-reports"
          />
          <PrivateRoute
            path="/report/:report"
            render={(props) => <ReportView {...props} />}
            permission="manage-reports"
          />
          <Route render={() => <Redirect to="/dashboard" />} />
        </Switch>
      </div>
    </div>
  );
};

wrappedRoutes.propTypes = {
  preloader: PropTypes.shape({
    isActive: PropTypes.bool,
  }),
};

wrappedRoutes.defaultProps = {
  preloader: {
    isActive: false,
  },
};

class CustomRouter extends PureComponent {
  static propTypes = {
    getFormData: PropTypes.func.isRequired,
    // getCategories: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    alert: PropTypes.shape({
      message: PropTypes.string,
      type: PropTypes.string,
    }),
    preloader: PropTypes.shape({
      isActive: PropTypes.bool,
    }),
    sidebar: PropTypes.shape({
      show: PropTypes.bool.isRequired,
      collapse: PropTypes.bool.isRequired,
      exclude: PropTypes.bool.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    alert: {
      message: "",
      type: "",
    },
    preloader: {
      isActive: false,
    },
  };

  constructor(props) {
    super(props);
    history.listen(() => {
      props.clearAlerts();
    });

    props.getFormData();
    // props.getCategories('horse');
  }

  getNotificationType = (title, message, color, image) => {
    let notification = {
      notificationContent: (
        <BasicNotification title={title} message={message} color={color} />
      ),
      position: "right-up",
    };

    if (image) {
      notification = {
        notificationContent: (
          <ImageNotification title={title} message={message} img={image} />
        ),
        position: "right-up",
      };
    }

    return showNotification(notification);
  };

  render() {
    const { alert, preloader, sidebar } = this.props;

    const layoutClass = classNames({
      "sidebar-hidden": sidebar.exclude,
      "sidebar-open": !sidebar.collapse,
    });

    return (
      <MainWrapper>
        <main className={layoutClass}>
          {alert.message &&
            this.getNotificationType(alert.title, alert.message, alert.color)}

          {preloader.isActive && (
            <svg className="load__icon">
              <path
                fill="#4ce1b6"
                d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
              />
            </svg>
          )}
          <Switch>
            <Route path="/testing" component={Testing} />

            <Route path="/login" component={LogIn} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password/:token?" component={ResetPassword} />
            <PrivateRoute path="/search" component={Search} />
            <PrivateRoute
              path="/"
              component={wrappedRoutes}
              data={{ preloader }}
            />
            <PrivateRoute path="/" exact component={Dashboard} />
          </Switch>
        </main>
      </MainWrapper>
    );
  }
}

function mapState(state) {
  const { alert, preloader, sidebar } = state;

  return {
    alert,
    preloader,
    sidebar,
  };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  getFormData: commonActions.getFormDataAction,

  // getCategories: commonActions.getCategoriesAction
};

export default connect(mapState, actionCreators)(CustomRouter);
