/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Permission from "../../../Permission/components/Permission";
import memberActions from "../../../../redux/actions/memberActions";
import ServerSideDataTable from "../../../../shared/components/table/ServerSideDataTable";

class DataTable extends PureComponent {
  static propTypes = {
    getCorporateList: PropTypes.func.isRequired,
    corporate: PropTypes.shape({
      totalResults: PropTypes.number,
      filter: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        search: PropTypes.string,
      }),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          company_name: PropTypes.string.isRequired,
          register_no: PropTypes.string.isRequired,
          domain: PropTypes.string.isRequired,
        })
      ),
    }),
  };

  static defaultProps = {
    corporate: {
      search: "",
      totalResults: "",
    },
  };

  constructor(props) {
    super(props);
    // props.setDefaults();
    props.getCorporateList();
  }

  // componentDidMount() {
  //   console.log('componentDidMount');
  // }

  handleTableChange = (type, { searchText, page }) => {
    const { props } = this;
    props.getCorporateList({ currentPage: page, search: searchText });
  };

  customActions = (cell, row, rowIndex, custom) => {
    return (
      <ButtonToolbar className="table-actions">
        <Permission id="edit-members">
          <Button
            tag={Link}
            to={`corporate/edit/${row.id}`}
            color="primary"
            size="sm"
            className="rounded"
          >
            Edit
          </Button>
        </Permission>
        <Permission id="delete-members">
          <Button
            color="danger"
            size="sm"
            className="rounded"
            onClick={() => {
              if (
                window.confirm("Are you sure you wish to delete this item?")
              ) {
                custom(row.id);
              }
            }}
          >
            Delete
          </Button>
        </Permission>
      </ButtonToolbar>
    );
  };

  render() {
    const { props } = this;
    const { permissions, corporate } = props;
    const hasActionPerms =
      permissions.includes("edit-members") ||
      permissions.includes("delete-members");

    const columns = [
      {
        dataField: "id",
        text: "ID",
        hidden: true,
      },
      {
        dataField: "company_name",
        text: "Company Name",
      },
      {
        dataField: "register_no",
        text: "Register No",
      },
      {
        dataField: "domain",
        text: "Domain",
      },
      {
        dataField: "actions",
        text: "Actions",
        formatter: this.customActions,
        formatExtraData: props.deleteCorporate,
        hidden: !hasActionPerms,
      },
    ];

    return (
      <Col md={12} lg={12}>
        <Card>
          {corporate.list && (
            <CardBody>
              <ServerSideDataTable
                data={corporate.list}
                columns={columns}
                onTableChange={this.handleTableChange}
                page={corporate.filter.currentPage}
                sizePerPage={props.sizePerPage}
                totalSize={corporate.totalResults}
                searchText={corporate.filter.search}
              />
            </CardBody>
          )}
        </Card>
      </Col>
    );
  }
}

function mapState(state) {
  const authData = state.authentication;
  const permissions =
    typeof authData.user !== "undefined" &&
    Array.isArray(authData.user.permissions)
      ? authData.user.permissions
      : [];

  return {
    permissions,
    sizePerPage: state.member.sizePerPage,
    corporate: state.member.corporate,
  };
}

const actionCreators = {
  getCorporateList: memberActions.getCorporateListAction,
  deleteCorporate: memberActions.deleteCorporateAction,
  // setDefaults: memberActions.setCorporateDefaultsAction
};

export default connect(
  mapState,
  actionCreators
)(DataTable);
