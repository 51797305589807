/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Permission from "../../../Permission/components/Permission";
import serviceActions from "../../../../redux/actions/serviceActions";
import ServerSideDataTable from "../../../../shared/components/table/ServerSideDataTable";

class DataTable extends PureComponent {
  static propTypes = {
    getServiceList: PropTypes.func.isRequired,
    activity: PropTypes.shape({
      totalResults: PropTypes.number,
      filter: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        search: PropTypes.string,
      }),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          // company_name: PropTypes.string.isRequired,
          // register_no: PropTypes.string.isRequired,
          // domain: PropTypes.string.isRequired,
        })
      ),
    }),
  };

  static defaultProps = {
    activity: {
      search: "",
      totalResults: "",
      list: [],
    },
  };

  constructor(props) {
    super(props);
    // props.setDefaults();
    props.getServiceList("activity");
  }

  // componentDidMount() {
  //   console.log('componentDidMount');
  // }

  handleTableChange = (type, { searchText, page }) => {
    const { props } = this;
    props.getServiceList("activity", { currentPage: page, search: searchText });
  };

  customActions = (cell, row, rowIndex, custom) => {
    return (
      <ButtonToolbar className="table-actions">
        <Permission id="edit-services">
          <Button
            tag={Link}
            to={`activity/edit/${row.id}`}
            color="primary"
            size="sm"
            className="rounded"
          >
            Edit
          </Button>
        </Permission>
        <Permission id="delete-services">
          <Button
            color="danger"
            size="sm"
            className="rounded"
            onClick={() => {
              if (
                window.confirm("Are you sure you wish to delete this item?")
              ) {
                custom("activity", row.id);
              }
            }}
          >
            Delete
          </Button>
        </Permission>
      </ButtonToolbar>
    );
  };

  render() {
    const { props } = this;
    const { permissions, activity } = props;
    const hasActionPerms =
      permissions.includes("edit-services") ||
      permissions.includes("delete-services");

    const columns = [
      {
        dataField: "id",
        text: "ID",
        hidden: true,
      },
      {
        dataField: "name",
        text: "Name",
      },
      {
        dataField: "duration",
        text: "Duration",
      },
      {
        dataField: "price",
        text: "Price",
      },
      {
        dataField: "actions",
        text: "Actions",
        formatter: this.customActions,
        formatExtraData: props.deleteService,
        hidden: !hasActionPerms,
      },
    ];

    return (
      <Col md={12} lg={12}>
        <Card>
          {activity.list && (
            <CardBody>
              <ServerSideDataTable
                data={activity.list}
                columns={columns}
                onTableChange={this.handleTableChange}
                page={activity.filter.currentPage}
                sizePerPage={activity.sizePerPage}
                totalSize={activity.totalResults}
                searchText={activity.filter.search}
              />
            </CardBody>
          )}
        </Card>
      </Col>
    );
  }
}

function mapState(state) {
  const authData = state.authentication;
  const permissions =
    typeof authData.user !== "undefined" &&
    Array.isArray(authData.user.permissions)
      ? authData.user.permissions
      : [];

  return {
    permissions,
    activity: state.service.activity,
  };
}

const actionCreators = {
  getServiceList: serviceActions.getServiceListAction,
  deleteService: serviceActions.deleteServiceAction,
  // setDefaults: memberActions.setCorporateDefaultsAction
};

export default connect(
  mapState,
  actionCreators
)(DataTable);
