import React from "react";
import {
	Col,
	Container,
	Row,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown
} from "reactstrap";
import { Link } from "react-router-dom";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import renderDatePickerField from "../../../shared/components/form/DatePicker";
import ActionsTable from "./components/ActionsTable";

const ReportView = () => (
	<Container className='dashboard'>
		<Row>
			<Col md={12}>
				<Link to='/reports/list'>
					<span className='lnr lnr-chevron-left' /> Reports
				</Link>
				<h3 className='page-title'>Reports</h3>
				<Row className='page-head'>
					<Col md={4}>
						<div className='form'>
							<div className='form__form-group'>
								<div className='form__form-group-field'>
									<Field
										name='s_date'
										component={renderDatePickerField}
										placeholder='From'
										selectsStart
									/>
									<div className='form__form-group-icon'>
										<CalendarBlankIcon />
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col md={4}>
						<div className='form'>
							<div className='form__form-group'>
								<div className='form__form-group-field'>
									<Field
										name='e_date'
										component={renderDatePickerField}
										placeholder='To'
										selectsEnd
									/>
									<div className='form__form-group-icon'>
										<CalendarBlankIcon />
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col md={4}>
						<UncontrolledDropdown className='cs-dropdown'>
							<DropdownToggle className='icon icon--right' outline>
								<p>
									Export as <ChevronDownIcon />
								</p>
							</DropdownToggle>
							<DropdownMenu className='dropdown__menu'>
								<DropdownItem>Excel</DropdownItem>
								<DropdownItem>CSV</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
					</Col>
				</Row>
			</Col>
		</Row>
		<Row>
			<ActionsTable />
		</Row>
	</Container>
);

export default reduxForm({
	form: "filter_form" // a unique identifier for this form
})(withTranslation("common")(ReportView));
