import React from 'react';
import {
  Container, Row
} from 'reactstrap';

import DataTable from './components/DataTable';
import ServiceCommonHeader from '../common/ServiceCommonHeader';

const PackageListing = props => (
  <Container className="dashboard">
    <ServiceCommonHeader title="Packages" />
    <Row>
      <DataTable {...props} />
    </Row>
  </Container>
);

export default PackageListing;
