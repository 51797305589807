import userConstants from "../../_constants/user.constants";

const authToken = localStorage.getItem('authToken');
const userData = JSON.parse(localStorage.getItem("user"));
const initialState = authToken ? { loggedIn: true, authToken, user: userData } : {};

function authenticationReducer( state = initialState, action) {
    switch(action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                logginIn: true,
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            }
        case userConstants.LOGIN_FAILURE:
            return {}
        case userConstants.LOGOUT:
            return {}
        case userConstants.VERIFY_PASSWORD_RESET:
            return {
                ...state,
                verifyData: action.data,
            }  
        case userConstants.RESET_NEW_PASSWORD:
            return {
                ...state,
                resetData: action.data,
            }      
            default:
                return state;
        
    }
}

export default authenticationReducer;