import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Permission = ({id, authData, children}) => {
    const { user: { permissions } } = authData;
    let hasPermission = false;
    if (typeof id !== 'undefined') {
        if (id.indexOf('|') !== -1) {
            let optionalPerms = id.split('|');
            optionalPerms = optionalPerms.filter((perm) => permissions.includes(perm));
            hasPermission = optionalPerms.length > 0;
        } else {
            hasPermission = permissions.includes(id);
        }
    }
    
    if (!hasPermission) {
        return null;
    }
    return (
        <>
            {children}
        </>
    );
};

Permission.propTypes = {
    id: PropTypes.string.isRequired,
    authData: PropTypes.objectOf(PropTypes.any),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element.isRequired
    ]),
};

Permission.defaultProps = {
    authData: {
        user: {
            permissions: [],
        },
    },
    children: null,
};

const mapStateToProps = (state) => ({ authData: state.authentication });

export default connect(mapStateToProps)(Permission);