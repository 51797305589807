import React, { PureComponent } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  ButtonToolbar,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { withTranslation } from 'react-i18next';
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import renderSelectField from "../../../../shared/components/form/Select";
import renderSelectAsyncField from "../../../../shared/components/form/AsyncSelect";
import { genderOptions } from "../../../../_constants/common.constants";
import renderDatePickerField from "../../../../shared/components/form/DatePicker";
import managerActions from "../../../../redux/actions/manager.actions";

import { Link } from "react-router-dom";

const renderField = ({ input, placeholder, type }) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: "",
  meta: null,
  type: "text",
};

class VerticalForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    id: PropTypes.string,
    shouldDisable: PropTypes.bool,
  };

  static defaultProps = {
    id: null,
    shouldDisable: false,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
      activeTab: "1",
    };
  }

  componentDidMount() {
    const { props } = this;
    if (props.id) {
      props.getManager(props.id);
    }
  }

  componentWillUnmount() {
    const { props } = this;
    props.clearManagerForm();
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  getValues = (values) => {
    const { props } = this;
    const data = values;
    if (data) {
      data.gender = values && values.gender ? values.gender.value : null;
      data.country_id = values && values.country ? values.country.value : null;
    }
    props.addUpdateManager(JSON.stringify(data), props.id);
  };

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      id,
      shouldDisable,
    } = this.props;

    const { activeTab } = this.state;

    return (
      <Col md={12} lg={12} xl={12}>
        <form className="form" onSubmit={handleSubmit(this.getValues)}>
          <div className="formInner">
            <Card>
              <CardBody>
                <div className="tabs">
                  <div className="tabs__wrap">
                    <Nav tabs className="cs-form-tabs">
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => {
                            this.toggle("1");
                          }}
                        >
                          Basic Information
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => {
                            this.toggle("2");
                          }}
                        >
                          Additional Information
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <div className="form__field-group">
                          <Row>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Name
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="name"
                                    component={renderField}
                                    type="text"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Email
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="email"
                                    component={renderField}
                                    type="email"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Mobile
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="mobile"
                                    component={renderField}
                                    type="tel"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Password
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="password"
                                    component={renderField}
                                    type="password"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Confirm Password
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="password_confirmation"
                                    component={renderField}
                                    type="password"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </TabPane>
                      <TabPane tabId="2">
                        <div className="form__field-group">
                          <Row>
                            <Col md={12}>
                              <div className="card__title">
                                <h5 className="bold-text">
                                  Personal Information
                                </h5>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Gender
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="gender"
                                    component={renderSelectField}
                                    placeholder="Select Gender"
                                    type="text"
                                    options={genderOptions}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Date of Birth
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="date_of_birth"
                                    component={renderDatePickerField}
                                  />
                                  <div className="form__form-group-icon">
                                    <CalendarBlankIcon />
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <div className="card__title card__seperation">
                                <h5 className="bold-text">
                                  Contact Information
                                </h5>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Street 1
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="address_line1"
                                    component={renderField}
                                    type="text"
                                    placeholder="Street 1"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Street 2
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="address_line2"
                                    component={renderField}
                                    type="text"
                                    placeholder="Street 2"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Post Code
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="post_code"
                                    component={renderField}
                                    type="text"
                                    placeholder="Post Code"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Country
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="country"
                                    component={renderSelectAsyncField}
                                    api="dropdown/countries"
                                    placeholder="Select Country"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
                {activeTab !== "3" && (
                  <div className="form__field-group">
                    <ButtonToolbar className="form__button-toolbar">
                      <Button
                        className="rounded"
                        type="submit"
                        color="primary"
                        disabled={pristine || submitting || shouldDisable}
                      >
                        {id ? "Update" : "Add"}
                      </Button>
                      {/* {id ? (
                        <Button
                          className="rounded"
                          type="button"
                          onClick={reset}
                          disabled={pristine || submitting || shouldDisable}
                        >
                          Cancel
                        </Button>
                      ) : ( */}
                      <Button
                        className="rounded"
                        tag={Link}
                        outline
                        // color="primary"
                        to="/manager/listing"
                      >
                        Cancel
                      </Button>
                      {/* )} */}
                    </ButtonToolbar>
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </form>
      </Col>
    );
  }
}

const mapState = (state) => {
  if (!state.manager.addFormData.data) {
    return {};
  }

  const formData = state.manager.addFormData.data;

  return {
    initialValues: {
      name: formData.name ? formData.name : "",
      email: formData.email ? formData.email : "",
      mobile: formData.mobile ? formData.mobile : "",
      password: formData.password ? formData.password : "",
      password_confirmation: formData.password_confirmation
        ? formData.password_confirmation
        : "",
      gender: formData.genderdata ? formData.genderdata : null,
      date_of_birth: formData.date_of_birth
        ? new Date(formData.date_of_birth)
        : "",
      address_line1: formData.address_line1 ? formData.address_line1 : "",
      address_line2: formData.address_line2 ? formData.address_line2 : "",
      post_code: formData.post_code ? formData.post_code : "",
      country: formData.country ? formData.country : null,
    },
    shouldDisable: state.preloader.shouldDisable,
  };
};

const actionCreators = {
  getManager: managerActions.getManagerAction,
  addUpdateManager: managerActions.addUpdateManagerAction,
  clearManagerForm: managerActions.clearManagerForm,
};

const createReduxForm = reduxForm({ form: "manager_add_edit" });

export default connect(mapState, actionCreators)(createReduxForm(VerticalForm));
