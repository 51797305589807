/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Permission from "../../../Permission/components/Permission";
import serviceActions from "../../../../redux/actions/serviceActions";
import ServerSideDataTable from "../../../../shared/components/table/ServerSideDataTable";

class DataTable extends PureComponent {
  static propTypes = {
    getCategoryList: PropTypes.func.isRequired,
    categories: PropTypes.shape({
      totalResults: PropTypes.number,
      filter: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        search: PropTypes.string,
      }),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          // company_name: PropTypes.string.isRequired,
          // register_no: PropTypes.string.isRequired,
          // domain: PropTypes.string.isRequired,
        })
      ),
    }),
  };

  static defaultProps = {
    categories: {
      search: "",
      totalResults: "",
    },
  };

  // static propTypes = {
  //   // t: PropTypes.func.isRequired,
  //   getMemberList: PropTypes.func.isRequired,
  //   member: PropTypes.shape({
  //     totalResults: PropTypes.number,
  //     filter: PropTypes.shape({
  //       currentPage: PropTypes.number.isRequired,
  //       search: PropTypes.string,
  //     }),
  //     list: PropTypes.arrayOf(PropTypes.shape({
  //       id: PropTypes.number.isRequired,
  //       // company_name: PropTypes.string.isRequired,
  //       // register_no: PropTypes.string.isRequired,
  //       // domain: PropTypes.string.isRequired,
  //     }))
  //   }),
  // };

  // static defaultProps = {
  //   member: {
  //     filter: {
  //       search: '',
  //     },
  //     totalResults: null,
  //   },
  // };

  constructor(props) {
    super(props);
    // props.setDefaults();
    props.getCategoryList();
  }

  // componentDidMount() {
  //   console.log('componentDidMount');
  // }

  handleTableChange = (type, { searchText, page }) => {
    const { props } = this;
    // console.log(type, { search: searchText, currentPage: page })
    props.getCategoryList({ search: searchText, currentPage: page });
  };

  customActions = (cell, row, rowIndex, custom) => {
    return (
      <ButtonToolbar className="table-actions">
        <Permission id="edit-services">
          <Button
            tag={Link}
            to={`categories/edit/${row.id}`}
            color="primary"
            size="sm"
            className="rounded"
          >
            Edit
          </Button>
        </Permission>
        <Permission id="delete-services">
          <Button
            color="danger"
            size="sm"
            className="rounded"
            onClick={() => {
              if (
                window.confirm("Are you sure you wish to delete this item?")
              ) {
                custom(row.id);
              }
            }}
          >
            Delete
          </Button>
        </Permission>
      </ButtonToolbar>
    );
  };

  render() {
    const { props } = this;
    const { permissions, categories } = props;
    const hasActionPerms =
      permissions.includes("edit-services") ||
      permissions.includes("delete-services");

    // console.log('categories', categories);

    const columns = [
      {
        dataField: "id",
        text: "ID",
        hidden: true,
      },
      {
        dataField: "category",
        text: "Name",
      },
      {
        dataField: "actions",
        text: "Actions",
        formatter: this.customActions,
        formatExtraData: props.deleteCategory,
        hidden: !hasActionPerms,
      },
    ];

    return (
      <Col md={12} lg={12}>
        <Card>
          {categories.list && (
            <CardBody>
              <ServerSideDataTable
                data={categories.list}
                columns={columns}
                onTableChange={this.handleTableChange}
                page={categories.filter.currentPage}
                sizePerPage={categories.sizePerPage}
                totalSize={categories.totalResults}
                searchText={categories.filter.search}
              />
            </CardBody>
          )}
        </Card>
      </Col>
    );
  }
}

function mapState(state) {
  const authData = state.authentication;
  const permissions =
    typeof authData.user !== "undefined" &&
    Array.isArray(authData.user.permissions)
      ? authData.user.permissions
      : [];

  return {
    permissions,
    categories: state.service.categories,
  };
}

const actionCreators = {
  getCategoryList: serviceActions.getCategoryListAction,
  deleteCategory: serviceActions.deleteCategoryAction,
  // setDefaults: memberActions.setCorporateDefaultsAction
};

export default connect(
  mapState,
  actionCreators
)(DataTable);
