import React, { PureComponent } from "react";
import { Card, CardBody, Col, Row, Button, ButtonToolbar } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import RenderMultiCheckBoxField from "../../../../shared/components/form/MultiCheckBox";
import rolesActions from "../../../../redux/actions/roles.actions";

const InputField = ({ placeholder, type, value, disabled }) => (
  <div className="form__form-group-input-wrap">
    <input
      placeholder={placeholder}
      type={type}
      value={value}
      disabled={disabled}
    />
  </div>
);

InputField.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

InputField.defaultProps = {
  placeholder: "",
  type: "text",
  value: "",
  disabled: false,
};

class VerticalForm extends PureComponent {
  static propTypes = {
    formDefaults: PropTypes.objectOf(PropTypes.any),
    formData: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    formDefaults: {},
    formData: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      currentRoles: [],
    };
  }

  componentDidMount() {
    const { props } = this;
    props.getUserRoles(props.id);
  }

  componentWillUnmount() {
    const { props } = this;
    props.clearUserRoles();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { props } = this;
    const { currentRoles } = this.state;
    const rolesData = {
      roles: currentRoles,
    };
    props.UpdateUserRoles(JSON.stringify(rolesData), props.id);
  };

  onRoleChange = (e) => {
    const { formData } = this.props;
    const { currentRoles } = this.state;

    // Duplicate the state data and assign the old roles.
    let rolesData = [...currentRoles];
    if (currentRoles.length === 0) {
      rolesData = formData.roles;
    }

    const currentSet = new Set(rolesData);
    if (e.target.checked) {
      currentSet.add(e.target.value);
    } else {
      currentSet.delete(e.target.value);
    }

    this.setState({
      currentRoles: [...currentSet],
    });
  };

  resetForm = () => {
    this.setState({
      currentRoles: [],
    });
  };

  render() {
    const { formDefaults, formData } = this.props;
    const { currentRoles } = this.state;
    const roles =
      typeof formDefaults.roles !== "undefined" ? formDefaults.roles : [];

    let rolesData = [...currentRoles];
    if (
      currentRoles.length === 0 &&
      typeof formData.roles !== "undefined" &&
      formData.roles.length > 0
    ) {
      rolesData = formData.roles;
    }

    return (
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody>
            <form className="form" onSubmit={this.handleSubmit}>
              <div className="formInner px-4 py-3 mx-3 my-2">
                <Row>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Name</span>
                      <div className="form__form-group-field">
                        <InputField
                          name="name"
                          type="text"
                          value={
                            typeof formData.name !== "undefined"
                              ? formData.name
                              : ""
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Email</span>
                      <div className="form__form-group-field">
                        <InputField
                          name="email"
                          type="email"
                          value={
                            typeof formData.email !== "undefined"
                              ? formData.email
                              : ""
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Roles</span>
                    </div>
                    {roles.map((role) => {
                      return (
                        <div className="form__form-group" key={role.value}>
                          <div className="form__form-group-field">
                            <RenderMultiCheckBoxField
                              input={{
                                name: "roles[]",
                                value: role.value,
                                checked: rolesData.includes(role.value),
                                onChange: this.onRoleChange,
                              }}
                              label={role.label}
                              className="colored-click"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Col>
                </Row>
                <ButtonToolbar className="form__button-toolbar">
                  <Button
                    className="rounded"
                    type="submit"
                    color="primary"
                    disabled={currentRoles.length === 0}
                  >
                    Submit
                  </Button>
                  <Button
                    className="rounded"
                    type="button"
                    onClick={this.resetForm}
                    disabled={currentRoles.length === 0}
                  >
                    Cancel
                  </Button>
                </ButtonToolbar>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  formDefaults: state.common.formDefaults,
  formData: state.roles.userFormdata.data,
});

const actionCreators = {
  getUserRoles: rolesActions.getSingleUserRolesAction,
  UpdateUserRoles: rolesActions.updateSingleUserRolesAction,
  clearUserRoles: rolesActions.clearUserRolesForm,
};

export default connect(
  mapStateToProps,
  actionCreators
)(VerticalForm);
