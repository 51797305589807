import { managerConstants } from "../../_constants/dashboard.constants";

const initialState = {
  list: [],
  sizePerPage: 10,
  totalResults: null,
  filter: {
    currentPage: 1,
    search: "",
  },
  addFormData: {},
};

const managerReducer = (state = initialState, action) => {
  switch (action.type) {
    case managerConstants.MANAGER_LIST:
      return {
        ...state,
        list: action.data.data,
        filter: {
          search: action.filter.search ? action.filter.search : "",
          currentPage: action.filter.currentPage
            ? action.filter.currentPage
            : 1,
        },
        totalResults: action.data.meta.total,
        last_page: action.data.meta.last_page,
      };
    case managerConstants.MANAGER_ADD:
      return {
        ...state,
      };
    case managerConstants.MANAGER_UPDATE:
      return {
        ...state,
        addFormData: action.data,
      };
    case managerConstants.MANAGER_GET:
      return {
        ...state,
        addFormData: action.data,
      };
    case managerConstants.MANAGER_GET_CLEAR:
      return {
        ...state,
        ...state.location,
        addFormData: {},
      };
    case managerConstants.MANAGER_DELETE:
      return {
        ...state,
        managerDeleteData: action.data,
      };
    default:
      return state;
  }
};

export default managerReducer;
