/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import Calendar from "./Calendar";
import CalendarHelper from "../../../_helpers/CalendarHelper";
import calendarActions from "../../../redux/actions/calendarActions";

class BigCalendar extends PureComponent {
  static propTypes = {
    getCalendarEvents: PropTypes.func.isRequired,
    getCalendarEventsByTime: PropTypes.func.isRequired,

    booking: PropTypes.shape({
      totalResults: PropTypes.number,
      filter: PropTypes.shape({
        currentPage: PropTypes.number,
        search: PropTypes.string,
      }),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
        })
      ),
    }),
  };

  static defaultProps = {
    booking: {
      filter: {
        search: "",
      },
      totalResults: 0,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      date: null,
    };
    const filter = {
      start: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      end: moment()
        .endOf("month")
        .format("YYYY-MM-DD"),
      format: props.calendartime,
    };
    props.getCalendarEventsByTime(filter);
    this.helper = new CalendarHelper();
  }

  onNavigate = (date) => {
    const { props } = this;
    this.setState({
      date: date,
    });
    const filter = {
      start: moment(date)
        .startOf("month")
        .format("YYYY-MM-DD"),
      end: moment(date)
        .endOf("month")
        .format("YYYY-MM-DD"),
      format: props.calendartime,
    };
    props.getCalendarEventsByTime(filter);
  };
  onChange = () => {
    const { props } = this;
    const filter = {
      start: moment(this.state.date ? this.state.date : new Date())
        .startOf("month")
        .format("YYYY-MM-DD"),
      end: moment(this.state.date ? this.state.date : new Date())
        .endOf("month")
        .format("YYYY-MM-DD"),
      format: props.calendartime,
    };
    props.getCalendarEventsByTime(filter);
  };

  componentDidUpdate(prevProps) {
    if (this.props.calendartime !== prevProps.calendartime) {
      this.onChange();
    }
  }

  render() {
    const { props } = this;
    const { calendar } = props;
    const handleRecurring = (events) => {
      this.helper.setEvents(events);
      const newEvents = this.helper.getEvents();
      return newEvents;
    };
    return (
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody>
            <Calendar
              events={handleRecurring(calendar.events)}
              onChange={this.onChange}
              onNavigate={this.onNavigate}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

function mapState(state) {
  return {
    calendar: state.calendar,
  };
}

const actionCreators = {
  getCalendarEvents: calendarActions.getCalendarEventsAction,
  getCalendarEventsByTime: calendarActions.getCalendarEventsByTimeAction,
};

export default connect(mapState, actionCreators)(BigCalendar);
