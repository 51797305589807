import React, { PureComponent } from "react";
// import AsyncSelect from 'react-select';
import PropTypes from "prop-types";
// import Async, { makeAsyncSelect } from 'react-select/async';
import AsyncSelect from "react-select/lib/Async";

import API from "../../../api/API";
import GlobalConstans from "../../../_constants/global.constants";
import { constructUrlParams } from "../../../_helpers/CommonFunctions";

class ParticipantField extends PureComponent {
	static propTypes = {
		onChange: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
		placeholder: PropTypes.string,
		isDisabled: PropTypes.bool,
		isChanged: PropTypes.bool,
		value: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				label: PropTypes.string,
			}),
		]).isRequired,
		api: PropTypes.string,
		clearable: PropTypes.bool,
		booking: PropTypes.string,
		bookingType: PropTypes.number
	};

	static defaultProps = {
		placeholder: "",
		// options: [],
		isDisabled: false,
		isChanged: false,
		clearable: false,
		api: "",
		booking: false,
		bookingType: null,
	};

	constructor(props) {
		super(props);
		this.state = { api: props.api, booking: props.booking, bookingType: props.bookingType, defaultOptions: [] };
	}

	componentWillMount() {
		const { api, booking, bookingType } = this.state;
		const urlParams = constructUrlParams({
			s: "",
			bid: booking,
			type: bookingType
		});
		const url = `${GlobalConstans.base_url}${api}${urlParams}`;
		API.getDropDown(url, true)
			.then((response) => {
				this.setState({
					defaultOptions: response.data.data,
				});
			})
			.catch(() => {
				this.setState({
					defaultOptions: [],
				});
			});
	}

	componentDidUpdate(prevProps){
		if(prevProps.isChanged != this.props.isChanged){
			const { api, booking, bookingType } = this.state;
		const urlParams = constructUrlParams({
			s: "",
			bid: booking,
			type: bookingType
		});
		const url = `${GlobalConstans.base_url}${api}${urlParams}`;
		API.getDropDown(url, true)
			.then((response) => {
				this.setState({
					defaultOptions: response.data.data,
				});
			})
			.catch(() => {
				this.setState({
					defaultOptions: [],
				});
			});
		}
	}

	handleChange = (selectedOption) => {
		const { onChange } = this.props;
		onChange(selectedOption);
	};

	loadOptions = (inputValue, callback) => {
		const { props } = this;
		const { api, booking, bookingType } = this.state;

		const urlParams = constructUrlParams({
			s: inputValue,
			bid: booking,
			type: bookingType,
		});
		const url = `${GlobalConstans.base_url}${api}${urlParams}`;
		API.getDropDown(url)
			.then((response) => {
				let list = [];
				if (props.showAll) {
					list = list.concat(response.data.data);
					callback(list);
				}
			})
			.catch(() => {
				callback();
			});
	};

	render() {
		const { value, name, placeholder, isDisabled, clearable, isChanged } = this.props;

		const { defaultOptions } = this.state;
			return (
				<AsyncSelect
					loadOptions={this.loadOptions}
					defaultOptions={defaultOptions}
					onInputChange={this.handleInputChange}
					name={name}
					value={value}
					onChange={this.handleChange}
					// options={options}
					isClearable={clearable}
					className="react-select"
					placeholder={placeholder}
					classNamePrefix="react-select"
					isDisabled={isDisabled}
				/>
			);
	}
}

const renderParticipantField = (props) => {
	const { input, meta, options, placeholder, isDisabled, isChanged, api, booking, bookingType } = props;
	return (
		<div className="form__form-group-input-wrap">
			<ParticipantField
				{...input}
				options={options}
				placeholder={placeholder}
				isDisabled={isDisabled}
				isChanged={isChanged}
				api={api}
				booking={booking}
				bookingType={bookingType}
				showAll
				clearable
			/>
			{meta.touched && meta.error && (
				<span className="form__form-group-error">{meta.error}</span>
			)}
		</div>
	);
};

renderParticipantField.propTypes = {
	input: PropTypes.shape({
		onChange: PropTypes.func,
		name: PropTypes.string,
	}).isRequired,
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string,
	}),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
		})
	),
	placeholder: PropTypes.string,
	isDisabled: PropTypes.bool,
	isChanged: PropTypes.bool,
	api: PropTypes.string.isRequired,
	booking: PropTypes.string,
	bookingType: PropTypes.number,
	showAll: PropTypes.bool,
	clearable: PropTypes.bool,
};

renderParticipantField.defaultProps = {
	meta: null,
	options: [],
	placeholder: "",
	isDisabled: false,
	isChanged: false,
	showAll: false,
	clearable: false,
};

export default renderParticipantField;
