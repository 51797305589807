/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, Badge, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import rolesActions from "../../../../redux/actions/roles.actions";
import ServerSideDataTable from "../../../../shared/components/table/ServerSideDataTable";

class DataTable extends PureComponent {
  static propTypes = {
    // t: PropTypes.func.isRequired,
    getUserRoles: PropTypes.func.isRequired,
    roles: PropTypes.shape({
      totalResults: PropTypes.number,
      filter: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        search: PropTypes.string,
      }),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
        })
      ),
    }),
  };

  static defaultProps = {
    roles: {
      filter: {
        search: "",
      },
      totalResults: null,
    },
  };

  constructor(props) {
    super(props);
    // props.setDefaults();
    props.getUserRoles();
  }

  handleTableChange = (type, { searchText, page }) => {
    const { props } = this;
    props.getUserRoles({ currentPage: page, search: searchText });
  };

  customActions = (cell, row, rowIndex, formatExtraData) => {
    // eslint-disable-next-line react/destructuring-assignment
    return (
      <ButtonToolbar className="table-actions">
        <Button
          tag={Link}
          to={`edit/${row.id}`}
          color="primary"
          size="sm"
          className="rounded"
        >
          {formatExtraData.t("common.edit")}
        </Button>
      </ButtonToolbar>
    );
  };

  rolesFormatter = (cell, row) => {
    return (
      <div className="mb-2">
        {row.roles.map((role, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <p key={`${row.id}-${index}`}>
            <Badge color="light" className="text-dark font-weight-bold py-2 mr-2">
              {role}
            </Badge>
          </p>
        ))}
      </div>
    );
  };

  render() {
    const { props } = this;
    const { roles } = props;

    const columns = [
      {
        dataField: "id",
        text: "ID",
        hidden: true,
      },
      {
        dataField: "name",
        text: "Name",
      },
      {
        dataField: "email",
        text: "Email",
      },
      {
        dataField: "mobile",
        text: "Mobile",
      },
      {
        dataField: "gender",
        text: "Gender",
      },
      {
        dataField: "roles",
        text: "Roles",
        formatter: this.rolesFormatter,
      },
      {
        dataField: "actions",
        text: "Actions",
        formatter: this.customActions,
        formatExtraData: props,
      },
    ];

    return (
      <Col md={12} lg={12}>
        <Card>
          {roles.list && (
            <CardBody>
              <ServerSideDataTable
                data={roles.list}
                columns={columns}
                onTableChange={this.handleTableChange}
                page={roles.filter.currentPage}
                sizePerPage={roles.sizePerPage}
                totalSize={roles.totalResults}
                searchText={roles.filter.search}
              />
            </CardBody>
          )}
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({ roles: state.roles });

const actionCreators = {
  getUserRoles: rolesActions.getUserRolesAction,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation("common")(DataTable));
