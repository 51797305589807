import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
	Button,
} from 'reactstrap';
// import PropTypes from "prop-types";
import PropTypes from "prop-types";
import { excludeSidebar } from '../../../redux/actions/sidebarActions';
import userActions from '../../../redux/actions/userActions';

class ForgotPasswordForm extends PureComponent {

	static propTypes = {
		dispatch: PropTypes.func.isRequired,
	};

	constructor() {
		super();
		this.state = {
			showPassword: false
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(excludeSidebar(true));

	}

	componentWillUnmount() {
		const { dispatch } = this.props;
		dispatch(excludeSidebar(false));
	}

	showPassword = e => {
		e.preventDefault();
		this.setState(prevState => ({ showPassword: !prevState.showPassword }));
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { props } = this;
		props.resetPassword(props.email);
	}

	render() {

		return (
			<div className='forget-password'>
				<form className='form' onSubmit={this.handleSubmit}>
					<div className='form__form-group'>
						<span className='form__form-group-label'>Email ID</span>
						<div className='form__form-group-field'>
							<Field
								name='name'
								component='input'
								type='email'
								placeholder='Email ID'
							/>
						</div>
					</div>
					<Button className="btn btn-primary rounded account__btn account__btn--small" 
						color="primary" type="submit">
							Submit
					</Button>
				</form>
			</div>
		);
	}
}

// function mapState(state) {
// 	const { loggingIn } = state.authentication;
// 	return { loggingIn };
// }

const actionCreators = {
	resetPassword: userActions.resetPasswordAction,
};


export default connect((state) => {
	const selector = formValueSelector('forgot_password_form'); // <-- same as form name
	const email = selector(state, 'name');
	return {
		email,
	};
}, actionCreators)(reduxForm({
	form: 'forgot_password_form', // a unique identifier for this form
})(ForgotPasswordForm));

// export default connect(mapState, actionCreators)(ForgotPassword);