import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import TrendingDownIcon from 'mdi-react/TrendingDownIcon';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';

const BookingCancels = (props) => (
  <Col md={12} xl={3} lg={6} xs={12}>
    <Card>
      <CardBody className="dashboard__booking-card">
        <div className="dashboard__booking-total-container">
          <h5 className="dashboard__booking-total-title">{props.data.this_month}</h5>
		  {props.data.sales==='up'?<TrendingUpIcon className="dashboard__trend-icon" />:<TrendingDownIcon className="dashboard__trend-icon" />}
        </div>
        <h5 className="dashboard__booking-total-description">Booking cancels</h5>
        <div className="progress-wrap progress-wrap--small progress-wrap--turquoise-gradient progress-wrap--rounded">
          <p className="dashboard__booking-card-progress-label progress__label">{props.data.sales_percentage} %</p>
          <Progress value={props.data.sales_percentage} />
        </div>
      </CardBody>
    </Card>
  </Col>
);

export default BookingCancels;
