import React from "react";
import { Col, Container, Row } from "reactstrap";
import PropTypes from 'prop-types';

import VerticalForm from "./components/VerticalForm";
import showResults from "./Show";

const LocationSingle = ({ match }) => {
	
	const title = match.params.action === 'add' ? 'Add Horse Location' : 'Edit Horse Location';

	return(
		<Container className='dashboard'>
			<Row>
				<Col md={12}>
					<h3 className='page-title'>{title}</h3>
				</Col>
			</Row>
			<Row>
				<VerticalForm onSubmit={showResults} id={match.params.id} key={match.params.id}/>
			</Row>
		</Container>
	);

}

LocationSingle.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			action: PropTypes.string.isRequired,
			id: PropTypes.string
		})
	})
	// sizePerPage: PropTypes.number.isRequired
};

LocationSingle.defaultProps = {
	match: {
		params: {
			id: null
		}
	},
};

export default LocationSingle;
