import clonedeep from "lodash.clonedeep";
import { rolesConstants} from "../../_constants/dashboard.constants";

const initialState = {
    list: [],
    sizePerPage: 10,
    totalResults: null,
    filter: {
        currentPage: 1,
        search: '',
    },
    permsFormData: {},
    userFormdata: {},
}

const rolesReducer = ( state = initialState, action) => {
    switch(action.type) {
        case rolesConstants.GET_USER_ROLES:
            return {
                ...state,
                list: action.data.data,
                filter: {
                    search: action.filter.search ? action.filter.search : '',
                    currentPage: action.filter.currentPage ? action.filter.currentPage : 1,
                },
                totalResults: action.data.meta.total,
                last_page: action.data.meta.last_page,
            };
        case rolesConstants.GET_SINGLE_USER_ROLES:
            return {
                ...state,
                userFormdata: action.data,
            };
        case rolesConstants.UPDATE_SINGLE_USER_ROLES: {
            const currentUserData = clonedeep(state.userFormdata);
			currentUserData.data.roles = action.data;
            return {
                ...state,
                userFormdata: currentUserData,
            };
		}
		case rolesConstants.CLEAR_SINGLE_USER_ROLES:
			return {
                ...state,
                userFormdata: {},
            };
        case rolesConstants.GET_ROLES_PERMISSIONS:
            return {
                ...state,
                permsFormData: action.data,
            };
        case rolesConstants.UPDATE_ROLES_PERMISSIONS: {
            const currentPermsData = clonedeep(state.permsFormData);
            currentPermsData.data.permissions[action.data.currentRole] = action.data.currentPerms.permissions;
            return {
                ...state,
                permsFormData: currentPermsData
            };
        }
        default:
            return state;
    }

};

export default rolesReducer;