import React, { PureComponent } from "react";
import { Card, CardBody, Col, Row, Button, ButtonToolbar } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import serviceActions from "../../../../redux/actions/serviceActions";
// import validate from "./validate";

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: "",
  meta: null,
  type: "text",
};

class VerticalForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    id: PropTypes.string,
  };

  static defaultProps = {
    id: null,
  };

  // constructor() {
  // 	super();
  // 	this.state = {
  // 	};
  // }

  componentDidMount() {
    const { props } = this;
    if (props.id) {
      props.getCategory(props.id);
    }
  }

  // componentWillUnmount() {
  // 	const { props } = this;
  // 	props.clearCorporateFrom();
  // }

  getValues = (values) => {
    const { props } = this;
    props.addUpdateCategory(JSON.stringify(values), props.id);
  };

  render() {
    const { handleSubmit, pristine, reset, submitting, id } = this.props;

    return (
      <Col md={12} lg={12} xl={12}>
        <form className="form" onSubmit={handleSubmit(this.getValues)}>
          <div className="formInner">
            <Card>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Category Name
                      </span>
                      <span style={{ color: "red" }}>*</span>
                      <div className="form__form-group-field">
                        <Field
                          name="category"
                          component={renderField}
                          type="text"
                          placeholder="Category Name"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <ButtonToolbar className="form__button-toolbar">
                  <Button
                    className="rounded"
                    type="submit"
                    color="primary"
                    disabled={pristine || submitting}
                  >
                    {id ? "Update" : "Add"}
                  </Button>
                  {/* {id ? (
                    <Button
                      className="rounded"
                      type="button"
                      onClick={reset}
                      disabled={pristine || submitting}
                    >
                      Cancel
                    </Button>
                  ) : ( */}
                  <Button
                    className="rounded"
                    tag={Link}
                    outline
                    // color="primary"
                    to="/services/categories"
                  >
                    Cancel
                  </Button>
                  {/* )} */}
                </ButtonToolbar>
              </CardBody>
            </Card>
          </div>
        </form>
      </Col>
    );
  }
}

// const initialValues = {
// 	category_name: "Activity"
// };

// export default connect(() => initialValues)(
// 	reduxForm({
// 		form: "edit_service_category", // a unique identifier for this form
// 		validate
// 	})(withTranslation("common")(VerticalForm))
// );

const mapState = (state) => {
  if (!state.service.categories.addFormData.data) {
    return {};
  }

  return {
    initialValues: {
      category: state.service.categories.addFormData.data.category
        ? state.service.categories.addFormData.data.category
        : "",
    },
  };
};

const actionCreators = {
  getCategory: serviceActions.getCategoryAction,
  addUpdateCategory: serviceActions.addUpdateCategoryAction,
  // clearCorporateFrom: serviceActions.clearCorporateFrom,
};
//

// You have to connect() to any reducers that you wish to connect to yourself
export default connect(
  mapState,
  actionCreators // bind account loading action creator
)(
  reduxForm({
    form: "service_category_add_edit", // a unique identifier for this form
    // validate
  })(withTranslation("common")(VerticalForm))
);
