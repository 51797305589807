import React, { useEffect, useState, PureComponent} from "react";

import { Col, Container, Row } from 'reactstrap';
import { connect } from "react-redux";
import DashboardActions from "../../redux/actions/dashboardActions";

import TotalProfitEarned from './components/TotalProfitEarned';
import TotalCustomers from './components/TotalCustomers';
import TotalBookings from './components/TotalBookings';
import BookingCancels from './components/BookingCancels';
import AppointmentsChart from './components/AppointmentsChart';
import SalesChart from './components/SalesChart';
import { constructUrlParams } from "../../_helpers/CommonFunctions";

const Dashboard = (props) => {
	// constructor(props) {
	// 	super(props);
	// 	props.getDashboardData();
	// }
	const today = new Date();

  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth() + 1);
  useEffect(() => {
    const urlParams = constructUrlParams({
      year: year,
      month: month,
    });
    props.getDashboardData(urlParams);
  }, [year, month]);

		const { dashstats } = props;
		return (
			<Container className="dashboard">
				<Row>
					<Col md={12}>
						<h3 className="page-title">This Month</h3>
					</Col>
				</Row>
				<Row>
					<TotalProfitEarned data={dashstats.widgets.sales} />
					<TotalBookings data={dashstats.widgets.orders} />
					<TotalCustomers data={dashstats.widgets.members}/>
					<BookingCancels data={dashstats.widgets.cancels} />
				</Row>
				<Row>
					<AppointmentsChart data={dashstats.charts.sales} year={year} month={month} setYear={setYear} setMonth={setMonth}  />
				</Row>
			</Container>
		);
}

function mapState(state) {
	return {
		dashstats: state.dashboard.dashstats,
	};
}

const actionCreators = {
	getDashboardData: DashboardActions.getDashboardAction,
};

export default connect(
	mapState,
	actionCreators
)(Dashboard);
