import React from "react";
import { Col, Container, Row } from "reactstrap";
import PropTypes from "prop-types";
// import useParams from "react-router-dom";
import VerticalForm from "./components/VerticalForm";
import showResults from "./Show";

const CorporateSingle = ({ match }) => {
  const title = match.params.action === "add" ? "Add Member" : "Edit Member";

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">{title}</h3>
        </Col>
      </Row>
      <Row>
        <VerticalForm
          onSubmit={showResults}
          id={match.params.id}
          key={match.params.id}
          action={match.params.action}
        />
      </Row>
    </Container>
  );
};

CorporateSingle.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string.isRequired,
      id: PropTypes.string,
    }),
  }),
  // sizePerPage: PropTypes.number.isRequired
};

CorporateSingle.defaultProps = {
  match: {
    params: {
      id: null,
    },
  },
};

export default CorporateSingle;
