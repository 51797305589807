import React, {useState} from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Panel from "../../../../shared/components/Panel";
import Picker from 'react-month-picker';
import '../../../../css/monthpicker.css';
// import 'react-month-picker/css/month-picker.css';



// const data = [
//   {
//     name: "Mon",
//     a: 590,
//     b: 1400,
//     c: 100,
//   },
//   {
//     name: "Tue",
//     a: 868,
//     b: 1506,
//     c: 120,
//   },
//   {
//     name: "Wed",
//     a: 1397,
//     b: 989,
//     c: 220,
//   },
//   {
//     name: "Thu",
//     a: 1480,
//     b: 1228,
//     c: 280,
//   },
//   {
//     name: "Fri",
//     a: 1520,
//     b: 1100,
//     c: 380,
//   },
//   {
//     name: "Sat",
//     a: 1520,
//     b: 1100,
//     c: 180,
//   },
//   {
//     name: "Sun",
//     a: 1400,
//     b: 1700,
//     c: 270,
//   },
// ];
class MonthBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        value: this.props.value || 'N/A',
    }
    this._handleClick = this._handleClick.bind(this);
  }

  componentWillReceiveProps(nextProps){
    this.setState({
        value: nextProps.value || 'N/A',
    })
  }

  render() {
    return (
      <div className="box" onClick={this._handleClick}>
          <label>{this.state.value}</label>
          <span class="lnr lnr-chevron-down small float-right mt-2"></span>
      </div>
    );
  }

  _handleClick(e) {
    this.props.onClick && this.props.onClick(e);
  }
}

const SalesChart = (props) => {
  const { t } = props;
  const [mvalue, setMvalue] = useState({year: props.year, month: props.month});
  let pickAMonth = null;
  const pickerLang = {
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    from: 'From', to: 'To',
}
const handleClickMonthBox = (e) => {
  pickAMonth.show()
}
const handleAMonthDissmis = (value)=> {
  
}

const handleAMonthChange = (value, text) => {
  props.setYear(value);
  props.setMonth(text);
  setMvalue({year: value, month: text});
  pickAMonth.dismiss();
}


        const makeText = m => {
            if (m && m.year && m.month) return (pickerLang.months[m.month-1] + ' ' + m.year)
            return '?'
        }
  return (
    <Panel md={12} lg={12} xl={12} title={t("Sales Charts")}>
                        <Picker
                            // ref="pickAMonth"
                            ref={(ref)=>{pickAMonth=ref}}
                            // years={[2008, 2011, 2012, 2014, 2016]}
                            value={mvalue}
                            lang={pickerLang.months}
                            onChange={handleAMonthChange}
                            onDismiss={handleAMonthDissmis}
                        >
                            <MonthBox value={makeText(mvalue)} onClick={handleClickMonthBox} />
                        </Picker>
      <ResponsiveContainer height={250} className="dashboard__area">
      
        <AreaChart
          data={props.salesData}
          margin={{ top: 20, left: -15, bottom: 20 }}
        >
          <XAxis dataKey="name" tickLine={false} />
          <YAxis tickLine={false} />
          <Tooltip />
          <Legend />
          <CartesianGrid />
          <Area name="Booking" type="monotone" dataKey="booking" fill="#4ce1b6" stroke="#4ce1b6" fillOpacity={0.2} />
          <Area name="Horses" type="monotone" dataKey="horses" fill="#70bbfd" stroke="#70bbfd" fillOpacity={0.2} />
          <Area name="Membership" type="monotone" dataKey="membership" fill="#ffc658" stroke="#ffc658" fillOpacity={0.2} />
        </AreaChart>
      </ResponsiveContainer>
    </Panel>
  );
};

SalesChart.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation("common")(SalesChart);
