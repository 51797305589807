import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import MagnifyIcon from "mdi-react/MagnifyIcon";

const SearchComp = (props) => {
  let input;
  const handleClick = (e) => {
    e.preventDefault();
    props.onSearch(input.value);
  };
  const { searchText } = props;

  return (
    <div className="row justify-content-end">
      <form className="form col-3" onSubmit={handleClick}>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <input
              name="search"
              type="text"
              ref={(n) => {
                input = n;
              }}
              placeholder="Search..."
              onClick={handleClick}
              defaultValue={searchText}
            />
            <button
              type="submit"
              className="form__form-group-icon"
              onClick={handleClick}
            >
              <MagnifyIcon />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

SearchComp.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchText: PropTypes.string,
};
SearchComp.defaultProps = {
  searchText: "",
};

export default class ServerSideDataTableWithHeaders extends PureComponent {
  static propTypes = {
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        dataField: PropTypes.string,
        text: PropTypes.string,
        editable: PropTypes.bool,
        sortable: PropTypes.bool,
      })
    ).isRequired,
    onTableChange: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    page: PropTypes.number,
    totalSize: PropTypes.number,
    sizePerPage: PropTypes.number.isRequired,
    searchText: PropTypes.string,
    hideSearchFilter: PropTypes.bool,
  };

  static defaultProps = {
    page: 1,
    totalSize: "",
    searchText: "",
    hideSearchFilter: true,
  };

  render() {
    const {
      data,
      columns,
      onTableChange,
      sizePerPage,
      totalSize,
      searchText,
      page,
      hideSearchFilter,
    } = this.props;

    const customTotal = (from, to, size) => {
      return (
        <span className="react-bootstrap-table-pagination-total">
          Showing {from} to {to} of {size} Results
        </span>
      );
    };

    const noDataFound = () => {
      return <div className="no-results">No Results Found</div>;
    };

    const options = {
      page,
      paginationSize: sizePerPage,
      sizePerPage: sizePerPage || 10,
      totalSize,
      hideSizePerPage: true, // Hide the sizePerPage dropdown always
      showTotal: true,
      paginationTotalRenderer: customTotal,
      hidePageListOnlyOnePage: true,
    };

    let headers = Object.keys(data);
    let headersIndexes = [];

    let lengths = headers.map((k, i) => {
      return data[headers[i]].length;
    });

    let allRows = headers.map((k, i) => {
      let arr = [{ service_name: k }, ...data[k]];
      //   if (i == headers.length - 1) {
      //     headersIndexes.push(
      //       data[headers[i - 1]].length + headersIndexes[i - 1]
      //     );
      //   } else
      if (i != 0) {
        if (i == 1) {
          headersIndexes.push(data[headers[i - 1]].length + i);
        } else {
          headersIndexes.push(
            data[headers[i - 1]].length +
              i +
              lengths.slice(0, i - 1).reduce((x, y) => x + y)
          );
        }
      } else {
        headersIndexes.push(0);
      }
      return arr;
    });

    let mergedRows = [].concat.apply([], allRows);

    const rowStyleFormat = (row, rowIdx) => {
      return {
        backgroundColor: headersIndexes.indexOf(rowIdx) != -1 && "#ccc",
      };
    };
    return (
      <>
        <ToolkitProvider
          keyField="id"
          data={mergedRows}
          columns={columns}
          search
        >
          {(toolkitprops) => (
            <div className="table">
              {/* {hideSearchFilter && (
                <SearchComp
                  {...toolkitprops.searchProps}
                  searchText={searchText}
                />
              )} */}
              <BootstrapTable
                striped
                {...toolkitprops.baseProps}
                remote
                noDataIndication={noDataFound}
                bordered={false}
                // pagination={paginationFactory(options)}
                onTableChange={onTableChange}
                rowStyle={rowStyleFormat}
              />
            </div>
          )}
        </ToolkitProvider>
        {/* <ToolkitProvider keyField="id" data={result} columns={columns} search>
          {(toolkitprops) => (
            <div className="table">
              
              <BootstrapTable
                striped
                {...toolkitprops.baseProps}
                remote
                noDataIndication={noDataFound}
                bordered={false}
                pagination={paginationFactory(options)}
                onTableChange={onTableChange}
              />
            </div>
          )}
        </ToolkitProvider> */}
      </>
    );
  }
}
