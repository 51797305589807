import React from "react";
import { Col, Container, Row, Button } from "reactstrap";

import { Link } from "react-router-dom";
import Permission from "../../Permission/components/Permission";
import DataTable from "./components/DataTable";

const CategoryListing = (props) => (
  <Container className="dashboard">
    <Row>
      <Col md={9}>
        <h3 className="page-title">Activity Category</h3>
      </Col>
      <Col md={3} className="text-right">
        <Permission id="edit-services">
          <Button
            className="rounded"
            tag={Link}
            to="categories/add"
            color="primary"
          >
            Add New Category
          </Button>
        </Permission>
      </Col>
    </Row>
    <Row>
      <DataTable {...props} />
    </Row>
  </Container>
);

export default CategoryListing;
