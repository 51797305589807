import React from 'react';
import {
  Col, Container, Row
} from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
// import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
// import paginationFactory from 'react-bootstrap-table2-paginator';

// import { Link } from 'react-router-dom';
// import DataTable from './components/DataTable';


const columns = [{
  dataField: 'id',
  text: 'Product ID'
}, {
    dataField: 'company_name',
  text: 'Product Name'
}, {
    dataField: 'register_no',
  text: 'Product Price'
}];

const products = 
  [
    // { "id": 24, "company_name": "ads", "register_no": "asd", "domain": "asd" },
    // { "id": 28, "company_name": "ads", "register_no": "asd", "domain": "asd" },
    // { "id": 33, "company_name": "Google", "register_no": "aq3423", "domain": "ecommerce" },
    // { "id": 39, "company_name": "Google", "register_no": "aq3423", "domain": "ecommerce" },
    // { "id": 44, "company_name": "Google", "register_no": "aq3423", "domain": "ecommerce" },
    // { "id": 45, "company_name": "Google", "register_no": "aq3423", "domain": "ecommerce" },
    // { "id": 55, "company_name": "Google", "register_no": "aq3423", "domain": "ecommerce" },
    // { "id": 56, "company_name": "Google", "register_no": "aq3423", "domain": "ecommerce" },
    // { "id": 57, "company_name": "Google", "register_no": "aq3423", "domain": "ecommerce" },
    // { "id": 58, "company_name": "Google", "register_no": "aq3423", "domain": "ecommerce" },
  ];


const Testing = () => (
  <Container className="dashboard">
    <Row>
      <Col md={8}>
        <BootstrapTable keyField='id' data={products} columns={columns} />
      </Col>
    </Row>
  </Container>
);

export default Testing;
