/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from "react";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";

class DropZoneMultipleField extends PureComponent {
	static propTypes = {
		onChange: PropTypes.func.isRequired,
		onDropzoneChange: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
		value: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.arrayOf(
				PropTypes.shape({
					name: PropTypes.string
				})
			)
		]).isRequired,
		accept: PropTypes.string.isRequired,
		disabled: PropTypes.bool,
	};

	static defaultProps = {
		disabled: false
	}


	constructor() {
		super();
		this.state = {};
		this.onDrop = this.onDrop.bind(this);
	}

	onDrop(files) {
		const { onChange, onDropzoneChange } = this.props;
		console.log(files);
		// API.uploadDocument({
		// 	object_id : id,
		// 	documents: files,
		// 	type
		// }).then(
		// 	(response) => {
		// 		console.log(response);
		// 		// eslint-disable-next-line no-plusplus
		// 		for(let i = 0; i < files.length; i++){
		// 			this.removeFile(i);
		// 		}

		// 	}
		// ).catch(
		// 	(error) => {
		// 		console.log(error)
		// 	}
		// )
		
		onChange(
			files.map(fl =>
				Object.assign(fl, {
					preview: URL.createObjectURL(fl)
				})
			)
		);
		onDropzoneChange(files);
	}

	removeFile = (index, e) => {
		const { value, onChange } = this.props;
		if(e) {
			e.preventDefault();
		}
		onChange(value.filter((val, i) => i !== index));
	};

	render() {
		const { name, value, accept, disabled } = this.props;
		console.log(this.props);
		const files = value;

		return (
			// eslint-disable-next-line prefer-template
			<div className={"dropzone dropzone--multiple " + (disabled ? ' dropzone--disabled' : '')}>
				<Dropzone
					className='dropzone__input'
					accept={accept}
					multiple={false}
					name={name}
					disabled={disabled}
					onDrop={filesToUpload => {
						this.onDrop(value ? value.concat(filesToUpload) : filesToUpload);
					}}
					// onDrop={filesToUpload => {
					// 	this.onDrop(value ? value.concat(filesToUpload) : filesToUpload);
					// }}
				>
					{({ getRootProps, getInputProps }) => (
						<div {...getRootProps()} className='dropzone__input'>
							{(!files || files.length === 0) && (
								<div className='dropzone__drop-here'>
									<span className='lnr lnr-upload' /> Drop files here to upload
								</div>
							)}
							<input {...getInputProps()} />
						</div>
					)}
				</Dropzone>
				{files && Array.isArray(files) && (
					<div className='dropzone__imgs-wrapper'>
						{files.map((file, i) => (
							<div
								className='dropzone__img'
								key={i}
								style={{ backgroundImage: `url(${file.preview})` }}
							>
								<p className='dropzone__img-name'>{file.name}</p>
								<button
									className='dropzone__img-delete'
									type='button'
									onClick={e => this.removeFile(i, e)}
								>
									Remove
								</button>
							</div>
						))}
					</div>
				)}
			</div>
		);
	}
}

const renderDropZoneMultipleField = props => {
	const { input, accept, onDropzoneChange, disabled } = props;
	return <DropZoneMultipleField {...input} accept={accept} onDropzoneChange={onDropzoneChange} disabled={disabled} />;
};

renderDropZoneMultipleField.propTypes = {
	input: PropTypes.shape({
		onChange: PropTypes.func,
		name: PropTypes.string,
	}).isRequired,
	accept: PropTypes.string,
	// id: PropTypes.string,
	// type: PropTypes.string,
	disabled: PropTypes.bool,
	onDropzoneChange: PropTypes.func.isRequired,
};

renderDropZoneMultipleField.defaultProps = {
	accept: ".pdf, .doc, .docx, .png, .jpeg, .jpg",
	disabled: false
};

export default renderDropZoneMultipleField;
