import alertConstants from '../../_constants/alert.constants';

function clear() {
    return dispatch => {
        dispatch({ type: alertConstants.CLEAR })
    }
}

let timeout;
function startCountdown(dispatch) {
    if (timeout) {
        clearTimeout(timeout);
    }
    timeout = setTimeout(dispatch(clear()), 1000);
}

function success(message) {
    return dispatch => {
        dispatch({ type: alertConstants.SUCCESS, message });
        startCountdown(dispatch);
    }
}

function error(message) {
    return dispatch => {
        dispatch({ type: alertConstants.ERROR, message })
        startCountdown(dispatch);
    }
}


const alertActions = {
    success,
    error,
    clear
};

export default alertActions;