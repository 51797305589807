import React from 'react';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid,
  Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from '../../../../shared/components/Panel';

const data = [{ name: 'Mon', a: 12, b: 33 },
  { name: 'Tue', a: 10, b: 23 },
  { name: 'Wed', a: 2, b: 21 },
  { name: 'Thu', a: 30, b: 13 },
  { name: 'Fri', a: 20, b: 34 },
  { name: 'Sat', a: 10, b: 12 },
  { name: 'Sun', a: 11, b: 23 }];

const ABTestingAnalytics = ({ t }) => (
  <Panel md={12} lg={12} xl={12} title={t('Bookings')}>
    <ResponsiveContainer height={250} className="dashboard__area">
      <AreaChart data={data} margin={{ top: 20, left: -15, bottom: 20 }}>
        <XAxis dataKey="name" tickLine={false} />
        <YAxis tickLine={false} />
        <Tooltip />
        <Legend />
        <CartesianGrid />
        <Area name="Offline" type="monotone" dataKey="a" fill="#4ce1b6" stroke="#4ce1b6" fillOpacity={0.2} />
        <Area name="Online" type="monotone" dataKey="b" fill="#70bbfd" stroke="#70bbfd" fillOpacity={0.2} />
      </AreaChart>
    </ResponsiveContainer>
  </Panel>
);

ABTestingAnalytics.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ABTestingAnalytics);
