import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
	Button,
} from 'reactstrap';
// import PropTypes from "prop-types";
import PropTypes from "prop-types";
import { excludeSidebar } from '../../../redux/actions/sidebarActions';
import userActions from '../../../redux/actions/userActions';

class ResetPasswordForm extends PureComponent {

	static propTypes = {
		dispatch: PropTypes.func.isRequired,
	};

	constructor() {
		super();
		this.state = {
			showPassword: false
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(excludeSidebar(true));
		const { match } = this.props;
		const token = match.params.token;
		console.log(token, 'token')
		this.props.getUserEmailFromToken(token);
	}

	componentWillUnmount() {
		const { dispatch } = this.props;
		dispatch(excludeSidebar(false));
	}

	showPassword = e => {
		e.preventDefault();
		this.setState(prevState => ({ showPassword: !prevState.showPassword }));
	};

	saveData = (values) => {
		console.log(values, 'values......');
		// e.preventDefault();
		const { props } = this;
		const params = JSON.stringify({
			email: values.email ? values.email : "",
			password: values.password ? values.password : "",
			password_confirmation: values.password_confirmation ? values.password_confirmation : "",
			token: values.token ? values.token : "",
		  });
		props.resetPasswordWithNewPassword(params);
	}

	render() {

		const {handleSubmit} = this.props;

		return (
			<div className='reset-password'>
				<form className='form' onSubmit={handleSubmit(this.saveData)}>
					<div className='form__form-group'>
						<span className='form__form-group-label'>Email ID</span>
						<div className='form__form-group-field'>
							<Field
								name='email'
								component='input'
								type='email'
								placeholder='Email ID'
							/>
						</div>
					</div>
					<div className='form__form-group'>
						<span className='form__form-group-label'>Password</span>
						<div className='form__form-group-field'>
							<Field
								name='password'
								component='input'
								type='password'
								placeholder='Password'
							/>
						</div>
					</div>
					<div className='form__form-group'>
						<span className='form__form-group-label'>Confirm Password</span>
						<div className='form__form-group-field'>
							<Field
								name='password_confirmation'
								component='input'
								type='password'
								placeholder='Confirm Password'
							/>
						</div>
					</div>
					<div className='form__form-group'>
						<div className='form__form-group-field'>
							<Field
								name='token'
								component='input'
								type='hidden'
							/>
						</div>
					</div>
					<Button className="btn btn-primary rounded account__btn account__btn--small" 
						color="primary" type="submit" disabled={!this.props.verifyData}>
							Submit
					</Button>
				</form>
			</div>
		);
	}
}

// function mapState(state) {
// 	const { loggingIn } = state.authentication;
// 	return { loggingIn };
// }
const mapState = (state, ownProps) => {
	console.log(state, 'state - map state')
	const verifyData = state.authentication && state.authentication.verifyData ? state.authentication.verifyData : null;
	const selector = formValueSelector('reset_password_form'); // <-- same as form name
	let initValues = verifyData;
	return {
		initialValues: initValues,
		verifyData: verifyData,
	};
}
const actionCreators = {
	getUserEmailFromToken: userActions.getUserEmailFromTokenAction,
	resetPasswordWithNewPassword: userActions.resetPasswordWithNewPasswordAction,
};


export default connect(mapState, actionCreators)(reduxForm({
	form: 'reset_password_form', // a unique identifier for this form
})(ResetPasswordForm));

// export default connect(mapState, actionCreators)(ForgotPassword);