import React from 'react';
import {
  Col, Container, Row
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import DataTable from './components/DataTable';
import VerticalForm from './components/VerticalForm'; 

const UserRoles = (props) => {
  const { t, match } = props;
  const isRoleEdit = match.path.includes('edit');
  const title = isRoleEdit ? t('roles.user_edit') : t('roles.user');

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">{title}</h3>
        </Col>
      </Row>
      <Row>
        {!isRoleEdit ? <DataTable {...props} /> : <VerticalForm id={match.params.id} /> }
      </Row>
    </Container>
  );
}

UserRoles.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string,
      id: PropTypes.string
    })
  })
};

UserRoles.defaultProps = {
  match: {
    params: {
      id: null
    }
  },
};

export default withTranslation('common')(UserRoles);