/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import { formValueSelector } from 'redux-form';
import PropTypes from "prop-types";
import Permission from "../../../Permission/components/Permission";
import ServerSideFilterDataTable from "../../../../shared/components/table/ServerSideFilterDataTable";
import horseActions from "../../../../redux/actions/horseActions";

class DataTable extends PureComponent {
  static propTypes = {
    // getHorseList: PropTypes.func.isRequired,
    // setDefaults: PropTypes.func.isRequired,
    horse: PropTypes.shape({
      totalResults: PropTypes.number,
      filter: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        search: PropTypes.string,
      }),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
        })
      ),
    }),
  };

  static defaultProps = {
    horse: {
      filter: {
        search: "",
      },
      totalResults: null,
    },
  };

  // constructor(props) {
  //   super(props);
  //   console.log('constructor');
  //   // props.setDefaults();
  //   // props.getHorseList();
  // }

  componentDidMount() {
    const { props } = this;
    props.getHorseList();
  }

  // eslint-disable-next-line no-unused-vars
  handleTableChange = (type, { searchText, page }) => {
    const { props } = this;

    props.getHorseList({
      currentPage: page,
      category: props.horse.filter.category,
      search: props.horse.filter.search,
      layout: props.horse.filter.layout,
    });
  };

  handleFilterChange = (filters) => {
    const { props } = this;

    props.getHorseList({
      currentPage: 1,
      category: filters.category,
      search: filters.search,
      layout: filters.layout,
    });
  };

  customActions = (cell, row, rowIndex, custom) => {
    const { props } = this;
    const { horse } = props;
    const { filter } = horse;

    return (
      <ButtonToolbar className="table-actions">
        <Permission id="edit-horses">
          <Button
            className="rounded"
            tag={Link}
            to={
              filter.layout === "horse"
                ? `/horse/edit/${row.id}`
                : `/member/edit/${row.id}`
            }
            color="primary"
            size="sm"
          >
            Edit
          </Button>
        </Permission>
        {filter.layout === "horse" && (
          <Permission id="delete-horses">
            <Button
              className="rounded"
              color="danger"
              size="sm"
              onClick={() => {
                if (
                  window.confirm("Are you sure you wish to delete this item?")
                ) {
                  custom(row.id);
                }
              }}
            >
              Delete
            </Button>
          </Permission>
        )}
      </ButtonToolbar>
    );
  };

  render() {
    const { props } = this;
    const { permissions, horse } = props;

    const columns =
      horse.filter.layout === "horse"
        ? [
            {
              dataField: "id",
              text: "ID",
              hidden: true,
            },
            {
              dataField: "name",
              text: "Name",
              // show: filter.layout === 'horse'
            },
            {
              dataField: "category_name",
              text: "Category",
            },
            {
              dataField: "owner_name",
              text: "Owner",
            },
            {
              dataField: "price",
              text: "Price",
            },
            {
              dataField: "location_name",
              text: "Location",
            },
            {
              dataField: "actions",
              text: "Actions",
              formatter: this.customActions,
              formatExtraData: props.deleteHorse,
              hidden: !(
                permissions.includes("edit-horses") ||
                permissions.includes("delete-horses")
              ),
            },
          ]
        : [
            {
              dataField: "id",
              text: "ID",
              hidden: true,
            },
            {
              dataField: "name",
              text: "Owner",
            },
            {
              dataField: "membership",
              text: "Membership",
            },
            {
              dataField: "horses_count",
              text: "No of Horses",
            },
            {
              dataField: "actions",
              text: "Actions",
              formatter: this.customActions,
              hidden: !permissions.includes("edit-horses"),
            },
          ];

    return (
      <Col md={12} lg={12}>
        <Card>
          {horse.list && (
            <CardBody>
              <ServerSideFilterDataTable
                data={horse.list}
                columns={columns}
                onTableChange={this.handleTableChange}
                page={horse.filter.currentPage}
                sizePerPage={horse.sizePerPage}
                totalSize={horse.totalResults}
                searchText={horse.filter.search}
                onFilterChange={this.handleFilterChange}
              />
            </CardBody>
          )}
        </Card>
      </Col>
    );
  }
}

function mapState(state) {
  const authData = state.authentication;
  const permissions =
    typeof authData.user !== "undefined" &&
    Array.isArray(authData.user.permissions)
      ? authData.user.permissions
      : [];

  return {
    permissions,
    horse: state.horse,
  };
}

const actionCreators = {
  getHorseList: horseActions.getHorseListAction,
  deleteHorse: horseActions.deleteHorseAction,
};

export default connect(
  mapState,
  actionCreators
)(DataTable);
