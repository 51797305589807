import API from "../../api/API";
import { horseConstants } from "../../_constants/dashboard.constants";
import history from "../../_helpers/history";
import alertActions from "./alertActions";
import preloaderActions from "./preloaderActions";
import { constructUrlParams } from "../../_helpers/CommonFunctions";

function setHorseDefaultsAction() {
  return { type: horseConstants.HORSE_SET_DEFAULTS };
}

function getHorseListAction(filters) {
  // eslint-disable-next-line no-param-reassign
  filters = typeof filters !== "undefined" ? filters : "";

  let custFilter = filters;

  function success(data) {
    return {
      type: horseConstants.HORSE_LIST,
      data,
      filter: custFilter,
    };
  }

  return (dispatch, getState) => {
    dispatch(preloaderActions.show());
    const { horse } = getState();
    const { filter } = horse;

    if (typeof custFilter === "undefined" || !custFilter) {
      custFilter = filter;
    }

    let currentPage = custFilter.currentPage ? custFilter.currentPage : 1;
    if (typeof custFilter.last_page !== "undefined") {
      currentPage =
        custFilter.currentPage > custFilter.last_page
          ? custFilter.last_page
          : custFilter.currentPage;
    }

    let urlParams = "";
    if (custFilter) {
      const params = {
        page: currentPage,
        search:
          typeof custFilter.search !== "undefined" ? custFilter.search : "",
        category:
          typeof custFilter.category !== "undefined" && custFilter.category
            ? custFilter.category.value
            : "",
        layout:
          typeof custFilter.layout !== "undefined" ? custFilter.layout : "",
      };
      urlParams = constructUrlParams(params);
    }

    let api = "getHorses";

    if (custFilter && custFilter.layout === "owner") {
      api = "getHorseOwners";
    }

    API[api](urlParams).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function deleteHorseAction(id) {
  function success(list) {
    return {
      type: horseConstants.HORSE_DELETE,
      list,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.deleteHorse(id).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
          dispatch(getHorseListAction());
          dispatch(alertActions.success(response.data.message));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function getHorseDataAction(id) {
  function success(data) {
    return {
      type: horseConstants.HORSE_GET,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.getHorse(id).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function addUpdateHorseAction(params, id) {
  function success(data) {
    return {
      type: id ? horseConstants.HORSE_UPDATE : horseConstants.HORSE_ADD,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.addUpdateHorse(params, id).then(
      (response) => {
        if (response.data) {
          history.push(`/horse/edit/${response.data.data.id}`);
          if (!id) {
            dispatch(success(response.data));
          }
          dispatch(alertActions.success(response.data.message));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
        // dispatch(alertActions.error('sadasdasd'));
      }
    );
  };
}

function addHorseAction(params) {
  // function success(data) {
  //     return {
  //         type: horseConstants.HORSE_ADD,
  //         data
  //     }
  // }

  // console.log(params);

  return (dispatch) => {
    dispatch(preloaderActions.show());
    // console.log(params);
    API.addUpdateHorse(params).then(
      (response) => {
        if (response.data) {
          // dispatch(success(response.data));
          dispatch(alertActions.success(response.data.message));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
        // dispatch(alertActions.error('sadasdasd'));
      }
    );
  };
}

function clearHorseFrom() {
  return {
    type: horseConstants.HORSE_GET_CLEAR,
  };
}

function getHorseLocationsAction(filters) {
  let custFilter = filters;

  function success(data) {
    return {
      type: horseConstants.HORSE_LOCATION_LIST,
      data,
      filter: {
        currentPage: custFilter.currentPage,
        search: custFilter.search,
      },
    };
  }

  return (dispatch, getState) => {
    dispatch(preloaderActions.show());
    const { horse } = getState();
    const { location } = horse;

    if (typeof filters === "undefined") {
      custFilter = location.filter;
    }

    if (custFilter.currentPage > 1) {
      custFilter.currentPage =
        custFilter.currentPage > location.last_page
          ? location.last_page
          : custFilter.currentPage;
    }

    const urlParams = constructUrlParams({
      page: custFilter.currentPage,
      search: custFilter.search,
    });

    API.getHorseLocations(urlParams).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}
function deleteHorseLocationAction(id) {
  function success(data) {
    return {
      type: horseConstants.HORSE_LOCATION_DELETE,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.deleteHorseLocation(id).then(
      (response) => {
        if (response.data) {
          dispatch(alertActions.success(response.data.message));
          dispatch(success(response.data));
          dispatch(getHorseLocationsAction());
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}
function addUpdateHorseLocationAction(params, id) {
  function success(data) {
    return {
      type: id
        ? horseConstants.HORSE_LOCATION_UPDATE
        : horseConstants.HORSE_LOCATION_ADD,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.addUpdateHorseLocation(params, id).then(
      (response) => {
        if (response.data) {
          history.push(`/horse/locations/edit/${response.data.data.id}`);
          if (!id) {
            dispatch(success(response.data));
          }
          dispatch(alertActions.success(response.data.message));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}
function getHorseLocationAction(id) {
  function success(data) {
    return {
      type: horseConstants.HORSE_LOCATION_GET,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.getHorseLocation(id).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function clearHorseLocationFrom() {
  return {
    type: horseConstants.HORSE_LOCATION_GET_CLEAR,
  };
}

function getHorseCategoriesAction() {
  function success(data) {
    return {
      type: horseConstants.HORSE_CATEGORIES_GET,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.getDropdownCategories(1).then(
      (response) => {
        // console.log(response);
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function getHorseListByTrainerAction(id, filters) {
  let custFilter = filters;

  function success(data) {
    return {
      type: horseConstants.HORSE_LIST_BY_TRAINER,
      data,
      //   filter: {
      //     currentPage: custFilter.currentPage,
      //     search: custFilter.search,
      //   },
    };
  }

  return (dispatch, getState) => {
    dispatch(preloaderActions.show());
    const { horse } = getState();

    // if (typeof filters === "undefined") {
    //   custFilter = horse.horseListByTrainer.filter;
    // }

    // if (custFilter.currentPage > 1) {
    //   custFilter.currentPage =
    //     custFilter.currentPage > horse.horseListByTrainer.last_page
    //       ? horse.horseListByTrainer.last_page
    //       : custFilter.currentPage;
    // }

    // const urlParams = constructUrlParams({
    //   page: custFilter.currentPage,
    //   search: custFilter.search,
    // });
    // console.log(urlParams);

    API.getHorseListByTrainers(id, {}).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

const horseActions = {
  getHorseListAction,
  deleteHorseAction,
  getHorseDataAction,
  addUpdateHorseAction,
  clearHorseFrom,
  setHorseDefaultsAction,
  getHorseLocationsAction,
  deleteHorseLocationAction,
  addUpdateHorseLocationAction,
  getHorseLocationAction,
  clearHorseLocationFrom,
  addHorseAction,
  getHorseCategoriesAction,
  getHorseListByTrainerAction,
};

export default horseActions;
