import API from "../../api/API";
import { dashboardConstants } from "../../_constants/dashboard.constants";
import alertActions from "./alertActions";
import preloaderActions from "./preloaderActions";

function getDashboardAction(params) {
  function success(data) {
    return {
      type: dashboardConstants.DASHBOARD_GET,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.getDashStats(params).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

const dashboardActions = {
  getDashboardAction,
};

export default dashboardActions;
