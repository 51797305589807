import { horseConstants } from "../../_constants/dashboard.constants";

const loactionDefaults = {
  list: null,
  totalResults: 0,
  last_page: 1,
  filter: {
    currentPage: 1,
    search: "",
  },
  addFormData: {},
};

const initialState = {
  list: null,
  sizePerPage: 10,
  filter: {
    currentPage: 1,
    category: { label: "All", value: "" },
    search: "",
    layout: "horse",
  },
  location: loactionDefaults,
  addFormData: {},
  categories: [],
  horseListByTrainer: null,
};

function horseReducer(state = initialState, action) {
  switch (action.type) {
    case horseConstants.HORSE_LIST:
      // console.log(action);
      return {
        ...state,
        addFormData: {},
        list: action.data.data,
        totalResults: action.data.meta.total,
        last_page: action.data.meta.last_page,
        filter: {
          currentPage:
            action.filter && action.filter.currentPage
              ? action.filter.currentPage
              : state.filter.currentPage,
          category:
            action.filter && action.filter.category
              ? action.filter.category
              : state.filter.category,
          search:
            action.filter && action.filter.search
              ? action.filter.search
              : state.filter.search,
          layout:
            action.filter && action.filter.layout
              ? action.filter.layout
              : state.filter.layout,
        },
        // corporate: {
        //     ...state.corporate,
        //     list: action.list.data,
        //     totalResults: action.list.meta.total,
        //     last_page: action.list.meta.last_page,
        //     currentPage: action.list.meta.current_page,
        // }
      };
    case horseConstants.HORSE_DELETE:
      return {
        ...state,
        totalResults: action.data.total,
        last_page: action.data.last_page,
      };
    case horseConstants.HORSE_GET:
      return {
        ...state,
        addFormData: action.data,
      };
    case horseConstants.HORSE_GET_CLEAR:
      return {
        ...state,
        corporate: {
          ...state.corporate,
          addFormData: {},
        },
      };
    case horseConstants.HORSE_ADD:
      return {
        ...state,
      };
    case horseConstants.HORSE_UPDATE:
      return {
        ...state,
        addFormData: action.data,
      };
    case horseConstants.HORSE_SET_DEFAULTS:
      return {
        state,
      };
    case horseConstants.HORSE_LOCATION_LIST:
      return {
        ...state,
        list: action.data.data,
        filters: action.filters,
        location: {
          ...state.location,
          list: action.data.data,
          filter: {
            search: action.filter.search ? action.filter.search : "",
            currentPage: action.filter.currentPage
              ? action.filter.currentPage
              : 1,
          },
          totalResults: action.data.meta.total,
          last_page: action.data.meta.last_page,
        },
      };
    case horseConstants.HORSE_LOCATION_ADD:
      return {
        ...state,
      };
    case horseConstants.HORSE_LOCATION_UPDATE:
      return {
        ...state,
        location: {
          ...state.location,
          addFormData: action.data,
        },
      };
    case horseConstants.HORSE_LOCATION_GET:
      return {
        ...state,
        location: {
          ...state.location,
          addFormData: action.data,
        },
      };
    case horseConstants.HORSE_LOCATION_DELETE:
      return {
        ...state,
        location: {
          ...state.location,
          totalResults: action.data.total,
          last_page: action.data.last_page,
        },
      };
    case horseConstants.HORSE_LOCATION_GET_CLEAR:
      return {
        ...state,
        location: {
          ...state.location,
          addFormData: {},
        },
      };
    case horseConstants.HORSE_CATEGORIES_GET:
      return {
        ...state,
        categories: action.data.data,
      };

    case horseConstants.HORSE_LIST_BY_TRAINER:
      return {
        ...state,
        horseListByTrainer: action.data.data,
      };

    default:
      return state;
  }
}

export default horseReducer;
