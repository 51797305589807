import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import SearchForm from "./components/SearchForm";

import { connect } from "react-redux";

import globalSearchActions from "../../redux/actions/globalSearchActions";
import { Redirect } from "react-router-dom";

const getValidUrl = (url = "") => {
  let newUrl = window.decodeURIComponent(url);
  newUrl = newUrl.trim().replace(/\s/g, "");

  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`;
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`;
  }

  return newUrl;
};
const Search = (props) => {
  const [bookings, setBookings] = useState(null);
  const [corporates, setCorporates] = useState(null);
  const [horses, setHorses] = useState(null);
  const [services, setServices] = useState(null);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    props.getGlobalSearch({
      search: "",
    });
  }, []);

  useEffect(() => {
    let indexCount = 1;
    setBookings(
      props.globalSearch.list.booking
        ? Object.keys(props.globalSearch.list.booking).reverse()
            .filter((k, i) => {
              if (i <= indexCount) {
                return k;
              }
            })
            .map((k, i) => {
              return (
                <li>
                  <a
                    target="_blank"
                    href={getValidUrl(props.globalSearch.list.booking[k].link)}
                  >
                    {props.globalSearch.list.booking[k].name}
                  </a>
                </li>
              );
            })
        : null
    );
    setCorporates(
      props.globalSearch.list.corporate
        ? Object.keys(props.globalSearch.list.corporate).reverse()
            .filter((k, i) => {
              if (i <= indexCount) {
                return k;
              }
            })
            .map((k, i) => {
              return (
                <li>
                  <a
                    target="_blank"
                    href={getValidUrl(
                      props.globalSearch.list.corporate[k].link
                    )}
                  >
                    {props.globalSearch.list.corporate[k].name}
                  </a>
                </li>
              );
            })
        : null
    );

    setHorses(
      props.globalSearch.list.horse
        ? Object.keys(props.globalSearch.list.horse).reverse()
            .filter((k, i) => {
              if (i <= indexCount) {
                return k;
              }
            })
            .map((k, i) => {
              return (
                <li>
                  <a
                    target="_blank"
                    href={getValidUrl(props.globalSearch.list.horse[k].link)}
                  >
                    {props.globalSearch.list.horse[k].name}
                  </a>
                </li>
              );
            })
        : null
    );

    setServices(
      props.globalSearch.list.service
        ? Object.keys(props.globalSearch.list.service).reverse()
            .filter((k, i) => {
              if (i <= indexCount) {
                return k;
              }
            })
            .map((k, i) => {
              return (
                <li>
                  <a
                    target="_blank"
                    href={getValidUrl(props.globalSearch.list.service[k].link)}
                  >
                    {props.globalSearch.list.service[k].name}
                  </a>
                </li>
              );
            })
        : null
    );

    setUsers(
      props.globalSearch.list.user
        ? Object.keys(props.globalSearch.list.user).reverse()
            .filter((k, i) => {
              if (i <= indexCount) {
                return k;
              }
            })
            .map((k, i) => {
              return (
                <li>
                  <a
                    target="_blank"
                    href={getValidUrl(props.globalSearch.list.user[k].link)}
                  >
                    {props.globalSearch.list.user[k].name}
                  </a>
                </li>
              );
            })
        : null
    );
  }, [props.globalSearch]);

  const resetResults = () => {
    setBookings(null);
    setCorporates(null);
    setHorses(null);
    setServices(null);
    setUsers(null);
  };
		let isMember =
      props.authData.user &&
      props.authData.user.userrole &&
      props.authData.user.userrole.includes("member")
        ? true
        : false;
  return (<>
    {isMember && <Redirect to={"/dashboard"} />}
    <div className="search-wrap">
      <Button
        className="search-close"
        onClick={() => {
          window.history.go(-1);
        }}
      >
        <span className="lnr lnr-cross" />
      </Button>
      <div className="search-wrap-in">
        <SearchForm
          onSubmit={(searchObj) => {
            searchObj =
              searchObj && searchObj.search ? searchObj : { search: "" };
            props.getGlobalSearch(searchObj);
          }}
          onChange={(searchObj) => {
            searchObj =
              searchObj && searchObj.search ? searchObj : { search: "" };
            props.getGlobalSearch(searchObj);
          }}
        />
        <Row>
          {bookings && (
            <Col md={6}>
              <Card className="search-resul-card rerports-card">
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Bookings</h5>
                  </div>
                  <ul className="list-unstyled">{bookings}</ul>
                </CardBody>
              </Card>
            </Col>
          )}
          {corporates && (
            <Col md={6}>
              <Card className="search-resul-card rerports-card">
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Corporates</h5>
                  </div>
                  <ul className="list-unstyled">{corporates}</ul>
                </CardBody>
              </Card>
            </Col>
          )}
          {horses && (
            <Col md={6}>
              <Card className="search-resul-card rerports-card">
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Horses</h5>
                  </div>
                  <ul className="list-unstyled">{horses}</ul>
                </CardBody>
              </Card>
            </Col>
          )}
          {services && (
            <Col md={6}>
              <Card className="search-resul-card rerports-card">
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Services</h5>
                  </div>
                  <ul className="list-unstyled">{services}</ul>
                </CardBody>
              </Card>
            </Col>
          )}
          {users && (
            <Col md={6}>
              <Card className="search-resul-card rerports-card">
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Users</h5>
                  </div>
                  <ul className="list-unstyled">{users}</ul>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </div>
    </div></>
  );
};

function mapState(state) {
  const authData = state.authentication;
  const permissions =
    typeof authData.user !== "undefined" &&
    Array.isArray(authData.user.permissions)
      ? authData.user.permissions
      : [];
  return {
    permissions,
    globalSearch: state.globalSearch,
     authData: state.authentication 
  };
}

const actionCreators = {
  getGlobalSearch: globalSearchActions.getGlobalSearchAction,
};

export default connect(mapState, actionCreators)(Search);

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
