import React, { PureComponent, useState } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import MagnifyIcon from "mdi-react/MagnifyIcon";
import CloseCircleIcon from "mdi-react/CloseCircleIcon";


const SearchComp = (props) => {
    let input;
    const handleClick = (e) => {
        e.preventDefault();
        props.onSearch(input.value);
    };
    const { searchText, searchClass } = props;
    const [inputtext, setInputtext] = useState(null);
    return (
        <div className="row justify-content-end">
            <form className={"form " + (searchClass ? searchClass : "col-3")} onSubmit={handleClick}>
                <div className='form__form-group'>
                    <div className='form__form-group-field'>
                        <input
                            name='search'
                            type='text'
                            ref={n => { input = n }}
                            placeholder={props.placeholder ? props.placeholder : 'Search...'}
                            onClick={handleClick}
                            defaultValue={searchText}
                            onChange={(e)=>{
                                setInputtext(e.target.value);
                            }}
                        />
                        {inputtext != null && <button type="button" onClick={(e)=>{
                            setInputtext(null);
                            input.value = '';
                            handleClick(e);
                        }} className="form__form-group-icon" style={{position: 'absolute', right: '35px', backgroundColor: 'transparent', borderColor: 'transparent', zIndex: '100'}}>
                            <CloseCircleIcon />
                        </button>}
                        <button
                            type="submit"
                            className="form__form-group-icon"
                            onClick={handleClick}>
                            <MagnifyIcon />
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

SearchComp.propTypes = {
    onSearch: PropTypes.func.isRequired,
    searchText: PropTypes.string,
    searchClass: PropTypes.string,
}
SearchComp.defaultProps = {
    searchText: ''
}


export default class ServerSideDataTable extends PureComponent {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape({
            dataField: PropTypes.string,
            text: PropTypes.string,
            editable: PropTypes.bool,
            sortable: PropTypes.bool,
        })).isRequired,
        onTableChange: PropTypes.func.isRequired,
        data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        page: PropTypes.number,
        totalSize: PropTypes.number,
        sizePerPage: PropTypes.number.isRequired,
        searchText: PropTypes.string,
        hideSearchFilter: PropTypes.bool,
        placeholder: PropTypes.string,
    };

    static defaultProps = {
        page: 1,
        totalSize: '',
        searchText: '',
        hideSearchFilter: true
    }



    render() {
        const { data, columns, onTableChange, sizePerPage, totalSize, searchText, page, hideSearchFilter, searchClass, placeholder } = this.props;

        const customTotal = (from, to, size) => {
            return (
                <span className="react-bootstrap-table-pagination-total">
                    Showing {from} to {to} of {size} Results
            </span>
            )
        };

        const noDataFound = () => {
            return (<div className="no-results">No Results Found</div>);
        };

        const options = {
            page,
            paginationSize: sizePerPage,
            sizePerPage: sizePerPage || 10,
            totalSize,
            hideSizePerPage: true, // Hide the sizePerPage dropdown always
            showTotal: true,
            paginationTotalRenderer: customTotal,
            hidePageListOnlyOnePage: true,
        };

        return (
            <ToolkitProvider
                keyField="id"
                data={data}
                columns={columns}
                search
            >
                {
                    toolkitprops => (
                        <div className="table">
                            { hideSearchFilter && <SearchComp {...toolkitprops.searchProps} searchText={searchText} searchClass={searchClass} placeholder={placeholder} />}
                            <BootstrapTable
								striped
                                {...toolkitprops.baseProps}
                                remote
                                noDataIndication={noDataFound}
                                bordered={false}
                                pagination={paginationFactory(options)}
                                onTableChange={onTableChange}
                            />
                        </div>
                    )
                }
            </ToolkitProvider>
        );
    }
}