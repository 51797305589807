import { change } from 'redux-form';
import API from '../../api/API';
import { documentConstants } from '../../_constants/dashboard.constants';
import alertActions from "./alertActions";
import preloaderActions from "./preloaderActions";



function getDocumentsAction(params) {

    function success(data) {
        return {
            type: documentConstants.DOCUMENT_GET,
            data,
            document_type: params.type
        }
    }

    return dispatch => {
        dispatch(preloaderActions.show());
        API.getDocument({ type: params.type, id: params.id }).then(
            response => {
                if (response.data) {
                    dispatch(success(response.data));
                }
                dispatch(preloaderActions.hide());
            }, error => {
                dispatch(preloaderActions.hide());
                if (error && error.response && error.response.data && error.response.data.message) {
                    dispatch(alertActions.error(error.response.data.error.message));
                }
            });
    };
}

function deleteDocumentAction(params) {

    function success(data) {
        return {
            type: documentConstants.DOCUMENT_DELETE,
            data,
            document_type: params.type
        }
    }

    return dispatch => {
        dispatch(preloaderActions.show());
        API.deleteDocument({ id: params.attachment_id }).then(
            response => {
                if (response.data) {
                    dispatch(alertActions.success(response.data.message));
                    dispatch(success(response.data));
                    dispatch(getDocumentsAction({ type: params.type, id: params.id }))
                }
                dispatch(preloaderActions.hide());
            }, error => {
                dispatch(preloaderActions.hide());
                if (error && error.response && error.response.data && error.response.data.message) {
                    dispatch(alertActions.error(error.response.data.error.message));
                }
            });
    };
}

function uploadDocumentAction(params) {

    // function success(data) {
    //     return {
    //         type: documentConstants.MEMBER_DOCUMENT_UPLOAD,
    //         data
    //     }
    // }

    return dispatch => {
        dispatch(preloaderActions.show(true));
        API.uploadDocument({
            object_id: params.id,
            documents: params.documents,
            type: params.type
        }).then(
            response => {
                if (response.data) {
                    dispatch(alertActions.success(response.data.message));
                    // dispatch(success(response.data));
                    if (params.type === 'user') {
                        dispatch(change('member_add_edit', 'member-documents', ''));
                    } else {
                        dispatch(change('horse_add_edit', 'horse-documents', ''));
                    }
                    dispatch(getDocumentsAction({ type: params.type, id: params.id }))

                }
                dispatch(preloaderActions.hide(false));
            }, error => {
                // dispatch(change('member_add_edit', 'member-documents', ''));
                if (params.type === 'user') {
                    dispatch(change('member_add_edit', 'member-documents', ''));
                } else {
                    dispatch(change('horse_add_edit', 'horse-documents', ''));
                }
                dispatch(preloaderActions.hide(false));
                // console.log(error.response);
                if (error && error.response && error.response.data && error.response.data.error.message) {
                    dispatch(alertActions.error(error.response.data.error.message));
                }
            });
    };
}



const documentActions = {
    getDocumentsAction,
    uploadDocumentAction,
    deleteDocumentAction,

}

export default documentActions;