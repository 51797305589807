import React, { PureComponent } from "react";
import { Card, CardBody, Col, Row, Button, ButtonToolbar } from "reactstrap";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import EyeIcon from 'mdi-react/EyeIcon';
import { withTranslation } from "react-i18next";
// import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderSelectField from "../../../../shared/components/form/Select";
import serviceActions from "../../../../redux/actions/serviceActions";
import renderSelectAsyncField from "../../../../shared/components/form/AsyncSelect";
// import validate from './validate';
// import renderDatePickerField from '../../../../shared/components/form/DatePicker';

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: "",
  meta: null,
  type: "text",
};

class VerticalForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    servicetype: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }).isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    id: PropTypes.string,
  };

  static defaultProps = {
    id: null,
  };

  // constructor(props) {
  //   super(props);
  //   console.log(props);
  //   this.state = {
  //     // showMaxItem: false,
  //   };
  // }

  componentDidMount() {
    const { props } = this;
    if (props.id) {
      props.getService("singleSession", props.id);
    }
  }

  componentWillUnmount() {
    const { props } = this;
    props.clearServiceFrom();
  }

  getValues = (values) => {
    const { props } = this;
    const data = {};
    data.service_type = 1;

    if (values) {
      data.service_category = values.category ? values.category.value : null;
      // data.duration = values.service_duration
      //   ? values.service_duration.value
      //   : null;
      data.duration = "00:45:00";
      data.repeat_type = values.service_repeat_type
        ? values.service_repeat_type.value
        : null;
      data.max_riders = values.max_riders;

      if (values.service_repeat_type) {
        if (values.service_repeat_type.value === 1) {
          data.max_riders = 1;
        }
      }

      data.price = values.price;
      data.offer_price = values.offer_price;
      data.service_name = values.service_name;
    }

    props.addUpdateService("singleSession", JSON.stringify(data), props.id);
  };

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      servicetype,
      id,
    } = this.props;

    return (
      <Col md={12} lg={12} xl={12}>
        <form className="form" onSubmit={handleSubmit(this.getValues)}>
          <div className="formInner">
            <Card>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Name</span>
                      <span style={{ color: "red" }}>*</span>
                      <div className="form__form-group-field">
                        <Field
                          name="service_name"
                          component={renderField}
                          type="text"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Service Type
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="service_repeat_type"
                          component={renderSelectField}
                          type="text"
                          options={[
                            { value: 2, label: "Group" },
                            { value: 1, label: "Private" },
                          ]}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  {servicetype && servicetype.value === 2 && (
                    <Col md={6}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Max riders
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="max_riders"
                            component={renderField}
                            type="text"
                            placeholder="Max riders"
                          />
                        </div>
                      </div>
                    </Col>
                  )}
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Duration</span>
                      <div className="form__form-group-field">
                        <Field
                          name="service_duration"
                          component={renderSelectField}
                          input={{value:{ value: "00:45:00", label: "45MINS" }}}
                          isDisabled={true}
                          type="text"
                          options={[
                            // { value: "00:10:00", label: "10MINS" },
                            // { value: "00:20:00", label: "20MINS" },
                            // { value: "00:30:00", label: "30MINS" },
                            // { value: "00:40:00", label: "40MINS" },
                            { value: "00:45:00", label: "45MINS" },
                            // { value: "00:50:00", label: "50MINS" },
                            // { value: "01:00:00", label: "1HR" },
                            // { value: "02:00:00", label: "2HR" },
                            // { value: "03:00:00", label: "3HR" },
                            // { value: "04:00:00", label: "4HR" },
                            // { value: "04:00:00", label: "5HR" },
                          ]}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Price</span>
                      <div className="form__form-group-field">
                        <Field
                          name="price"
                          component={renderField}
                          type="text"
                          placeholder="Price"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Offer Price
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="offer_price"
                          component={renderField}
                          type="text"
                          placeholder="Offer Price"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Service Category
                      </span>
                      <div className="form__form-group-field">
                        {/* <Field
                          name="service_category"
                          component={renderSelectField}
                          type="text"
                          options={[
                            { value: 'Categoty 1', label: 'Categoty 1' },
                            { value: 'Categoty 2', label: 'Categoty 2' },
                            { value: 'Categoty 3', label: 'Categoty 3' },
                          ]}
                          placeholder="Service Category"
                        /> */}
                        <Field
                          name="category"
                          component={renderSelectAsyncField}
                          api="categorylist/3"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <ButtonToolbar className="form__button-toolbar">
                  {/* <Button tag={Link} to={{ pathname: 'listing', search: '?action=edit' }} color="primary">
                    Submit
                  </Button>
                  <Button type="button" onClick={reset} disabled={pristine || submitting}>
                    Cancel
                  </Button> */}
                  <Button
                    className="rounded"
                    type="submit"
                    color="primary"
                    disabled={pristine || submitting}
                  >
                    {id ? "Update" : "Add"}
                  </Button>
                  {/* {id ? (
                    <Button
                      className="rounded"
                      type="button"
                      onClick={reset}
                      disabled={pristine || submitting}
                    >
                      Cancel
                    </Button>
                  ) : ( */}
                  <Button
                    className="rounded"
                    tag={Link}
                    outline
                    // color="primary"
                    to="/services/single"
                  >
                    Cancel
                  </Button>
                  {/* )} */}
                </ButtonToolbar>
              </CardBody>
            </Card>
          </div>
        </form>
      </Col>
    );
  }
}

// export default connect((state) => {
//   const servicetype = selector(state, 'servicetype');
//   return {
//     initialValues: {
//       name: 'GROUP JUNIOR BEGINNER',
//       servicetype: { value: 'group', label: 'Group' },
//       duration: { value: '45MINS', label: '45MINS' },
//       service_category: { value: 'Categoty 2', label: 'Categoty 2' },
//       price: '200.00',
//       max_riders: '20',
//     },
//     servicetype,
//   };
// })(reduxForm({
//   form: 'edit_service',
//   validate,
// })(withTranslation('common')(VerticalForm)));

const selector = formValueSelector("service_single_add_edit"); // <-- same as form name

const mapState = (state) => {
  const servicetype = selector(state, "service_repeat_type");
  let initValues = state.service.singleSession.addFormData.data ? state.service.singleSession.addFormData.data : null;
  

  if (initValues) {
    initValues.service_repeat_type = initValues.repeat_type
      ? initValues.repeat_type
      : null;
    initValues.service_duration = initValues.duration
      ? initValues.duration
      : null;
      initValues.category = initValues.service_category
      ? initValues.service_category
      : null;
  }


  return {
    initialValues: initValues,
    servicetype,
  };
};

const actionCreators = {
  getService: serviceActions.getServiceAction,
  addUpdateService: serviceActions.addUpdateServiceAction,
  clearServiceFrom: serviceActions.clearServiceFromAction,
};
//

// You have to connect() to any reducers that you wish to connect to yourself
export default connect(
  mapState,
  actionCreators // bind account loading action creator
)(
  reduxForm({
    form: "service_single_add_edit", // a unique identifier for this form
    // validate
  })(withTranslation("common")(VerticalForm))
);
