import React from "react";
import { Col, Container, Row } from "reactstrap";
import { Redirect } from "react-router-dom";

import PropTypes from "prop-types";
import VerticalForm from "./components/VerticalForm";
import showResults from "./Show";

const BookingSingle = (props) => {
  const { match } = props;
  const title = match.params.action === "add" ? "Add Lesson" : "Edit Lesson";

  return (
    <>
      {match.params.action != "add" && match.params.action != "edit" && (
        <Redirect to={{ pathname: "/dashboard" }} />
      )}
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{title}</h3>
          </Col>
        </Row>
        <Row>
          <VerticalForm
            onSubmit={showResults}
            id={match.params.id}
            key={match.params.id}
            from={props.location.from}
            selectedSlot={props.location.selected}
          />
        </Row>
      </Container>
    </>
  );
};

BookingSingle.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string.isRequired,
      id: PropTypes.string,
    }),
  }),
  // sizePerPage: PropTypes.number.isRequired
};

BookingSingle.defaultProps = {
  match: {
    params: {
      id: null,
    },
  },
};

export default BookingSingle;
