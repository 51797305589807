import React, { useEffect, useState } from "react";
import { Container, Card, CardBody, Col, Row } from "reactstrap";
import AppointmentsChart from "./components/AppointmentsChart";
import SalesChart from "./components/SalesChart";

import { connect } from "react-redux";
import API from "../../../api/API";
import reportAnalyticsStatActions from "../../../redux/actions/reportAnalyticsStatActions";
import { constructUrlParams } from "../../../_helpers/CommonFunctions";


const Analytics = (props) => {
  const today = new Date();

  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth() + 1);
  useEffect(() => {
    const urlParams = constructUrlParams({
      year: year,
      month: month,
    });
    props.getAnalyticsStatAction(urlParams);
  }, [year, month]);



  let widgets = props.analyticsStat.list
    ? Object.keys(props.analyticsStat.list.widgets).map((k, i) => {
      let label = k == 'cancels' ? 'Cancellations' : k;
        return (
          <Col md={4}>
            <Card className="anlytics-card">
              <CardBody>
                <div className="card_cs_title">
                  <h5 className="bold-text">
                    {label}
                    {/* <span className="cnt">3000</span> */}
                  </h5>
                </div>
                <ul className="list-unstyled">
                  <li>
                    <span>Last Month</span>
                    <strong className="list-stats">
                      {props.analyticsStat.list.widgets[k].last_month}
                    </strong>
                  </li>
                  <li>
                    <span>Sales</span>
                    <strong className="list-stats">
                      {props.analyticsStat.list.widgets[k].sales}
                    </strong>
                  </li>
                  <li>
                    <span>Sales Percentage</span>
                    <strong className="list-stats">
                      {props.analyticsStat.list.widgets[k].sales_percentage}
                    </strong>
                  </li>
                  {props.analyticsStat.list.widgets[k].current_month &&
                  <li>
                    <span>Current Month</span>
                    <strong className="list-stats">
                      {props.analyticsStat.list.widgets[k].current_month}
                    </strong>
                  </li>}

                  <li>
                    <span>This Month</span>
                    <strong className="list-stats">
                      {props.analyticsStat.list.widgets[k].this_month}
                    </strong>
                  </li>
                  {props.analyticsStat.list.widgets[k].this_year &&
                  <li>
                    <span>This Year</span>
                    <strong className="list-stats">
                      {props.analyticsStat.list.widgets[k].this_year}
                    </strong>
                  </li>}

                </ul>
              </CardBody>
            </Card>
          </Col>
        );
      })
    : null;

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <div style={{marginBottom: '20px'}}>
          <h3 className="page-title"  style={{display: 'inline-block'}}>Analytics</h3><h5 style={{display: 'inline-block', marginLeft: '10px'}}>(Billable Bookings)</h5>
          </div>
        </Col>
      </Row>
      <Row>{widgets}</Row>
      <Row>
        {/* <AppointmentsChart /> */}
        {props.analyticsStat.list &&
          props.analyticsStat.list.charts &&
          props.analyticsStat.list.charts.sales_chart && (
            <SalesChart salesData={props.analyticsStat.list.charts.sales_chart} year={year} month={month} setYear={setYear} setMonth={setMonth} />
          )}
      </Row>
    </Container>
  );
};

function mapState(state) {
  const authData = state.authentication;
  const permissions =
    typeof authData.user !== "undefined" &&
    Array.isArray(authData.user.permissions)
      ? authData.user.permissions
      : [];

  return {
    permissions,
    analyticsStat: state.analyticsStat,
  };
}

const actionCreators = {
  getAnalyticsStatAction: reportAnalyticsStatActions.getAnalyticsStatAction,
};

export default connect(mapState, actionCreators)(Analytics);
