import React from "react";
import { Col, Container, Row, Button } from "reactstrap";

import { Link } from "react-router-dom";
import Permission from "../../Permission/components/Permission";
import DataTable from "./components/DataTable";

const BookingCancelListing = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Bookings Cancelled</h3>
      </Col>
    </Row>
    <Row>
      <DataTable />
    </Row>
  </Container>
);

export default BookingCancelListing;
