import { reportConstants } from "../../_constants/dashboard.constants";

const initialState = {
  list: null,
};

function reportAnalyticsStatReducer(state = initialState, action) {
  switch (action.type) {
    case reportConstants.REPORT_ANALYTICS_STAT:
      return {
        ...state,
        list: action.data.data,
      };
    default:
      return state;
  }
}

export default reportAnalyticsStatReducer;
