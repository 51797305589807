import React from "react";
import {
  Col,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import Permission from "../../Permission/components/Permission";

const ServiceCommonHeader = (props) => {
  const { title } = props;
  return (
    <Row>
      <Col md={9}>
        <h3 className="page-title">{title}</h3>
      </Col>
      <Col md={3} className="text-right">
        <Permission id="edit-services">
          <UncontrolledDropdown>
            <DropdownToggle
              className="icon icon--right rounded"
              color="primary"
            >
              <p>
                Add New <ChevronDownIcon />
              </p>
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <DropdownItem>
                <Link to="/services/single/add">New Single Session</Link>
              </DropdownItem>
              <DropdownItem>
                <Link to="/services/packages/add">New Package</Link>
              </DropdownItem>
              <DropdownItem>
                <Link to="/services/activity/add">New Acitivity</Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Permission>
      </Col>
    </Row>
  );
};

ServiceCommonHeader.propTypes = {
  title: PropTypes.string.isRequired,
  // corporate: PropTypes.shape({
  //     totalResults: PropTypes.number,
  //     currentPage: PropTypes.number.isRequired,
  //     search: PropTypes.string,
  //     list: PropTypes.arrayOf(PropTypes.shape({
  //         id: PropTypes.number.isRequired,
  //         company_name: PropTypes.string.isRequired,
  //         register_no: PropTypes.string.isRequired,
  //         domain: PropTypes.string.isRequired,
  //     }))
  // }),
};

//   ServiceCommonHeader.defaultProps = {
//     corporate: {
//         search: '',
//         totalResults: '',
//     },
// };

export default ServiceCommonHeader;
