import React, { PureComponent } from "react";
import classnames from "classnames";

import { Field } from "redux-form";
import clonedeep from "lodash.clonedeep";

import API from "../../../../api/API";

import renderParticipantField from "../../../../shared/components/form/Participants";

class SlideInAddBooking extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      riderData: {},
      horseData: {},
    };
  }

  toggleContent = (key) => {
    const { state } = this;
    const newState = clonedeep(state);
    newState[key].toggle =
      "toggle" in newState[key] ? !newState[key].toggle : false;

    this.setState(newState);
  };

  getRiderDetails = (e) => {
    const { state } = this;

    if (e && "value" in e) {
      const newState = clonedeep(state);
      API.getMember(e.value).then(
        (response) => {
          if (response.data) {
            newState.riderData = {
              data: response.data.data,
              toggle: true,
            };
            this.setState(newState);
          } else {
            this.resetState("riderData");
          }
        },
        () => {
          this.resetState("riderData");
        }
      );
    }
  };

  getHorseDetails = (e) => {
    const { state } = this;

    if (e && "value" in e) {
      const newState = clonedeep(state);
      API.getHorse(e.value).then(
        (response) => {
          if (response.data) {
            newState.horseData = {
              data: response.data.data,
              toggle: true,
            };
            this.setState(newState);
          } else {
            this.resetState("horseData");
          }
        },
        () => {
          this.resetState("horseData");
        }
      );
    }
  };

  resetState = (key = "") => {
    const { state } = this;
    let newState = {
      riderData: {},
      horseData: {},
    };
    if (key.length > 0) {
      newState = clonedeep(state);
      newState[key] = {};
    }

    this.setState(newState);
  };

  render() {
    const { id, rider, horse, bookingtype, isChanged } = this.props;
    const { riderData, horseData } = this.state;

    const showRiderDetails =
      "value" in rider && Object.keys(riderData).length > 0;
    const showHorseDetails =
      "value" in horse && Object.keys(horseData).length > 0;

    return (
      <>
        <div className="form__form-group">
          <label className="form__form-group-label">Select Rider</label>
          <span style={{ color: "red" }}>*</span>
          <Field
            name="asign_member"
            component={renderParticipantField}
            booking={id}
            bookingType={bookingtype}
            api="participantlist/riders"
            onChange={(e) => {
              this.getRiderDetails(e);
              this.props.onChange();
            }}
            isChanged={isChanged}
            placeholder="Select Rider"
          />
          {showRiderDetails && (
            <div
              className={classnames({
                "cs-accordion": true,
                open: "toggle" in riderData && riderData.toggle,
              })}
            >
              <h4
                className="cs-accordion-title"
                onClick={() => this.toggleContent("riderData")}
              >
                DETAILS
                <span className="lnr lnr-chevron-down" />
              </h4>
              <div className="cs-accordion-content">
                <ul className="slide-in-content-item-list">
                  <li className="col-half">
                    <span>ID:</span>
                    <span>{riderData.data.id}</span>
                  </li>
                  <li className="col-half">
                    <span>Company:</span>
                    <span>
                      {riderData.data.corporate
                        ? riderData.data.corporate.label
                        : ""}
                    </span>
                  </li>
                  <li className="col-half">
                    <span>Phone:</span>
                    <span>{riderData.data.mobile}</span>
                  </li>
                  <li className="col-half">
                    <span>Email:</span>
                    <span>{riderData.data.email}</span>
                  </li>
                  <li className="col-half">
                    <span>Horses:</span>
                    <span></span>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className="form__form-group">
          <label className="form__form-group-label">Select Horse</label>
          <span style={{ color: "red" }}>*</span>
          <Field
            name="asign_horse"
            component={renderParticipantField}
            booking={id}
            bookingType={bookingtype}
            api="participantlist/horses"
            onChange={(e) => {
              this.getHorseDetails(e);
              this.props.onChange();
            }}
            isChanged={isChanged}
            placeholder="Select Horse"
          />
          {showHorseDetails && (
            <div
              className={classnames({
                "cs-accordion": true,
                open: "toggle" in horseData && horseData.toggle,
              })}
            >
              <h4
                className="cs-accordion-title"
                onClick={() => this.toggleContent("horseData")}
              >
                DETAILS
                <span className="lnr lnr-chevron-down" />
              </h4>
              <div className="cs-accordion-content">
                <ul className="slide-in-content-item-list">
                  <li className="col-half">
                    <span>ID:</span>
                    <span>{horseData.data.id}</span>
                  </li>
                  <li className="col-half">
                    <span>Owner:</span>
                    <span>
                      {horseData.data.owner_name
                        ? horseData.data.owner_name
                        : ""}
                    </span>
                  </li>
                  <li className="col-half">
                    <span>Category:</span>
                    <span>
                      {horseData.data.category_name
                        ? horseData.data.category_name
                        : ""}
                    </span>
                  </li>
                  <li className="col-half">
                    <span>FEI ID:</span>
                    <span>{horseData.data.horse_fei_id}</span>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default SlideInAddBooking;
