import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const PermissionGroup = ({name, authData, children}) => {
    const { user: { permissions } } = authData;
    let hasPermission = false;
    if (typeof name !== 'undefined') {
        const regex = RegExp(name);
        hasPermission = permissions.some((permission) => regex.test(permission) );
    }
    
    if (!hasPermission) {
        return null;
    }
    return (
        <>
            {children}
        </>
    );
};

PermissionGroup.propTypes = {
    name: PropTypes.string.isRequired,
    authData: PropTypes.objectOf(PropTypes.any),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element.isRequired
    ]),
};

PermissionGroup.defaultProps = {
    authData: {
        user: {
            permissions: [],
        },
    },
    children: null,
};

const mapStateToProps = (state) => ({ authData: state.authentication });

export default connect(mapStateToProps)(PermissionGroup);