/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Permission from "../../../Permission/components/Permission";
import bookingActions from "../../../../redux/actions/bookingActions";
import ServerSideDataTable from "../../../../shared/components/table/ServerSideDataTable";

class DataTable extends PureComponent {
  static propTypes = {
    getBookingList: PropTypes.func.isRequired,
    booking: PropTypes.shape({
      totalResults: PropTypes.number,
      filter: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        search: PropTypes.string,
      }),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
        })
      ),
    }),
  };

  static defaultProps = {
    booking: {
      filter: {
        search: "",
      },
      totalResults: "",
    },
  };

  constructor(props) {
    super(props);
    // props.setDefaults();
    props.getBookingList();
  }

  // componentDidMount() {
  //   console.log('componentDidMount');
  // }

  handleTableChange = (type, { searchText, page }) => {
    const { props } = this;
    props.getBookingList({ currentPage: page, search: searchText });
  };

  customActions = (cell, row, rowIndex, custom) => {
    return (
      <ButtonToolbar className="table-actions">
        <Permission id="edit-bookings">
          <Button
            tag={Link}
            to={`/booking/edit/${row.id}`}
            color="primary"
            size="sm"
            className="rounded"
          >
            Edit
          </Button>
        </Permission>

        <Permission id="delete-bookings">
          <Button
            color="danger"
            size="sm"
            className="rounded"
            onClick={() => {
              if (
                window.confirm("Are you sure you wish to delete this item?")
              ) {
                custom(row.id);
              }
            }}
          >
            Delete
          </Button>
        </Permission>
        <Button
          tag={Link}
          to={`/booking/cancel/${row.id}`}
          color="secondary"
          size="sm"
          className="rounded"
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          size="sm"
          className="rounded"
          onClick={() => {
            if (
              window.confirm("Are you sure you wish to no show this item?")
            ) {
              this.props.noShowBooking(row.id);
            }
          }}
        >
          No Show
        </Button>
      </ButtonToolbar>
    );
  };

  render() {
    const { props } = this;
    const { permissions, booking } = props;
    const hasActionPerms =
      permissions.includes("edit-bookings") ||
      permissions.includes("delete-bookings");

    const columns = [
      {
        dataField: "id",
        text: "Bid",
      },
      {
        dataField: "service_name",
        text: "Lesson Type",
      },
      
      {
        dataField: "date",
        text: "Added Date",
      },
      {
        dataField: "start_time",
        text: "Start Time",
      },
      {
        dataField: "end_time",
        text: "End Time",
      },
      {
        dataField: "trainer_name",
        text: "Trainer",
      },
      {
        dataField: "arena_name",
        text: "Arena",
      },
      {
        dataField: "created_user",
        text: "Customer name",
      },
      // {
      //   dataField: 'notes',
      //   text: 'Notes',
      // },
      {
        dataField: "actions",
        text: "Actions",
        formatter: this.customActions,
        formatExtraData: props.deleteBooking,
        hidden: !hasActionPerms,
        headerStyle: (colum, colIndex) => {
          return { width: '350px'};
        }
      },
    ];

    return (
      <Col md={12} lg={12}>
        <Card>
          {booking.list && (
            <CardBody>
              <ServerSideDataTable
                data={booking.list}
                columns={columns}
                onTableChange={this.handleTableChange}
                page={booking.filter.currentPage}
                sizePerPage={booking.sizePerPage}
                totalSize={booking.totalResults}
                searchText={booking.filter.search}
                placeholder={'Search by lesson type, customer name, phone number, trainer'}
                searchClass={'col-6'}
              />
            </CardBody>
          )}
        </Card>
      </Col>
    );
  }
}

function mapState(state) {
  const authData = state.authentication;
  const permissions =
    typeof authData.user !== "undefined" &&
    Array.isArray(authData.user.permissions)
      ? authData.user.permissions
      : [];

  return {
    permissions,
    booking: state.booking,
  };
}

const actionCreators = {
  getBookingList: bookingActions.getBookingListAction,
  deleteBooking: bookingActions.deleteBookingAction,
  noShowBooking: bookingActions.noShowBookingAction,

  // setDefaults: bookingActions.setCorporateDefaultsAction
};

export default connect(mapState, actionCreators)(DataTable);
