/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import reportChangeLogActions from "../../../../../redux/actions/reportChangeLogActions";
import ServerSideDataTable from "../../../../../shared/components/table/ServerSideDataTable";

// let tableData = null;
class DataTable extends PureComponent {
  static propTypes = {
    getReportChangeLogAction: PropTypes.func.isRequired,
    changeLog: PropTypes.shape({
      totalResults: PropTypes.number,
      filter: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        search: PropTypes.string,
        // st_date: PropTypes.string,
        // ed_date: PropTypes.string,
      }),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          object_id: PropTypes.number.isRequired,
          added_date: PropTypes.string.isRequired,
        })
      ),
    }),
  };

  static defaultProps = {
    changeLog: {
      search: "",
      totalResults: null,
    },
  };

  constructor(props) {
    super(props);
    // props.setDefaults();

    props.getReportChangeLogAction({});
  }

  // componentDidMount() {
  //   console.log("componentDidMount");
  // }

  customActions = (cell, row, rowIndex, custom) => {
    return (
      <ButtonToolbar className="table-actions">
        {/* <Permission id="edit-bookings"> */}
        <Button
          tag={Link}
          to={`/reports/change-log-view/${row.object_id}`}
          color="primary"
          size="sm"
          className="rounded"
        >
          View
        </Button>
        {/* </Permission> */}
      </ButtonToolbar>
    );
  };

  render() {
    const { props } = this;
    const { changeLog } = props;

    const columns = [
      {
        dataField: "object_id",
        text: "ID",
        // hidden: true,
      },
      {
        dataField: "object",
        text: "Object",
      },
      {
        dataField: "added_date",
        text: "Added Date",
      },
      {
        dataField: "actions",
        text: "Actions",
        formatter: this.customActions,
        formatExtraData: props.deleteBooking,
        // hidden: !hasActionPerms,
      },
    ];
    return (
      <Col md={12} lg={12}>
        <Card>
          {changeLog && changeLog.list && (
            <CardBody>
              <ServerSideDataTable
                data={changeLog.list}
                columns={columns}
                // onTableChange={this.handleTableChange}
                page={changeLog.filter.currentPage}
                sizePerPage={props.sizePerPage}
                totalSize={changeLog.totalResults}
                // searchText={changeLog.filter.search}
                hideSearchFilter={false}
              />
            </CardBody>
          )}
        </Card>
      </Col>
    );
  }
}

function mapState(state) {
  const authData = state.authentication;
  const permissions =
    typeof authData.user !== "undefined" &&
    Array.isArray(authData.user.permissions)
      ? authData.user.permissions
      : [];
  return {
    permissions,
    sizePerPage: state.changeLog.sizePerPage,
    changeLog: state.changeLog,
  };
}

const actionCreators = {
  getReportChangeLogAction: reportChangeLogActions.getReportChangeLogAction,
  // setDefaults: memberActions.setCorporateDefaultsAction
};

export default connect(mapState, actionCreators)(DataTable);
