import React from "react";
// import { connect } from 'react-redux';
import ResetPasswordForm from "./components/ResetPassword";

const ResetPassword = props => (
	<div className='account'>
		<div className='account__wrapper'>
			<div className='account__card'>
				<div className='account__head'>
					<h3 className='account__title'>
						Welcome to
						<span className='account__logo'>
							{" "}
							Nashmi
							<span className='account__logo-accent'>.io</span>
						</span>
					</h3>
					<h4 className='account__subhead subhead'>Stable Management System</h4>
				</div>
				<ResetPasswordForm onSubmit {...props}/>
			</div>
		</div>
	</div>
);

export default ResetPassword;
