import React, { PureComponent } from "react";
import {
	Card,
	CardBody,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
	Table,
	Row
} from "reactstrap";
import { Link } from "react-router-dom";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import PropTypes from "prop-types";
import Pagination from "../../../../shared/components/pagination/Pagination";
// import { CryptoTableProps } from '../../../../shared/prop-types/TablesProps';

// import { CryptoTableProps } from '../../../../shared/prop-types/TablesProps';

export default class ActionsTable extends PureComponent {
	constructor() {
		super();

		this.heads = [
			{
				key: "item",
				name: "Item",
				sortable: true
			},
			{
				key: "items_sold",
				name: "Items Sold",
				sortable: true
			},
			{
				key: "gross_sales",
				name: "Gross Sales",
				sortable: true
			},
			{
				key: "discounts",
				name: "Discounts",
				sortable: true
			},
			{
				key: "refunds",
				name: "Refunds",
				sortable: true
			},
			{
				key: "net_sales",
				name: "Net Sales",
				sortable: true
			}
		];

		const initialPageNumber = 1;
		const initialRowsCount = 10;

		const minRows = 1;
		const maxRows = 1;
		const rowsCount = Math.random() * (maxRows - minRows);

		const originalRows = this.createRows(rowsCount + minRows);
		const currentPageRows = this.filterRows(
			originalRows,
			initialPageNumber,
			initialRowsCount
		);

		this.state = {
			rows: originalRows,
			rowsToShow: currentPageRows,
			pageOfItems: initialPageNumber,
			itemsToShow: initialRowsCount
		};
	}

	getRandomDate = (start, end) =>
		new Date(
			start.getTime() + Math.random() * (end.getTime() - start.getTime())
		).toLocaleDateString();

	createRows = numberOfRows => {
		const rows = [];
		for (let i = 1; i < numberOfRows + 1; i += 1) {
			rows.push({
				item: "Total",
				items_sold: "0",
				gross_sales: "AED 0",
				discounts: "AED 0",
				refunds: "AED 0",
				net_sales: "AED 0"
				/* breed: ['Abaco Barb', 'American', 'Austrian', 'Boerperd', 'Curly Horse'][Math.floor((Math.random() * 5))],
        fei_id: ['106OL71', '106OL71', '106OL71', '106OL71'][Math.floor((Math.random() * 4))],
        fei_issue_date: this.getRandomDate(new Date(2018, 3, 1), new Date(2000, 3, 1)),
        chip_id: 2645 + i,
        status: ['Unavailable', 'Available'][Math.floor((Math.random() * 2))], */
			});
		}
		rows.filter = (obj, predicate) =>
			rows.assign(
				...rows
					.keys(obj)
					.filter(key => predicate(obj[key]))
					.map(key => ({ [key]: obj[key] }))
			);
		return rows;
	};

	filterRows = (originalRows, pageNumber, rowsOnPage) => {
		const rowsFrom = rowsOnPage * (pageNumber - 1);
		const rowsTo = rowsFrom + rowsOnPage;
		return originalRows.slice(rowsFrom, rowsTo);
	};

	onChangePage = pageOfItems => {
		const { rows, itemsToShow } = this.state;
		if (pageOfItems) {
			const rowsToShow = this.filterRows(rows, pageOfItems, itemsToShow);
			this.setState({ rowsToShow, pageOfItems });
		}
	};

	searchResults = () => {};

	render() {
		const { rows, itemsToShow, pageOfItems, rowsToShow } = this.state;

		return (
			<Col md={12} lg={12} xl={12}>
				<Card>
					<CardBody>
						<Row>
							<Col md={9}>
								<div className='card__title'>
									{/* <h5 className="bold-text">Text</h5> */}
									{/* <h5 className="subhead">Use default table</h5> */}
								</div>
							</Col>
							<Col md={3} className='text-right'>
								<form className='form'>
									<div className='form__form-group'>
										<div className='form__form-group-field'>
											<input
												name='search'
												type='text'
												placeholder='Search...'
												onChange={this.searchResults(this.value)}
											/>
											<div className='form__form-group-icon'>
												<MagnifyIcon />
											</div>
										</div>
									</div>
								</form>
							</Col>
						</Row>
						<Table responsive className='table--bordered'>
							<thead>
								<tr>
									{this.heads.map((entry, index) => (
										<th data-index={index}>{entry.name}</th>
									))}
								</tr>
							</thead>
							<tbody>
								{rowsToShow.map((entry, index) => (
									<tr>
										<td data-index={index}>{entry.item}</td>
										<td data-index={index}>{entry.items_sold}</td>
										<td data-index={index}>{entry.gross_sales}</td>
										<td data-index={index}>{entry.discounts}</td>
										<td data-index={index}>{entry.refunds}</td>
										<td data-index={index}>{entry.net_sales}</td>
									</tr>
								))}
							</tbody>
						</Table>
						<Pagination
							itemsCount={rows.length}
							itemsToShow={itemsToShow}
							pageOfItems={pageOfItems}
							onChangePage={this.onChangePage}
						/>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

const DropDownMore = ({ index, handleDeleteRow }) => (
	<UncontrolledDropdown className='dashboard__table-more'>
		<DropdownToggle>
			<p>
				<DotsHorizontalIcon />
			</p>
		</DropdownToggle>
		<DropdownMenu className='dropdown__menu'>
			<Link to={`/dashboard_crypto/edit/${index}`}>
				<DropdownItem>Edit</DropdownItem>
			</Link>
			<DropdownItem onClick={handleDeleteRow}>Delete</DropdownItem>
		</DropdownMenu>
	</UncontrolledDropdown>
);
DropDownMore.propTypes = {
	index: PropTypes.number.isRequired,
	handleDeleteRow: PropTypes.func.isRequired
};
