import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import Calendar from "./Calendar";

const TrainerCalendar = () => (
	<Col md={12} lg={12} xl={12}>
		<Card>
			<CardBody>
				<Calendar />
			</CardBody>
		</Card>
	</Col>
);

export default TrainerCalendar;
