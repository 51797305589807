import React, { PureComponent } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  ButtonToolbar,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// import EyeIcon from 'mdi-react/EyeIcon';
// import { withTranslation } from 'react-i18next';
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import renderSelectField from "../../../../shared/components/form/Select";
import renderSelectAsyncField from "../../../../shared/components/form/AsyncSelect";

// import validate from './validate';
import renderDatePickerField from "../../../../shared/components/form/DatePicker";
import renderDropZoneMultipleField from "../../../../shared/components/form/DropZoneMultiple";
// import AddOwner from '../../AddOwner';
import horseActions from "../../../../redux/actions/horseActions";
import documentActions from "../../../../redux/actions/documentActions";

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: "",
  meta: null,
  type: "text",
};

class VerticalForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    selectedOwner: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    id: PropTypes.string,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        attachment_url: PropTypes.string,
      })
    ),
    shouldDisable: PropTypes.bool,
    formDefaults: PropTypes.shape({}),
  };

  static defaultProps = {
    selectedOwner: {},
    id: null,
    shouldDisable: false,
    documents: [],
    categories: [],
    formDefaults: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      activeTab: "1",
    };
  }

  componentWillMount() {
    const { props } = this;
    props.getHorseCategories();
  }

  componentDidMount() {
    const { props } = this;
    if (props.id) {
      props.getHorse(props.id);
      props.getDocuments({ type: "horse", id: props.id });
    }
  }

  componentWillUnmount() {
    // console.log('componentWillUnmount');
    const { props } = this;
    props.clearHorseFrom();
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  getValues = (values) => {
    const { props } = this;
    const params = {
      horse_name: values.name,
      owner_id: values.owner ? values.owner.value : "",
      category_id: values.category ? values.category.value : "",
      price: values.price,
      location_id: values.location ? values.location.value : "",
      horse: values.horse,
      height: values.height,
      weight: values.weight,
      dob: values.dob,
      color: values.color,
      gender: values.gender ? values.gender.value : "",
      breed: values.breed,
      fei_issued_on: values.fei_issued_on,
      horse_fei_id: values.horse_fei_id,
      chip_id: values.chip_id,
    };
    props.addUpdateHorse(JSON.stringify(params), props.id);
  };

  removeDocument = (id, e) => {
    const { props } = this;
    // console.log(i, e);
    e.preventDefault();
    e.stopPropagation();
    if (window.confirm("Are you sure you wish to delete this item?")) {
      props.deleteDocument({ id: props.id, type: "horse", attachment_id: id });
    }
  };

  previewDocument = (e, documentUrl) => {
    e.preventDefault();
    window.open(documentUrl, "_blank");
  };

  onDropzoneChange = (filesToUpload) => {
    const { props } = this;
    // console.log(filesToUpload);
    if (!filesToUpload) {
      return;
    }
    props.uploadDocument({
      type: "horse",
      id: props.id,
      documents: filesToUpload,
    });

    // this.onDropzoneChange(value ? value.concat(filesToUpload) : filesToUpload);
  };

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      selectedOwner,
      id,
      documents,
      shouldDisable,
      categories,
      formDefaults,
    } = this.props;

    const { activeTab } = this.state;

    // console.log("selectedOwner", selectedOwner);

    // let ModalButton;
    // if (selectedOwner && selectedOwner.value) {
    //   ModalButton = <AddOwner />;
    // } else {
    //   ModalButton = <Button color="primary" disabled className="input-btn-r rounded">Add Horse</Button>;
    // }

    return (
      <Col md={12} lg={12} xl={12}>
        <form className="form" onSubmit={handleSubmit(this.getValues)}>
          <div className="formInner">
            <Card>
              <CardBody>
                <div className="tabs">
                  <div className="tabs__wrap">
                    <Nav tabs className="cs-form-tabs">
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => {
                            this.toggle("1");
                          }}
                        >
                          Basic Information
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => {
                            this.toggle("2");
                          }}
                        >
                          Additional Information
                        </NavLink>
                      </NavItem>
                      {selectedOwner && (
                        <NavItem>
                          <NavLink
                            tag={Link}
                            to={"/member/edit/" + selectedOwner.value}
                          >
                            Back to Owner
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <div className="form__field-group">
                          <Row>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Horse Name
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="name"
                                    component={renderField}
                                    type="text"
                                    placeholder="Horse Name"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Owner
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field form__form-group-field-with-btn">
                                  <Field
                                    name="owner"
                                    component={renderSelectAsyncField}
                                    // type="text"
                                    api="ownerlist"
                                    placeholder="Choose an owner"
                                  />
                                  {/* <Field
                                      name="owner_id"
                                      // api="getOwners"
                                      component={renderSelectAsyncField}
                                      type="text"
                                      placeholder="Choose an owner"
                                      options={[
                                        { value: '1', label: 'Owner 1' },
                                        { value: '2', label: 'Owner 2' },
                                        { value: '3', label: 'Owner 3' },
                                      ]}
                                    {...{
                                      api: "getOwners"
                                    }}
                                    /> */}
                                  {/* {ModalButton} */}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Category
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="category"
                                    component={renderSelectField}
                                    type="text"
                                    placeholder="Choose a category"
                                    options={categories}
                                  />

                                  {/* <Field
                                    name="category"
                                    component={renderSelectAsyncField}
                                    api="categorylist/1"
                                    placeholder="Choose an category"
                                  /> */}
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Price
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="price"
                                    component={renderField}
                                    type="number"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Location
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  {/* <Field
                                      name="location_id"
                                      component={renderSelectField}
                                      type="text"
                                      placeholder="Choose a Location"
                                      options={[
                                        { value: '1', label: 'Location A' },
                                        { value: '2', label: 'Location B' },
                                        { value: '3', label: 'Location C' },
                                      ]}
                                    /> */}

                                  <Field
                                    name="location"
                                    component={renderSelectAsyncField}
                                    api="horselocationlist"
                                    placeholder="Choose a Location"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          {id && (
                            <Row>
                              <Col md={12}>
                                <div className="form__form-group">
                                  <span className="form__form-group-label">
                                    Horse Documents
                                  </span>
                                  <ul className="list-inline doc-list">
                                    {// eslint-disable-next-line no-unused-vars
                                    documents &&
                                      Array.isArray(documents) &&
                                      documents.map((document, i) => (
                                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                                        <li
                                          className="list-inline-item"
                                          onClick={(e) => {
                                            this.previewDocument(
                                              e,
                                              document.attachment_url
                                            );
                                          }}
                                          onKeyDown={(e) => {
                                            this.previewDocument(
                                              e,
                                              document.attachment_url
                                            );
                                          }}
                                        >
                                          {/* <div to={document.attachment_url} target="_blank"> */}
                                          <div className="dropzone__img-main">
                                            <div
                                              className="dropzone__img"
                                              style={{
                                                backgroundImage: `url(${document.attachment_url})`,
                                              }}
                                            >
                                              <p className="dropzone__img-name">
                                                {document.name}
                                              </p>
                                            </div>
                                            <Button
                                              className="dropzone__img-delete"
                                              type="button"
                                              onClick={(e) => {
                                                this.removeDocument(
                                                  document.id,
                                                  e
                                                );
                                              }}
                                            >
                                              Remove
                                            </Button>
                                          </div>
                                          {/* </div> */}
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                                <div className="form__form-group">
                                  <span className="form__form-group-label">
                                    Add More Documents
                                  </span>
                                  <div className="form__form-group-field cs-dropzone-doc">
                                    <Field
                                      disabled={shouldDisable}
                                      name="horse-documents"
                                      id={id}
                                      type="user"
                                      onDropzoneChange={this.onDropzoneChange}
                                      component={renderDropZoneMultipleField}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          )}
                        </div>
                      </TabPane>
                      <TabPane tabId="2">
                        <div className="form__field-group">
                          <Row>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Height
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="height"
                                    component={renderField}
                                    type="number"
                                    placeholder="6.2"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Weight
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="weight"
                                    component={renderField}
                                    type="number"
                                    placeholder="900"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Date of Birth
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="dob"
                                    component={renderDatePickerField}
                                    placeholder="12/12/1222"
                                  />
                                  <div className="form__form-group-icon">
                                    <CalendarBlankIcon />
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Color
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="color"
                                    component={renderField}
                                    type="text"
                                    placeholder="White"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Gender
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="gender"
                                    component={renderSelectField}
                                    type="text"
                                    options={formDefaults.horse_gender}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Breed
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="breed"
                                    component={renderField}
                                    type="text"
                                    placeholder="Australian"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  FEI issued date
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="fei_issued_on"
                                    component={renderDatePickerField}
                                    placeholder="12/12/1222"
                                  />
                                  <div className="form__form-group-icon">
                                    <CalendarBlankIcon />
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  FEI ID
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="horse_fei_id"
                                    component={renderField}
                                    type="text"
                                    placeholder="123ASP1"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Chip ID
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="chip_id"
                                    component={renderField}
                                    type="text"
                                    placeholder="241DA"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
                <div className="form__field-group">
                  <ButtonToolbar className="form__button-toolbar">
                    <Button
                      className="rounded"
                      type="submit"
                      color="primary"
                      disabled={pristine || submitting || shouldDisable}
                    >
                      {id ? "Update" : "Add"}
                    </Button>
                    {/* {id ? (
                      <Button
                        className="rounded"
                        type="button"
                        onClick={reset}
                        disabled={pristine || submitting || shouldDisable}
                      >
                        Cancel
                      </Button>
                    ) : ( */}
                    <Button
                      className="rounded"
                      tag={Link}
                      outline
                      // color="primary"
                      to="/horse/listing"
                    >
                      Cancel
                    </Button>
                    {/* )} */}

                    {/* <Button
                    color="primary"
                    tag={Link}
                    to={{ pathname: '/horse/listing', search: '?action=added' }}
                  >Submit
                  </Button>
                  <Button type="button" onClick={reset} disabled={pristine || submitting}>
                    Cancel
                  </Button> */}
                  </ButtonToolbar>
                </div>
              </CardBody>
            </Card>
          </div>
        </form>
      </Col>
    );
  }
}
const selector = formValueSelector("horse_add_edit"); // <-- same as form name

const mapState = (state) => {
  if (!state.horse.addFormData.data) {
    return {
      categories: state.horse.categories,
      formDefaults: state.common.formDefaults,
    };
  }
  const selectedOwner = selector(state, "owner");

  return {
    selectedOwner,
    documents: state.document.horse.data,
    shouldDisable: state.preloader.shouldDisable,
    categories: state.horse.categories,
    formDefaults: state.common.formDefaults,

    initialValues: {
      name: state.horse.addFormData.data.name
        ? state.horse.addFormData.data.name
        : "",
      owner: state.horse.addFormData.data.owner
        ? state.horse.addFormData.data.owner
        : null,
      category: state.horse.addFormData.data.category
        ? state.horse.addFormData.data.category
        : null,
      price: state.horse.addFormData.data.price
        ? state.horse.addFormData.data.price
        : "",
      location: state.horse.addFormData.data.location
        ? state.horse.addFormData.data.location
        : null,
      horse: state.horse.addFormData.data.horse
        ? state.horse.addFormData.data.horse
        : "",
      height: state.horse.addFormData.data.height
        ? state.horse.addFormData.data.height
        : "",
      weight: state.horse.addFormData.data.weight
        ? state.horse.addFormData.data.weight
        : "",
      dob: state.horse.addFormData.data.dob
        ? new Date(state.horse.addFormData.data.dob)
        : "",
      color: state.horse.addFormData.data.color
        ? state.horse.addFormData.data.color
        : "",
      gender: state.horse.addFormData.data.gender
        ? state.horse.addFormData.data.gender
        : null,
      breed: state.horse.addFormData.data.breed
        ? state.horse.addFormData.data.breed
        : "",
      fei_issued_on: state.horse.addFormData.data.fei_issued_on
        ? new Date(state.horse.addFormData.data.fei_issued_on)
        : "",
      horse_fei_id: state.horse.addFormData.data.horse_fei_id
        ? state.horse.addFormData.data.horse_fei_id
        : "",
      chip_id: state.horse.addFormData.data.chip_id
        ? state.horse.addFormData.data.chip_id
        : "",
    },
  };
};

const actionCreators = {
  getHorse: horseActions.getHorseDataAction,
  addUpdateHorse: horseActions.addUpdateHorseAction,
  clearHorseFrom: horseActions.clearHorseFrom,
  getDocuments: documentActions.getDocumentsAction,
  deleteDocument: documentActions.deleteDocumentAction,
  uploadDocument: documentActions.uploadDocumentAction,
  getHorseCategories: horseActions.getHorseCategoriesAction,
};

// You have to connect() to any reducers that you wish to connect to yourself
export default connect(
  mapState,
  actionCreators // bind account loading action creator
)(
  reduxForm({
    form: "horse_add_edit", // a unique identifier for this form
    // validate
  })(VerticalForm)
);

// const selector = formValueSelector('edit_horse'); // <-- same as form name

// export default connect((state) => {
//   // can select values individually

//   const selectedOwner = selector(state, 'owner');
//   // const favoriteColorValue = selector(state, 'favoriteColor')
//   // or together as a group
//   // const { firstName, lastName } = selector(state, 'firstName', 'lastName')
//   return {
//     selectedOwner,
//     initialValues: {
//       owner: { value: 'Owner 2', label: 'Owner 2' },
//       category: { value: 'Livery', label: 'Livery' },
//       location: { value: 'Location B', label: 'Location B' },
//       horsename: 'Alexander',
//       price: '200',
//     },
//   };
// })(reduxForm({
//   form: 'edit_horse', // a unique identifier for this form
//   // validate,
// })(withTranslation('common')(VerticalForm)));
