import React from "react";
import LogInForm from "./components/LogInForm";



const LogIn = props => (
	<div className="account">
		<div className="account__wrapper">
			<div className="account__card">
				<div className="account__head">
					<h3 className="account__title">
						Welcome to
						<span className="account__logo">
							{" "}
							Nashmi
							<span className="account__logo-accent">.io</span>
						</span>
					</h3>
					<h4 className="account__subhead subhead">Stable Management System</h4>
				</div>
				<LogInForm onSubmit {...props}/>
			</div>
		</div>
	</div>
);


// const connectedRegisterPage = connect(mapState, actionCreators)(RegisterPage);
// export { connectedRegisterPage as RegisterPage };

export default LogIn;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
