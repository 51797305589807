import { globalSearch } from "../../_constants/common.constants";

const initialState = {
  list: {},
};

function globalSearchReducer(state = initialState, action) {
  switch (action.type) {
    case globalSearch.GLOBAL_SEARCH:
      return {
        ...state,
        list: action.data.data,
      };

    default:
      return state;
  }
}

export default globalSearchReducer;
