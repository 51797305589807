import { trainerConstants} from "../../_constants/dashboard.constants";

const initialState = {
    list: null,
    sizePerPage: 10,
    totalResults: null,
    filter: {
        currentPage: 1,
        search: '',
    },
    addFormData: {},
}

function trainerReducer( state = initialState, action) {
    switch(action.type) {
        case trainerConstants.TRAINER_LIST:
            return {
                ...state,
                list: action.data.data,
                filter: {
                    search: action.filter.search ? action.filter.search : '',
                    currentPage: action.filter.currentPage ? action.filter.currentPage : 1,
                },
                totalResults: action.data.meta.total,
                last_page: action.data.meta.last_page,
            };
        case trainerConstants.TRAINER_ADD:
            return {
                ...state,
            };
        case trainerConstants.TRAINER_UPDATE:
            return {
                ...state,
                addFormData: action.data,
            };
        case trainerConstants.TRAINER_GET:
            return {
                ...state,
                addFormData: action.data,
            };
        case trainerConstants.TRAINER_DELETE:
            return {
                ...state,
                totalResults: action.data.total,
                last_page: action.data.last_page
            };
        case trainerConstants.TRAINER_GET_CLEAR:
            return {
                ...state,
                addFormData: {},
            };
        default:
            return state;
        
    }
}

export default trainerReducer;