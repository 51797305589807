import React from "react";
import { Link } from "react-router-dom";
import { Container, Card, CardBody, Col, Row, CardSubtitle } from "reactstrap";

const ReportsList = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Reports</h3>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <Card className="rerports-card">
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Sales Reports</h5>
              {/* <CardSubtitle>Some info here</CardSubtitle> */}
            </div>
            <ul className="list-unstyled">
              <li>
                <Link to="/report/sales-report">Sales Report</Link>
              </li>
              <li>
                <Link to="/report/sales-by-service">Sales By Service</Link>
              </li>
              <li>
                <Link to="/report/sales-by-activity">Sales By Activity</Link>
              </li>
              <li>
                <Link to="/report/sales-by-package">Sales By Package</Link>
              </li>
              <li>
                <Link to="/report/sales-by-trainer">Sales By Trainer</Link>
              </li>
              <li>
                <Link to="/report/sales-by-livery">Livery Report</Link>
              </li>
              {/* <li>
                <Link to="/report/sales-log">Sales Log</Link>
              </li> */}
            </ul>
          </CardBody>
        </Card>
      </Col>
      <Col md={6}>
        <Card className="rerports-card">
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Membership Reports</h5>
              {/* <CardSubtitle>Some info here</CardSubtitle> */}
            </div>
            <ul className="list-unstyled">
              <li>
                <Link to="/report/membership">Membership</Link>
              </li>
              <li>
                <Link to="/report/corporates">Corporates</Link>
              </li>
              {/* <li>
                <Link to="/report/membership-log">Membership Log</Link>
              </li> */}
            </ul>
          </CardBody>
        </Card>
      </Col>
      <Col md={6}>
        <Card className="rerports-card">
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Horse Reports</h5>
              {/* <CardSubtitle>Some info here</CardSubtitle> */}
            </div>
            <ul className="list-unstyled">
              <li>
                <Link to="/report/horse">Horse Reports</Link>
              </li>
              <li>
                <Link to="/report/horse-by-owners">Horse By Owners</Link>
              </li>
              {/* <li>
                <Link to="/report/membership-log">Horse Log</Link>
              </li> */}
            </ul>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default ReportsList;
