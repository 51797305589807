import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import VerticalForm from './components/VerticalForm';

const ManagerSingle = ({ match }) => {

  const title = match.params.action === 'add' ? 'Add Manager' : 'Edit Manager';

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">{title}</h3>
        </Col>
      </Row>
      <Row>
        <VerticalForm id={match.params.id} key={match.params.id} />
      </Row>
    </Container>
  );
};

ManagerSingle.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string.isRequired,
      id: PropTypes.string
    })
  })
};

ManagerSingle.defaultProps = {
  match: {
    params: {
      id: null
    }
  },
};

export default ManagerSingle;
