/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import reportCorporateActions from "../../../../redux/actions/reportCorporateActions";
import ServerSideDataTable from "../../../../shared/components/table/ServerSideDataTable";

class DataTable extends PureComponent {
  static propTypes = {
    getReportCorporateAction: PropTypes.func.isRequired,
    corporateReport: PropTypes.shape({
      totalResults: PropTypes.number,
      filter: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        search: PropTypes.string,
      }),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          corporate_name: PropTypes.string.isRequired,
          number_of_members: PropTypes.number.isRequired,
          amount: PropTypes.string.isRequired,
        })
      ),
    }),
  };

  static defaultProps = {
    corporateReport: {
      search: "",
      totalResults: null,
    },
  };

  constructor(props) {
    super(props);
    // props.setDefaults();

    props.getReportCorporateAction({
      st_date: props.st_date,
      ed_date: props.ed_date,
    });
  }

  // componentDidMount() {
  //   console.log("componentDidMount");
  // }

  componentDidUpdate(prevProps) {
    if (
      prevProps.st_date != this.props.st_date ||
      prevProps.ed_date != this.props.ed_date
    ) {
      this.props.getReportCorporateAction({
        st_date: this.props.st_date,
        ed_date: this.props.ed_date,
      });
    }
  }

  handleTableChange = (type, { searchText, page }) => {
    const { props } = this;
    props.getReportCorporateAction({
      currentPage: page,
      search: searchText,
      st_date: props.st_date,
      ed_date: props.ed_date,
    });
  };

  render() {
    const { props, state } = this;
    const { permissions, corporateReport } = props;

    const columns = [
      {
        dataField: "corporate_name",
        text: "Corporate Name",
      },
      {
        dataField: "number_of_members",
        text: "Number of Members",
      },
      {
        dataField: "amount",
        text: "Amount",
      },
    ];

    return (
      <Col md={12} lg={12}>
        <Card>
          {corporateReport.list && (
            <CardBody>
              <ServerSideDataTable
                data={corporateReport.list}
                columns={columns}
                onTableChange={this.handleTableChange}
                page={corporateReport.filter.currentPage}
                sizePerPage={props.sizePerPage}
                totalSize={corporateReport.totalResults}
                searchText={corporateReport.filter.search}
              />
            </CardBody>
          )}
        </Card>
      </Col>
    );
  }
}

function mapState(state) {
  const authData = state.authentication;
  const permissions =
    typeof authData.user !== "undefined" &&
    Array.isArray(authData.user.permissions)
      ? authData.user.permissions
      : [];
  return {
    permissions,
    sizePerPage: state.corporateReport.sizePerPage,
    corporateReport: state.corporateReport,
  };
}

const actionCreators = {
  getReportCorporateAction: reportCorporateActions.getReportCorporateAction,
  // setDefaults: memberActions.setCorporateDefaultsAction
};

export default connect(mapState, actionCreators)(DataTable);
