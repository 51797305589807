import alertConstants from '../../_constants/alert.constants';

function alert(state = {}, action) {
    switch (action.type) {
        case alertConstants.SUCCESS:
            return {
                type: 'alert-success',
                color: 'success',
                title: 'Success',
                message: action.message
            };
        case alertConstants.ERROR:
            return {
                type: 'alert-danger',
                title: 'Oops',
                color: 'danger',
                message: action.message
            };
        case alertConstants.CLEAR:
            return {};
        default:
            return state
    }
}

export default alert;