import React, { PureComponent } from "react";
import { Card, CardBody, Col, Row, Button, ButtonToolbar } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import renderSelectField from "../../../../shared/components/form/Select";
import RenderMultiCheckBoxField from "../../../../shared/components/form/MultiCheckBox";
import rolesActions from "../../../../redux/actions/roles.actions";

class VerticalForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    formData: PropTypes.objectOf(PropTypes.any),
    formDefaults: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    formDefaults: {},
    formData: {
      permissions: {},
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      currentPerms: [],
    };
  }

  componentDidMount() {
    const { props } = this;
    props.getPermissions();
  }

  getValues = (values) => {
    const { props } = this;
    const currentRole = values.role.value;
    const { currentPerms } = this.state;
    const permsValues = {
      permissions: currentPerms,
    };
    props.UpdatePermissions(JSON.stringify(permsValues), currentRole);
  };

  onRoleChange = (data) => {
    const {
      props: {
        formData: { permissions },
      },
    } = this;
    const currentPermissions = permissions[data.value];
    this.setState({
      currentPerms: currentPermissions,
    });
  };

  onPermissionsChange = (e) => {
    const { currentPerms } = this.state;
    const currentSet = new Set(currentPerms);
    if (e.target.checked) {
      currentSet.add(e.target.value);
    } else {
      currentSet.delete(e.target.value);
    }
    this.setState({
      currentPerms: [...currentSet],
    });
  };

  resetForm = () => {
    const { reset } = this.props;
    reset();
    this.setState({
      currentPerms: [],
    });
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      formDefaults,
      formData: { permissions },
    } = this.props;
    const { currentPerms } = this.state;

    const allPermissions =
      typeof permissions.superadmin !== "undefined"
        ? permissions.superadmin
        : [];

    return (
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody>
            <form className="form" onSubmit={handleSubmit(this.getValues)}>
              <div className="formInner px-4 py-3 mx-3 my-2">
                <Row>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Role</span>
                      <div className="form__form-group-field">
                        <Field
                          name="role"
                          component={renderSelectField}
                          placeholder="Choose"
                          options={formDefaults.roles}
                          onChange={this.onRoleChange}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                {allPermissions.length > 0 && (
                  <Row>
                    <Col md={6}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Permissions
                        </span>
                      </div>
                      {allPermissions.map((permission) => {
                        const splittedPerms = permission
                          .split("-")
                          .map(
                            (subPart) =>
                              subPart.charAt(0).toUpperCase() + subPart.slice(1)
                          );

                        return (
                          <div className="form__form-group" key={permission}>
                            <div className="form__form-group-field">
                              <RenderMultiCheckBoxField
                                input={{
                                  name: "permissions[]",
                                  value: permission,
                                  checked: currentPerms.includes(permission),
                                  onChange: this.onPermissionsChange,
                                }}
                                label={splittedPerms.join(" ")}
                                className="colored-click"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </Col>
                  </Row>
                )}
                <ButtonToolbar className="form__button-toolbar">
                  <Button
                    className="rounded"
                    type="submit"
                    color="primary"
                    disabled={pristine || submitting}
                  >
                    Submit
                  </Button>
                  <Button
                    className="rounded"
                    type="button"
                    onClick={this.resetForm}
                    disabled={pristine || submitting}
                  >
                    Cancel
                  </Button>
                </ButtonToolbar>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  formDefaults: state.common.formDefaults,
  formData: state.roles.permsFormData.data,
});

const actionCreators = {
  getPermissions: rolesActions.getPermissionsAction,
  UpdatePermissions: rolesActions.UpdatePermissionsAction,
};

const createReduxForm = reduxForm({ form: "manage_permissions" });

export default connect(
  mapStateToProps,
  actionCreators
)(createReduxForm(VerticalForm));
