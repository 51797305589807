import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button, Col, Row, ButtonToolbar, Modal } from "reactstrap";
import { Field, reduxForm, FormSection, getFormValues } from "redux-form";
import { connect } from "react-redux";

import renderSelectAsyncField from "../../shared/components/form/AsyncSelect";
// import renderMultiSelectField from "../../shared/components/form/MultiSelect";
import memberActions from "../../redux/actions/memberActions";

import horseActions from "../../redux/actions/horseActions";

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: "",
  meta: null,
  type: "text",
};

class AddHorse extends PureComponent {
  static propTypes = {
    colored: PropTypes.bool,
    header: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    // ownerId: PropTypes.string,
  };

  static defaultProps = {
    colored: false,
    header: false,
    // ownerId: null
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
    // this.MToggleFirst = this.MToggleFirst.bind(this);
    // this.MToggleSecond = this.MToggleSecond.bind(this);
  }

  cancel = () => {};

  getValues = (e) => {
    const { props } = this;
    e.preventDefault();
    if (!props.formValues) {
      return;
    }
    const values = props.formValues.add_new_horse_data;

    const params = {
      horse_name: values.name,
      //   owner_id: props.ownerId,
      category_id: values.category ? values.category.value : "",
    };

    // props.addHorseAction(JSON.stringify(params));
    props.addHorseByMember(JSON.stringify(params), props.ownerId, this.toggle);
  };

  toggle() {
    this.setState((prevState) => ({ modal: !prevState.modal }));
    const { props } = this;
    if (props.handleSave) {
      props.handleSave();
    }
  }

  render() {
    const { colored, header, handleSubmit } = this.props;
    const { modal } = this.state;

    let Icon;

    return (
      <>
        <Button
          color="primary"
          onClick={this.toggle}
          className="input-btn-r rounded"
        >
          Add Horse
        </Button>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          className="modal-dialog--primary add-horse-modal theme-light"
        >
          <div className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={this.toggle}
            />
            {header ? "" : Icon}
          </div>
          <div className="modal__body">
            {/* <form className='form form-section' onSubmit={handleSubmit}>
							<FormSection name='add_existing_horse_data'>
								<div className='formInner'>
									<h4 className='bold-text  modal__title'>
										Add Existing Horse
									</h4>
									<Row>
										<Col md={12}>
											<div className='form__form-group'>
												<span className='form__form-group-label'>
													Add Horse
												</span>
												<div className='form__form-group-field'>
													<Field
														name='existing_horse'
														component={renderMultiSelectField}
														type='text'
														placeholder='Add horses'
														options={[
															{ value: "Horse 1", label: "Horse 1" },
															{ value: "Horse 2", label: "Horse 2" },
															{ value: "Horse 3", label: "Horse 3" }
														]}
													/>
												</div>
											</div>
										</Col>
									</Row>
									<ButtonToolbar className='modal__actions'>
										<Button className="rounded" onClick={this.toggle}>Cancel</Button>{" "}
										<Button 
											className="rounded"
											outline={colored}
											color='primary'
                                            onClick={this.cancel}
										>
											Save
										</Button>
									</ButtonToolbar>
								</div>
							</FormSection>
						</form> */}
            <form
              className="form form-section"
              onSubmit={handleSubmit(this.getValues)}
            >
              <FormSection name="add_new_horse_data">
                <div className="formInner">
                  <h4 className="bold-text  modal__title">Add New Horse</h4>
                  <Row>
                    <Col md={6}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Horse Name
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="name"
                            component={renderField}
                            type="text"
                            placeholder="Horse Name"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Category</span>
                        <div className="form__form-group-field">
                          <Field
                            name="category"
                            placeholder="Choose a category"
                            component={renderSelectAsyncField}
                            api="categorylist/1"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <ButtonToolbar className="modal__actions rounded">
                    <Button onClick={this.toggle}>Cancel</Button>{" "}
                    <Button
                      outline={colored}
                      color="primary"
                      type="button"
                      onClick={(e) => this.getValues(e)}
                    >
                      Save & Add New
                    </Button>
                  </ButtonToolbar>
                </div>
              </FormSection>
            </form>
          </div>
        </Modal>
      </>
    );
  }
}

// const mapDispatchToProps = dispatch => {
// 	return {
// 		resetExistingHorseSection: () => {
// 			dispatch(resetSection("add_new_horse", "add_existing_horse_data"));
// 		},
// 		resetNewHorseSection: () => {
// 			dispatch(resetSection("add_new_horse", "add_new_horse_data"));
//         },
//         addHorse: horseActions.addHorseAction,
// 	};
// };

const actionCreators = {
  addHorseAction: horseActions.addHorseAction,
  addHorseByMember: memberActions.addHorseByMemberAction,
};

const mapStateToProps = (state) => {
  return {
    formValues: getFormValues("add_horse")(state),
  };
};

export default connect(
  mapStateToProps,
  actionCreators
)(
  reduxForm({
    form: "add_horse", // a unique identifier for this form
  })(AddHorse)
);
