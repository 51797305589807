export const memberConstants = {
  CORPORATE_LIST: "CORPORATE_LIST",
  CORPORATE_GET: "CORPORATE_GET",
  CORPORATE_ADD: "CORPORATE_ADD",
  CORPORATE_UPDATE: "CORPORATE_UPDATE",
  CORPORATE_DELETE: "CORPORATE_DELETE",
  CORPORATE_GET_CLEAR: "CORPORATE_GET_CLEAR",
  CORPORATE_SET_DEFAULTS: "CORPORATE_SET_DEFAULTS",
  MEMBER_LIST: "MEMBER_LIST",
  MEMBER_GET: "MEMBER_GET",
  MEMBER_ADD: "MEMBER_ADD",
  MEMBER_UPDATE: "MEMBER_UPDATE",
  MEMBER_DELETE: "MEMBER_DELETE",
  MEMBER_GET_CLEAR: "MEMBER_GET_CLEAR",
  MEMBER_SET_DEFAULTS: "MEMBER_SET_DEFAULTS",
  MEMBER_DOCUMENT_GET: "MEMBER_DOCUMENT_GET",
  MEMBER_DOCUMENT_DELETE: "MEMBER_DOCUMENT_DELETE",
  MEMBER_DOCUMENT_UPLOAD: "MEMBER_DOCUMENT_UPLOAD",
  MEMBER_HORSE_LIST: "MEMBER_HORSE_LIST",
  MEMBER_HORSE_DELETE: "MEMBER_HORSE_DELETE",
  MEMBER_HORSE_BY_MEMBER: "MEMBER_HORSE_BY_MEMBER",
};

export const managerConstants = {
  MANAGER_LIST: "MANAGER_LIST",
  MANAGER_GET: "MANAGER_GET",
  MANAGER_GET_CLEAR: "MANAGER_GET_CLEAR",
  MANAGER_ADD: "MANAGER_ADD",
  MANAGER_UPDATE: "MANAGER_UPDATE",
};

export const rolesConstants = {
  GET_USER_ROLES: "GET_USER_ROLES",
  GET_SINGLE_USER_ROLES: "GET_SINGLE_USER_ROLES",
  UPDATE_SINGLE_USER_ROLES: "UPDATE_SINGLE_USER_ROLES",
  CLEAR_SINGLE_USER_ROLES: "CLEAR_SINGLE_USER_ROLES",
  GET_ROLES_PERMISSIONS: "GET_ROLES_PERMISSIONS",
  UPDATE_ROLES_PERMISSIONS: "UPDATE_ROLES_PERMISSIONS",
};

export const documentConstants = {
  DOCUMENT_GET: "DOCUMENT_GET",
  DOCUMENT_DELETE: "DOCUMENT_DELETE",
  DOCUMENT_UPLOAD: "DOCUMENT_UPLOAD",
};

export const horseConstants = {
  HORSE_LIST: "HORSE_LIST",
  HORSE_GET: "HORSE_GET",
  HORSE_ADD: "HORSE_ADD",
  HORSE_UPDATE: "HORSE_UPDATE",
  HORSE_DELETE: "HORSE_DELETE",
  HORSE_GET_CLEAR: "HORSE_GET_CLEAR",
  HORSE_SET_DEFAULTS: "HORSE_SET_DEFAULTS",
  HORSE_UPDATE_FILTER: "HORSE_UPDATE_FILTER",

  HORSE_LOCATION_LIST: "HORSE_LOCATION_LIST",
  HORSE_LOCATION_GET: "HORSE_LOCATION_GET",
  HORSE_LOCATION_ADD: "HORSE_LOCATION_ADD",
  HORSE_LOCATION_UPDATE: "HORSE_LOCATION_UPDATE",
  HORSE_LOCATION_DELETE: "HORSE_LOCATION_DELETE",
  HORSE_LOCATION_GET_CLEAR: "HORSE_LOCATION_GET_CLEAR",

  HORSE_CATEGORIES_GET: "HORSE_CATEGORIES_GET",
  HORSE_LIST_BY_TRAINER: "HORSE_LIST_BY_TRAINER",
};

export const trainerConstants = {
  TRAINER_LIST: "TRAINER_LIST",
  TRAINER_GET: "TRAINER_GET",
  TRAINER_ADD: "TRAINER_ADD",
  TRAINER_UPDATE: "TRAINER_UPDATE",
  TRAINER_DELETE: "TRAINER_DELETE",
  TRAINER_GET_CLEAR: "TRAINER_GET_CLEAR",
  TRAINER_SET_DEFAULTS: "TRAINER_SET_DEFAULTS",
};

export const reportConstants = {
  REPORT_HORSE: "REPORT_HORSE",
  REPORT_HORSE_BY_OWNER: "REPORT_HORSE_BY_OWNER",
  REPORT_MEMBERSHIP: "REPORT_MEMBERSHIP",
  REPORT_SALES_BY_SERVICE: "REPORT_SALES_BY_SERVICE",
  REPORT_SALES_BY_TRAINER: "REPORT_SALES_BY_TRAINER",
  REPORT_SALES_REPORT: "REPORT_SALES_REPORT",
  REPORT_LIVERY_REPORT: "REPORT_LIVERY_REPORT",
  REPORT_CORPORATE: "REPORT_CORPORATE",
  REPORT_ANALYTICS_STAT: "REPORT_ANALYTICS_STAT",
  REPORT_CHANGE_LOG: "REPORT_CHANGE_LOG",
};

export const bookingConstants = {
  BOOKING_LIST: "BOOKING_LIST",
  BOOKING_CANCELLED_LIST: "BOOKING_CANCELLED_LIST",
  BOOKING_GET: "BOOKING_GET",
  BOOKING_ADD: "BOOKING_ADD",
  BOOKING_UPDATE: "BOOKING_UPDATE",
  BOOKING_DELETE: "BOOKING_DELETE",
  BOOKING_GET_CLEAR: "BOOKING_GET_CLEAR",
  GROUP_BOOKING_GET: "GROUP_BOOKING_GET",
  GROUP_BOOKING_ADD: "GROUP_BOOKING_ADD",
  GROUP_BOOKING_CLEAR: "GROUP_BOOKING_CLEAR",
  BOOKING_PARTICIPANTS_LIST: "BOOKING_PARTICIPANTS_LIST",
  BOOKING_PARTICIPANTS_ADD: "BOOKING_PARTICIPANTS_ADD",
  BOOKING_PARTICIPANTS_DELETE: "BOOKING_PARTICIPANTS_DELETE",
  BOOKING_CANCEL_TIME_SLOTS: "BOOKING_CANCEL_TIME_SLOTS",
  BOOKING_CANCEL: "BOOKING_CANCEL",
  BOOKING_UPDATE_WITH_NEW_TIMES: "BOOKING_UPDATE_WITH_NEW_TIMES",
  BOOKING_CANCEL_CLEAR: "BOOKING_CANCEL_CLEAR",
  BOOKING_NO_SHOW: "BOOKING_NO_SHOW",
  BOOKING_NO_SHOWS_LIST: "BOOKING_NO_SHOWS_LIST",
  USER_BOOKING_LIST: "USER_BOOKING_LIST",
};

export const serviceConstants = {
  // SINGLE_SESSION_LIST: 'SINGLE_SESSION_LIST',
  // SINGLE_SESSION_GET: 'SINGLE_SESSION_GET',
  // SINGLE_SESSION_ADD: 'SINGLE_SESSION_ADD',
  // SINGLE_SESSION_UPDATE: 'SINGLE_SESSION_UPDATE',
  // SINGLE_SESSION_DELETE: 'SINGLE_SESSION_DELETE',

  // PACKAGE_LIST: 'PACKAGE_LIST',
  // PACKAGE_GET: 'PACKAGE_GET',
  // PACKAGE_ADD: 'PACKAGE_ADD',
  // PACKAGE_UPDATE: 'PACKAGE_UPDATE',
  // PACKAGE_DELETE: 'PACKAGE_DELETE',

  // ACTIVITY_LIST: 'ACTIVITY_LIST',
  // ACTIVITY_GET: 'ACTIVITY_GET',
  // ACTIVITY_ADD: 'ACTIVITY_ADD',
  // ACTIVITY_UPDATE: 'ACTIVITY_UPDATE',
  // ACTIVITY_DELETE: 'ACTIVITY_DELETE',

  SERVICE_LIST: "SERVICE_LIST",
  SERVICE_GET: "SERVICE_GET",
  SERVICE_ADD: "SERVICE_ADD",
  SERVICE_UPDATE: "SERVICE_UPDATE",
  SERVICE_DELETE: "SERVICE_DELETE",

  CATEGORY_LIST: "CATEGORY_LIST",
  CATEGORY_GET: "CATEGORY_GET",
  CATEGORY_ADD: "CATEGORY_ADD",
  CATEGORY_UPDATE: "CATEGORY_UPDATE",
  CATEGORY_DELETE: "CATEGORY_DELETE",

  SERVICE_GET_CLEAR: "SERVICE_GET_CLEAR",

  TRAINER_GET_CLEAR: "TRAINER_GET_CLEAR",
  TRAINER_SET_DEFAULTS: "TRAINER_SET_DEFAULTS",
};

export const calendarConstants = {
  CALENDAR_GET: "CALENDAR_GET",
  CALENDAR_GET_BY_TIME: "CALENDAR_GET_BY_TIME",
  TRAINER_GET: "TRAINER_GET",
};

export const dashboardConstants = {
  DASHBOARD_GET: "DASHBOARD_GET",
};

// const dashboardConstants = {
//     memberConstants
// };

// export default dashboardConstants;
