import { documentConstants} from "../../_constants/dashboard.constants";

const initialState = {
    horse: [],
    member: []
}

function documentReducer( state = initialState, action) {
    switch(action.type) {
        case documentConstants.DOCUMENT_GET:
            return {
                ...state,
                [action.document_type]: action.data
            };
            
            
        default:
            return state;   
    }
}

export default documentReducer;