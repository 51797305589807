import { change } from "redux-form";
import API from "../../api/API";
import { memberConstants } from "../../_constants/dashboard.constants";
import history from "../../_helpers/history";
import alertActions from "./alertActions";
import preloaderActions from "./preloaderActions";
import { constructUrlParams } from "../../_helpers/CommonFunctions";

function setCorporateDefaultsAction() {
  return { type: memberConstants.CORPORATE_SET_DEFAULTS };
}

function getCorporateListAction(filters) {
  let custFilter = filters;

  function success(data) {
    return {
      type: memberConstants.CORPORATE_LIST,
      data,
      filter: {
        currentPage: custFilter.currentPage,
        search: custFilter.search,
      },
    };
  }

  return (dispatch, getState) => {
    dispatch(preloaderActions.show());
    const { member } = getState();
    const { corporate } = member;

    if (typeof filters === "undefined") {
      custFilter = corporate.filter;
    }

    if (custFilter.currentPage > 1) {
      custFilter.currentPage =
        custFilter.currentPage > corporate.last_page
          ? corporate.last_page
          : custFilter.currentPage;
    }

    const urlParams = constructUrlParams({
      page: custFilter.currentPage,
      search: custFilter.search,
    });
    // console.log(urlParams);

    API.getCorporates(urlParams).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function deleteCorporateAction(id) {
  function success(list) {
    return {
      type: memberConstants.CORPORATE_DELETE,
      list,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.deleteCorporate(id).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
          dispatch(getCorporateListAction());
          dispatch(alertActions.success(response.data.message));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function getCorporateDataAction(id) {
  function success(data) {
    return {
      type: memberConstants.CORPORATE_GET,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.getCorporate(id).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function addUpdateCorporateAction(params, id) {
  function success(data) {
    return {
      type: id
        ? memberConstants.CORPORATE_UPDATE
        : memberConstants.CORPORATE_ADD,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.addUpdateCorporate(params, id).then(
      (response) => {
        if (response.data) {
          history.push(`/member/corporate/edit/${response.data.data.id}`);
          if (!id) {
            dispatch(success(response.data));
          }
          dispatch(alertActions.success(response.data.message));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
        // dispatch(alertActions.error('sadasdasd'));
      }
    );
  };
}

function clearCorporateFrom() {
  return {
    type: memberConstants.CORPORATE_GET_CLEAR,
  };
}

function getMemberListAction(filters) {
  let custFilter = filters;

  function success(data) {
    return {
      type: memberConstants.MEMBER_LIST,
      data,
      filter: {
        currentPage: custFilter.currentPage,
        search: custFilter.search,
      },
    };
  }

  return (dispatch, getState) => {
    dispatch(preloaderActions.show());
    const { member } = getState();
    // const { location } = horse;

    if (typeof filters === "undefined") {
      custFilter = member.filter;
    }

    if (custFilter.currentPage > 1) {
      custFilter.currentPage =
        custFilter.currentPage > member.last_page
          ? member.last_page
          : custFilter.currentPage;
    }

    const urlParams = constructUrlParams({
      page: custFilter.currentPage,
      search: custFilter.search,
    });
    // console.log(urlParams);

    API.getMembers(urlParams).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function deleteMemberAction(id) {
  function success(data) {
    return {
      type: memberConstants.MEMBER_DELETE,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.deleteMember(id).then(
      (response) => {
        if (response.data) {
          dispatch(alertActions.success(response.data.message));
          dispatch(success(response.data));
          dispatch(getMemberListAction());
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function addUpdateMemberAction(params, id) {
  function success(data) {
    return {
      type: id ? memberConstants.MEMBER_UPDATE : memberConstants.MEMBER_ADD,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.addUpdateMember(params, id).then(
      (response) => {
        if (response.data) {
          history.push(`/member/edit/${response.data.data.record}`);

          if (!id) {
            dispatch(success(response.data));
          }
          dispatch(alertActions.success(response.data.message));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function getMemberAction(id) {
  function success(data) {
    return {
      type: memberConstants.MEMBER_GET,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.getMember(id).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function getMemberDocumentsAction(params) {
  function success(data) {
    return {
      type: memberConstants.MEMBER_DOCUMENT_GET,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.getDocument({ type: params.type, id: params.id }).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function deleteMemberDocumentAction(params) {
  function success(data) {
    return {
      type: memberConstants.MEMBER_DOCUMENT_DELETE,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.deleteDocument({ id: params.attachment_id }).then(
      (response) => {
        if (response.data) {
          dispatch(alertActions.success(response.data.message));
          dispatch(success(response.data));
          dispatch(
            getMemberDocumentsAction({ type: params.type, id: params.id })
          );
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function uploadMemberDocumentAction(params) {
  // function success(data) {
  //     return {
  //         type: memberConstants.MEMBER_DOCUMENT_UPLOAD,
  //         data
  //     }
  // }

  return (dispatch) => {
    dispatch(preloaderActions.show(true));
    API.uploadDocument({
      object_id: params.id,
      documents: params.documents,
      type: params.type,
    }).then(
      (response) => {
        if (response.data) {
          dispatch(alertActions.success(response.data.message));
          // dispatch(success(response.data));
          if (params.type === "user") {
            dispatch(change("member_add_edit", "member-documents", ""));
          } else {
            dispatch(change("horse_add_edit", "horse-documents", ""));
          }
          dispatch(
            getMemberDocumentsAction({ type: params.type, id: params.id })
          );
        }
        dispatch(preloaderActions.hide(false));
      },
      (error) => {
        // dispatch(change('member_add_edit', 'member-documents', ''));
        if (params.type === "user") {
          dispatch(change("member_add_edit", "member-documents", ""));
        } else {
          dispatch(change("horse_add_edit", "horse-documents", ""));
        }
        dispatch(preloaderActions.hide(false));
        // console.log(error.response);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function getOwnerHorseListAction(filters) {
  let custFilter = filters;

  function success(data) {
    return {
      type: memberConstants.MEMBER_HORSE_LIST,
      data,
      filter: {
        currentPage: custFilter.currentPage,
        search: custFilter.search,
      },
    };
  }

  return (dispatch, getState) => {
    dispatch(preloaderActions.show());
    const { member } = getState();

    if (typeof filters === "undefined") {
      custFilter = member.horse.filter;
    }

    if (custFilter.currentPage > 1) {
      custFilter.currentPage =
        custFilter.currentPage > member.horse.last_page
          ? member.horse.last_page
          : custFilter.currentPage;
    }

    const urlParams = constructUrlParams({
      page: custFilter.currentPage,
    });

    API.getOwnerHorses(`${filters.owner_id}${urlParams}`).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function deleteOwnerHorseAction(params) {
  function success(list) {
    return {
      type: memberConstants.MEMBER_HORSE_DELETE,
      list,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.deleteOwnerHorse(params.horseId, params.ownerId).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
          dispatch(getOwnerHorseListAction());
          dispatch(alertActions.success(response.data.message));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function clearMemberFrom() {
  return {
    type: memberConstants.MEMBER_GET_CLEAR,
  };
}

function addHorseByMemberAction(params, ownerId, callback) {
  function success(list) {
    return {
      type: memberConstants.MEMBER_HORSE_BY_MEMBER,
      list,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.horseByMember(params, ownerId).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
          dispatch(alertActions.success(response.data.message));
          if (callback) {
            callback();
          }
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

const memberActions = {
  getCorporateListAction,
  deleteCorporateAction,
  getCorporateDataAction,
  addUpdateCorporateAction,
  clearCorporateFrom,
  setCorporateDefaultsAction,
  getMemberListAction,
  deleteMemberAction,
  addUpdateMemberAction,
  getMemberAction,
  clearMemberFrom,
  getMemberDocumentsAction,
  uploadMemberDocumentAction,
  deleteMemberDocumentAction,
  getOwnerHorseListAction,
  deleteOwnerHorseAction,
  addHorseByMemberAction,
};

export default memberActions;
