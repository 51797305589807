import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
// import { createLogger } from 'redux-logger';
import { reducer as reduxFormReducer } from "redux-form";
import {
  sidebarReducer,
  themeReducer,
  alertReducer,
  authenticationReducer,
  managerReducer,
  rolesReducer,
  memberReducer,
  preloaderReducer,
  horseReducer,
  trainerReducer,
  bookingReducer,
  serviceReducer,
  documentReducer,
  commonReducer,
  calendarReducer,
  dashboardReducer,
  reportHorseReducer,
  reportHorseByOwnerReducer,
  reportMembershipReducer,
  reportCorporateReducer,
  reportSalesByServiceReducer,
  reportSalesByTrainerReducer,
  reportSalesReportReducer,
  reportLiveryReportReducer,
  globalSearchReducer,
  reportAnalyticsStatReducer,
  reportChangeLogReducer,
} from "../../redux/reducers/index";

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  alert: alertReducer,
  authentication: authenticationReducer,
  manager: managerReducer,
  roles: rolesReducer,
  member: memberReducer,
  preloader: preloaderReducer,
  horse: horseReducer,
  trainer: trainerReducer,
  booking: bookingReducer,
  service: serviceReducer,
  document: documentReducer,
  common: commonReducer,
  calendar: calendarReducer,
  dashboard: dashboardReducer,
  horseReport: reportHorseReducer,
  horseByOwnerReport: reportHorseByOwnerReducer,
  membershipReport: reportMembershipReducer,
  corporateReport: reportCorporateReducer,
  salesByServiceReport: reportSalesByServiceReducer,
  salesByTrainerReport: reportSalesByTrainerReducer,
  salesReport: reportSalesReportReducer,
  liveryReport: reportLiveryReportReducer,
  globalSearch: globalSearchReducer,
  analyticsStat: reportAnalyticsStatReducer,
  changeLog: reportChangeLogReducer,
});

// const loggerMiddleware = createLogger();
// const composeEnhancer = window.devToolsExtension && window.devToolsExtension();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  composeEnhancer(
    applyMiddleware(
      thunkMiddleware
      // loggerMiddleware
    )
  )
);

export default store;

// import { createStore, applyMiddleware } from 'redux';
// import thunkMiddleware from 'redux-thunk';
// import { createLogger } from 'redux-logger';
// import rootReducer from '../_reducers';

// const loggerMiddleware = createLogger();

// export const store = createStore(
//     rootReducer,
//     applyMiddleware(
//         thunkMiddleware,
//         loggerMiddleware
//     )
// );
