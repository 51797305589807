import { serviceConstants } from "../../_constants/dashboard.constants";

const commonDefaults = {
	list: [],
	totalResults: null,
	sizePerPage: 10,
	filter: {
		currentPage: 1,
		search: "",
	},
	addFormData: {},
};

const initialState = {
	all: commonDefaults,
	singleSession: commonDefaults,
	packages: commonDefaults,
	activity: commonDefaults,
	categories: commonDefaults,
};

function serviceReducer(state = initialState, action) {
	switch (action.type) {
		case serviceConstants.CATEGORY_LIST:
			return {
				...state,
				categories: {
					...state.categories,
					list: action.data.data,
					totalResults: action.data.meta.total,
					last_page: action.data.meta.last_page,
					filter: {
						currentPage: action.data.meta.current_page,
						search: action.search,
					},
				},
			};
		case serviceConstants.CATEGORY_DELETE:
			return {
				...state,
				categories: {
					...state.categories,
					totalResults: action.data.total,
					last_page: action.data.last_page,
				},
			};
		case serviceConstants.CATEGORY_GET:
			return {
				...state,
				categories: {
					...state.categories,
					addFormData: action.data,
				},
			};
		case serviceConstants.CATEGORY_ADD:
			return {
				...state,
				categories: {
					...state.categories,
					addFormData: {},
				},
			};
		case serviceConstants.CATEGORY_UPDATE:
			return {
				...state,
				categories: {
					...state.categories,
					addFormData: {},
				},
			};

		case serviceConstants.SERVICE_LIST:
			return {
				...state,
				[action.serviceType ? action.serviceType : "singleSession"]: {
					...state[action.serviceType ? action.serviceType : "singleSession"],
					list: action.data.data,
					totalResults: action.data.meta.total,
					last_page: action.data.meta.last_page,
					filter: {
						currentPage: action.data.meta.current_page,
						search: action.search,
					},
				},
			};
		case serviceConstants.SERVICE_DELETE:
			return {
				...state,
				[action.serviceType ? action.serviceType : "singleSession"]: {
					...state[action.serviceType ? action.serviceType : "singleSession"],
					totalResults: action.data.total,
					last_page: action.data.last_page,
				},
			};
		case serviceConstants.SERVICE_GET:
			// console.log(action);
			return {
				...state,
				[action.serviceType ? action.serviceType : "singleSession"]: {
					...state[action.serviceType ? action.serviceType : "singleSession"],
					addFormData: action.data,
				},
			};
		case serviceConstants.SERVICE_ADD:
			return {
				...state,
				[action.serviceType ? action.serviceType : "singleSession"]: {
					...state[action.serviceType ? action.serviceType : "singleSession"],
					addFormData: {},
				},
			};
		case serviceConstants.SERVICE_UPDATE:
			return {
				...state,
				[action.serviceType ? action.serviceType : "singleSession"]: {
					...state[action.serviceType ? action.serviceType : "singleSession"],
					addFormData: {},
				},
			};
		case serviceConstants.SERVICE_GET_CLEAR:
			return {
				...state,
				all: {
					...state.all,
					addFormData: {},
				},
				singleSession: {
					...state.singleSession,
					addFormData: {},
				},
				packages: {
					...state.packages,
					addFormData: {},
				},
				activity: {
					...state.activity,
					addFormData: {},
				},
				categories: {
					...state.categories,
					addFormData: {},
				},
			};

		default:
			return state;
	}
}

export default serviceReducer;
