import React, { useState, useEffect } from "react";
import {
  ButtonGroup,
  ButtonToolbar,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";

import { Link } from "react-router-dom";
import BigCalendar from "./components/BigCalendar";
import { connect } from "react-redux";

// import EventLabels from './components/EventLabels';

const Calendar = (props) => {
  const [calendartime, setCalendartime] = useState("ALL");
  let isTrainer =
    props.authData && props.authData.user && props.authData.user.userrole
      ? props.authData.user.userrole.includes("trainer")
      : false;
  return (
    <Container>
      <Row>
        <Col md={9}>
          <h3 className="page-title">Calendar</h3>
          <ButtonToolbar>
            <ButtonGroup>
              <Button
                className="rounded"
                outline={calendartime !== "ALL"}
                color="primary"
                type="button"
                onClick={() => {
                  setCalendartime("ALL");
                }}
              >
                ALL
              </Button>
              <Button
                // className="rounded"
                outline={calendartime !== "AM"}
                color="primary"
                type="button"
                onClick={() => {
                  setCalendartime("AM");
                }}
              >
                AM
              </Button>
              <Button
                className="rounded"
                outline={calendartime !== "PM"}
                color="primary"
                type="button"
                onClick={() => {
                  setCalendartime("PM");
                }}
              >
                PM
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
        <Col md={3} className="text-right">
          {isTrainer && (
            <Button
              tag={Link}
              to={"/horse/list-by-trainer/" + props.authData.user.id}
              color="primary"
              className="rounded"
            >
              Today
            </Button>
          )}
          <Button
            tag={Link}
            to="/trainer-calendar"
            color="primary"
            className="rounded"
          >
            By Trainer
          </Button>
        </Col>
      </Row>
      <Row>
        <BigCalendar calendartime={calendartime} />
      </Row>
    </Container>
  );
};

// export default Calendar;

// const mapStateToProps = (state) => ({
//   rolesData: state.roles,
// });

const mapStateToProps = (state) => {
  const authData = state.authentication;
  // const permissions =
  //   typeof authData.user !== "undefined" &&
  //   Array.isArray(authData.user.permissions)
  //     ? authData.user.permissions
  //     : [];
  return {
    authData,
  };
};

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(Calendar);
