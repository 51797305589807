import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import VerticalForm from './components/VerticalForm';

const RolesPermissions = ({ t }) => {
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">{t('roles.permissions')}</h3>
        </Col>
      </Row>
      <Row>
        <VerticalForm />
      </Row>
    </Container>
  );
};

RolesPermissions.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(RolesPermissions);
