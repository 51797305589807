/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import reportSalesReportActions from "../../../../../redux/actions/reportSalesReportActions";
import ServerSideDataTable from "../../../../../shared/components/table/ServerSideDataTable";

class DataTable extends PureComponent {
  static propTypes = {
    getReportSalesReportAction: PropTypes.func.isRequired,
    salesReport: PropTypes.shape({
      totalResults: PropTypes.number,
      filter: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        type: PropTypes.string,
        search: PropTypes.string,
        st_date: PropTypes.string,
        ed_date: PropTypes.string,
      }),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          order_id: PropTypes.string.isRequired,
          price: PropTypes.string.isRequired,
          tax: PropTypes.string.isRequired,
          tax_amount: PropTypes.string.isRequired,
          booking_count: PropTypes.number.isRequired,
          total: PropTypes.string.isRequired,
          horse_name: PropTypes.string.isRequired,
        })
      ),
    }),
  };

  static defaultProps = {
    salesReport: {
      search: "",
      totalResults: null,
    },
  };

  constructor(props) {
    super(props);
    // props.setDefaults();
    props.getReportSalesReportAction({
      st_date: props.st_date,
      ed_date: props.ed_date,
    });
  }

  // componentDidMount() {
  //   console.log("componentDidMount");
  // }
  componentDidUpdate(prevProps) {
    if (
      prevProps.st_date != this.props.st_date ||
      prevProps.ed_date != this.props.ed_date
    ) {
      this.props.getReportSalesReportAction({
        st_date: this.props.st_date,
        ed_date: this.props.ed_date,
      });
    }
  }

  handleTableChange = (type, { searchText, page }) => {
    const { props } = this;
    props.getReportSalesReportAction({
      currentPage: page,
      search: searchText,
      st_date: props.st_date,
      ed_date: props.ed_date,
    });
  };

  render() {
    const { props } = this;
    const { permissions, salesReport } = props;

    const columns = [
      {
        dataField: "order_id",
        text: "ORDER ID",
        // hidden: true,
      },
      {
        dataField: "price",
        text: "Price",
      },

      {
        dataField: "tax",
        text: "Tax",
      },
      {
        dataField: "tax_amount",
        text: "Tax Amount",
      },
      {
        dataField: "total",
        text: "Total",
      },
      {
        dataField: "booking_count",
        text: "Booking Count",
      },
      {
        dataField: "horse_name",
        text: "Horse Name",
      },
    ];
    return (
      <Col md={12} lg={12}>
        <Card>
          {salesReport.list && (
            <CardBody>
              <ServerSideDataTable
                data={salesReport.list}
                columns={columns}
                onTableChange={this.handleTableChange}
                page={salesReport.filter.currentPage}
                sizePerPage={props.sizePerPage}
                totalSize={salesReport.totalResults}
                searchText={salesReport.filter.search}
              />
            </CardBody>
          )}
        </Card>
      </Col>
    );
  }
}

function mapState(state) {
  const authData = state.authentication;
  const permissions =
    typeof authData.user !== "undefined" &&
    Array.isArray(authData.user.permissions)
      ? authData.user.permissions
      : [];
  return {
    permissions,
    sizePerPage: state.salesReport.sizePerPage,
    salesReport: state.salesReport,
  };
}

const actionCreators = {
  getReportSalesReportAction:
    reportSalesReportActions.getReportSalesReportAction,
  // setDefaults: memberActions.setCorporateDefaultsAction
};

export default connect(mapState, actionCreators)(DataTable);
