import React from 'react';
import {
  Col, Container, Row, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Permission from "../../Permission/components/Permission";
import DataTable from './components/DataTable';

const ManagerListing = (props) => {
  const { t } = props;

  return (
    <Container className="dashboard">
      <Row>
        <Col md={9}>
          <h3 className="page-title">{t('manager.page_title')}</h3>
        </Col>
        <Col md={3} className="text-right">
          <Permission id="edit-managers">
            <Button tag={Link} to="/manager/add" color="primary" className="rounded">{t('manager.add_manager')}</Button>
          </Permission>
        </Col>
      </Row>
      <Row>
        <DataTable {...props} />
      </Row>
    </Container>
  );
}

ManagerListing.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ManagerListing);