import React from 'react';
import {
  Col, Container, Row, Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Permission from "../../Permission/components/Permission";
import DataTable from './components/DataTable';

const TrainerListing = props => (
  <Container className="dashboard">
    <Row>
      <Col md={9}>
        <h3 className="page-title">Trainer Listing</h3>
      </Col>
      <Col md={3} className="text-right">
        <Permission id="edit-trainers">
          <Button tag={Link} to="/trainer/add" color="primary" className="rounded">Add Trainer</Button>
        </Permission>
      </Col>
    </Row>
    <Row>
      {/* <Table /> */}
      <DataTable {...props} />

    </Row>
  </Container>
);

export default TrainerListing;
