import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
// import useParams from "react-router-dom";
import VerticalForm from './components/VerticalForm';
import showResults from './Show';
import memberActions from '../../../redux/actions/memberActions';


const TrainerSingle = ({ match }) => {


// console.log(match);




  const title = match.params.action === 'add' ? 'Add Trainer' : 'Edit Trainer';

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">{title}</h3>
        </Col>
      </Row>
      <Row>
        <VerticalForm onSubmit={showResults} id={match.params.id} key={match.params.id} />
      </Row>
    </Container>
  );
};

TrainerSingle.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string.isRequired,
      id: PropTypes.string
    })
  }),
  // clearCorporateFrom: PropTypes.func.isRequired,
  // sizePerPage: PropTypes.number.isRequired
};

TrainerSingle.defaultProps = {
  match: {
    params: {
      id: null
    }
  },
};

// export default TrainerSingle;

const mapState = () => {
  return {
    
  };
}

const actionCreators = {
  clearCorporateFrom: memberActions.clearCorporateFrom,
};
// 

// You have to connect() to any reducers that you wish to connect to yourself
export default connect(
  mapState,
  actionCreators// bind account loading action creator
)(withTranslation('common')(TrainerSingle));

