import React, { PureComponent } from "react";
import { Card, CardBody, Col, Row, Button, ButtonToolbar } from "reactstrap";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import moment from "moment";
import clonedeep from "lodash.clonedeep";

import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import renderDatePickerField from "../../../../shared/components/form/DatePicker";
import renderTimePickerField from "../../../../shared/components/form/TimePicker";
import renderTimePickerAvailableField from "../../../../shared/components/form/TimePickerAvailable";
import bookingActions from "../../../../redux/actions/bookingActions";
import { Redirect } from "react-router-dom";

class VerticalForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    bookingtype: PropTypes.string,
    assignMember: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    assignHorse: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    bookingTypeSingle: PropTypes.shape({}),
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    id: PropTypes.string,
    participants: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    bookingtype: "",
    bookingsubtype: "",
    assignMember: {},
    assignHorse: {},
    id: null,
    participants: [],
    bookingTypeSingle: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      toggleContent: {
        service: false,
      },
      selectedDate: null,
      isCancelled: false,
    };
  }

  componentDidMount() {
    const { props } = this;
    // const { dispatch } = props;

    if (props.id) {
      props.getBooking(props.id);
      props.getBookingParticipants(props.id);
      this.toggleContent("service");
      props.cancelBookingTimeSlots(props.id);
    }
  }

  componentWillUnmount() {
    this.props.clearBookingCancelForm();
  }

  toggleContent = (key) => {
    const { state } = this;
    const newState = clonedeep(state);
    newState.toggleContent[key] = !newState.toggleContent[key];

    this.setState(newState);
  };

  getValues = (values) => {
    const { props } = this;

    const data = {};

    if (values) {
      data.recurrence = values.period ? values.period : "once";
      // Now, get the start and end time according to the given date.
      const startTime = moment(values.alternate_booking_start_time, "HH:mm");
      const endTime = moment(values.booking_end_time, "HH:mm");

      data.start_time = moment(values.booking_date)
        .utc()
        .add(startTime.hours(), "hours")
        .add(startTime.minutes(), "minutes")
        .format("YYYY-MM-DD HH:mm:ss");
      data.end_time = moment(values.booking_date)
        .utc()
        .add(endTime.hours(), "hours")
        .add(endTime.minutes(), "minutes")
        .format("YYYY-MM-DD HH:mm:ss");
      // ddd MMM DD YYYY HH:mm:ss z

      data.weekdays = "";
      if (data.recurrence !== "once") {
        if (data.recurrence === "weekly") {
          let days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
          let byweekday = [];
          if ("days" in values && values.days.length > 0) {
            values.days.forEach((day, index) => {
              if (day) {
                byweekday.push(days[index]);
              }
            });
          }
          if (byweekday.length === 0) {
            byweekday = ["MO"];
          }
          data.weekdays = byweekday.join(",");
        }
      }
    }

    // props.addUpdateBooking(JSON.stringify(data), props.id, {}, props.from);

    props.updateBookingWithNewTimes(this.props.id, data);
    this.setState({
      isCancelled: true
    });
  };

  calculateEndTime = (e, type) => {
    const { props } = this;
    const { bookingTypeSingle, dispatch, bookingStartTime } = props;
    if (!e && type === "bookingType") {
      return;
    }

    if (e && !e.duration && !bookingTypeSingle.duration) {
      return;
    }

    let duration;

    switch (type) {
      case "startTime":
        // eslint-disable-next-line prefer-destructuring
        duration = bookingTypeSingle.duration;
        break;
      case "bookingType":
        // eslint-disable-next-line prefer-destructuring
        duration = e.duration;
        break;
      default:
        // eslint-disable-next-line prefer-destructuring
        duration = bookingTypeSingle.duration;
        break;
    }

    const hours = moment(duration, "HH:mm:ss").hours();
    const minutes = moment(duration, "HH:mm:ss").minutes();
    let originTime = null;
    if (!e) {
      if (type === "startTime") {
        originTime = moment().startOf("day");
      }
    } else {
      originTime = e.duration ? bookingStartTime : e;
    }

    dispatch(
      change(
        "booking_cancel",
        "booking_end_time",
        moment(originTime)
          .add(hours, "hours")
          .add(minutes, "minutes")
      )
    );
  };

  cancelBooking = () => {
    this.props.cancelBooking(this.props.id, {
      reason: this.props.initialValues.reason,
      cancel_date: this.props.initialValues.date,
    });

    this.setState({
      isCancelled: true
    });
  };


  onlySuggestedDates = (date) => {
    
    if(this.props.cancelTimeSlotsData){
      

      const offset = date.getTimezoneOffset()
      let newDate = new Date(date.getTime() - (offset*60*1000))

    return this.props.availableDates.indexOf(newDate.toISOString().split('T')[0]) != -1;
    }
  }

  render() {
    const {
      dispatch,
      handleSubmit,
      bookingtype,
      bookingsubtype,
      bookingTypeSingle,
      cancelTimeSlotsData,
      cancelBookingData,
      initialValues,
    } = this.props;
    const { toggleContent } = this.state;

    const isSessionSelected = bookingtype.length > 0;
    let isBookingSelected = isSessionSelected;
    if (bookingtype !== "2") {
      isBookingSelected = isSessionSelected && bookingsubtype.length > 0;
    }
    const isServiceSelected = isBookingSelected && "value" in bookingTypeSingle;

    let bookingTypeTitle = "";
    if (isBookingSelected) {
      bookingTypeTitle = "Service";
      if (bookingtype === "2") {
        bookingTypeTitle = "Activity";
      } else if (bookingtype === "3") {
        bookingTypeTitle = "Package";
      }
    }
    return (
      <>
        {((initialValues && initialValues.status == 2) || this.state.isCancelled ||
          (cancelBookingData &&
            cancelBookingData.message &&
            cancelBookingData.message == "Booking Cancelled Succssfully")) && (
          <Redirect to={"/booking/listing"} />
        )}
        <Col md={12} lg={12} xl={12}>
          <form className="form" onSubmit={handleSubmit(this.getValues)}>
            <div className="formInner">
              <div className="form__field-group">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Reason for Cancellation
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="reason"
                              component="textarea"
                              type="text"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">Date</span>
                          <div className="form__form-group-field">
                            <Field
                              name="booking_date"
                              component={renderDatePickerField}
                              placeholder="12/12/1222"
                              filterDate={this.onlySuggestedDates}
                              onChange={(date)=>{
                                const offset = date.getTimezoneOffset()
                                let newDate = new Date(date.getTime() - (offset*60*1000))

                                this.setState({
                                  selectedDate: newDate.toISOString().split('T')[0]
                                });
                                if(cancelTimeSlotsData){
                                let startTime = '';
                                let endTime = '';
                                for(let i =0; i<cancelTimeSlotsData.length; i++){
                                  if(cancelTimeSlotsData[i].date == newDate.toISOString().split('T')[0]){
                                    startTime = cancelTimeSlotsData[i].start_time;
                                    endTime = cancelTimeSlotsData[i].end_time;
                                    break;
                                  }
                                }
                                dispatch(
                                  change(
                                    "booking_cancel",
                                    "alternate_booking_start_time",
                                    moment(startTime)
                                      
                                  )
                                );
                                dispatch(
                                  change(
                                    "booking_cancel",
                                    "booking_end_time",
                                    moment(endTime)
                                      
                                  )
                                );
                                  }
                              }}
                              // disabled
                            />
                            <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Do you wish to choose alternate time slot?
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="alternate_booking_start_time"
                              onChange={(e) => {
                                this.calculateEndTime(e, "startTime");
                              }}
                              component={renderTimePickerAvailableField}
                              placeholder=""
                              cancelTimeSlotsData={cancelTimeSlotsData}
                              selectedDate={this.state.selectedDate}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            End Time
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="booking_end_time"
                              component={renderTimePickerField}
                              placeholder=""
                              disabled
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>

              {/* {isSessionSelected && ( */}
              <div className="form__field-group">
                <Card>
                  <CardBody>
                    <ButtonToolbar className="form__button-toolbar">
                      <Button
                        className="rounded"
                        type="submit"
                        color="primary"
                        // onClick={this.updateBookingWithNewTimes}
                      >
                        Book for new Slot
                      </Button>
                      {/* {id ? (
                      <Button
                        className="rounded"
                        type="button"
                        onClick={reset}
                        disabled={pristine || submitting}
                      >
                        Cancel
                      </Button>
                    ) : ( */}
                      <Button
                        outline
                        // color="primary"
                        className="rounded"
                        type="button"
                        onClick={this.cancelBooking}
                      >
                        Cancel booking
                      </Button>
                      {/* )} */}
                    </ButtonToolbar>
                  </CardBody>
                </Card>
              </div>
              {/* )} */}
            </div>
          </form>
        </Col>
      </>
    );
  }
}

const selector = formValueSelector("booking_cancel");

const mapState = (state, ownProps) => {
  const bookingStartTime = selector(state, "alternate_booking_start_time");
  const bookingEndTime = selector(state, "booking_end_time");

  const period = selector(state, "period");
  const reason = selector(state, "reason");
  let weekdays = [];

  let bookingTypeSingle = selector(state, "bookingtype_single");

  let start = new Date();
  start.setHours(0, 0, 0, 0);

  if (!bookingTypeSingle) {
    bookingTypeSingle = {};
  }


  let initValues = state.booking.addFormData.data
    ? state.booking.addFormData.data
    : null;

  if (initValues) {
    initValues.bookingtype_single = initValues.service
      ? initValues.service
      : null;
    initValues.booking_date = state.booking.cancelTimeSlotsData
    ? 
      new Date(state.booking.cancelTimeSlotsData[0].date)
    : initValues.date
      ? new Date(initValues.date)
      : null;
    initValues.alternate_booking_start_time = state.booking.cancelTimeSlotsData
      ? moment(
          moment(state.booking.cancelTimeSlotsData[0].start_time).format("HH:mm"),
          "HH:mm"
        )
      : initValues.start_time
      ? moment(initValues.start_time, "HH:mm")
      : null;
    initValues.booking_end_time = state.booking.cancelTimeSlotsData
      ? moment(
          moment(state.booking.cancelTimeSlotsData[0].end_time).format("HH:mm"),
          "HH:mm"
        )
      : initValues.end_time
      ? moment(initValues.end_time, "HH:mm")
      : null;
    initValues.period = initValues.recurrence ? initValues.recurrence : "";
    if (typeof initValues.weekdays !== "undefined") {
      let days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
      let currentWeekDays = initValues.weekdays.split(",");
      days.forEach((day) => {
        weekdays.push(currentWeekDays.includes(day));
      });
    }
    initValues.bookingStartTime = bookingStartTime;
    initValues.bookingEndTime = bookingEndTime;
    initValues.reason = reason;
  }
  if (!ownProps.id && !initValues) {
    initValues = {};
    initValues.booking_date = start;
  }

  return {
    initialValues: initValues,
    bookingStartTime,
    bookingTypeSingle,
    period,
    weekdays,
    cancelTimeSlotsData: state.booking.cancelTimeSlotsData,
    availableDates: state.booking.availableDates,
    cancelBookingData: state.booking.cancelBookingData,
  };
};

const actionCreators = {
  getBooking: bookingActions.getBookingAction,
  clearBookingCancelForm: bookingActions.clearBookingCancelForm,
  getBookingParticipants: bookingActions.getBookingParticipantsAction,
  cancelBookingTimeSlots: bookingActions.cancelBookingTimeSlotsAction,
  cancelBooking: bookingActions.cancelBookingAction,
  updateBookingWithNewTimes: bookingActions.updateBookingWithNewTimesAction,
};

export default connect(
  mapState,
  actionCreators
)(
  reduxForm({
    form: "booking_cancel",
  })(VerticalForm)
);
