import React, { PureComponent } from "react";
import {
	Button,
} from 'reactstrap';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from "redux-form";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import userActions from '../../../redux/actions/userActions';
import { excludeSidebar } from '../../../redux/actions/sidebarActions';
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import renderCheckBoxField from "../../../shared/components/form/CheckBox";

class LogInForm extends PureComponent {
	// static propTypes = {
	// 	handleSubmit: PropTypes.func.isRequired
	// };
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		// sidebar: SidebarProps.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			showPassword: false
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(excludeSidebar(true));

	}

	componentWillUnmount() {
		const { dispatch } = this.props;
		dispatch(excludeSidebar(false));
	}

	// changeSidebarVisibility = () => {
	// 	const { dispatch } = this.props;
	// 	dispatch(changeSidebarVisibility());
	// };

	// changeMobileSidebarVisibility = () => {
	// 	const { dispatch } = this.props;
	// 	dispatch(changeMobileSidebarVisibility());
	// };

	showPassword = e => {
		e.preventDefault();
		this.setState(prevState => ({ showPassword: !prevState.showPassword }));
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { props } = this;
		props.authUser(props.username, props.password);
		// const { handleSubmit } = this.props;
		// handleSubmit();
	}

	render() {
		
		const { showPassword } = this.state;

		return (
			<form className='form' onSubmit={this.handleSubmit}>
				<div className='form__form-group'>
					<span className='form__form-group-label'>Username</span>
					<div className='form__form-group-field'>
						<div className='form__form-group-icon'>
							<AccountOutlineIcon />
						</div>
						<Field
							name='name'
							component='input'
							type='text'
							placeholder='Name'
						/>
					</div>
				</div>
				<div className='form__form-group'>
					<span className='form__form-group-label'>Password</span>
					<div className='form__form-group-field'>
						<div className='form__form-group-icon'>
							<KeyVariantIcon />
						</div>
						<Field
							name='password'
							component='input'
							type={showPassword ? "text" : "password"}
							placeholder='Password'
						/>
						<button
							className={`form__form-group-button${
								showPassword ? " active" : ""
							}`}
							onClick={e => this.showPassword(e)}
							type='button'
						>
							<EyeIcon />
						</button>
					</div>
					<div className='account__forgot-password'>
						<a href='/forgot-password'>Forgot a password?</a>
					</div>
				</div>
				<div className='form__form-group'>
					<div className='form__form-group-field'>
						<Field
							name='remember_me'
							component={renderCheckBoxField}
							label='Remember me'
						/>
					</div>
				</div>
				<Button className="rounded" color="primary" type="submit">Sign In</Button>
				
			</form>
		);
	}
}

// export default reduxForm({
// 	form: "log_in_form"
// })(LogInForm);

function mapState(state) {
	const { loggingIn } = state.authentication;
	const selector = formValueSelector('log_in_form'); // <-- same as form name

	const username = selector(state, 'name');
	const password = selector(state, 'password');

	return {
		loggingIn,
		username,
		password,
		sidebar: state.sidebar
	};
}

const actionCreators = {
	authUser: userActions.authUser,
	// toggleSidebar: changeSidebarVisibility
};

// export default connect(mapState, actionCreators)(LogIn);
export default connect(mapState, actionCreators)(reduxForm({
	form: 'log_in_form', // a unique identifier for this form
})(LogInForm));
