import API from "../../api/API";
import { managerConstants } from "../../_constants/dashboard.constants";
import history from "../../_helpers/history";
import alertActions from "./alertActions";
import preloaderActions from "./preloaderActions";
import { constructUrlParams } from "../../_helpers/CommonFunctions";

const getManagerListAction = (filters) => {
  let custFilter = filters;

  const success = (data) => {
    return {
      type: managerConstants.MANAGER_LIST,
      data,
      filter: {
        currentPage: custFilter.currentPage,
        search: custFilter.search,
      },
    };
  };

  return (dispatch, getState) => {
    dispatch(preloaderActions.show());
    const { manager } = getState();

    if (typeof filters === "undefined") {
      custFilter = manager.filter;
    }

    if (custFilter.currentPage > 1) {
      custFilter.currentPage =
        custFilter.currentPage > manager.last_page
          ? manager.last_page
          : custFilter.currentPage;
    }

    const urlParams = constructUrlParams({
      page: custFilter.currentPage,
      search: custFilter.search,
    });

    API.getManagers(urlParams).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
};

const getManagerAction = (id) => {
  const success = (data) => {
    return {
      type: managerConstants.MANAGER_GET,
      data,
    };
  };

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.getManager(id).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
};

const addUpdateManagerAction = (params, id) => {
  const success = (data) => {
    return {
      type: id ? managerConstants.MANAGER_UPDATE : managerConstants.MANAGER_ADD,
      data,
    };
  };

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.addUpdateManager(params, id).then(
      (response) => {
        if (response.data) {
          history.push(`/manager/edit/${response.data.data.record}`);

          if (!id) {
            dispatch(success(response.data));
          }
          dispatch(alertActions.success(response.data.message));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
};

const deleteManagerAction = (id) => {
  const success = (data) => {
    return {
      type: managerConstants.MANAGER_DELETE,
      data,
    };
  };

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.deleteManager(id).then(
      (response) => {
        if (response.data) {
          dispatch(alertActions.success(response.data.message));
          dispatch(getManagerListAction());
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
};

function clearManagerForm() {
  return {
    type: managerConstants.MANAGER_GET_CLEAR,
  };
}

const managerActions = {
  getManagerListAction,
  getManagerAction,
  addUpdateManagerAction,
  clearManagerForm,
  deleteManagerAction,
};

export default managerActions;
