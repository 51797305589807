import { dashboardConstants} from "../../_constants/dashboard.constants";


const initialState = {
    dashstats: {
		widgets:{"sales":[],"orders":[],"cancels":[],"members":[]},
		charts:{"sales":[]},
	}

}

function dashboardReducer( state = initialState, action) {
    switch(action.type) {
		case dashboardConstants.DASHBOARD_GET:
            return {
                ...state,
                dashstats: action.data.data,
            };
		default:
            return state;   
    }
}

export default dashboardReducer;