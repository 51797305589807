import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({
  component: Component,
  authData,
  data,
  permission,
  permissionGroup,
  ...rest
}) => {
  const { loggedIn, user } = authData;

  return (
    <Route
      {...rest}
      render={(props) => {
        const isGuarded = typeof user !== "undefined" && loggedIn;
        if (!isGuarded) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        const { permissions } = user;
        const DashBoardRedirect = () => {
          if (permissions.includes("manage-dashboard")) {
            return <Redirect to={{ pathname: "/dashboard" }} />;
          }

          return <Redirect to={{ pathname: "/login" }} />;
        };

        if (
          typeof permission !== "undefined" &&
          permission &&
          !permissions.includes(permission)
        ) {
          return <DashBoardRedirect />;
        }

        if (typeof permissionGroup !== "undefined" && permissionGroup) {
          const regex = RegExp(permissionGroup);
          const hasPermission = permissions.some((perm) => regex.test(perm));
          if (!hasPermission) {
            return <DashBoardRedirect />;
          }
        }

        return <Component {...props} {...data} user={user} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  authData: PropTypes.objectOf(PropTypes.any),
  permission: PropTypes.string,
  permissionGroup: PropTypes.string,
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.string,
  }),
};
PrivateRoute.defaultProps = {
  location: {},
  authData: {},
  permission: "",
  permissionGroup: "",
};

const mapStateToProps = (state) => ({ authData: state.authentication });

export default connect(mapStateToProps)(PrivateRoute);
