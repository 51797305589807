import { bookingConstants } from "../../_constants/dashboard.constants";

const initialState = {
  list: null,
  sizePerPage: 10,
  currentPage: 1,
  filter: {
    currentPage: 1,
    search: "",
  },
  addFormData: {},
  groupFormData: {},
  participantData: [],
  bookingCancelled: {
    list: null,
    sizePerPage: 10,
    currentPage: 1,
    filter: {
      currentPage: 1,
      search: "",
    },
  },
  bookingNoshows: {
    list: null,
    sizePerPage: 10,
    currentPage: 1,
    filter: {
      currentPage: 1,
      search: "",
    },
  },
  userBookings: {
    list: null,
    sizePerPage: 10,
    currentPage: 1,
    filter: {
      currentPage: 1,
      search: "",
    },
  },
};

function bookingReducer(state = initialState, action) {
  switch (action.type) {
    case bookingConstants.BOOKING_LIST:
      return {
        ...state,
        list: action.data.data,
        filter: {
          search: action.filter.search ? action.filter.search : "",
          currentPage: action.filter.currentPage
            ? action.filter.currentPage
            : 1,
        },
        totalResults: action.data.meta.total,
        last_page: action.data.meta.last_page,
      };
    case bookingConstants.BOOKING_ADD:
      return {
        ...state,
      };
    case bookingConstants.BOOKING_UPDATE:
      return {
        ...state,
        addFormData: action.data,
      };
    case bookingConstants.BOOKING_GET:
      return {
        ...state,
        addFormData: action.data,
      };
    case bookingConstants.BOOKING_DELETE:
      return {
        ...state,
        totalResults: action.data.total,
        last_page: action.data.last_page,
      };
    case bookingConstants.BOOKING_GET_CLEAR:
      return {
        ...state,
        ...state.location,
        addFormData: {},
      };
    case bookingConstants.GROUP_BOOKING_GET:
      return {
        ...state,
        groupFormData: action.data,
      };
    case bookingConstants.GROUP_BOOKING_ADD:
      return {
        ...state,
      };
    case bookingConstants.GROUP_BOOKING_CLEAR:
      return {
        ...state,
        groupFormData: {},
      };
    case bookingConstants.BOOKING_PARTICIPANTS_LIST:
      return {
        ...state,
        participantData: action.data.data,
      };
    case bookingConstants.BOOKING_PARTICIPANTS_ADD: {
      const dataArray = [];
      dataArray.push(action.data.data);

      return {
        ...state,
        participantData:
          state.participantData.length > 0
            ? state.participantData.concat(action.data.data)
            : dataArray,
      };
    }
    case bookingConstants.BOOKING_PARTICIPANTS_DELETE:
      return {
        ...state,
        participantData: action.data,
      };
    case bookingConstants.BOOKING_CANCEL_TIME_SLOTS:
      let slotsArray = [];
      for (let o in action.data) {
        // action.data[o].date =
        //   action.data[o].start_time.split(" ")[0] ==
        //     action.data[o].end_time.split(" ")[0] &&
        //   action.data[o].start_time.split(" ")[0];

        action.data[o].date = action.data[o].start_time.split(" ")[0];
        slotsArray.push(action.data[o]);
      }

      let availableDates = [];
      for (let i = 0; i < slotsArray.length; i++) {
        if (availableDates.indexOf(slotsArray[i].date) == -1) {
          availableDates.push(slotsArray[i].date);
        }
      }
      return {
        ...state,
        cancelTimeSlotsData: slotsArray,
        availableDates: availableDates,
      };

    case bookingConstants.BOOKING_CANCEL:
      return {
        ...state,
        cancelBookingData: action.data,
      };
    case bookingConstants.BOOKING_NO_SHOW:
      return {
        ...state,
      };
    case bookingConstants.BOOKING_UPDATE_WITH_NEW_TIMES:
      return {
        ...state,
        updateBookingWithNewTimesData: action.data.data,
      };

    case bookingConstants.BOOKING_CANCEL_CLEAR:
      return {
        ...state,
        ...state.location,
        cancelBookingData: {},
        updateBookingWithNewTimesData: {},
        addFormData: {},
      };

    case bookingConstants.BOOKING_CANCELLED_LIST:
      return {
        ...state,
        bookingCancelled: {
          list: action.data.data,
          filter: {
            search: action.filter.search ? action.filter.search : "",
            currentPage: action.filter.currentPage
              ? action.filter.currentPage
              : 1,
          },
          totalResults: action.data.meta.total,
          last_page: action.data.meta.last_page,
        },
      };
    case bookingConstants.BOOKING_NO_SHOWS_LIST:
      return {
        ...state,
        bookingNoshows: {
          list: action.data.data,
          filter: {
            search: action.filter.search ? action.filter.search : "",
            currentPage: action.filter.currentPage
              ? action.filter.currentPage
              : 1,
          },
          totalResults: action.data.meta.total,
          last_page: action.data.meta.last_page,
        },
      };
    case bookingConstants.USER_BOOKING_LIST:
      return {
        ...state,
        userBookings: {
          list: action.data.data,
          filter: {
            search: action.filter.search ? action.filter.search : "",
            currentPage: action.filter.currentPage
              ? action.filter.currentPage
              : 1,
          },
          totalResults: action.data.meta.total,
          last_page: action.data.meta.last_page,
        },
      };
    default:
      return state;
  }
}

export default bookingReducer;
