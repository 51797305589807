import React, { PureComponent } from "react";
import { Col } from "reactstrap";
import classnames from "classnames";

import { Field } from "redux-form";
import clonedeep from "lodash.clonedeep";

import API from "../../../../api/API";

import renderParticipantField from "../../../../shared/components/form/Participants";

class AddBooking extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      riderData: {},
      horseData: {},
    };
  }

  componentDidMount() {
    const { rider, horse } = this.props;
    
    // this.getRiderDetails(rider);
    // setTimeout(() => {
    //   this.getHorseDetails(horse);
    // }, 1000);
  }

  toggleContent = (key) => {
    const { state } = this;
    const newState = clonedeep(state);
    newState[key].toggle =
      "toggle" in newState[key] ? !newState[key].toggle : false;

    this.setState(newState);
  };

  getRiderDetails = (e) => {
    const { state } = this;

    if (e && "value" in e) {
      const newState = clonedeep(state);
      API.getMember(e.value).then(
        (response) => {
          if (response.data) {
            newState.riderData = {
              data: response.data.data,
              toggle: true,
            };
            this.setState(newState);
          } else {
            this.resetState("riderData");
          }
        },
        () => {
          this.resetState("riderData");
        }
      );
    }
  };

  getHorseDetails = (e) => {
    const { state } = this;

    if (e && "value" in e) {
      const newState = clonedeep(state);
      API.getHorse(e.value).then(
        (response) => {
          if (response.data) {
            newState.horseData = {
              data: response.data.data,
              toggle: true,
            };
            this.setState(newState);
          } else {
            this.resetState("horseData");
          }
        },
        () => {
          this.resetState("horseData");
        }
      );
    }
  };

  resetState = (key = "") => {
    const { state } = this;
    let newState = {
      riderData: {},
      horseData: {},
    };
    if (key.length > 0) {
      newState = clonedeep(state);
      newState[key] = {};
    }

    this.setState(newState);
  };

  render() {
    const { id, rider, horse, bookingtype, isChanged } = this.props;
    const { riderData, horseData } = this.state;

    const showRiderDetails =
      "value" in rider && Object.keys(riderData).length > 0;
    const showHorseDetails =
      "value" in horse && Object.keys(horseData).length > 0;

    return (
      <>
        <Col sm="12" lg="6">
          <div className="form__form-group">
            <label className="form__form-group-label">Select Rider</label>
            <span style={{ color: "red" }}>*</span>
            <Field
              name="user"
              component={renderParticipantField}
              booking={id}
              bookingType={bookingtype}
              isChanged={isChanged}
              api="participantlist/riders"
              onChange={(e) => {
                this.getRiderDetails(e);
                this.props.onChange();
              }}
              placeholder="Select Rider"
            />
            {showRiderDetails && (
              <div
                className={classnames({
                  "cs-accordion": true,
                  open: "toggle" in riderData && riderData.toggle,
                })}
              >
                <h4
                  className="cs-accordion-title"
                  onClick={() => this.toggleContent("riderData")}
                >
                  <strong>DETAILS</strong>
                  <span className="lnr lnr-chevron-down" />
                </h4>
                <div className="cs-accordion-content">
                  <ul className="slide-in-content-item-list">
                    <li className="col-half">
                      <span>
                        <strong>ID:</strong>
                      </span>
                      <span>{riderData.data.id}</span>
                    </li>
                    <li className="col-half">
                      <span>
                        <strong>Company:</strong>
                      </span>
                      <span>
                        {riderData.data.corporate
                          ? riderData.data.corporate.label
                          : ""}
                      </span>
                    </li>
                    <li className="col-half">
                      <span>
                        <strong>Phone:</strong>
                      </span>
                      <span>{riderData.data.mobile}</span>
                    </li>
                    <li className="col-half">
                      <span>
                        <strong>Email:</strong>
                      </span>
                      <span>{riderData.data.email}</span>
                    </li>
                    <li className="col-half">
                      <span>
                        <strong>Horses:</strong>
                      </span>
                      <span></span>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </Col>
        {!this.props.riderOnly &&
        <Col sm="12" lg="6">
          <div className="form__form-group">
            <label className="form__form-group-label">Select Horse</label>
            <span style={{ color: "red" }}>*</span>
            <Field
              name="horse"
              component={renderParticipantField}
              booking={id}
              bookingType={bookingtype}
              isChanged={isChanged}
              api="participantlist/horses"
              onChange={(e) => {
                this.getHorseDetails(e);
                this.props.onChange();
              }}
              placeholder="Select Horse"
            />
            {showHorseDetails && (
              <div
                className={classnames({
                  "cs-accordion": true,
                  open: "toggle" in horseData && horseData.toggle,
                })}
              >
                <h4
                  className="cs-accordion-title"
                  onClick={() => this.toggleContent("horseData")}
                >
                  <strong>DETAILS</strong>
                  <span className="lnr lnr-chevron-down" />
                </h4>
                <div className="cs-accordion-content">
                  <ul className="slide-in-content-item-list">
                    <li className="col-half">
                      <span>
                        <strong>ID:</strong>
                      </span>
                      <span>{horseData.data.id}</span>
                    </li>
                    <li className="col-half">
                      <span>
                        <strong>Owner:</strong>
                      </span>
                      <span>
                        {horseData.data.owner_name
                          ? horseData.data.owner_name
                          : ""}
                      </span>
                    </li>
                    <li className="col-half">
                      <span>
                        <strong>Category:</strong>
                      </span>
                      <span>
                        {horseData.data.category_name
                          ? horseData.data.category_name
                          : ""}
                      </span>
                    </li>
                    <li className="col-half">
                      <span>
                        <strong>FEI ID:</strong>
                      </span>
                      <span>{horseData.data.horse_fei_id}</span>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </Col>}
      </>
    );
  }
}

export default AddBooking;