import { miscConstants} from "../../_constants/common.constants";

const initialState = {
    formDefaults: {},
}

function commonReducer( state = initialState, action) {
    switch(action.type) {
        case miscConstants.FORMDATA_GET:
            return {
                ...state,
                formDefaults: action.data.data.dropdown
            };

        default:
            return state;   
    }
}

export default commonReducer;