import axios from "axios";
import GlobalConstans from "../_constants/global.constants";

const { CancelToken } = axios;

const cancelId = {
  getHorseOwners: "",
  getDropDown: "",
  uploadDocument: "",
};

let bearerToken;
let tokenType;

function getToken() {
  let token = "";
  if (!bearerToken) {
    const userToken = JSON.parse(localStorage.getItem("authToken"));
    if (userToken) {
      bearerToken = JSON.parse(localStorage.getItem("authToken")).access_token;
      tokenType = JSON.parse(localStorage.getItem("authToken")).token_type;
    }
  }
  token = `${tokenType} ${bearerToken}`;
  return token;
}

// declare a request interceptor
axios.interceptors.request.use(
  (config) => {
    const customConfig = config;
    customConfig.headers.Authorization = getToken();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function login(username, password) {
  const url = `${GlobalConstans.base_url}login`;
  const urlParams = {
    email: username,
    password,
  };

  return axios
    .post(url, urlParams)
    .then((response) => {
      if (response && response.data.access_token) {
        bearerToken = response.data.access_token;
        tokenType = response.data.token_type;
      }

      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getUser() {
  const url = `${GlobalConstans.base_url}profile`;
  return axios
    .post(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("authToken");
  bearerToken = null;
  tokenType = null;
}

function resetPassword(email) {
  const url = `${GlobalConstans.base_url}password/create`;
  const urlParams = {
    email,
  };
  return axios
    .post(url, urlParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
/*
    Corporate
*/

function getCorporates(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}corporates${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function deleteCorporate(id) {
  const url = `${GlobalConstans.base_url}corporate/${id}`;
  return axios
    .delete(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function addUpdateCorporate(params, id) {
  let iD = id;
  iD = typeof iD !== "undefined" ? iD : "";
  const url = iD
    ? `${GlobalConstans.base_url}corporate/${iD}`
    : `${GlobalConstans.base_url}corporate`;
  return axios
    .post(url, JSON.parse(params))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function getCorporate(id) {
  let iD = id;
  iD = typeof iD !== "undefined" ? iD : "";
  const url = `${GlobalConstans.base_url}corporate/${iD}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

/*
    Horse
*/
function getHorses(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}horses${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function deleteHorse(id) {
  const url = `${GlobalConstans.base_url}horse/${id}`;
  return axios
    .delete(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function addUpdateHorse(params, id) {
  let iD = id;
  iD = typeof iD !== "undefined" ? iD : "";

  const url = iD
    ? `${GlobalConstans.base_url}horse/${iD}`
    : `${GlobalConstans.base_url}horse`;

  return axios
    .post(url, JSON.parse(params))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function getHorse(id) {
  let iD = id;
  iD = typeof iD !== "undefined" ? iD : "";
  const url = `${GlobalConstans.base_url}horse/${iD}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

const cancelToken = (name) => {
  return new CancelToken((c) => {
    cancelId[name] = c;
  });
};

function getHorseOwners(params) {
  if (cancelId.getHorseOwners) {
    cancelId.getHorseOwners();
  }
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}getownerhorse${param}`;
  return axios
    .get(url, {
      cancelToken: cancelToken("getHorseOwners"),
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getHorseListByTrainers(id, params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}horselistbytrainer/${id}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

/*
    Horse Locations
*/
function getHorseLocations(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}horselocations${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function deleteHorseLocation(id) {
  const url = `${GlobalConstans.base_url}horselocation/${id}`;
  return axios
    .delete(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function addUpdateHorseLocation(params, id) {
  let iD = id;
  iD = typeof iD !== "undefined" ? iD : "";
  const url = iD
    ? `${GlobalConstans.base_url}horselocation/${iD}`
    : `${GlobalConstans.base_url}horselocation`;
  return axios
    .post(url, JSON.parse(params))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function getHorseLocation(id) {
  let iD = id;
  iD = typeof iD !== "undefined" ? iD : "";
  const url = `${GlobalConstans.base_url}horselocation/${iD}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

/*
    Member
*/
function getMembers(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}members${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function deleteMember(id) {
  const url = `${GlobalConstans.base_url}member/${id}`;
  return axios
    .delete(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function addUpdateMember(params, id) {
  let iD = id;
  iD = typeof iD !== "undefined" ? iD : "";
  const url = iD
    ? `${GlobalConstans.base_url}member/${iD}`
    : `${GlobalConstans.base_url}member`;
  return axios
    .post(url, JSON.parse(params))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function getMember(id) {
  let iD = id;
  iD = typeof iD !== "undefined" ? iD : "";
  const url = `${GlobalConstans.base_url}member/${iD}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getOwnerHorses(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}gethorse/${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function deleteOwnerHorse(horseId, ownerId) {
  const url = `${GlobalConstans.base_url}horsebyowner/${horseId}/${ownerId}`;
  return axios
    .delete(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Managers
 */

const getManagers = (params) => {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}managers${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

const getManager = (id) => {
  let iD = id;
  iD = typeof iD !== "undefined" ? iD : "";
  const url = `${GlobalConstans.base_url}manager/${iD}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

const addUpdateManager = (params, id) => {
  let iD = id;
  iD = typeof iD !== "undefined" ? iD : "";
  const url = iD
    ? `${GlobalConstans.base_url}manager/${iD}`
    : `${GlobalConstans.base_url}manager`;
  return axios
    .post(url, JSON.parse(params))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Roles
 */

const getAllUserRoles = (params) => {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}user-permissions${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

const getSingleUserRoles = (id) => {
  const url = `${GlobalConstans.base_url}user-permissions/${id}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

const updateSingleUserRoles = (params, id) => {
  const url = `${GlobalConstans.base_url}user-permissions/${id}`;
  return axios
    .post(url, JSON.parse(params))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

const getAllPermissions = () => {
  const url = `${GlobalConstans.base_url}permissions`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

const UpdatePermissions = (params, role) => {
  const url = `${GlobalConstans.base_url}permissions/${role}`;
  return axios
    .post(url, JSON.parse(params))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

/*
    Trainer
*/
function getTrainers(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}trainers${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function deleteTrainer(id) {
  const url = `${GlobalConstans.base_url}trainer/${id}`;
  return axios
    .delete(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function addUpdateTrainer(params, id) {
  let iD = id;
  iD = typeof iD !== "undefined" ? iD : "";
  const url = iD
    ? `${GlobalConstans.base_url}trainer/${iD}`
    : `${GlobalConstans.base_url}trainer`;
  return axios
    .post(url, JSON.parse(params))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function getTrainer(id) {
  let iD = id;
  iD = typeof iD !== "undefined" ? iD : "";
  const url = `${GlobalConstans.base_url}trainer/${iD}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

/*
    Booking
*/
function getBookings(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}bookings${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function deleteBooking(id) {
  const url = `${GlobalConstans.base_url}booking/${id}`;
  return axios
    .delete(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function addUpdateBooking(params, id) {
  let iD = id;
  iD = typeof iD !== "undefined" ? iD : "";
  const url = iD
    ? `${GlobalConstans.base_url}booking/${iD}`
    : `${GlobalConstans.base_url}booking`;
  return axios
    .post(url, JSON.parse(params))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function getBooking(id) {
  let iD = id;
  iD = typeof iD !== "undefined" ? iD : "";
  const url = `${GlobalConstans.base_url}booking/${iD}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getGroupBooking(id) {
  let iD = id;
  iD = typeof iD !== "undefined" ? iD : "";
  const url = `${GlobalConstans.base_url}getgroup/${iD}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function addGroupBooking(params, id) {
  let iD = id;
  iD = typeof iD !== "undefined" ? iD : "";
  const url = `${GlobalConstans.base_url}addgroup/${iD}`;
  return axios
    .post(url, JSON.parse(params))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getBookingParticipants(id) {
  const url = `${GlobalConstans.base_url}participants/${id}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function addBookingParticipant(params) {
  const url = `${GlobalConstans.base_url}participant`;
  return axios
    .post(url, JSON.parse(params))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function deleteBookingParticipant(id) {
  const url = `${GlobalConstans.base_url}participant/${id}`;
  return axios
    .delete(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

/*
    UploadFile
*/
function uploadDocument(params) {
  if (cancelId.uploadDocument) {
    cancelId.uploadDocument();
  }

  const fd = new FormData();
  fd.set("object_id", params.object_id);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < params.documents.length; i++) {
    fd.append(`documents[${i}]`, params.documents[i]);
  }

  const url = `${GlobalConstans.base_url}upload/${params.type}`;
  return axios
    .post(url, fd, {
      "Content-Type": "multipart/form-data",
      cancelToken: cancelToken("uploadDocument"),
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function getDocument(params) {
  const url = `${GlobalConstans.base_url}attachments/${params.type}/${params.id}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function deleteDocument(params) {
  const url = `${GlobalConstans.base_url}attachment/${params.id}`;
  return axios
    .delete(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

/*
    Categories
*/

function getCategories(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}categories/3${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function deleteCategory(id) {
  const url = `${GlobalConstans.base_url}category/${id}`;
  return axios
    .delete(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function addUpdateCategory(params, id) {
  let iD = id;
  const postParams = JSON.parse(params);
  postParams.object = 3;
  iD = typeof iD !== "undefined" ? iD : "";
  const url = iD
    ? `${GlobalConstans.base_url}category/${iD}`
    : `${GlobalConstans.base_url}category`;
  return axios
    .post(url, postParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function getCategory(id) {
  let iD = id;
  iD = typeof iD !== "undefined" ? iD : "";
  const url = `${GlobalConstans.base_url}category/${iD}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

/*
    Services
*/

function getServices(type, params) {
  let serviceType = type;

  switch (type) {
    case "singleSession":
      serviceType = "service";
      break;
    case "packages":
      serviceType = "package";
      break;
    case "activity":
      serviceType = "activity";
      break;
    default:
      serviceType = "service";
      break;
  }

  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}packages/${serviceType}${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function deleteService(type, id) {
  const url = `${GlobalConstans.base_url}package/${id}`;
  return axios
    .delete(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function addUpdateService(type, params, id) {
  let serviceType = type;

  switch (type) {
    case "singleSession":
      serviceType = 1;
      break;
    case "packages":
      serviceType = 3;
      break;
    case "activity":
      serviceType = 2;
      break;
    default:
      serviceType = 1;
      break;
  }

  const serviceParams = JSON.parse(params);
  serviceParams.service_type = serviceType;

  let iD = id;
  iD = typeof iD !== "undefined" ? iD : "";
  const url = iD
    ? `${GlobalConstans.base_url}package/${iD}`
    : `${GlobalConstans.base_url}package`;
  return axios
    .post(url, serviceParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function getService(type, id) {
  let iD = id;
  iD = typeof iD !== "undefined" ? iD : "";
  const url = `${GlobalConstans.base_url}package/${iD}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

/*
    Calendar
*/

function getCalendarEvents(urlParams) {
  const url = `${GlobalConstans.base_url}calendar`;
  return axios
    .get(url, { params: urlParams })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getCalendarEventsByTime(urlParams) {
  const url = `${GlobalConstans.base_url}calendartime`;
  return axios
    .get(url, { params: urlParams })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getCalendarTrainers() {
  const url = `${GlobalConstans.base_url}trainercalendar`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

/*
    Common
*/

function getDropDown(url, shouldCancel) {
  if (!shouldCancel && cancelId.getDropDown) {
    cancelId.getDropDown();
  }

  return axios
    .get(url, {
      cancelToken: cancelToken("getDropDown"),
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getFormData() {
  const url = `${GlobalConstans.base_url}formdata`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function getDropdownCategories(type) {
  const url = `${GlobalConstans.base_url}categorylist/${type}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getDashStats(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}dashboard/stats${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getHorseReport(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}horsereports${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getHorseByOwnerReport(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}getownerhorse${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getMemberReport(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}memberreports${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function getCorporateReport(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}corporatereports${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getSalesByTrainerReport(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}salesbytrainer${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getSalesByServiceReport(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}salesbyservicetype${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getSalesReport(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}salesreport${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getLiveryReport(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}liveryreport${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function exportHorseReport(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}exporthorse${param}`;
  return axios({
    url: url,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function exportMemberReport(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}exportmember${param}`;
  return axios({
    url: url,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function exportCorporateReport(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}exportcorporate${param}`;
  return axios({
    url: url,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function exportSalesReport(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}exportsales${param}`;
  return axios({
    url: url,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function exportSalesByServiceReport(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}exportsalesbyservice${param}`;
  return axios({
    url: url,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function exportTrainerReport(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}exporttrainer${param}`;
  return axios({
    url: url,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function exportLiveryReport(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}exportlivery${param}`;
  return axios({
    url: url,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getGlobalSearch(searchKey) {
  let url = `${GlobalConstans.base_url}globalsearch`;
  if (searchKey.trim() != "") {
    url += "/" + searchKey;
  }
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function horseByMember(params, ownerId) {
  const url = `${GlobalConstans.base_url}horsebymember/` + ownerId;

  return axios
    .post(url, JSON.parse(params))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function exportHorseByOwnerReport(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}exporthorsebyowner${param}`;
  return axios({
    url: url,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getAnalyticsStat(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}analytics/stats${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getChangeLog(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}changelog${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function cancelBookingTimeSlots(id) {
  const url = `${GlobalConstans.base_url}c_timeslot/${id}`;
  // const url = `${GlobalConstans.base_url}c_timeslot_copy/${id}`;
  return axios
    .post(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function cancelBooking(id, params) {
  const url = `${GlobalConstans.base_url}c_booking/${id}`;
  return axios
    .post(url, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function updateBookingWithNewTimes(id, params) {
  const url = `${GlobalConstans.base_url}n_booking/${id}`;
  return axios
    .post(url, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getCancelledBookingList(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}getcancelled${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getNoshowsList(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}getnoshows${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getUserBookings(params) {
  let param = params;
  param = typeof param !== "undefined" ? param : "";
  const url = `${GlobalConstans.base_url}userbookings${param}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function deleteManager(id) {
  const url = `${GlobalConstans.base_url}manager/${id}`;
  return axios
    .delete(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function noShowBooking(id) {
  const url = `${GlobalConstans.base_url}ns_booking/${id}`;
  return axios
    .post(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function getUserEmailFromToken(token) {
  const url = `${GlobalConstans.base_url}password/verify/${token}`;
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
function resetPasswordWithNewPassword(params) {
  console.log(params, 'api params');
  const url = `${GlobalConstans.base_url}password/reset`;
  return axios
    .post(url, JSON.parse(params))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

const API = {
  login,
  getUser,
  logout,
  resetPassword,
  getCorporates,
  deleteCorporate,
  addUpdateCorporate,
  getCorporate,
  getHorses,
  deleteHorse,
  addUpdateHorse,
  getHorse,
  getHorseOwners,
  getHorseLocations,
  deleteHorseLocation,
  addUpdateHorseLocation,
  getHorseLocation,
  getMembers,
  deleteMember,
  addUpdateMember,
  getMember,
  getOwnerHorses,
  deleteOwnerHorse,
  getManagers,
  getManager,
  addUpdateManager,
  getAllUserRoles,
  getSingleUserRoles,
  updateSingleUserRoles,
  getAllPermissions,
  UpdatePermissions,
  getTrainers,
  deleteTrainer,
  addUpdateTrainer,
  getTrainer,
  getBookings,
  deleteBooking,
  addUpdateBooking,
  getBooking,
  getGroupBooking,
  addGroupBooking,
  getDropDown,
  uploadDocument,
  getDocument,
  deleteDocument,
  getCategories,
  deleteCategory,
  addUpdateCategory,
  getCategory,
  getServices,
  deleteService,
  addUpdateService,
  getService,
  getDropdownCategories,
  getFormData,
  getBookingParticipants,
  addBookingParticipant,
  deleteBookingParticipant,
  getCalendarEventsByTime,
  getCalendarEvents,
  getCalendarTrainers,
  getDashStats,
  getHorseReport,
  getHorseByOwnerReport,
  getMemberReport,
  getCorporateReport,
  getSalesByServiceReport,
  getSalesByTrainerReport,
  getSalesReport,
  getLiveryReport,
  exportHorseReport,
  exportMemberReport,
  exportHorseByOwnerReport,
  exportSalesReport,
  exportSalesByServiceReport,
  exportTrainerReport,
  exportLiveryReport,
  exportCorporateReport,
  getGlobalSearch,
  horseByMember,
  getAnalyticsStat,
  getChangeLog,
  cancelBookingTimeSlots,
  cancelBooking,
  noShowBooking,
  updateBookingWithNewTimes,
  getCancelledBookingList,
  getNoshowsList,
  deleteManager,
  getHorseListByTrainers,
  getUserBookings,
  getUserEmailFromToken,
  resetPasswordWithNewPassword,
};

export default API;
