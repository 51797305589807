/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import managerActions from "../../../../redux/actions/manager.actions";
import ServerSideDataTable from "../../../../shared/components/table/ServerSideDataTable";

class DataTable extends PureComponent {
  static propTypes = {
    // t: PropTypes.func.isRequired,
    getManagerList: PropTypes.func.isRequired,
    manager: PropTypes.shape({
      totalResults: PropTypes.number,
      filter: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        search: PropTypes.string,
      }),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
        })
      ),
    }),
  };

  static defaultProps = {
    manager: {
      filter: {
        search: "",
      },
      totalResults: null,
    },
  };

  constructor(props) {
    super(props);
    // props.setDefaults();
    props.getManagerList();
  }

  handleTableChange = (type, { searchText, page }) => {
    const { props } = this;
    props.getManagerList({ currentPage: page, search: searchText });
  };
  deleteManager = (id) => {
    this.props.deleteManager(id);
  };

  customActions = (cell, row, rowIndex, formatExtraData) => {
    // eslint-disable-next-line react/destructuring-assignment
    return (
      <ButtonToolbar className="table-actions">
        <Button
          tag={Link}
          to={`edit/${row.id}`}
          color="primary"
          size="sm"
          className="rounded"
        >
          {formatExtraData.t("common.edit")}
        </Button>
        <Button
          onClick={() => {
            this.deleteManager(row.id);
          }}
          color="danger"
          size="sm"
          className="rounded"
        >
          Delete
        </Button>
      </ButtonToolbar>
    );
  };

  render() {
    const { props } = this;
    const { permissions, manager } = props;
    const hasActionPerms = permissions.includes("edit-managers");

    const columns = [
      {
        dataField: "id",
        text: "ID",
        hidden: true,
      },
      {
        dataField: "name",
        text: "Name",
      },
      {
        dataField: "email",
        text: "Email",
      },
      {
        dataField: "mobile",
        text: "Mobile",
      },
      {
        dataField: "gender",
        text: "Gender",
      },
      {
        dataField: "actions",
        text: "Actions",
        formatter: this.customActions,
        formatExtraData: props,
        hidden: !hasActionPerms,
      },
    ];

    return (
      <Col md={12} lg={12}>
        <Card>
          {manager.list && (
            <CardBody>
              <ServerSideDataTable
                data={manager.list}
                columns={columns}
                onTableChange={this.handleTableChange}
                page={manager.filter.currentPage}
                sizePerPage={manager.sizePerPage}
                totalSize={manager.totalResults}
                searchText={manager.filter.search}
              />
            </CardBody>
          )}
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  const authData = state.authentication;
  const permissions =
    typeof authData.user !== "undefined" &&
    Array.isArray(authData.user.permissions)
      ? authData.user.permissions
      : [];

  return {
    permissions,
    manager: state.manager,
  };
};

const actionCreators = {
  getManagerList: managerActions.getManagerListAction,
  deleteManager: managerActions.deleteManagerAction,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation("common")(DataTable));
