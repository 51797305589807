import React from "react";
import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer
} from "recharts";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Panel from "../../../shared/components/Panel";

const data = [
	{
		name: "Mon",
		a: 590,
		b: 1400,
		c: 100
	},
	{
		name: "Tue",
		a: 868,
		b: 1506,
		c: 120
	},
	{
		name: "Wed",
		a: 1397,
		b: 989,
		c: 220
	},
	{
		name: "Thu",
		a: 1480,
		b: 1228,
		c: 280
	},
	{
		name: "Fri",
		a: 1520,
		b: 1100,
		c: 380
	},
	{
		name: "Sat",
		a: 1520,
		b: 1100,
		c: 180
	},
	{
		name: "Sun",
		a: 1400,
		b: 1700,
		c: 270
	}
];

const SalesChart = ({ t }) => (
	<Panel md={12} lg={12} xl={12} title={t("Sales Charts")}>
		<ResponsiveContainer height={250} className='dashboard__area'>
			<AreaChart data={data} margin={{ top: 20, left: -15, bottom: 20 }}>
				<XAxis dataKey='name' tickLine={false} />
				<YAxis tickLine={false} />
				<Tooltip />
				<Legend />
				<CartesianGrid />
				<Area
					name='Membership'
					type='monotone'
					dataKey='a'
					fill='#4ce1b6'
					stroke='#4ce1b6'
					fillOpacity={0.2}
				/>
				<Area
					name='Service'
					type='monotone'
					dataKey='b'
					fill='#70bbfd'
					stroke='#70bbfd'
					fillOpacity={0.2}
				/>
				<Area
					name='Livery'
					type='monotone'
					dataKey='c'
					fill='#606875'
					stroke='#606875'
					fillOpacity={0.2}
				/>
			</AreaChart>
		</ResponsiveContainer>
	</Panel>
);

SalesChart.propTypes = {
	t: PropTypes.func.isRequired
};

export default withTranslation("common")(SalesChart);
