/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import memberActions from "../../../../redux/actions/memberActions";
import ServerSideDataTable from "../../../../shared/components/table/ServerSideDataTable";

class DataTable extends PureComponent {
  static propTypes = {
    // t: PropTypes.func.isRequired,
    getMemberList: PropTypes.func.isRequired,
    member: PropTypes.shape({
      totalResults: PropTypes.number,
      filter: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        search: PropTypes.string,
      }),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          // company_name: PropTypes.string.isRequired,
          // register_no: PropTypes.string.isRequired,
          // domain: PropTypes.string.isRequired,
        })
      ),
    }),
  };

  static defaultProps = {
    member: {
      filter: {
        search: "",
      },
      totalResults: null,
    },
  };

  constructor(props) {
    super(props);
    // props.setDefaults();
    props.getMemberList();
  }

  // componentDidMount() {
  //   console.log('componentDidMount');
  // }

  handleTableChange = (type, { searchText, page }) => {
    const { props } = this;
    props.getMemberList({ currentPage: page, search: searchText });
  };

  customActions = (cell, row, rowIndex, formatExtraData) => {
    // eslint-disable-next-line react/destructuring-assignment
    // console.log(cell, row, this.props , this.props.t);
    return (
      <ButtonToolbar className="table-actions">
        <Button
          tag={Link}
          to={`edit/${row.id}`}
          color="primary"
          size="sm"
          className="rounded"
        >
          {formatExtraData.t("common.edit")}
        </Button>
        {/* <Button
          color='danger'
          size='sm'
          onClick={() => {
            if (window.confirm("Are you sure you wish to delete this item?")) {
              custom(row.id)
            }
          }}
        >Delete</Button> */}
      </ButtonToolbar>
    );
  };

  render() {
    const { props } = this;
    const { permissions, member } = props;
    const hasActionPerms = permissions.includes("edit-members");

    const columns = [
      {
        dataField: "id",
        text: "ID",
        hidden: true,
      },
      {
        dataField: "name",
        text: "Name",
      },
      {
        dataField: "email",
        text: "Email",
      },
      {
        dataField: "mobile",
        text: "Mobile",
      },
      {
        dataField: "gender",
        text: "Gender",
      },
      {
        dataField: "expiry_date",
        text: "Expiry Date",
      },
      // {
      //   dataField: 'riding_experience',
      //   text: 'Riding Experience',
      // },
      {
        dataField: "actions",
        text: "Actions",
        formatter: this.customActions,
        formatExtraData: props,
        hidden: !hasActionPerms,
      },
    ];

    return (
      <Col md={12} lg={12}>
        <Card>
          {member.list && (
            <CardBody>
              <ServerSideDataTable
                data={member.list}
                columns={columns}
                onTableChange={this.handleTableChange}
                page={member.filter.currentPage}
                sizePerPage={member.sizePerPage}
                totalSize={member.totalResults}
                searchText={member.filter.search}
              />
            </CardBody>
          )}
        </Card>
      </Col>
    );
  }
}

function mapState(state) {
  const authData = state.authentication;
  const permissions =
    typeof authData.user !== "undefined" &&
    Array.isArray(authData.user.permissions)
      ? authData.user.permissions
      : [];

  return {
    permissions,
    member: state.member,
  };
}

const actionCreators = {
  getMemberList: memberActions.getMemberListAction,
  deleteMember: memberActions.deleteMemberAction,
  // setDefaults: memberActions.setCorporateDefaultsAction
};

export default connect(
  mapState,
  actionCreators
)(withTranslation("common")(DataTable));
