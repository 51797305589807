import {preloaderConstants} from '../../_constants/common.constants';

function preloaderReducer(state = { isActive: false}, action) {
    switch (action.type) {
        case preloaderConstants.SHOW:
            return {
                isActive: true,
                shouldDisable: action.data
            };
        case preloaderConstants.HIDE:
            return {
                isActive: false,
                shouldDisable: action.data
            };
        default:
            return state
    }
}

export default preloaderReducer;