import React, { PureComponent } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

import { Field, change } from "redux-form";
// import moment from "moment";

import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import renderDatePickerField from "../../../../../shared/components/form/DatePicker";
import renderTimePickerField from "../../../../../shared/components/form/TimePicker";
import renderCheckBoxField from "../../../../../shared/components/form/CheckBox";

/*
const renderField = ({
	input,
	placeholder,
	type,
	meta: { touched, error },
}) => (
	<div className="form__form-group-input-wrap">
		<input {...input} placeholder={placeholder} type={type} />
		{touched && error && (
			<span className="form__form-group-error">{error}</span>
		)}
	</div>
);
*/

class Recurrence extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
    };
  }

  componentDidMount() {
    const { props } = this;
    const { id, period, weekdays, dispatch } = props;

    if (typeof id !== "undefined" && id) {
      let tab = "1";
      if (period === "weekly") {
        tab = "2";
      }
      this.toggle(tab);
      dispatch(change("booking_single", "days", weekdays));
    }
  }

  toggle = (tab) => {
    const { dispatch } = this.props;
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
    let period = "daily";
    if (tab === "2") {
      period = "weekly";
    }
    dispatch(change("booking_single", "period", period));
  };

  render() {
    const { onStartTimeChange } = this.props;
    const { activeTab } = this.state;

    return (
      <>
        <h5 className="mt-2 mb-3">Recurrence</h5>
        <Row>
          <Col md={12}>
            <div className="tabs tabs--bordered-top">
              <div className="tabs__wrap">
                <Field name="period" component="input" type="hidden" />

                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      Daily
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      Weekly
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
										<NavLink
											className={classnames({
												active: activeTab === "3",
											})}
											onClick={() => {
												this.toggle("3");
											}}
										>
											Monthly
										</NavLink>
									</NavItem> */}
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">From</span>
                          <span style={{ color: "red" }}>*</span>
                          <div className="form__form-group-field">
                            <Field
                              name="booking_date"
                              component={renderDatePickerField}
                              placeholder="12/12/1222"
                              disabled
                            />
                            <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">To</span>
                          <span style={{ color: "red" }}>*</span>
                          <div className="form__form-group-field">
                            <Field
                              name="booking_date_to"
                              component={renderDatePickerField}
                              placeholder="12/12/1222"
                              disabled
                            />
                            <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Start Time
                          </span>
                          <span style={{ color: "red" }}>*</span>
                          <div className="form__form-group-field">
                            <Field
                              name="booking_start_time"
                              component={renderTimePickerField}
                              placeholder=""
                              onChange={onStartTimeChange}
                              disabled
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            End Time
                          </span>
                          <span style={{ color: "red" }}>*</span>
                          <div className="form__form-group-field">
                            <Field
                              name="booking_end_time"
                              component={renderTimePickerField}
                              placeholder=""
                              disabled
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col md={12}>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <div className="form__form-group">
                              <div className="form__form-group-field">
                                <Field
                                  name="days[0]"
                                  component={renderCheckBoxField}
                                  label="Monday"
                                  disabled
                                />
                              </div>
                            </div>
                          </li>
                          <li className="list-inline-item">
                            <div className="form__form-group">
                              <div className="form__form-group-field">
                                <Field
                                  name="days[1]"
                                  component={renderCheckBoxField}
                                  label="Tuesday"
                                  disabled
                                />
                              </div>
                            </div>
                          </li>
                          <li className="list-inline-item">
                            <div className="form__form-group">
                              <div className="form__form-group-field">
                                <Field
                                  name="days[2]"
                                  component={renderCheckBoxField}
                                  label="Wednesday"
                                  disabled
                                />
                              </div>
                            </div>
                          </li>
                          <li className="list-inline-item">
                            <div className="form__form-group">
                              <div className="form__form-group-field">
                                <Field
                                  name="days[3]"
                                  component={renderCheckBoxField}
                                  label="Thursday"
                                  disabled
                                />
                              </div>
                            </div>
                          </li>
                          <li className="list-inline-item">
                            <div className="form__form-group">
                              <div className="form__form-group-field">
                                <Field
                                  name="days[4]"
                                  component={renderCheckBoxField}
                                  label="Friday"
                                  disabled
                                />
                              </div>
                            </div>
                          </li>
                          <li className="list-inline-item">
                            <div className="form__form-group">
                              <div className="form__form-group-field">
                                <Field
                                  name="days[5]"
                                  component={renderCheckBoxField}
                                  label="Saturday"
                                  disabled
                                />
                              </div>
                            </div>
                          </li>
                          <li className="list-inline-item">
                            <div className="form__form-group">
                              <div className="form__form-group-field">
                                <Field
                                  name="days[6]"
                                  component={renderCheckBoxField}
                                  label="Sunday"
                                  disabled
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">From</span>
                          <span style={{ color: "red" }}>*</span>
                          <div className="form__form-group-field">
                            <Field
                              name="booking_date"
                              component={renderDatePickerField}
                              placeholder="12/12/1222"
                            />
                            <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">To</span>
                          <span style={{ color: "red" }}>*</span>
                          <div className="form__form-group-field">
                            <Field
                              name="booking_date_to"
                              component={renderDatePickerField}
                              placeholder="12/12/1222"
                              disabled
                            />
                            <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Start Time
                          </span>
                          <span style={{ color: "red" }}>*</span>
                          <div className="form__form-group-field">
                            <Field
                              name="booking_start_time"
                              component={renderTimePickerField}
                              placeholder=""
                              onChange={onStartTimeChange}
                              disabled
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            End Time
                          </span>
                          <span style={{ color: "red" }}>*</span>
                          <div className="form__form-group-field">
                            <Field
                              name="booking_end_time"
                              component={renderTimePickerField}
                              placeholder=""
                              disabled
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  {/* <TabPane tabId="3">
										<Row>
											<Col md={12}>
												<div className="form__form-group">
													<span className="form__form-group-label">
														Every Month Repeat On Date
													</span>
													<div className="form__form-group-field">
														<Field
															name="repeat"
															component={renderField}
															type="number"
															placeholder="1"
														/>
													</div>
												</div>
											</Col>
										</Row>
										<Row>
											<Col md={6}>
												<div className="form__form-group">
													<span className="form__form-group-label">From</span>
													<div className="form__form-group-field">
														<Field
															name="booking_date"
															component={renderDatePickerField}
															placeholder="12/12/1222"
														/>
														<div className="form__form-group-icon">
															<CalendarBlankIcon />
														</div>
													</div>
												</div>
											</Col>
											<Col md={6}>
												<div className="form__form-group">
													<span className="form__form-group-label">To</span>
													<div className="form__form-group-field">
														<Field
															name="booking_date_to"
															component={renderDatePickerField}
															placeholder="12/12/1222"
														/>
														<div className="form__form-group-icon">
															<CalendarBlankIcon />
														</div>
													</div>
												</div>
											</Col>
										</Row>
										<Row>
											<Col md={6}>
												<div className="form__form-group">
													<span className="form__form-group-label">
														Start Time
													</span>
													<div className="form__form-group-field">
														<Field
															name="booking_start_time"
															component={renderTimePickerField}
															placeholder=""
														/>
													</div>
												</div>
											</Col>
											<Col md={6}>
												<div className="form__form-group">
													<span className="form__form-group-label">
														End Time
													</span>
													<div className="form__form-group-field">
														<Field
															name="booking_end_time"
															component={renderTimePickerField}
															placeholder=""
															disabled
														/>
													</div>
												</div>
											</Col>
										</Row>
									</TabPane> */}
                </TabContent>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default Recurrence;
