import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";

class SearchForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      // showPassword: false
    };
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <Field
          name="search"
          component="input"
          type="text"
          placeholder="What are you looking for?"
        />
      </form>
    );
  }
}

export default reduxForm({
  form: "search-form",
})(SearchForm);
