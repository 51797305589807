import {preloaderConstants} from '../../_constants/common.constants';

function hide(shouldDisable) {
    return { type: preloaderConstants.HIDE, data: shouldDisable };
}
function show(shouldDisable) {
    return { type: preloaderConstants.SHOW, data: shouldDisable };
}

const preloaderActions = {
    show,
    hide
};

export default preloaderActions;