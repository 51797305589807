import React, { PureComponent } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

import { Field, change } from "redux-form";
// import moment from "moment";

import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import renderDatePickerField from "../../../../shared/components/form/DatePicker";
import renderTimePickerField from "../../../../shared/components/form/TimePicker";
import renderCheckBoxField from "../../../../shared/components/form/CheckBox";
import moment from "moment";

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

class SlideInRecurrence extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
    };
  }

  toggle = (tab) => {
    const { dispatch } = this.props;
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
    let period = "daily";
    if (tab === "2") {
      period = "weekly";
    }
    dispatch(change("booking_single", "period", period));
  };

  calculateEndTime = (e, type) => {
    const { props } = this;
    const {
      bookingTypeSingle,
      bookingStartTime,
      dispatch,
      selectedSlot,
    } = props;

    if (!e && type === "bookingType") {
      return;
    }

    if (e && !e.duration && !bookingTypeSingle.duration) {
      return;
    }

    let duration;

    switch (type) {
      case "startTime":
        // eslint-disable-next-line prefer-destructuring
        duration = bookingTypeSingle.duration;
        break;
      case "bookingType":
        // eslint-disable-next-line prefer-destructuring
        duration = e.duration;
        break;
      default:
        // eslint-disable-next-line prefer-destructuring
        duration = bookingTypeSingle.duration;
        break;
    }

    const hours = moment(duration, "HH:mm:ss").hours();
    const minutes = moment(duration, "HH:mm:ss").minutes();
    let originTime = null;
    if (!e) {
      if (type === "startTime") {
        originTime = moment().startOf("day");
      }
    } else {
      originTime = e.duration ? bookingStartTime : e;
      if (
        type === "bookingType" &&
        selectedSlot.slots &&
        selectedSlot.slots.length > 1
      ) {
        originTime = moment(selectedSlot.start);
      }
    }

    dispatch(
      change(
        "booking_single",
        "booking_end_time",
        moment(originTime)
          .add(hours, "hours")
          .add(minutes, "minutes")
      )
    );
  };

  render() {
    const { activeTab } = this.state;

    return (
      <div className="slide-in-content-item w-100 pb-0">
        <h3 className="mb-3">Recurrence</h3>
        <Row>
          <Col md={12}>
            <div className="tabs tabs--bordered-top">
              <div className="tabs__wrap">
                <Field name="period" component="input" type="hidden" />

                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      Daily
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      Weekly
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
										<NavLink
											className={classnames({
												active: activeTab === "3",
											})}
											onClick={() => {
												this.toggle("3");
											}}
										>
											Monthly
										</NavLink>
									</NavItem> */}
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">From</span>
                          <span style={{ color: "red" }}>*</span>
                          <div className="form__form-group-field">
                            <Field
                              name="booking_date"
                              component={renderDatePickerField}
                              placeholder="12/12/1222"
                            />
                            <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">To</span>
                          <span style={{ color: "red" }}>*</span>
                          <div className="form__form-group-field">
                            <Field
                              name="booking_date_to"
                              component={renderDatePickerField}
                              placeholder="12/12/1222"
                            />
                            <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Start Time
                          </span>
                          <span style={{ color: "red" }}>*</span>
                          <div className="form__form-group-field">
                            <Field
                              name="booking_start_time"
                              onChange={(e) => {
                                this.calculateEndTime(e, "startTime");
                              }}
                              component={renderTimePickerField}
                              placeholder=""
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            End Time
                          </span>
                          <span style={{ color: "red" }}>*</span>
                          <div className="form__form-group-field">
                            <Field
                              name="booking_end_time"
                              component={renderTimePickerField}
                              placeholder=""
                              disabled
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col md={12}>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <div className="form__form-group">
                              <div className="form__form-group-field">
                                <Field
                                  name="days[0]"
                                  component={renderCheckBoxField}
                                  defaultChecked
                                  label="Monday"
                                />
                              </div>
                            </div>
                          </li>
                          <li className="list-inline-item">
                            <div className="form__form-group">
                              <div className="form__form-group-field">
                                <Field
                                  name="days[1]"
                                  component={renderCheckBoxField}
                                  label="Tuesday"
                                />
                              </div>
                            </div>
                          </li>
                          <li className="list-inline-item">
                            <div className="form__form-group">
                              <div className="form__form-group-field">
                                <Field
                                  name="days[2]"
                                  component={renderCheckBoxField}
                                  label="Wednesday"
                                />
                              </div>
                            </div>
                          </li>
                          <li className="list-inline-item">
                            <div className="form__form-group">
                              <div className="form__form-group-field">
                                <Field
                                  name="days[3]"
                                  component={renderCheckBoxField}
                                  label="Thursday"
                                />
                              </div>
                            </div>
                          </li>
                          <li className="list-inline-item">
                            <div className="form__form-group">
                              <div className="form__form-group-field">
                                <Field
                                  name="days[4]"
                                  component={renderCheckBoxField}
                                  label="Friday"
                                />
                              </div>
                            </div>
                          </li>
                          <li className="list-inline-item">
                            <div className="form__form-group">
                              <div className="form__form-group-field">
                                <Field
                                  name="days[5]"
                                  component={renderCheckBoxField}
                                  label="Saturday"
                                />
                              </div>
                            </div>
                          </li>
                          <li className="list-inline-item">
                            <div className="form__form-group">
                              <div className="form__form-group-field">
                                <Field
                                  name="days[6]"
                                  component={renderCheckBoxField}
                                  label="Sunday"
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">From</span>
                          <span style={{ color: "red" }}>*</span>
                          <div className="form__form-group-field">
                            <Field
                              name="booking_date"
                              component={renderDatePickerField}
                              placeholder="12/12/1222"
                            />
                            <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">To</span>
                          <span style={{ color: "red" }}>*</span>
                          <div className="form__form-group-field">
                            <Field
                              name="booking_date_to"
                              component={renderDatePickerField}
                              placeholder="12/12/1222"
                            />
                            <div className="form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Start Time
                          </span>
                          <span style={{ color: "red" }}>*</span>
                          <div className="form__form-group-field">
                            <Field
                              name="booking_start_time"
                              onChange={(e) => {
                                this.calculateEndTime(e, "startTime");
                              }}
                              component={renderTimePickerField}
                              placeholder=""
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            End Time
                          </span>
                          <span style={{ color: "red" }}>*</span>
                          <div className="form__form-group-field">
                            <Field
                              name="booking_end_time"
                              component={renderTimePickerField}
                              placeholder=""
                              disabled
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  {/* <TabPane tabId="3">
										<Row>
											<Col md={12}>
												<div className="form__form-group">
													<span className="form__form-group-label">
														Every Month Repeat On Date
													</span>
													<div className="form__form-group-field">
														<Field
															name="repeat"
															component={renderField}
															type="number"
															placeholder="1"
														/>
													</div>
												</div>
											</Col>
										</Row>
										<Row>
											<Col md={6}>
												<div className="form__form-group">
													<span className="form__form-group-label">From</span>
													<div className="form__form-group-field">
														<Field
															name="booking_date"
															component={renderDatePickerField}
															placeholder="12/12/1222"
														/>
														<div className="form__form-group-icon">
															<CalendarBlankIcon />
														</div>
													</div>
												</div>
											</Col>
											<Col md={6}>
												<div className="form__form-group">
													<span className="form__form-group-label">To</span>
													<div className="form__form-group-field">
														<Field
															name="booking_date_to"
															component={renderDatePickerField}
															placeholder="12/12/1222"
														/>
														<div className="form__form-group-icon">
															<CalendarBlankIcon />
														</div>
													</div>
												</div>
											</Col>
										</Row>
										<Row>
											<Col md={6}>
												<div className="form__form-group">
													<span className="form__form-group-label">
														Start Time
													</span>
													<div className="form__form-group-field">
														<Field
															name="booking_start_time"
															component={renderTimePickerField}
															placeholder=""
														/>
													</div>
												</div>
											</Col>
											<Col md={6}>
												<div className="form__form-group">
													<span className="form__form-group-label">
														End Time
													</span>
													<div className="form__form-group-field">
														<Field
															name="booking_end_time"
															component={renderTimePickerField}
															placeholder=""
															disabled
														/>
													</div>
												</div>
											</Col>
										</Row>
									</TabPane> */}
                </TabContent>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SlideInRecurrence;
