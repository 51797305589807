/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Permission from "../../../Permission/components/Permission";
import horseActions from "../../../../redux/actions/horseActions";
import ServerSideDataTable from "../../../../shared/components/table/ServerSideDataTable";

class DataTable extends PureComponent {
  static propTypes = {
    getHorseLocations: PropTypes.func.isRequired,
    location: PropTypes.shape({
      totalResults: PropTypes.number,
      filter: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        search: PropTypes.string,
      }),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          location_name: PropTypes.string.isRequired,
          max_horses: PropTypes.number.isRequired,
          slots_available: PropTypes.number.isRequired,
        })
      ),
    }),
  };

  static defaultProps = {
    location: {
      search: "",
      totalResults: "",
    },
  };

  constructor(props) {
    super(props);
    // props.setDefaults();
    props.getHorseLocations();
  }

  componentDidMount() {
    // console.log('componentDidMount');
  }

  handleTableChange = (type, { searchText, page }) => {
    const { props } = this;
    props.getHorseLocations({ currentPage: page, search: searchText });
  };

  customActions = (cell, row, rowIndex, custom) => {
    return (
      <ButtonToolbar className="table-actions">
        <Permission id="edit-horses">
          <Button
            tag={Link}
            to={`locations/edit/${row.id}`}
            color="primary"
            size="sm"
            className="rounded"
          >
            Edit
          </Button>
        </Permission>
        <Permission id="delete-horses">
          <Button
            color="danger"
            size="sm"
            className="rounded"
            onClick={() => {
              if (
                window.confirm("Are you sure you wish to delete this item?")
              ) {
                custom(row.id);
              }
            }}
          >
            Delete
          </Button>
        </Permission>
      </ButtonToolbar>
    );
  };

  render() {
    const { props } = this;
    const { permissions, location } = props;
    const hasActionPerms =
      permissions.includes("edit-horses") ||
      permissions.includes("delete-horses");

    const columns = [
      {
        dataField: "id",
        text: "ID",
        hidden: true,
      },
      {
        dataField: "location_name",
        text: "Location",
      },
      {
        dataField: "max_horses",
        text: "Maximum horses",
      },
      // {
      //   dataField: "slots_available",
      //   text: "Slots Available",
      // },
      {
        dataField: "actions",
        text: "Actions",
        formatter: this.customActions,
        formatExtraData: props.deleteHorseLocation,
        hidden: !hasActionPerms,
      },
    ];

    return (
      <Col md={12} lg={12}>
        <Card>
          {location.list && (
            <CardBody>
              <ServerSideDataTable
                data={location.list}
                columns={columns}
                onTableChange={this.handleTableChange}
                page={location.filter.currentPage}
                sizePerPage={props.sizePerPage}
                totalSize={location.totalResults}
                searchText={location.filter.search}
              />
            </CardBody>
          )}
        </Card>
      </Col>
    );
  }
}

function mapState(state) {
  const authData = state.authentication;
  const permissions =
    typeof authData.user !== "undefined" &&
    Array.isArray(authData.user.permissions)
      ? authData.user.permissions
      : [];

  return {
    permissions,
    sizePerPage: state.horse.sizePerPage,
    location: state.horse.location,
  };
}

const actionCreators = {
  getHorseLocations: horseActions.getHorseLocationsAction,
  deleteHorseLocation: horseActions.deleteHorseLocationAction,
  // setDefaults: horseActions.setCorporateDefaultsAction
};

export default connect(mapState, actionCreators)(DataTable);
