/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Permission from "../../../Permission/components/Permission";
import trainerActions from "../../../../redux/actions/trainerActions";
import ServerSideDataTableWithHeaders from "../../../../shared/components/table/ServerSideDataTableWithHeaders";
import horseActions from "../../../../redux/actions/horseActions";

class DataTable extends PureComponent {
  static propTypes = {
    getHorseListByTrainer: PropTypes.func.isRequired,
    horseListByTrainer: PropTypes.shape({
      totalResults: PropTypes.number,
      filter: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        search: PropTypes.string,
      }),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          company_name: PropTypes.string.isRequired,
          register_no: PropTypes.string.isRequired,
          domain: PropTypes.string.isRequired,
        })
      ),
    }),
  };

  static defaultProps = {
    horseListByTrainer: {
      search: "",
      totalResults: null,
    },
  };

  constructor(props) {
    super(props);
    // props.setDefaults();
    props.getHorseListByTrainer(props.id);
  }

  // componentDidMount() {
  //   console.log('componentDidMount');
  // }

  handleTableChange = (type, { searchText, page }) => {
    const { props } = this;
    props.getHorseListByTrainer(props.id, {
      currentPage: page,
      search: searchText,
    });
  };

  render() {
    const { props } = this;
    const { permissions, horseListByTrainer } = props;
    const hasActionPerms =
      permissions.includes("edit-trainers") ||
      permissions.includes("delete-trainers");

    const columns = [
      {
        dataField: "id",
        text: "ID",
        hidden: true,
      },
      {
        dataField: "service_name",
        text: "Event Name",
      },
      {
        dataField: "rider",
        text: "Rider",
      },
      {
        dataField: "horse",
        text: "Horse",
      },
      {
        dataField: "date",
        text: "Date",
      },
      {
        dataField: "time",
        text: "Time",
      },
    ];

    let headers = horseListByTrainer && Object.keys(horseListByTrainer);

    let horseListByTrainerUpdated =
      headers &&
      headers.map((k, i) => {
        let tempArr = horseListByTrainer[k].map((key, index) => {
          key.time =
            key.start_time && key.end_time
              ? key.start_time.split(" ")[1] +
                " - " +
                key.end_time.split(" ")[1]
              : null;
          return 0;
        });
        let arr = [];
        return arr;
      });
    return (
      <Col md={12} lg={12}>
        <Card>
          {horseListByTrainer && (
            <CardBody>
              <ServerSideDataTableWithHeaders
                data={horseListByTrainer}
                columns={columns}
                onTableChange={this.handleTableChange}
                // page={horseListByTrainer.filter.currentPage}
                sizePerPage={props.sizePerPage}
                // totalSize={horseListByTrainer.totalResults}
                // searchText={horseListByTrainer.filter.search}
              />
            </CardBody>
          )}
        </Card>
      </Col>
    );
  }
}

function mapState(state) {
  const authData = state.authentication;
  const permissions =
    typeof authData.user !== "undefined" &&
    Array.isArray(authData.user.permissions)
      ? authData.user.permissions
      : [];

  return {
    permissions,
    // sizePerPage: state.horseListByTrainer.sizePerPage,
    horseListByTrainer: state.horse.horseListByTrainer,
  };
}

const actionCreators = {
  getHorseListByTrainer: horseActions.getHorseListByTrainerAction,
  // setDefaults: memberActions.setCorporateDefaultsAction
};

export default connect(mapState, actionCreators)(DataTable);
