import { memberConstants} from "../../_constants/dashboard.constants";


const corporateDefaults = {
    list: [],
    totalResults: null,
    filter: {
        currentPage: 1,
        search: '',
    },
    addFormData: {}
}
const horseDefaults = {
    list: [],
    totalResults: null,
    filter: {
        currentPage: 1,
        search: '',
    },
}

const initialState = {
    list: [],
    sizePerPage: 10,
    totalResults: null,
    filter: {
        currentPage: 1,
        search: '',
    },
    horse: horseDefaults,
    addFormData: {},
    documents: [],
    corporate: corporateDefaults
}




function memberReducer( state = initialState, action) {
    switch(action.type) {
        case memberConstants.CORPORATE_LIST:
            return {
                ...state,
                corporate: {
                    ...state.corporate,
                    list: action.data.data,
                    totalResults: action.data.meta.total,
                    last_page: action.data.meta.last_page,
                    filter: {
                        currentPage: action.data.meta.current_page,
                        search: action.search
                    }
                }
            };
        case memberConstants.CORPORATE_DELETE:
            return {
                ...state,
                corporate: {
                    ...state.corporate,
                    totalResults: state.corporate.totalResults - 1,
                }
            };
        case memberConstants.CORPORATE_GET:
            return {
                ...state,
                corporate: {
                    ...state.corporate,
                    addFormData: action.data,
                }
            };
        case memberConstants.CORPORATE_GET_CLEAR:
            return {
                ...state,
                corporate: {
                    ...state.corporate,
                    addFormData: {},
                }
            };
        case memberConstants.CORPORATE_ADD:
            return {
                ...state,
            };
        case memberConstants.CORPORATE_UPDATE:
            return {
                ...state,
                corporate: {
                    ...state.corporate,
                    addFormData: action.data,
                }
            };
        case memberConstants.CORPORATE_SET_DEFAULTS:
            return {
                ...state,
                corporate: corporateDefaults
            };

        case memberConstants.MEMBER_LIST:
            return {
                ...state,
                list: action.data.data,
                filter: {
                    search: action.filter.search ? action.filter.search : '',
                    currentPage: action.filter.currentPage ? action.filter.currentPage : 1,
                },
                totalResults: action.data.meta.total,
                last_page: action.data.meta.last_page,
            };
        case memberConstants.MEMBER_ADD:
            return {
                ...state,
            };
        case memberConstants.MEMBER_UPDATE:
            return {
                ...state,
                addFormData: action.data,
            };
        case memberConstants.MEMBER_GET:
            return {
                ...state,
                addFormData: action.data,
            };
        case memberConstants.MEMBER_DELETE:
            return {
                ...state,
                totalResults: action.data.total,
                last_page: action.data.last_page
            };
        case memberConstants.MEMBER_GET_CLEAR:
            return {
                ...state,
                ...state.location,
                addFormData: {},
            };
        case memberConstants.MEMBER_DOCUMENT_GET:
            return {
                ...state,
                documents: action.data
            };
        case memberConstants.MEMBER_HORSE_LIST:
            return {
                ...state,
                horse: {
                    ...state.horse,
                    list: action.data.data,
                    totalResults: action.data.meta.total,
                    last_page: action.data.meta.last_page,
                    filter: {
                        currentPage: action.data.meta.current_page,
                        search: action.search
                    }
                }
            };
        case memberConstants.MEMBER_HORSE_DELETE:
            return {
                ...state,
                horse: {
                    ...state.horse,
                    // totalResults: action.data.total,
                    // last_page: action.data.last_page
                }
            };
        // case memberConstants.MEMBER_DOCUMENT_UPLOAD:
        //     return {
        //         ...state,
        //         documents: action.data
        //     };
        default:
            return state;
    }

}

export default memberReducer;