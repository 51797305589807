import moment from "moment";
import { rrulestr } from "rrule";

class CalendarHelper {
  constructor() {
    this.events = [];
    this.localeMoment = moment;
  }

  setLocaleMoment(localeMoment) {
    this.localeMoment = localeMoment;
  }

  getEvents() {
    let events = this.events.map((k, i) => {
      let item = k;
      item.start = new Date(k.start);
      item.end = new Date(k.end);
      return item;
    });
    return events;
  }

  setEvents(events) {
    this.events = Array.from(events);
    this.handleRecurringEvents();
  }

  removeEvent(event) {
    const index = this.events.indexOf(event);
    if (index !== -1) {
      this.events.splice(index, 1);
    }
  }

  addEvent(event) {
    let pos = 0;
    const eventStart = this.localeMoment(event.start);
    this.events.forEach((item, index) => {
      const start = this.localeMoment(item.start);
      if (eventStart >= start) {
        pos = index + 1;
      }
    });
    this.events.splice(pos, 0, event);
  }
  getUnique(arr, index) {
    const unique = arr
      .map((e) => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }

  handleRecurringEvents() {
    this.events = this.getUnique(this.events, "id");
    const rEvents = this.events.filter(
      (event) => event.rrule && /RRULE:/.test(event.rrule)
    );
    rEvents.forEach((item) => {
      this.removeEvent(item);
    });

    rEvents.forEach((item) => {
      const oldStart = this.localeMoment(item.start);
      const oldEnd = this.localeMoment(item.end);

      let rule = rrulestr(item.rrule);

      if (typeof rule.origOptions.dtstart === "undefined") {
        rule.origOptions.dtstart = this.localeMoment(item.start).toDate();
      }
      rule = rrulestr(rule.toString());
      const rDates = rule.all();
      rDates.forEach((time, index) => {
        const event = {
          ...item,
          id: `${item.id}-${index}`,
          start: this.localeMoment(time).format("YYYY-MM-DD HH:mm:ss"),
          end: this.localeMoment(time)
            .add(oldEnd.diff(oldStart), "ms")
            .format("YYYY-MM-DD HH:mm:ss"),
        };
        this.addEvent(event);
      });
    });
  }
}

export default CalendarHelper;
