import API from "../../api/API";
import { calendarConstants } from "../../_constants/dashboard.constants";
import alertActions from "./alertActions";
import preloaderActions from "./preloaderActions";

function getCalendarEventsAction(filter) {
  function success(data) {
    return {
      type: calendarConstants.CALENDAR_GET,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.getCalendarEvents(filter).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function getCalendarEventsByTimeAction(filter) {
  function success(data) {
    return {
      type: calendarConstants.CALENDAR_GET_BY_TIME,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.getCalendarEventsByTime(filter).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function getCalendarTrainersAction() {
  function success(data) {
    return {
      type: calendarConstants.TRAINER_GET,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.getCalendarTrainers().then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

const documentActions = {
  getCalendarEventsAction,
  getCalendarEventsByTimeAction,
  getCalendarTrainersAction,
};

export default documentActions;
