import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { connect } from "react-redux";
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import DataTable from "./components/DataTable";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import DatePicker from "react-datepicker";
import API from "../../../../api/API";
import { constructUrlParams } from "../../../../_helpers/CommonFunctions";

const ReportSalesByTrainer = (props) => {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const [stdate, setStdate] = useState(firstDay);
  const [eddate, setEddate] = useState(lastDay);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Link to="/reports/list">
            Reports
          </Link>
          <span className="lnr lnr-chevron-right" style={{fontSize: "9px", marginRight: "5px", marginLeft: "5px"}}></span>
          <Link to="/report/sales-by-trainer">
          Sales by trainer reports
          </Link>
          <h3 className="page-title" style={{marginTop: "15px", marginBottom: 0}} >Sales by trainer reports</h3>
        </Col>
      </Row>
      <Row md={12} className="page-head">
        <Col md={4}>
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="date-picker">
                  <DatePicker
                    className="form__form-group-datepicker"
                    selected={stdate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date_ob) => {
                      setStdate(date_ob);
                    }}
                    dropDownMode="select"
                  />
                </div>
                <div className="form__form-group-icon">
                  <CalendarBlankIcon />
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="date-picker">
                  <DatePicker
                    className="form__form-group-datepicker"
                    selected={eddate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date_ob) => {
                      setEddate(date_ob);
                    }}
                    dropDownMode="select"
                  />
                </div>
                <div className="form__form-group-icon">
                  <CalendarBlankIcon />
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div className="cs-dropdown dropdown">
            <UncontrolledDropdown>
              <DropdownToggle
                className="icon icon--right btn btn-outline-secondary"
                // color="primary"
              >
                <p>
                  Export as <ChevronDownIcon />
                </p>
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu">
                <DropdownItem
                  onClick={(e) => {
                    let formated_stdate = stdate
                      ? stdate.getFullYear() +
                        "-" +
                        (parseInt(stdate.getMonth()) + 1) +
                        "-" +
                        stdate.getDate()
                      : "";

                    let formated_eddate = eddate
                      ? eddate.getFullYear() +
                        "-" +
                        (parseInt(eddate.getMonth()) + 1) +
                        "-" +
                        eddate.getDate()
                      : "";

                    const urlParams = constructUrlParams({
                      type: "xlsx",
                      search: props.salesByTrainerReport.filter.search,
                      st_date: formated_stdate,
                      ed_date: formated_eddate,
                    });
                    API.exportTrainerReport(urlParams).then(
                      (response) => {
                        if (response.data) {
                          const url_link = window.URL.createObjectURL(
                            new Blob([response.data])
                          );
                          const link = document.createElement("a");
                          link.href = url_link;
                          link.setAttribute("download", "file.xlsx");
                          document.body.appendChild(link);
                          link.click();
                        }
                      },
                      (error) => {
                        if (
                          error &&
                          error.response &&
                          error.response.data &&
                          error.response.data.message
                        ) {
                        }
                      }
                    );
                  }}
                >
                  Excel
                </DropdownItem>
                <DropdownItem
                  onClick={(e) => {
                    let formated_stdate = stdate
                      ? stdate.getFullYear() +
                        "-" +
                        (parseInt(stdate.getMonth()) + 1) +
                        "-" +
                        stdate.getDate()
                      : "";

                    let formated_eddate = eddate
                      ? eddate.getFullYear() +
                        "-" +
                        (parseInt(eddate.getMonth()) + 1) +
                        "-" +
                        eddate.getDate()
                      : "";

                    const urlParams = constructUrlParams({
                      type: "",
                      search: props.salesByTrainerReport.filter.search,
                      st_date: formated_stdate,
                      ed_date: formated_eddate,
                    });

                    API.exportTrainerReport(urlParams).then(
                      (response) => {
                        if (response.data) {
                          const url_link = window.URL.createObjectURL(
                            new Blob([response.data])
                          );
                          const link = document.createElement("a");
                          link.href = url_link;
                          link.setAttribute("download", "file.csv");
                          document.body.appendChild(link);
                          link.click();
                        }
                      },
                      (error) => {
                        if (
                          error &&
                          error.response &&
                          error.response.data &&
                          error.response.data.message
                        ) {
                        }
                      }
                    );
                  }}
                >
                  CSV
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </Col>
      </Row>
      <Row>
        <DataTable
          {...props}
          st_date={
            stdate
              ? stdate.getFullYear() +
                "-" +
                (parseInt(stdate.getMonth()) + 1) +
                "-" +
                stdate.getDate()
              : ""
          }
          ed_date={
            eddate
              ? eddate.getFullYear() +
                "-" +
                (parseInt(eddate.getMonth()) + 1) +
                "-" +
                eddate.getDate()
              : ""
          }
        />
      </Row>
    </Container>
  );
};

function mapState(state) {
  const authData = state.authentication;
  const permissions =
    typeof authData.user !== "undefined" &&
    Array.isArray(authData.user.permissions)
      ? authData.user.permissions
      : [];
  return {
    permissions,
    sizePerPage: state.salesByTrainerReport.sizePerPage,
    salesByTrainerReport: state.salesByTrainerReport,
  };
}

const actionCreators = {};

export default connect(mapState, actionCreators)(ReportSalesByTrainer);
