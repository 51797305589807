import API from '../../api/API';
import { miscConstants } from '../../_constants/common.constants';


function getFormDataAction() {

    function success(data) {
        return {
            type: miscConstants.FORMDATA_GET,
            data,
        }
    }

    return dispatch => {
        API.getFormData().then(
            response => {
                if (response.data) {
                    dispatch(success(response.data));
                }
            }, error => {
                console.log(error);
            });
    };
}

// function getCategoriesAction(type) {
//     function success(data) {
//         return {
//             type: miscConstants.CATEGORIES_GET,
//             data
//         }
//     }

//     const categoryId;
//     switch (type) {
//         case 'horse':
//             categoryId = 1;
//             break;
//         case 'service':
//             categoryId = 3;
//             break;
        
//         default:
//             categoryId = 1;
//             break;
//     }


//     return dispatch => {
//         API.getDropdownCategories(categoryId).then(
//             response => {
//                 console.log(response);
//                 if (response.data) {
//                     dispatch(success(response.data));
//                 }
//             }, error => {
//                 if (error && error.response && error.response.data && error.response.data.message) {
//                     console.log(error.response.data.error.message);

//                 }
//             });
//     };
// }


const commonActions = {
    getFormDataAction,
    // getCategoriesAction

}

export default commonActions;