import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import TimePicker from "rc-time-picker";
import AvTimerIcon from "mdi-react/AvTimerIcon";
import classNames from "classnames";

import moment from "moment";

class TimePickerAvailableField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    timeMode: PropTypes.bool.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.any),
    ]),
    isDisabled: PropTypes.bool,
  };

  static defaultProps = {
    isDisabled: false,
  };

  state = {
    open: false,
  };

  setOpen = ({ open }) => {
    this.setState({ open });
  };

  toggleOpen = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  disabledHours = () => {
    const { cancelTimeSlotsData, selectedDate } = this.props;
    let newSelectedDate = selectedDate ? selectedDate : cancelTimeSlotsData[0].date;
    let availableHours = cancelTimeSlotsData.map((k, i) => {
      if(newSelectedDate == k.date){
        return moment(k.start_time).hours();
      } else {
        return null;
      }
    });
    const allHours = [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
    ];

    let disabledHours = allHours
      .filter((x) => !availableHours.includes(x))
      .concat(availableHours.filter((x) => !allHours.includes(x)));
    return disabledHours;
  };

  disabledMinutes = () => {
    const { cancelTimeSlotsData } = this.props;
    let availableMinutes = cancelTimeSlotsData.map((k, i) => {
      return moment(k.start_time).minutes();
    });
    const allMinutes = [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      59,
    ];

    let disabledMinutes = allMinutes
      .filter((x) => !availableMinutes.includes(x))
      .concat(availableMinutes.filter((x) => !allMinutes.includes(x)));
    return disabledMinutes;
  };

  render() {
    const { name, onChange, timeMode, isDisabled, value } = this.props;

    const { open } = this.state;
    const btnClass = classNames({
      "form__form-group-button": true,
      active: open,
    });

    // let dynamicProps = null;
    // if (defaultValue) {
    //   dynamicProps = { defaultValue: moment(defaultValue) };
    // }
    return (
      <div className="form__form-group-field">
        <TimePicker
          open={open}
          onOpen={this.setOpen}
          onClose={this.setOpen}
          name={name}
          onChange={onChange}
          showSecond={false}
          use12Hours={timeMode}
          // {...dynamicProps}
          disabled={isDisabled}
          value={value ? moment(value) : null}
          autoComplete="off"
          disabledHours={this.disabledHours}
          disabledMinutes={this.disabledMinutes}
          hideDisabledOptions={true}
        />
        <button className={btnClass} type="button" onClick={this.toggleOpen}>
          <AvTimerIcon />
        </button>
      </div>
    );
  }
}

const renderTimePickerAvailableField = (props) => {
  const {
    input,
    timeMode,
    defaultValue,
    disabled,
    cancelTimeSlotsData,
    selectedDate,
  } = props;
  return (
    <TimePickerAvailableField
      {...input}
      timeMode={timeMode}
      defaultValue={defaultValue}
      isDisabled={disabled}
      cancelTimeSlotsData={cancelTimeSlotsData}
      selectedDate={selectedDate}
    />
  );
};

renderTimePickerAvailableField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  timeMode: PropTypes.bool,
  defaultValue: PropTypes.number,
  disabled: PropTypes.bool,
  cancelTimeSlotsData: PropTypes.array,
  selectedDate: PropTypes.string,
};

renderTimePickerAvailableField.defaultProps = {
  timeMode: false,
  defaultValue: 0,
  disabled: false,
  cancelTimeSlotsData: [],
  selectedDate: null
};

export default renderTimePickerAvailableField;
