import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import TimePicker from "rc-time-picker";
import AvTimerIcon from "mdi-react/AvTimerIcon";
import classNames from "classnames";

import moment from "moment";

class TimePickerField extends PureComponent {
	static propTypes = {
		onChange: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
		timeMode: PropTypes.bool.isRequired,
		value: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.objectOf(PropTypes.any),
		]),
		isDisabled: PropTypes.bool,
	};

	static defaultProps = {
		isDisabled: false,
	};

	state = {
		open: false,
	};

	setOpen = ({ open }) => {
		this.setState({ open });
	};

	toggleOpen = (e) => {
		e.preventDefault();
		this.setState((prevState) => ({ open: !prevState.open }));
	};

	render() {
		const { name, onChange, timeMode, isDisabled, value } = this.props;
		const { open } = this.state;
		const btnClass = classNames({
			"form__form-group-button": true,
			active: open,
		});

		// let dynamicProps = null;
		// if (defaultValue) {
		//   dynamicProps = { defaultValue: moment(defaultValue) };
		// }

		return (
			<div className="form__form-group-field">
				<TimePicker
					open={open}
					onOpen={this.setOpen}
					onClose={this.setOpen}
					name={name}
					onChange={onChange}
					showSecond={false}
					use12Hours={timeMode}
					// {...dynamicProps}
					disabled={isDisabled}
					value={value ? moment(value) : null}
					autoComplete="off"
				/>
				<button className={btnClass} type="button" onClick={this.toggleOpen}>
					<AvTimerIcon />
				</button>
			</div>
		);
	}
}

const renderTimePickerField = (props) => {
	const { input, timeMode, defaultValue, disabled } = props;
	return (
		<TimePickerField
			{...input}
			timeMode={timeMode}
			defaultValue={defaultValue}
			isDisabled={disabled}
		/>
	);
};

renderTimePickerField.propTypes = {
	input: PropTypes.shape({
		onChange: PropTypes.func,
		name: PropTypes.string,
	}).isRequired,
	timeMode: PropTypes.bool,
	defaultValue: PropTypes.number,
	disabled: PropTypes.bool,
};

renderTimePickerField.defaultProps = {
	timeMode: false,
	defaultValue: 0,
	disabled: false,
};

export default renderTimePickerField;
