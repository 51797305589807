import { change, reset } from "redux-form";
import API from "../../api/API";
import { bookingConstants } from "../../_constants/dashboard.constants";
import history from "../../_helpers/history";
import alertActions from "./alertActions";
import preloaderActions from "./preloaderActions";
import serviceActions from "./serviceActions";
import { constructUrlParams } from "../../_helpers/CommonFunctions";

function getBookingListAction(filters) {
  let custFilter = filters;

  function success(data) {
    return {
      type: bookingConstants.BOOKING_LIST,
      data,
      filter: {
        currentPage: custFilter.currentPage,
        search: custFilter.search,
      },
    };
  }

  return (dispatch, getState) => {
    dispatch(preloaderActions.show());
    const { booking } = getState();

    if (typeof filters === "undefined") {
      custFilter = booking.filter;
    }

    if (custFilter.currentPage > 1) {
      custFilter.currentPage =
        custFilter.currentPage > booking.last_page
          ? booking.last_page
          : custFilter.currentPage;
    }

    const urlParams = constructUrlParams({
      page: custFilter.currentPage,
      search: custFilter.search,
    });
    // console.log(urlParams);

    API.getBookings(urlParams).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function getCancelledBookingListAction(filters) {
  let custFilter = filters;

  function success(data) {
    if (data.data && data.data.length > 0) {
      let newData = data.data.map((k, i) => {
        k.cancel_date = k.cancel_date.split(" ")[0];
        return k;
      });
      data.data = newData;
    }
    return {
      type: bookingConstants.BOOKING_CANCELLED_LIST,
      data,
      filter: {
        currentPage: custFilter.currentPage,
        search: custFilter.search,
      },
    };
  }

  return (dispatch, getState) => {
    dispatch(preloaderActions.show());
    const { booking } = getState();
    if (typeof filters === "undefined") {
      custFilter = booking.bookingCancelled.filter;
    }

    if (custFilter.currentPage > 1) {
      custFilter.currentPage =
        custFilter.currentPage > booking.bookingCancelled.last_page
          ? booking.bookingCancelled.last_page
          : custFilter.currentPage;
    }

    const urlParams = constructUrlParams({
      page: custFilter.currentPage,
      search: custFilter.search,
    });
    // console.log(urlParams);

    API.getCancelledBookingList(urlParams).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function getNoshowsListAction(filters) {
  let custFilter = filters;

  function success(data) {
    if (data.data && data.data.length > 0) {
      let newData = data.data.map((k, i) => {
        k.cancel_date = k.cancel_date.split(" ")[0];
        return k;
      });
      data.data = newData;
    }
    return {
      type: bookingConstants.BOOKING_NO_SHOWS_LIST,
      data,
      filter: {
        currentPage: custFilter.currentPage,
        search: custFilter.search,
      },
    };
  }

  return (dispatch, getState) => {
    dispatch(preloaderActions.show());
    const { booking } = getState();
    if (typeof filters === "undefined") {
      custFilter = booking.bookingNoshows.filter;
    }

    if (custFilter.currentPage > 1) {
      custFilter.currentPage =
        custFilter.currentPage > booking.bookingNoshows.last_page
          ? booking.bookingNoshows.last_page
          : custFilter.currentPage;
    }

    const urlParams = constructUrlParams({
      page: custFilter.currentPage,
      search: custFilter.search,
    });

    API.getNoshowsList(urlParams).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function getUserBookingListAction(filters) {
  let custFilter = filters;

  function success(data) {
    return {
      type: bookingConstants.USER_BOOKING_LIST,
      data,
      filter: {
        currentPage: custFilter.currentPage,
        search: custFilter.search,
      },
    };
  }

  return (dispatch, getState) => {
    dispatch(preloaderActions.show());
    const { booking } = getState();

    if (typeof filters === "undefined") {
      custFilter = booking.userBookings.filter;
    }

    if (custFilter.currentPage > 1) {
      custFilter.currentPage =
        custFilter.currentPage > booking.userBookings.last_page
          ? booking.userBookings.last_page
          : custFilter.currentPage;
    }

    const urlParams = constructUrlParams({
      page: custFilter.currentPage,
      id: filters.id,
    });

    API.getUserBookings(`${urlParams}`).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function deleteBookingAction(id, callback) {
  function success(data) {
    return {
      type: bookingConstants.BOOKING_DELETE,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.deleteBooking(id).then(
      (response) => {
        if (response.data) {
          dispatch(alertActions.success(response.data.message));
          dispatch(success(response.data));
          if (callback) {
            callback();
          } else {
            dispatch(getBookingListAction());
          }
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function deleteBookingFromAction(id, bookingId) {
  function success(data) {
    return {
      type: bookingConstants.BOOKING_DELETE,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.deleteBooking(id).then(
      (response) => {
        if (response.data) {
          dispatch(alertActions.success(response.data.message));
          dispatch(success(response.data));
          dispatch(getBookingAction(bookingId));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function addUpdateBookingAction(params, id, calendarProps = {}, from = null) {
  function success(data) {
    return {
      type: id ? bookingConstants.BOOKING_UPDATE : bookingConstants.BOOKING_ADD,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.addUpdateBooking(params, id).then(
      (response) => {
        if (response.data && response.data.data) {
          if (Object.keys(calendarProps).length === 0) {
            if (from && from == "trainer-calendar") {
              history.push(`/trainer-calendar`);
            } else {
              history.push(`/booking/edit/${response.data.data.id}`);
            }
            if (!id) {
              dispatch(success(response.data));
            }
          } else {
            dispatch(reset("booking_single"));
            if (calendarProps.bookingsubtype === "group") {
              calendarProps.updateModal({
                id: response.data.data.id,
                resourceId: response.data.data.id,
              });
            } else {
              calendarProps.closeModal(true);
            }
          }

          dispatch(alertActions.success(response.data.message));
        } else {
          dispatch(alertActions.error(response.data.message));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}
function getBookingAction(id) {
  function success(data) {
    return {
      type: bookingConstants.BOOKING_GET,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.getBooking(id).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
          dispatch(
            serviceActions.getServiceAction(
              "all",
              response.data.data.service.value,
              true
            )
          );
          dispatch(getGroupBookingAction(id));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function clearBookingForm() {
  return {
    type: bookingConstants.BOOKING_GET_CLEAR,
  };
}

function getGroupBookingAction(id, start_date, end_date) {
  function success(data) {
    return {
      type: bookingConstants.GROUP_BOOKING_GET,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.getGroupBooking(id).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function addGroupBookingAction(params, id) {
  function success(data) {
    return {
      type: bookingConstants.GROUP_BOOKING_ADD,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.addGroupBooking(params, id).then(
      (response) => {
        if (response.data && response.data.data) {
          dispatch(getGroupBookingAction(id));
          dispatch(success(response.data));
          dispatch(alertActions.success(response.data.message));
          dispatch(reset("booking_single"));
        } else {
          dispatch(getGroupBookingAction(id));
          dispatch(success(response.data));
          dispatch(alertActions.error(response.data.message));
          dispatch(reset("booking_single"));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function clearGroupBookingForm() {
  return {
    type: bookingConstants.GROUP_BOOKING_CLEAR,
  };
}

function getBookingParticipantsAction(id) {
  function success(data) {
    return {
      type: bookingConstants.BOOKING_PARTICIPANTS_LIST,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());

    API.getBookingParticipants(id).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function addBookingParticipantAction(id) {
  function success(data) {
    return {
      type: bookingConstants.BOOKING_PARTICIPANTS_ADD,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());

    API.addBookingParticipant(id).then(
      (response) => {
        if (response.data) {
          dispatch(change("booking_single", "asign_member", ""));
          dispatch(change("booking_single", "asign_horse", ""));

          dispatch(success(response.data));
          dispatch(alertActions.success(response.data.message));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function deleteBookingParticipantAction(id) {
  function success(data) {
    return {
      type: bookingConstants.BOOKING_PARTICIPANTS_DELETE,
      data,
    };
  }

  return (dispatch, getState) => {
    dispatch(preloaderActions.show());

    const { booking } = getState();
    const { participantData } = booking;

    const participants = participantData.slice(0);
    const index = participants.map((item) => item.id).indexOf(id);

    API.deleteBookingParticipant(id).then(
      (response) => {
        if (response.data) {
          participants.splice(index, 1);

          dispatch(alertActions.success(response.data.message));
          dispatch(success(participants));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function cancelBookingTimeSlotsAction(id) {
  function success(data) {
    return {
      type: bookingConstants.BOOKING_CANCEL_TIME_SLOTS,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.cancelBookingTimeSlots(id).then(
      (response) => {
        if (response.data) {
          dispatch(alertActions.success(response.data.message));
          dispatch(success(response.data));
          dispatch(getBookingListAction());
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function cancelBookingAction(id, params) {
  function success(data) {
    return {
      type: bookingConstants.BOOKING_CANCEL,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.cancelBooking(id, params).then(
      (response) => {
        if (response.data) {
          dispatch(alertActions.success(response.data.message));
          dispatch(success(response.data));
          dispatch(getBookingListAction());
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function updateBookingWithNewTimesAction(id, params) {
  function success(data) {
    return {
      type: bookingConstants.BOOKING_UPDATE_WITH_NEW_TIMES,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.updateBookingWithNewTimes(id, params).then(
      (response) => {
        if (response.data) {
          dispatch(alertActions.success(response.data.message));
          dispatch(success(response.data));
          dispatch(getBookingAction(id));
          dispatch(cancelBookingTimeSlotsAction(id));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function clearBookingCancelForm() {
  return {
    type: bookingConstants.BOOKING_CANCEL_CLEAR,
  };
}

function noShowBookingAction(id, callback) {
  function success(data) {
    return {
      type: bookingConstants.BOOKING_NO_SHOW,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.noShowBooking(id).then(
      (response) => {
        if (response.data) {
          dispatch(alertActions.success(response.data.message));
          dispatch(success(response.data));
          if (callback) {
            callback();
          } else {
            dispatch(getBookingListAction());
          }
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

const BookingActions = {
  getBookingListAction,
  deleteBookingAction,
  deleteBookingFromAction,
  addUpdateBookingAction,
  getBookingAction,
  clearBookingForm,
  getGroupBookingAction,
  addGroupBookingAction,
  clearGroupBookingForm,
  getBookingParticipantsAction,
  addBookingParticipantAction,
  deleteBookingParticipantAction,
  cancelBookingTimeSlotsAction,
  cancelBookingAction,
  updateBookingWithNewTimesAction,
  clearBookingCancelForm,
  getCancelledBookingListAction,
  getNoshowsListAction,
  noShowBookingAction,
  getUserBookingListAction,
};

export default BookingActions;
