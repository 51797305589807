import React from "react";
import { Col, Container, Row, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Permission from "../../Permission/components/Permission";
import DataTable from "./components/DataTable";

const HorseLocations = (props) => (
  <Container className="dashboard">
    <Row>
      <Col md={9}>
        <h3 className="page-title">Horse Locations</h3>
      </Col>
      <Col md={3} className="text-right">
        <Permission id="edit-horses">
          <Button
            tag={Link}
            to="locations/add"
            color="primary"
            className="rounded"
          >
            Add Location
          </Button>
        </Permission>
      </Col>
    </Row>
    <Row>
      <DataTable {...props} />
    </Row>
  </Container>
);

export default HorseLocations;
