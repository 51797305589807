/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Button, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import { formValueSelector } from 'redux-form';
import PropTypes from "prop-types";
import memberActions from "../../../../redux/actions/memberActions";
import ServerSideDataTable from "../../../../shared/components/table/ServerSideDataTable";
// import horseActions from '../../../../redux/actions/horseActions';

class HorseList extends PureComponent {
  static propTypes = {
    // getCorporateList: PropTypes.func.isRequired,
    getHorseList: PropTypes.func.isRequired,
    ownerId: PropTypes.string.isRequired,
    // ownerId: PropTypes.string.isRequired,
    // setDefaults: PropTypes.func.isRequired,
    horse: PropTypes.shape({
      totalResults: PropTypes.number,
      currentPage: PropTypes.number,
      search: PropTypes.string,
      list: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string,
          category: PropTypes.string,
          price: PropTypes.string,
          location: PropTypes.string,
        })
      ),
    }),
    // sizePerPage: PropTypes.number.isRequired
  };

  static defaultProps = {
    horse: {
      currentPage: 1,
      search: "",
      totalResults: "",
      list: [],
    },
  };

  constructor(props) {
    super(props);
    // props.setDefaults();
    // props.getCorporateList();
    if (props.ownerId) {
      props.getHorseList({ owner_id: props.ownerId });
    }
  }

  componentDidMount() {
    const { props } = this;
    if (props.ownerId) {
      props.getHorseList({ owner_id: props.ownerId });
    }
  }

  handleTableChange = (type, { searchText, page }) => {
    const { props } = this;
    // props.getCorporateList(page, searchText);
    props.getHorseList({
      currentPage: page,
      search: searchText,
      owner_id: props.ownerId,
    });
  };

  //   handleFilterChange = (filters) => {
  //     const {props} = this;

  //     console.log(filters);
  //     props.getHorseList(filters);
  //     // props.getHorseList(page, searchText);

  //     // const { props } = this;
  //     // console.log('handleFilterChange', props);
  //   }

  customActions = (cell, row, rowIndex, custom) => {
    // console.log("this.props", this.props);
    // const { props } = this;
    // const { horse } = props;
    // const { filter } = horse;

    return (
      <ButtonToolbar className="table-actions">
        <Button
          className="rounded"
          tag={Link}
          to={
            // filter.layout === "horse"
            `/horse/edit/${row.id}`
            // `/member/edit/${row.id}`
          }
          color="primary"
          size="sm"
        >
          Edit
        </Button>
        <Button
          className="rounded"
          color="danger"
          size="sm"
          onClick={() => {
            if (window.confirm("Are you sure you wish to delete this item?")) {
              custom.deleteOwnerHorse({
                horseId: row.id,
                ownerId: custom.ownerId,
              });
            }
          }}
        >
          Delete
        </Button>
      </ButtonToolbar>
    );
  };

  render() {
    const { props } = this;
    const { horse } = props;
    // const { corporate, horse } = props;
    // const { corporate } = props;

    const columns = [
      {
        dataField: "id",
        text: "ID",
        hidden: true,
      },
      {
        dataField: "name",
        text: "Name",
      },
      {
        dataField: "category",
        text: "Category",
      },
      {
        dataField: "price",
        text: "Price",
      },
      {
        dataField: "location",
        text: "Location",
      },
      {
        dataField: "actions",
        text: "Actions",
        formatter: this.customActions,
        formatExtraData: props,
      },
    ];

    return (
      horse.list.length > 0 && (
        <ServerSideDataTable
          data={horse.list}
          columns={columns}
          onTableChange={this.handleTableChange}
          page={horse.currentPage}
          sizePerPage={props.sizePerPage}
          totalSize={horse.totalResults}
          searchText={horse.search}
          // onFilterChange={this.handleFilterChange}
          hideSearchFilter={false}
        />
      )
      //   <Col md={12} lg={12}>
      //     <Card>
      //       {corporate.list && <CardBody>

      //       </CardBody>}
      //     </Card>
      //   </Col>
    );
  }
}

function mapState(state) {
  // console.log(state)
  // const filterForm = formValueSelector('horse_listing_filter');
  return {
    sizePerPage: state.member.sizePerPage,
    // corporate: state.member.corporate,
    horse: state.member.horse,
    // search: filterForm(state, 'search'),
    // layout: filterForm(state, 'layout'),
    // category: filterForm(state, 'category')
  };
}

const actionCreators = {
  deleteOwnerHorse: memberActions.deleteOwnerHorseAction,
  getHorseList: memberActions.getOwnerHorseListAction,
};

export default connect(mapState, actionCreators)(HorseList);
