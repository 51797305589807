import React, { PureComponent } from "react";
import PropTypes from "prop-types";
// import { Col } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
// import MagnifyIcon from "mdi-react/MagnifyIcon";
import FilterForm from "./TableFilters";

const SearchComp = (props) => {
  // console.log(props)
  // let input;
  // const handleClick = (e) => {
  //     e.preventDefault();
  //     props.onSearch(input.value);
  // };
  const { searchText } = props;
  // console.log('searchText', searchText, input)
  // if (searchText) {
  //     input.value = searchText;
  // }
  const handleSubmit = () => {
    // console.log(e);
  };

  return <FilterForm onSubmit={handleSubmit} searchText={searchText} />;
};

SearchComp.propTypes = {
  // onSearch: PropTypes.func.isRequired,
  searchText: PropTypes.string,
};
SearchComp.defaultProps = {
  searchText: "",
};

export default class ServerSideFilterDataTable extends PureComponent {
  static propTypes = {
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        dataField: PropTypes.string,
        text: PropTypes.string,
        editable: PropTypes.bool,
        sortable: PropTypes.bool,
      })
    ).isRequired,
    onTableChange: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    page: PropTypes.number.isRequired,
    totalSize: PropTypes.number,
    sizePerPage: PropTypes.number.isRequired,
    searchText: PropTypes.string,
    onFilterChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    totalSize: "",
    searchText: "",
  };

  render() {
    // console.log(this.props);

    const {
      data,
      columns,
      onTableChange,
      sizePerPage,
      totalSize,
      searchText,
      onFilterChange,
      page,
    } = this.props;

    // console.log(data, columns, page, totalSize, searchText);

    const customTotal = (from, to, size) => {
      // console.log(from, to, size);
      return (
        <span className="react-bootstrap-table-pagination-total">
          Showing {from} to {to} of {size} Results
        </span>
      );
    };

    const noDataFound = () => {
      // console.log(noDataFound, from, to, size);
      // return (
      //     <span className="react-bootstrap-table-pagination-total">
      //         Showing {from} to {to} of {size} Results
      // </span>
      // )
      return <div className="no-results">No Results Found</div>;
    };

    const options = {
      page,
      paginationSize: sizePerPage,
      sizePerPage: sizePerPage || 10,
      totalSize,
      hideSizePerPage: true, // Hide the sizePerPage dropdown always
      showTotal: true,
      paginationTotalRenderer: customTotal,
    };

    return (
      <ToolkitProvider keyField="id" data={data} columns={columns} search>
        {(toolkitprops) => (
          <div className="table">
            <FilterForm
              {...toolkitprops.searchProps}
              onFilterChange={onFilterChange}
              searchText={searchText}
            />
            {/* <SearchComp {...toolkitprops.searchProps} searchText={searchText} /> */}
            <BootstrapTable
              striped
              {...toolkitprops.baseProps}
              remote
              noDataIndication={noDataFound}
              bordered={false}
              pagination={paginationFactory(options)}
              onTableChange={onTableChange}
            />
          </div>
        )}
      </ToolkitProvider>
    );
  }
}
