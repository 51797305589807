import API from "../../api/API";
import { reportConstants } from "../../_constants/dashboard.constants";
import history from "../../_helpers/history";
import alertActions from "./alertActions";
import preloaderActions from "./preloaderActions";
import { constructUrlParams } from "../../_helpers/CommonFunctions";

function getReportLiveryReportAction(filters) {
  let custFilter = filters;

  function success(data) {
    return {
      type: reportConstants.REPORT_LIVERY_REPORT,
      data,
      filter: {
        currentPage: custFilter.currentPage,
        search: custFilter.search,
        st_date: custFilter.st_date,
        ed_date: custFilter.ed_date,
      },
    };
  }

  return (dispatch, getState) => {
    dispatch(preloaderActions.show());
    const { liveryReport } = getState();

    if (typeof filters === "undefined" || !filters) {
      custFilter = liveryReport.filter;
    }

    if (custFilter.currentPage > 1) {
      custFilter.currentPage =
        custFilter.currentPage > liveryReport.last_page
          ? liveryReport.last_page
          : custFilter.currentPage;
    }

    // console.log(filters);

    const urlParams = constructUrlParams({
      page: custFilter.currentPage,
      search: custFilter.search,
      st_date: custFilter.st_date,
      ed_date: custFilter.ed_date,
    });
    // console.log(urlParams);

    API.getLiveryReport(urlParams).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

const reportLiveryReportActions = {
  getReportLiveryReportAction,
};

export default reportLiveryReportActions;
