import React, { PureComponent } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  ButtonToolbar,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { Field, reduxForm, formValueSelector, change, reset } from "redux-form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import moment from 'moment';
// import EyeIcon from 'mdi-react/EyeIcon';
// import { withTranslation } from 'react-i18next';
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import renderSelectField from "../../../../shared/components/form/Select";
import renderSelectAsyncField from "../../../../shared/components/form/AsyncSelect";
import { genderOptions } from "../../../../_constants/common.constants";
// import validate from './validate';
import renderDatePickerField from "../../../../shared/components/form/DatePicker";
import renderDropZoneMultipleField from "../../../../shared/components/form/DropZoneMultiple";
import memberActions from "../../../../redux/actions/memberActions";
import AddHorse from "../../../Horse/AddHorse";
// import ActionsTable from './ActionsTable';

import HorseList from "../../../Horse/Horse Listing/components/HorseList";
import BookingList from "../../../Booking/Booking Listing/components/BookingList";

// import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
// import FieldSet from '../../../General/FieldSet';

const renderField = ({
  input,
  onBlur,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} onblur={onBlur} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: "",
  meta: null,
  type: "text",
};

const RadioField = ({ name, onChange, options, isDisabled }) => {
  console.log(options, 'options')
  return (
    <ul className="list-inline">
      {options.map((option) => {
        const id = `${name}-${option.value}`;

        return (
          <li className="list-inline-item" key={id}>
            <span className="cs-radio">
              <Field
                name={name}
                component="input"
                type="radio"
                id={id}
                value={option.value.toString()}
                // checked={typeof option.checked != 'undefined' ? option.checked : null}
                onChange={onChange}
                disabled={isDisabled}
              />
              <label htmlFor={id}>{option.label}</label>
            </span>
          </li>
        );
      })}
    </ul>
  );
};


class VerticalForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    id: PropTypes.string,
    // dob: PropTypes.string,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        attachment_url: PropTypes.string,
      })
    ),

    // documents: PropTypes.shape({
    //   attachment_url: PropTypes.string,
    //   // description: PropTypes.string
    // }),
    shouldDisable: PropTypes.bool,
  };

  static defaultProps = {
    id: null,
    shouldDisable: false,
    // dob: '',
    documents: [],
  };

  constructor() {
    // console.log('constructor')
    super();
    this.state = {
      showPassword: false,
      activeTab: "1",
      horseListUpdate: false,
      nextVisible: false,
      basicInfo: {
        name: null,
        email: null,
        mobile: null,
        company: null
      },
      usertype: null,
    };
  }

  componentDidMount() {
    const { props } = this;
    if (props.id) {
      props.getMember(props.id);
      props.getMemberDocuments({ type: "user", id: props.id });
    }
  }

  componentWillUnmount() {
    const { props } = this;

    props.clearMemberFrom();
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  toggle = (tab) => {
    if (tab == "2" && !this.props.id) {

      if (
        !this.state.basicInfo.name || !this.state.basicInfo.email || !this.state.basicInfo.mobile
      ) {
        window.alert("Please fill the basic information");
        return;
      }
    }
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      if (tab == "3") {
        this.setState({
          activeTab: tab,
          nextVisible: false,
        });
      } else {
        this.setState({
          activeTab: tab,
        });
      }
    }
  };

  getValues = (values) => {
    const { props } = this;
    const data = values;
    console.log(values,'values')
    if (data) {
      data.name = values && values.name ? values.name : this.state.basicInfo.name;
      data.email = values && values.email ? values.email : this.state.basicInfo.email;
      data.mobile = values && values.mobile ? values.mobile : this.state.basicInfo.mobile;
      data.gender =
        values && values.genderField ? values.genderField.value : null;
      data.corporate_id =
        values && values.corporate ? values.corporate.value : null;
      data.country_id = values && values.country ? values.country.value : null;
    }
    // genderField

    if (this.state.activeTab == "2") {
      this.setState({
        nextVisible: true,
      });
    }

    props.addUpdateMember(JSON.stringify(data), props.id);
  };

  removeDocument = (id, e) => {
    const { props } = this;
    // console.log(i, e);
    e.preventDefault();
    e.stopPropagation();
    if (window.confirm("Are you sure you wish to delete this item?")) {
      props.deleteMemberDocument({
        id: props.id,
        type: "user",
        attachment_id: id,
      });
    }
  };

  previewDocument = (e, documentUrl) => {
    e.preventDefault();
    window.open(documentUrl, "_blank");
  };

  onDropzoneChange = (filesToUpload) => {
    const { props } = this;
    // console.log(filesToUpload);
    if (!filesToUpload) {
      return;
    }
    props.uploadMemberDocument({
      type: "user",
      id: props.id,
      documents: filesToUpload,
    });

    // this.onDropzoneChange(value ? value.concat(filesToUpload) : filesToUpload);
  };

  updateHorseList = () => {
    this.setState({
      horseListUpdate: !this.state.horseListUpdate,
    });
  };

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      id,
      documents,
      shouldDisable,
      initialValues,
      dispatch,
    } = this.props;

    const { activeTab } = this.state;

    const usertypeConst = initialValues && initialValues.corporate ? 2 : 1;
    
    if(id && initialValues && !this.state.usertype){
      dispatch(
        change(
          "member_add_edit",
          "user",
          initialValues && initialValues.corporate ? "2" : "1"
        )
      );
        }
    return (
      <Col md={12} lg={12} xl={12}>
        <form className="form" onSubmit={handleSubmit(this.getValues)}>
          <div className="formInner">
            <Card>
              <CardBody>
                <div className="tabs">
                  <div className="tabs__wrap">
                    <Nav tabs className="cs-form-tabs">
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => {
                            this.toggle("1");
                          }}
                        >
                          Basic Information
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => {
                            this.toggle("2");
                          }}
                        >
                          Additional Information
                        </NavLink>
                      </NavItem>
                      {this.props.action == "edit" && (
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "3",
                            })}
                            onClick={() => {
                              this.toggle("3");
                            }}
                          >
                            Horses
                          </NavLink>
                        </NavItem>
                      )}
                      {this.props.action == "edit" && (
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "4",
                            })}
                            onClick={() => {
                              this.toggle("4");
                            }}
                          >
                            Bookings
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <div className="form__field-group">
                          <Row>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Name
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="name"
                                    component={renderField}
                                    type="text"
                                    onBlur={(e)=>{
                                      this.setState({
                                        basicInfo: {
                                          name: e.target.value,
                                          email: this.state.basicInfo.email,
                                          mobile: this.state.basicInfo.mobile,
                                          company: this.state.basicInfo.company
                                        }
                                      });
                                    }
                                  }
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Email
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="email"
                                    component={renderField}
                                    type="email"
                                    onBlur={(e)=>{
                                      this.setState({
                                        basicInfo: {
                                          name: this.state.basicInfo.name,
                                          email: e.target.value,
                                          mobile: this.state.basicInfo.mobile,
                                          company: this.state.basicInfo.company
                                        }
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Mobile
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="mobile"
                                    component={renderField}
                                    type="tel"
                                    onBlur={(e)=>{
                                      this.setState({
                                        basicInfo: {
                                          name: this.state.basicInfo.name,
                                          email: this.state.basicInfo.email,
                                          mobile: e.target.value,
                                          company: this.state.basicInfo.company
                                        }
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                           </Row>
                            <Row>
                            <Col md={12}><div className="form__form-group">
                            <RadioField
                          name="user"
                          onChange={(e) => {
                            if(e.target.value == 1){
                              dispatch(
                                change(
                                  "member_add_edit",
                                  "corporate",
                                  ""
                                )
                              );
                            }
                            this.setState({
                              usertype: e.target.value
                            });
                          }}
                          options={[
                            {
                              value: 1,
                              label: "I'm a personal user",
                            },
                            {
                              value: 2,
                              label: "I'm a corporate user",
                            },
                          ]}
                        /></div>
                            </Col>
                            {((this.state.usertype && this.state.usertype == 2) || (id && !this.state.usertype && usertypeConst == 2))&&
                            <Col md={12}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Select a Company
                                </span>
                                {/* <span style={{ color: "red" }}>*</span> */}
                                <div className="form__form-group-field form__form-group-field-with-btn">
                                  <Field
                                    name="corporate"
                                    component={renderSelectAsyncField}
                                    api="corporatedropdown"
                                    onChange={(data)=>{
                                      this.setState({
                                        basicInfo: {
                                          name: this.state.basicInfo.name,
                                          email: this.state.basicInfo.email,
                                          mobile: this.state.basicInfo.mobile,
                                          company: data.value
                                        }
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>}
                            </Row>
                            <Row>
                            {id && (
                              <Col md={12}>
                                <div className="form__form-group">
                                  <span className="form__form-group-label">
                                    Member Documents
                                  </span>
                                  {/* {documents && Array.isArray(documents) && ( */}
                                  <ul className="list-inline doc-list">
                                    {// eslint-disable-next-line no-unused-vars
                                    documents &&
                                      Array.isArray(documents) &&
                                      documents.map((document, i) => (
                                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                                        <li
                                          className="list-inline-item"
                                          onClick={(e) => {
                                            this.previewDocument(
                                              e,
                                              document.attachment_url
                                            );
                                          }}
                                          onKeyDown={(e) => {
                                            this.previewDocument(
                                              e,
                                              document.attachment_url
                                            );
                                          }}
                                        >
                                          {/* <div to={document.attachment_url} target="_blank"> */}
                                          <div className="dropzone__img-main">
                                            <div
                                              className="dropzone__img"
                                              style={{
                                                backgroundImage: `url(${document.attachment_url})`,
                                              }}
                                            >
                                              <p className="dropzone__img-name">
                                                {document.name}
                                              </p>
                                            </div>
                                            <Button
                                              className="dropzone__img-delete"
                                              type="button"
                                              onClick={(e) => {
                                                this.removeDocument(
                                                  document.id,
                                                  e
                                                );
                                              }}
                                            >
                                              Remove
                                            </Button>
                                          </div>
                                          {/* </div> */}
                                        </li>
                                      ))}

                                    {/* <li className="list-inline-item">
                                      <Link to="http://google.com/" target="_blank">
                                        <div className="dropzone__img-main">
                                          <div className="dropzone__img">
                                            <p
                                              className="dropzone__img-name"
                                            >
                                              Doc Name
                                        </p>
                                          </div>
                                          <Button
                                            className="dropzone__img-delete"
                                            type="button"
                                            onClick={() => {
                                              window.confirm(
                                                "Are you sure you wish to delete this item?"
                                              );
                                            }}>
                                            Remove
                                      </Button>
                                        </div>
                                      </Link>
                                    </li> */}
                                  </ul>

                                  {/* )} */}
                                </div>
                                <div className="form__form-group">
                                  <span className="form__form-group-label">
                                    Add More Documents
                                  </span>
                                  <div className="form__form-group-field cs-dropzone-doc">
                                    <Field
                                      disabled={shouldDisable}
                                      name="member-documents"
                                      id={id}
                                      type="user"
                                      onDropzoneChange={this.onDropzoneChange}
                                      component={renderDropZoneMultipleField}
                                    />
                                  </div>
                                </div>
                              </Col>
                            )}
                          </Row>
                          <Row>
                            <Col md={12}>
                              {id && (
                                <Button
                                  className="rounded"
                                  // tag={Link}
                                  outline
                                  // color="primary"
                                  onClick={() => {
                                    this.toggle("2");
                                  }}
                                >
                                  Next
                                </Button>
                              )}
                              <Button
                                className="rounded"
                                type="submit"
                                color="primary"
                                disabled={
                                  pristine || submitting || shouldDisable
                                }
                              >
                                {id ? "Update" : "Add"}
                              </Button>

                              <Button
                                className="rounded"
                                tag={Link}
                                outline
                                // color="primary"
                                to="/member/listing"
                              >
                                Cancel
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </TabPane>
                      <TabPane tabId="2">
                        <div className="form__field-group">
                          <Row>
                            <Col md={12}>
                              <div className="card__title">
                                <h5 className="bold-text">
                                  Personal Information
                                </h5>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Gender
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="genderField"
                                    component={renderSelectField}
                                    placeholder="Select Gender"
                                    type="text"
                                    options={genderOptions}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Date of Birth
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="date_of_birth"
                                    component={renderDatePickerField}
                                    // placeholder="12/12/1222"
                                  />
                                  <div className="form__form-group-icon">
                                    <CalendarBlankIcon />
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <div className="card__title card__seperation">
                                <h5 className="bold-text">
                                  Contact Information
                                </h5>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Street 1
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="address_line1"
                                    component={renderField}
                                    type="text"
                                    placeholder="Street 1"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Street 2
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="address_line2"
                                    component={renderField}
                                    type="text"
                                    placeholder="Street 2"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Post Code
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="post_code"
                                    component={renderField}
                                    type="text"
                                    placeholder="Post Code"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Country
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="country"
                                    component={renderSelectAsyncField}
                                    // type="text"
                                    api="dropdown/countries"
                                    placeholder="Select Country"
                                    // options={[
                                    //   { value: 'Country', label: 'Country' },
                                    // ]}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <Button
                                className="rounded"
                                // tag={Link}
                                outline
                                // color="primary"
                                onClick={() => {
                                  this.toggle("1");
                                }}
                              >
                                Previous
                              </Button>
                              {this.state.nextVisible && (
                                <Button
                                  className="rounded"
                                  // tag={Link}
                                  outline
                                  // color="primary"
                                  onClick={() => {
                                    this.toggle("3");
                                  }}
                                >
                                  Next
                                </Button>
                              )}
                              <Button
                                className="rounded"
                                type="submit"
                                color="primary"
                                disabled={
                                  pristine || submitting || shouldDisable
                                }
                              >
                                {id ? "Update" : "Add"}
                              </Button>

                              <Button
                                className="rounded"
                                tag={Link}
                                outline
                                // color="primary"
                                to="/member/listing"
                              >
                                Cancel
                              </Button>
                            </Col>
                          </Row>
                          {/* <Row>
                            <Col md={12}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">Riding Experience</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="riding_experience"
                                    component={renderSelectField}
                                    placeholder="Select Riding Experience"
                                    type="text"
                                    options={[
                                      { value: 'Option 1', label: 'Option 1' },
                                      { value: 'Option 2', label: 'Option 2' },
                                      { value: 'Option 3', label: 'Option 3' },
                                      { value: 'Option 4', label: 'Option 4' },
                                    ]}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row> */}
                        </div>
                      </TabPane>
                      {this.props.action == "edit" && (
                        <TabPane tabId="3">
                          {this.state.horseListUpdate && (
                            <HorseList ownerId={id} />
                          )}
                          {!this.state.horseListUpdate && (
                            <HorseList ownerId={id} />
                          )}
                          <Row>
                            <Col md={12}>
                              {/* <Button
                                className="rounded"
                                // tag={Link}
                                outline
                                // color="primary"
                                onClick={() => {
                                  this.toggle("2");
                                }}
                              >
                                Previous
                              </Button> */}
                              <AddHorse
                                ownerId={id}
                                handleSave={this.updateHorseList}
                              />
                            </Col>
                          </Row>
                        </TabPane>
                      )}
                      {this.props.action == "edit" && (
                        <TabPane tabId="4">
                            <BookingList ownerId={id} />
                        </TabPane>
                      )}
                    </TabContent>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </form>
      </Col>
    );
  }
}

// const initialValues = {
//   initialValues: {
//     firstname: 'Alexander',
//     email: 'example@example.com',
//     mobile: '8899776655',
//   },
// };

// export default connect(() => initialValues)(reduxForm({
//   form: 'edit_member', // a unique identifier for this form
//   validate,
// })(withTranslation('common')(VerticalForm)));

const mapState = (state) => {
  if (!state.member.addFormData.data) {
    return {};
  }
  return {
    initialValues: {
      name: state.member.addFormData.data.name
        ? state.member.addFormData.data.name
        : "",
      email: state.member.addFormData.data.email
        ? state.member.addFormData.data.email
        : "",
      mobile: state.member.addFormData.data.mobile
        ? state.member.addFormData.data.mobile
        : "",
      corporate: state.member.addFormData.data.corporate
        ? state.member.addFormData.data.corporate
        : null,
      genderField: state.member.addFormData.data.genderdata
        ? state.member.addFormData.data.genderdata
        : null,
      date_of_birth: state.member.addFormData.data.date_of_birth
        ? new Date(state.member.addFormData.data.date_of_birth)
        : "",
      address_line1: state.member.addFormData.data.address_line1
        ? state.member.addFormData.data.address_line1
        : "",
      address_line2: state.member.addFormData.data.address_line2
        ? state.member.addFormData.data.address_line2
        : "",
      post_code: state.member.addFormData.data.post_code
        ? state.member.addFormData.data.post_code
        : "",
      country: state.member.addFormData.data.country
        ? state.member.addFormData.data.country
        : null,
      // riding_experience: state.member.addFormData.data.riding_experience
      //   ? state.member.addFormData.data.riding_experience
      //   : "",
    },
    documents: state.member.documents.data,
    shouldDisable: state.preloader.shouldDisable,
  };
};

const actionCreators = {
  getMember: memberActions.getMemberAction,
  addUpdateMember: memberActions.addUpdateMemberAction,
  clearMemberFrom: memberActions.clearMemberFrom,
  getMemberDocuments: memberActions.getMemberDocumentsAction,
  deleteMemberDocument: memberActions.deleteMemberDocumentAction,
  uploadMemberDocument: memberActions.uploadMemberDocumentAction,

  // addHorseByMember: memberActions.addHorseByMemberAction,
};
//

// You have to connect() to any reducers that you wish to connect to yourself
export default connect(
  mapState,
  actionCreators // bind account loading action creator
)(
  reduxForm({
    form: "member_add_edit", // a unique identifier for this form
    // validate
  })(VerticalForm)
);
// const selector = formValueSelector("member_add_edit");

// export default connect(mapState, (state, ownProps) => {
//   // console.log(ownProps, "ownProps");
//   // can select values individually
//   const nameValue = selector(state, "name");
//   const emailValue = selector(state, "email");
//   const mobileValue = selector(state, "mobile");
//   const corporateValue = selector(state, "corporate");
//   return {
//     // ...ownProps,
//     nameValue,
//     emailValue,
//     mobileValue,
//     corporateValue,
//     ...actionCreators,
//   };
// })(
//   reduxForm({
//     form: "member_add_edit", // a unique identifier for this form
//   })(VerticalForm)
// );
