import NotificationSystem from 'rc-notification';

let notification = null;

NotificationSystem.newInstance({}, n => { notification = n });

export const constructUrlParams = (filters) => {

    let urlParams = null;
    let i = 0;

    Object.keys(filters).forEach(key => {
        let symbol = `${urlParams}&`;
        if (i === 0) {
            symbol = '?';
        }
        
        if (filters[key]) {
            urlParams = `${symbol}${key}=${filters[key]}`;
        } else {
            urlParams = `${symbol}${key}=`;
        }
        
        i += 1;
    });

    return urlParams;
    
}

export const showNotification = ({notificationContent, position}) => {
    let pTop;
    let pLeft;
    
    switch (position) {
        case 'left-up':
            pTop = 0;
            pLeft = 0;
            break;
        case 'right-up':
            pTop = 0;
            pLeft = 'calc(100vw - 100%)';
            break;
        default:
            pTop = 0;
            pLeft = 0;
            break;
    }

    notification.notice({
        content: notificationContent,
        duration: 5,
        closable: true,
        style: { top: pTop, left: pLeft },
        className: position,
    });
};
