/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import reportLiveryReportActions from "../../../../../redux/actions/reportLiveryReportActions";
import ServerSideDataTable from "../../../../../shared/components/table/ServerSideDataTable";

class DataTable extends PureComponent {
  static propTypes = {
    getReportLiveryReportAction: PropTypes.func.isRequired,
    liveryReport: PropTypes.shape({
      totalResults: PropTypes.number,
      filter: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        type: PropTypes.string,
        search: PropTypes.string,
      }),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          number_of_horses: PropTypes.number.isRequired,
          amount: PropTypes.number.isRequired,
          location: PropTypes.string.isRequired,
        })
      ),
    }),
  };

  static defaultProps = {
    liveryReport: {
      search: "",
      totalResults: null,
    },
  };

  constructor(props) {
    super(props);
    // props.setDefaults();
    props.getReportLiveryReportAction({
      st_date: props.st_date,
      ed_date: props.ed_date,
    });
  }

  // componentDidMount() {
  //   console.log("componentDidMount");
  // }
  componentDidUpdate(prevProps) {
    if (
      prevProps.st_date != this.props.st_date ||
      prevProps.ed_date != this.props.ed_date
    ) {
      this.props.getReportLiveryReportAction({
        st_date: this.props.st_date,
        ed_date: this.props.ed_date,
      });
    }
  }

  handleTableChange = (type, { searchText, page }) => {
    const { props } = this;
    props.getReportLiveryReportAction({
      currentPage: page,
      search: searchText,
      st_date: props.st_date,
      ed_date: props.ed_date,
    });
  };

  render() {
    const { props } = this;
    const { permissions, liveryReport } = props;

    const columns = [
      {
        dataField: "location",
        text: "Location",
      },
      {
        dataField: "number_of_horses",
        text: "Number of Horses",
      },
      {
        dataField: "amount",
        text: "Total",
      },
    ];
    return (
      <Col md={12} lg={12}>
        <Card>
          {liveryReport.list && (
            <CardBody>
              <ServerSideDataTable
                data={liveryReport.list}
                columns={columns}
                onTableChange={this.handleTableChange}
                page={liveryReport.filter.currentPage}
                sizePerPage={props.sizePerPage}
                totalSize={liveryReport.totalResults}
                searchText={liveryReport.filter.search}
              />
            </CardBody>
          )}
        </Card>
      </Col>
    );
  }
}

function mapState(state) {
  const authData = state.authentication;
  const permissions =
    typeof authData.user !== "undefined" &&
    Array.isArray(authData.user.permissions)
      ? authData.user.permissions
      : [];
  return {
    permissions,
    sizePerPage: state.liveryReport.sizePerPage,
    liveryReport: state.liveryReport,
  };
}

const actionCreators = {
  getReportLiveryReportAction:
    reportLiveryReportActions.getReportLiveryReportAction,
  // setDefaults: memberActions.setCorporateDefaultsAction
};

export default connect(mapState, actionCreators)(DataTable);
