export const preloaderConstants = {
  SHOW: "PRELOADER_SHOW",
  HIDE: "PRELOADER_HIDE",
};

export const documentConstants = {
  DOCUMENT_GET: "DOCUMENT_GET",
  DOCUMENT_DELETE: "DOCUMENT_DELETE",
  DOCUMENT_UPLOAD: "DOCUMENT_UPLOAD",
};

export const miscConstants = {
  FORMDATA_GET: "FORMDATA_GET",

  // CATEGORIES_GET: 'CATEGORIES_GET'
};

export const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

// export default commonConstants;

export const globalSearch = {
  GLOBAL_SEARCH: "GLOBAL_SEARCH",
};
