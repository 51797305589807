import React from 'react';
import {
  Container, Row
} from 'reactstrap';

import DataTable from './components/DataTable';
import ServiceCommonHeader from '../common/ServiceCommonHeader';

const ActivityListing = props => (
  <Container className="dashboard">
    <ServiceCommonHeader title="Activities" />
    <Row>
      <DataTable {...props} />
    </Row>
  </Container>
);

export default ActivityListing;
