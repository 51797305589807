/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Permission from "../../../Permission/components/Permission";
import reportMembershipActions from "../../../../redux/actions/reportMembershipActions";
import ServerSideDataTable from "../../../../shared/components/table/ServerSideDataTable";

// let tableData = null;
class DataTable extends PureComponent {
  static propTypes = {
    getReportMembershipAction: PropTypes.func.isRequired,
    membershipReport: PropTypes.shape({
      totalResults: PropTypes.number,
      filter: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        search: PropTypes.string,
        st_date: PropTypes.string,
        ed_date: PropTypes.string,
      }),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          email: PropTypes.string.isRequired,
          mobile: PropTypes.string.isRequired,
          gender: PropTypes.string.isRequired,
          expiry_date: PropTypes.string,
          address_line1: PropTypes.string.isRequired,
          address_line2: PropTypes.string.isRequired,
          post_code: PropTypes.string,
          date_of_birth: PropTypes.string,
          roles: PropTypes.array,
          genderdata: PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
          }),
        })
      ),
    }),
  };

  static defaultProps = {
    membershipReport: {
      search: "",
      totalResults: null,
    },
  };

  constructor(props) {
    super(props);
    // props.setDefaults();
    this.state = {
      tableData: null,
    };
    props.getReportMembershipAction({
      st_date: props.st_date,
      ed_date: props.ed_date,
    });
  }

  // componentDidMount() {
  //   console.log("componentDidMount");
  // }
  componentDidUpdate(prevProps) {
    if (
      prevProps.st_date != this.props.st_date ||
      prevProps.ed_date != this.props.ed_date
    ) {
      // tableData = null;
      this.setState({
        tableData: null,
      });
      this.props.getReportMembershipAction({
        st_date: this.props.st_date,
        ed_date: this.props.ed_date,
      });
    }

    if (
      prevProps.membershipReport != this.props.membershipReport
      // prevProps.st_date != this.props.st_date ||
      // prevProps.ed_date != this.props.ed_date
    ) {
      this.setState({
        tableData:
          !this.state.tableData && this.props.membershipReport.list
            ? this.props.membershipReport.list.map((k, i) => {
                if (k.roles && typeof k.roles != "string") {
                  let s = k;
                  s.roles_string = k.roles.join(", ");
                  // k.roles = k.roles.join(",");
                  return s;
                }
              })
            : this.state.tableData,
      });
    }
  }

  handleTableChange = (type, { searchText, page }) => {
    const { props } = this;
    props.getReportMembershipAction({
      currentPage: page,
      search: searchText,
      st_date: props.st_date,
      ed_date: props.ed_date,
    });
  };

  getStyle = (cell, row, rowIndex, colIndex) => {
    return { maxWidth: '140px', whiteSpace: 'normal', wordWrap: 'break-word'};
  };

  render() {
    const { props, state } = this;
    const { permissions, membershipReport } = props;

    const columns = [
      {
        dataField: "id",
        text: "ID",
        hidden: true,
      },
      {
        dataField: "name",
        text: "Name",
        style: this.getStyle,
      },
      {
        dataField: "email",
        text: "Email",
        style: this.getStyle,
      },
      {
        dataField: "mobile",
        text: "Mobile",
      },
      {
        dataField: "gender",
        text: "Gender",
      },
      {
        dataField: "expiry_date",
        text: "Expiry Date",
      },
      {
        dataField: "address_line1",
        text: "Address Line 1",
        style: this.getStyle,
      },
      {
        dataField: "address_line2",
        text: "Address Line 2",
        style: this.getStyle,
      },
      {
        dataField: "post_code",
        text: "Post Code",
        style: this.getStyle,
      },
      {
        dataField: "date_of_birth",
        text: "Date of Birth",
      },
      {
        dataField: "roles_string",
        text: "Roles",
      },
    ];

    return (
      <Col md={12} lg={12}>
        <Card>
          {state.tableData && (
            <CardBody>
              <ServerSideDataTable
                data={state.tableData}
                columns={columns}
                onTableChange={this.handleTableChange}
                page={membershipReport.filter.currentPage}
                sizePerPage={props.sizePerPage}
                totalSize={membershipReport.totalResults}
                searchText={membershipReport.filter.search}
              />
            </CardBody>
          )}
        </Card>
      </Col>
    );
  }
}

function mapState(state) {
  const authData = state.authentication;
  const permissions =
    typeof authData.user !== "undefined" &&
    Array.isArray(authData.user.permissions)
      ? authData.user.permissions
      : [];
  return {
    permissions,
    sizePerPage: state.membershipReport.sizePerPage,
    membershipReport: state.membershipReport,
  };
}

const actionCreators = {
  getReportMembershipAction: reportMembershipActions.getReportMembershipAction,
  // setDefaults: memberActions.setCorporateDefaultsAction
};

export default connect(mapState, actionCreators)(DataTable);
