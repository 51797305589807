import API from '../../api/API';
import { trainerConstants } from '../../_constants/dashboard.constants';
import history from '../../_helpers/history';
import alertActions from "./alertActions";
import preloaderActions from "./preloaderActions";
import { constructUrlParams} from "../../_helpers/CommonFunctions";


function getTrainerListAction(filters) {
    let custFilter = filters;

    function success(data) {
        return {
            type: trainerConstants.TRAINER_LIST,
            data,
            filter: {
                currentPage: custFilter.currentPage,
                search: custFilter.search
            }
        }
    }

    return (dispatch, getState) => {
        dispatch(preloaderActions.show());
        const { trainer } = getState();


        if (typeof filters === 'undefined' || !filters) {
            custFilter = trainer.filter;
        }

        if (custFilter.currentPage > 1) {
            custFilter.currentPage = (custFilter.currentPage > trainer.last_page)
                ? trainer.last_page
                : custFilter.currentPage;
        }


        // console.log(filters);

        const urlParams = constructUrlParams({
            page: custFilter.currentPage,
            search: custFilter.search
        });
        // console.log(urlParams);

        API.getTrainers(urlParams).then(
            response => {
                if (response.data) {
                    dispatch(success(response.data));
                }
                dispatch(preloaderActions.hide());
            }, error => {
                dispatch(preloaderActions.hide());
                if (error && error.response && error.response.data && error.response.data.message) {
                    dispatch(alertActions.error(error.response.data.error.message));
                }
            });
    };
}
function deleteTrainerAction(id) {
    function success(data) {
        return {
            type: trainerConstants.TRAINER_DELETE,
            data
        }
    }

    return dispatch => {
        dispatch(preloaderActions.show());
        API.deleteTrainer(id).then(
            response => {
                if (response.data) {
                    dispatch(alertActions.success(response.data.message));
                    dispatch(success(response.data));
                    dispatch(getTrainerListAction())
                }
                dispatch(preloaderActions.hide());
            }, error => {
                dispatch(preloaderActions.hide());
                if (error && error.response && error.response.data && error.response.data.message) {
                    dispatch(alertActions.error(error.response.data.error.message));
                }
            });
    };
}
function addUpdateTrainerAction(params, id) {
    function success(data) {
        return {
            type: id ?
                trainerConstants.TRAINER_UPDATE :
                trainerConstants.TRAINER_ADD,
            data
        }
    }

    return dispatch => {
        dispatch(preloaderActions.show());
        API.addUpdateTrainer(params, id).then(
            response => {
                if (response.data) {
                    history.push(`/trainer/edit/${response.data.data.id}`);
                    if (!id) {
                        dispatch(success(response.data));
                    }
                    dispatch(alertActions.success(response.data.message));
                }
                dispatch(preloaderActions.hide());
            }, error => {
                dispatch(preloaderActions.hide());
                if (error && error.response && error.response.data && error.response.data.error) {
                    dispatch(alertActions.error(error.response.data.error.message));
                }
            });
    };
}
function getTrainerAction(id) {

    function success(data) {
        return {
            type: trainerConstants.TRAINER_GET,
            data
        }
    }

    return dispatch => {
        dispatch(preloaderActions.show());
        API.getTrainer(id).then(
            response => {
                if (response.data) {
                    dispatch(success(response.data));
                }
                dispatch(preloaderActions.hide());
            }, error => {
                dispatch(preloaderActions.hide());
                if (error && error.response && error.response.data && error.response.data.message) {
                    dispatch(alertActions.error(error.response.data.error.message));
                }
            });
    };
}

function clearTrainerForm() {
    return {
        type: trainerConstants.TRAINER_GET_CLEAR
    }
}

const trainerActions = {
    getTrainerListAction,
    deleteTrainerAction,
    addUpdateTrainerAction,
    getTrainerAction,
    clearTrainerForm,
}

export default trainerActions;