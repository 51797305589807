import { reportConstants } from "../../_constants/dashboard.constants";

const initialState = {
  list: null,
  sizePerPage: 10,
  totalResults: null,
  filter: {
    currentPage: 1,
    search: "",
  },
};

function reportSalesByServiceReducer(state = initialState, action) {
  switch (action.type) {
    case reportConstants.REPORT_SALES_BY_SERVICE:
      return {
        ...state,
        list: action.data.data,
        filter: {
          search: action.filter.search ? action.filter.search : "",
          currentPage: action.filter.currentPage
            ? action.filter.currentPage
            : 1,
        },
        totalResults: action.data.meta.total,
        last_page: action.data.meta.last_page,
      };
    default:
      return state;
  }
}

export default reportSalesByServiceReducer;
