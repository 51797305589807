const userConstants = {
    
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    RESET_SUCCESS: 'USERS_RESET_SUCCESS',
    RESET_FAILURE: 'USERS_RESET_FAILURE',
    VERIFY_PASSWORD_RESET: 'VERIFY_PASSWORD_RESET',
    RESET_NEW_PASSWORD: 'RESET_NEW_PASSWORD',

};
export default userConstants;