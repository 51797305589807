import React from 'react';
import {
  Col, Container, Row, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Permission from "../../Permission/components/Permission";
import DataTable from './components/DataTable';

const MemberListing = (props) => {
  const { t } = props;
  // console.log(props);
  return (
    <Container className="dashboard">
      <Row>
        <Col md={9}>
          <h3 className="page-title">{t('member.page_title')}</h3>
        </Col>
        <Col md={3} className="text-right">
          <Permission id="edit-members">
            <Button tag={Link} to="/member/add" color="primary" className="rounded">{t('member.add_member')}</Button>
          </Permission>
        </Col>
      </Row>
      <Row>
        <DataTable {...props} />
      </Row>
    </Container>
  );
}

MemberListing.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(MemberListing);