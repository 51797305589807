import React from "react";
import { Col, Container, Row, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Permission from "../../Permission/components/Permission";
import DataTable from "./components/DataTable";

const HorseListByTrainer = (props) => {
  const { match } = props;
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Todays Bookings</h3>
        </Col>
      </Row>
      <Row>
        {/* <Table /> */}
        <DataTable id={match.params.id} {...props} />
      </Row>
    </Container>
  );
};

export default HorseListByTrainer;
