import { calendarConstants } from "../../_constants/dashboard.constants";

const initialState = {
  events: [],
  trainercalender: [],
};

function calendarReducer(state = initialState, action) {
  switch (action.type) {
    case calendarConstants.CALENDAR_GET:
      return {
        ...state,
        events: state.events.concat(action.data.data),
      };
    case calendarConstants.CALENDAR_GET_BY_TIME:
      let events = action.data.data.map((k, i) => {
        // k.start = new Date(k.start);
        // k.end = new Date(k.end);
        return k;
      });
      return {
        ...state,
        events: events,
      };
    case calendarConstants.TRAINER_GET:
      return {
        ...state,
        trainercalender: action.data.data,
      };

    default:
      return state;
  }
}

export default calendarReducer;
