import React, { PureComponent } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  ButtonToolbar,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import EyeIcon from 'mdi-react/EyeIcon';
// import { withTranslation } from 'react-i18next';
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
// import validate from './validate';
import memberActions from "../../../../redux/actions/memberActions";
import renderDatePickerField from "../../../../shared/components/form/DatePickerWithMaxDate";

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: "",
  meta: null,
  type: "text",
};

class VerticalForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    id: PropTypes.string,
  };

  static defaultProps = {
    id: null,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
      activeTab: "1",
    };
  }

  componentDidMount() {
    const { props } = this;
    if (props.id) {
      props.getCorporate(props.id);
    }
  }

  componentWillUnmount() {
    // console.log('componentWillUnmount');
    const { props } = this;
    props.clearCorporateFrom();
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  getValues = (values) => {
    const { props } = this;
    // console.log(JSON.stringify(values), props.id);
    props.addUpdateCorporate(JSON.stringify(values), props.id);
  };

  render() {
    const { pristine, reset, submitting, id, handleSubmit } = this.props;
    // const { showPassword } = this.state;
    const { activeTab } = this.state;

    return (
      <Col md={12} lg={12} xl={12}>
        <form className="form" onSubmit={handleSubmit(this.getValues)}>
          <div className="formInner">
            <Card>
              <CardBody>
                <div className="tabs">
                  <div className="tabs__wrap">
                    <Nav tabs className="cs-form-tabs">
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => {
                            this.toggle("1");
                          }}
                        >
                          Basic Information
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => {
                            this.toggle("2");
                          }}
                        >
                          Contact Details
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <div className="form__field-group">
                          <Row>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Company Name
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="company_name"
                                    component={renderField}
                                    type="text"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Register Number
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="register_no"
                                    component={renderField}
                                    type="text"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Domain
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="domain"
                                    component={renderField}
                                    type="text"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Corporation Date
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="corporation_date"
                                    component={renderDatePickerField}
                                    // defaultValue={new Date('02/08/2005')}
                                  />
                                  <div className="form__form-group-icon">
                                    <CalendarBlankIcon />
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </TabPane>
                      <TabPane tabId="2">
                        <div className="form__field-group">
                          <Row>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Email
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="email"
                                    component={renderField}
                                    type="email"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Alternate Email
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="alternate_email"
                                    component={renderField}
                                    type="email"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Phone Number
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="phone"
                                    component={renderField}
                                    type="tel"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Alternate Phone Number
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="alternate_phone"
                                    component={renderField}
                                    type="tel"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
                <div className="form__field-group">
                  <ButtonToolbar className="form__button-toolbar">
                    {/* <Button tag={Link} to={{ pathname: 'corporate', search: '?action=edit' }} color="primary">
                        Update
                    </Button> */}
                    <Button
                      className="rounded"
                      type="submit"
                      color="primary"
                      disabled={pristine || submitting}
                    >
                      {id ? "Update" : "Add"}
                    </Button>
                    {/* {id ? (
                      <Button
                        className="rounded"
                        type="button"
                        onClick={reset}
                        disabled={pristine || submitting}
                      >
                        Cancel
                      </Button>
                    ) : ( */}
                    <Button
                      className="rounded"
                      tag={Link}
                      outline
                      // color="primary"
                      to="/member/corporate"
                    >
                      Cancel
                    </Button>
                    {/* )} */}
                  </ButtonToolbar>
                </div>
              </CardBody>
            </Card>
          </div>
        </form>
      </Col>
    );
  }
}

const mapState = (state) => {
  return {
    initialValues: state.member.corporate.addFormData.data,
  };
};

const actionCreators = {
  getCorporate: memberActions.getCorporateDataAction,
  addUpdateCorporate: memberActions.addUpdateCorporateAction,
  clearCorporateFrom: memberActions.clearCorporateFrom,
};
//

// You have to connect() to any reducers that you wish to connect to yourself
export default connect(
  mapState,
  actionCreators // bind account loading action creator
)(
  reduxForm({
    form: "corporate_add", // a unique identifier for this form
    // validate
  })(VerticalForm)
);
