/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Row, Col, Button, Table } from "reactstrap";
import classnames from "classnames";
import { Field, reduxForm, formValueSelector, change, reset } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import clonedeep from "lodash.clonedeep";
// import { RRule } from "rrule";

import API from "../../../api/API";

import SlideInRecurrence from "./SlideIn/SlideInRecurrence";
import SlideInAddBooking from "./SlideIn/SlideInAddBooking";
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import renderDatePickerField from "../../../shared/components/form/DatePicker";
import renderTimePickerField from "../../../shared/components/form/TimePicker";
import bookingActions from "../../../redux/actions/bookingActions";
import renderSelectAsyncField from "../../../shared/components/form/AsyncSelect";
import renderParticipantField from "../../../shared/components/form/Participants";

const RadioField = ({ name, onChange, options, isDisabled }) => {
  return (
    <ul className="list-inline">
      {options.map((option) => {
        const id = `${name}-${option.value}`;

        return (
          <li className="list-inline-item" key={id}>
            <span className="cs-radio">
              <Field
                name={name}
                component="input"
                type="radio"
                id={id}
                value={option.value.toString()}
                onChange={onChange}
              />
              <label htmlFor={id}>{option.label}</label>
            </span>
          </li>
        );
      })}
    </ul>
  );
};

class SlideIn extends PureComponent {
  static propTypes = {
    openModal: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      serviceData: {},
      riderHorseChanged: false,
      isChanged: false,
    };
  }
  static defaultProps = {
    bookingtype: "",
    bookingsubtype: "",
    assignMember: {},
    assignHorse: {},
    id: null,
    bookingTypeSingle: {},
  };
  componentDidMount() {
    const { props } = this;
    const { dispatch } = props;
    if (props.id) {
      props.getBooking(props.id);
      props.getBookingParticipants(props.id);
    } else {
      const { search } = window.location;
      const params = new URLSearchParams(search);
      const start = params.get("start");

      if (!this.hasCalendarTime()) {
        return;
      }

      const date = moment(this.hasCalendarTime());

      const startDate = new Date(start);
      dispatch(change("booking_single", "booking_date", new Date(date)));
      dispatch(
        change(
          "booking_single",
          "booking_start_time",
          moment(startDate, "HH:mm")
        )
      );
      dispatch(change("booking_single", "booking_end_time", ""));
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    const { props } = this;
    props.clearBookingForm();
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.endSession();
    }
  }

  toggleContent = (key) => {
    const { state } = this;
    const newState = clonedeep(state);
    newState[key].toggle =
      "toggle" in newState[key] ? !newState[key].toggle : false;

    this.setState(newState);
  };

  getValues = (values) => {
    const { props } = this;

    const data = {};
    if (values) {
      data.recurrence = values.period ? values.period : "once";
      data.service_id = values.bookingtype_single
        ? values.bookingtype_single.value
        : null;
      data.date = moment(values.booking_date).format("YYYY-MM-DD");

      // Now, get the start and end time according to the given date.
      const startTime = moment(values.booking_start_time, "HH:mm");
      const endTime = moment(values.booking_end_time, "HH:mm");
      data.start_time = moment(values.booking_date)
        .add(startTime.hours(), "hours")
        .add(startTime.minutes(), "minutes")
        .format("YYYY-MM-DD HH:mm:ss");
      data.end_time = moment(values.booking_date)
        .add(endTime.hours(), "hours")
        .add(endTime.minutes(), "minutes")
        .format("YYYY-MM-DD HH:mm:ss");

      data.trainer_id = values.trainer ? values.trainer.value : null;
      data.arena_id = values.arena ? values.arena.value : null;
      data.user_id = values.asign_member ? values.asign_member.value : null;
      data.horse_id = values.asign_horse ? values.asign_horse.value : null;
      data.notes = values.booking_notes;

      data.weekdays = "";
      if (data.recurrence !== "once") {
        if (data.recurrence === "weekly") {
          let days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
          let byweekday = [];
          if ("days" in values && values.days.length > 0) {
            values.days.forEach((day, index) => {
              if (day) {
                byweekday.push(days[index]);
              }
            });
          }
          if (byweekday.length === 0) {
            byweekday = ["MO"];
          }
          data.weekdays = byweekday.join(",");
        }
      }
    }

    props.addUpdateBooking(JSON.stringify(data), props.id, props);
  };
  onChangeRiderHorse = () => {
    this.setState({
      riderHorseChanged: true,
    });
  };


  handleGroupBooking = (values) => {
    const { props } = this;

    const data = {};
    // if (values)
    if (
      values &&
      props.selectedEvent &&
      props.selectedEvent.bookingData &&
      props.selectedEvent.bookingData.id &&
      "asign_member" in values &&
      "asign_horse" in values &&
      this.state.riderHorseChanged
    ) {
    
      data.user_id = values.asign_member.value;
      data.horse_id = values.asign_horse.value;

    props.addGroupBooking(JSON.stringify(data), props.selectedEvent.bookingData.id);
    setTimeout(()=>{
      this.setState({
        isChanged: !this.state.isChanged
      });
    }, 2000);
  }


  };

  hasCalendarTime = () => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    return params.get("start");
  };

  calculateEndTime = (e, type) => {
    const { props } = this;
    const {
      bookingTypeSingle,
      bookingStartTime,
      dispatch,
      selectedSlot,
    } = props;

    if (!e && type === "bookingType") {
      return;
    }

    if (e && !e.duration && !bookingTypeSingle.duration) {
      return;
    }

    let duration;

    switch (type) {
      case "startTime":
        // eslint-disable-next-line prefer-destructuring
        duration = bookingTypeSingle.duration;
        break;
      case "bookingType":
        // eslint-disable-next-line prefer-destructuring
        duration = e.duration;
        break;
      default:
        // eslint-disable-next-line prefer-destructuring
        duration = bookingTypeSingle.duration;
        break;
    }

    const hours = moment(duration, "HH:mm:ss").hours();
    const minutes = moment(duration, "HH:mm:ss").minutes();
    let originTime = null;
    if (!e) {
      if (type === "startTime") {
        originTime = moment().startOf("day");
      }
    } else {
      originTime = e.duration ? bookingStartTime : e;
      if (
        type === "bookingType" &&
        selectedSlot.slots &&
        selectedSlot.slots.length > 1
      ) {
        originTime = moment(selectedSlot.start);
      }
    }

    dispatch(
      change(
        "booking_single",
        "booking_end_time",
        moment(originTime)
          .add(hours, "hours")
          .add(minutes, "minutes")
      )
    );
  };

  onServiceChange = (e) => {
    const { dispatch, selectedSlot } = this.props;

    dispatch(
      change(
        "booking_single",
        "booking_date",
        moment(selectedSlot.start)
          .startOf("day")
          .toDate()
      )
    );

    if (selectedSlot.slots && selectedSlot.slots.length > 1) {
      dispatch(
        change(
          "booking_single",
          "booking_start_time",
          moment(selectedSlot.start)
        )
      );
    }

    if (selectedSlot && selectedSlot.resourceId && selectedSlot.resourceTitle) {
      dispatch(
        change("booking_single", "trainer", {
          label: selectedSlot.resourceTitle,
          value: selectedSlot.resourceId,
        })
      );
    }

    this.calculateEndTime(e, "bookingType");
    this.getServiceDetails(e);
  };

  getServiceDetails = (e) => {
    const { state } = this;

    if (e && "value" in e) {
      const newState = clonedeep(state);
      API.getService("", e.value).then(
        (response) => {
          if (response.data) {
            newState.serviceData = {
              data: response.data.data,
              toggle: true,
            };
            this.setState(newState);
          } else {
            this.resetState();
          }
        },
        () => {
          this.resetState();
        }
      );
    }
  };

  endSession = (autoClose = true) => {
    const { dispatch, closeModal, clearGroupBooking } = this.props;
    dispatch(reset("booking_single"));
    if (autoClose) {
      clearGroupBooking();
      closeModal();
    }
  };

  resetState = (key = "") => {
    const { state } = this;
    let newState = {
      serviceData: {},
    };
    if (key.length > 0) {
      newState = clonedeep(state);
      newState[key] = {};
    }

    this.setState(newState);
  };

  deleteBookingFrom = (id, bookingId) => {
    this.props.deleteBookingFrom(id, bookingId);
  };


  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      // bookingtype,
      bookingsubtype,
      assignMember,
      assignHorse,
      groupData,
      // id,
      bookingStartTime,
      dispatch,
      bookingTypeSingle,
      openModal,
      selectedSlot,
      selectedEvent,
    } = this.props;

    const id = selectedEvent && selectedEvent.bookingData? selectedEvent.bookingData.id : this.props.id;
    const bookingtype = selectedEvent && selectedEvent.bookingData && selectedEvent.bookingData.booking_type ? selectedEvent.bookingData.booking_type.value : this.props.bookingtype;

    const { serviceData } = this.state;

    const isSessionSelected = bookingtype.length > 0;
    let isBookingSelected = isSessionSelected;
    if (bookingtype !== "2") {
      isBookingSelected = isSessionSelected && bookingsubtype.length > 0;
    }
    const isServiceSelected = isBookingSelected && "value" in bookingTypeSingle;
    const showServiceDetails =
      isServiceSelected && Object.keys(serviceData).length > 0;
    let bookingTypeTitle = "";
    if (isBookingSelected) {
      bookingTypeTitle = "Service";
      if (bookingtype === "2") {
        bookingTypeTitle = "Activity";
      } else if (bookingtype === "3") {
        bookingTypeTitle = "Package";
      }
    }
    const isEventSelected =
      selectedEvent &&
      "bookingData" in selectedEvent &&
      "id" in selectedEvent.bookingData;

    /* Top open slidein toggle class 'slide-in-on' for body or <div className='slide-in'> */
    /* Top open accorion toggle class 'open' for <div className='cs-accordion'> */
    return (
      <div className={openModal ? "slide-in-on" : "slide-in"}>
        <div className="slide-in-wrap">
          <div className="slide-in-main" ref={this.wrapperRef}>
            {/* edit booking */}
            {isEventSelected && (
              <div className="slide-in-head">
                <h2>
                  {selectedEvent.bookingData.service.label}{" "}
                  <a href="/">
                    <span className="lnr lnr-exit-up" />
                  </a>
                </h2>
                <p className="slide-in-head-price">
                  AED {selectedEvent.bookingData.service.price}
                </p>
                <Button className="slide-in-close" onClick={this.endSession}>
                  <span className="lnr lnr-cross" />
                </Button>
              </div>
            )}
            {/* edit booking */}
            <div className="slide-in-content">
              {/* edit booking */}
              {isEventSelected && (
                <>
                  <div className="slide-in-content-item">
                    <div className="slide-in-content-item-head">
                      <h3>{selectedEvent.bookingData.booking_type.label}</h3>
                    </div>
                    <ul className="slide-in-content-item-list">
                      <li className="col-half">
                        <span>Max Riders:</span>
                        <span>
                          {selectedEvent.bookingData.service.max_riders}
                        </span>
                      </li>
                      <li className="col-half">
                        <span>Duration:</span>
                        <span>
                          {selectedEvent.bookingData.service.duration}
                        </span>
                      </li>
                      {/* <li className="col-half">
												<span>Service Cateogory:</span>
												<span></span>
											</li> */}
                    </ul>
                  </div>
                  <div className="slide-in-content-item">
                    <div className="slide-in-content-item-head">
                      <h3>
                        {selectedEvent.bookingData.booking_type.value === 3 ? (
                          "Recurrence"
                        ) : (
                          <>Date &amp; Time</>
                        )}
                      </h3>
                    </div>
                    <ul className="slide-in-content-item-list">
                      {selectedEvent.bookingData.booking_type.value === 3 && (
                        <li>
                          <span>Period:</span>
                          <span>Daily</span>
                        </li>
                      )}
                      {selectedEvent.bookingData.booking_type.value === 3 ? (
                        <li className="col-half">
                          <span>From:</span>
                          <span>
                            {moment(selectedEvent.bookingData.date).format(
                              "DD/MM/YYYY"
                            )}
                          </span>
                        </li>
                      ) : (
                        <li>
                          <span>Date:</span>
                          <span>
                            {moment(selectedEvent.bookingData.date).format(
                              "DD/MM/YYYY"
                            )}
                          </span>
                        </li>
                      )}
                      {/* {selectedEvent.bookingData.booking_type.value === 3 && (
                        <li className="col-half">
                          <span>To:</span>
                          <span></span>
                        </li>
                      )} */}
                      <li className="col-half">
                        <span>Start time:</span>
                        <span>{selectedEvent.bookingData.start_time}</span>
                      </li>
                      <li className="col-half">
                        <span>End time:</span>
                        <span>{selectedEvent.bookingData.end_time}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="slide-in-content-item">
                    <div className="slide-in-content-item-head">
                      <h3>Session</h3>
                    </div>
                    <ul className="slide-in-content-item-list">
                      <li>
                        <span>Trainer:</span>
                        <span>
                          <a href="/">
                            {selectedEvent.bookingData.trainer
                              ? selectedEvent.bookingData.trainer.label
                              : ""}
                          </a>
                        </span>
                      </li>
                      <li>
                        <span>Arena:</span>
                        <span>
                          <a href="/">
                            {selectedEvent.bookingData.arena
                              ? selectedEvent.bookingData.arena.label
                              : ""}
                          </a>
                        </span>
                      </li>
                      <li>
                        <span>Notes:</span>
                        <span>{selectedEvent.bookingData.notes}</span>
                      </li>
                    </ul>
                  </div>
                  {groupData &&
                    "data" in groupData &&
                    groupData.data.total_participant > 0 && (
                      <div className="slide-in-content-item">
                        <div className="slide-in-content-item-head">
                          <h3>
                            Bookings ({groupData.data.total_participant}/
                            {selectedEvent.bookingData.service.max_riders})
                          </h3>
                          {/* selectedEvent.bookingData.service.is_group === 1 && (
												<Button color="primary rounded">Add a Booking</Button>
											) */}
                        </div>
                        <Table responsive hover>
                          <thead>
                            <tr>
                              <th>Order ID</th>
                              <th>Rider</th>
                              <th>Horse</th>
                              {selectedEvent.bookingData.service.is_group ===
                                1 && <th>Actions</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {groupData.data.participants.map((groupItem) => {
                              return (
                                <tr key={groupItem.bid}>
                                  <td>{groupItem.bid}</td>
                                  <td>{groupItem.name}</td>
                                  <td>{groupItem.horse_name}</td>
                                  {selectedEvent.bookingData.service
                                    .is_group === 1 && (
                                    <td>
                                      <Button
                                        color="danger"
                                        size="sm"
                                        className="rounded"
                                        onClick={() => {
                                          this.deleteBookingFrom(
                                            groupItem.bid,
                                            id
                                          );
                                          setTimeout(()=>{
                                            this.setState({
                                              isChanged: !this.state.isChanged
                                            });
                                          }, 2000);
                                        }}

                                      >
                                        Delete
                                      </Button>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  {selectedEvent.bookingData.service.is_group === 1 && (
                    <div className="slide-in-content-item">
                      <div className="slide-in-content-item-head">
                        <h5>Add a Booking</h5>
                      </div>
                      <form
                        className="form form--vertical"
                        onSubmit={handleSubmit(this.handleGroupBooking)}
                      >
                        <SlideInAddBooking
                          id={id}
                          rider={assignMember}
                          horse={assignHorse}
                          bookingtype={bookingtype}
                          isChanged={this.state.isChanged}
                          onChange={this.onChangeRiderHorse}

                        />
                        <div className="slide-in-content-form-foot">
                          <div className="invisible"></div>
                          <Button
                            color="primary rounded"
                            disabled={
                              pristine ||
                              submitting ||
                              !(
                                "value" in assignMember &&
                                "value" in assignHorse
                              )
                            }
                          >
                            Add Booking
                          </Button>
                        </div>
                      </form>
                    </div>
                  )}
                </>
              )}
              {/* edit booking */}
              {/* new booking */}
              {selectedSlot && (
                <div className="slide-in-content-item">
                  <div className="slide-in-content-item-head">
                    <h3>Add a Session</h3>
                    <Button
                      className="slide-in-close"
                      onClick={this.endSession}
                    >
                      <span className="lnr lnr-cross" />
                    </Button>
                  </div>
                  <form
                    className="form form--vertical"
                    onSubmit={handleSubmit(this.getValues)}
                  >
                    <div className="slide-in-content-item w-100 pb-0">
                      <div className="form__form-group">
                        <label className="form__form-group-label">
                          Session type
                        </label>
                        <span style={{ color: "red" }}>*</span>
                        <RadioField
                          name="bookingtype"
                          onChange={(e) => {
                            let period = "once";
                            if (e.target.value === "3") {
                              period = "daily";
                            }
                            dispatch(
                              change("booking_single", "period", period)
                            );
                            dispatch(
                              change("booking_single", "bookingsubtype", "")
                            );
                            dispatch(
                              change("booking_single", "bookingtype_single", "")
                            );
                          }}
                          isDisabled={!!id}
                          options={[
                            {
                              value: 1,
                              label: "Single Session",
                            },
                            {
                              value: 3,
                              label: "Packages",
                            },
                            {
                              value: 2,
                              label: "Activity",
                            },
                          ]}
                        />
                      </div>

                      {isSessionSelected && bookingtype !== "2" && (
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Booking type
                          </label>
                          <span style={{ color: "red" }}>*</span>
                          <RadioField
                            name="bookingsubtype"
                            onChange={() => {
                              dispatch(
                                change(
                                  "booking_single",
                                  "bookingtype_single",
                                  ""
                                )
                              );
                            }}
                            isDisabled={!!id}
                            options={[
                              {
                                value: "private",
                                label: "Private",
                              },
                              {
                                value: "group",
                                label: "Group Session",
                              },
                            ]}
                          />
                        </div>
                      )}
                    </div>

                    {isBookingSelected && (
                      <div className="slide-in-content-item w-100 pb-0">
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Select {bookingTypeTitle}
                          </span>
                          <span style={{ color: "red" }}>*</span>
                          <div className="form__form-group-field">
                            <Field
                              isDisabled={!!id}
                              key={bookingsubtype}
                              name="bookingtype_single"
                              placeholder={`Select ${bookingTypeTitle}`}
                              component={renderSelectAsyncField}
                              api={`packagelist/${bookingtype}`}
                              group={bookingsubtype}
                              onChange={this.onServiceChange}
                            />
                          </div>

                          {showServiceDetails && (
                            <div
                              className={classnames({
                                "cs-accordion": true,
                                open:
                                  "toggle" in serviceData && serviceData.toggle,
                              })}
                            >
                              <h4
                                className="cs-accordion-title"
                                onClick={() =>
                                  this.toggleContent("serviceData")
                                }
                              >
                                DETAILS
                                <span className="lnr lnr-chevron-down" />
                              </h4>
                              <div className="cs-accordion-content">
                                <ul className="slide-in-content-item-list">
                                  <li className="col-half">
                                    <span>Duration:</span>
                                    <span>{serviceData.data.duration}</span>
                                  </li>
                                  <li className="col-half">
                                    <span>Price:</span>
                                    <span>{serviceData.data.price}</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {showServiceDetails &&
                      (bookingtype === "3" ? (
                        <SlideInRecurrence dispatch={dispatch}
                        bookingTypeSingle={bookingTypeSingle}
                        bookingStartTime={bookingStartTime}
                        selectedSlot={selectedSlot}  />
                      ) : (
                        <div className="slide-in-content-item w-100 pb-0">
                          <h3 className="mb-3">Date &amp; Time</h3>
                          <Row>
                            <Col md={12}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Date
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="booking_date"
                                    component={renderDatePickerField}
                                    placeholder="12/12/1222"
                                  />
                                  <div className="form__form-group-icon">
                                    <CalendarBlankIcon />
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Start Time
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="booking_start_time"
                                    onChange={(e) => {
                                      this.calculateEndTime(e, "startTime");
                                    }}
                                    component={renderTimePickerField}
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  End Time
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="booking_end_time"
                                    component={renderTimePickerField}
                                    placeholder=""
                                    disabled
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ))}

                    {showServiceDetails && (
                      <div className="slide-in-content-item w-100 pb-0">
                        <Row>
                          {/* <Col md={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                Trainer
                              </span>
                              <span style={{ color: "red" }}>*</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="trainer"
                                  placeholder="Select Trainer"
                                  component={renderSelectAsyncField}
                                  api="trainerlist"
                                  isDisabled={true}
                                />
                              </div>
                            </div>
                          </Col> */}
                          <Col md={12}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                Arena
                              </span>
                              <span style={{ color: "red" }}>*</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="arena"
                                  placeholder="Select Arena"
                                  component={renderSelectAsyncField}
                                  api="arenaslist"
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}

                    {/* showServiceDetails && (
											<ul className="list-inline">
												<li className="list-inline-item">
													<span className="cs-radio">
														<input
															type="radio"
															name="member-type"
															value="1"
															id="new-member"
														/>
														<label htmlFor="new-member">New Member</label>
													</span>
												</li>
												<li className="list-inline-item">
													<span className="cs-radio">
														<input
															type="radio"
															name="member-type"
															value="1"
															id="existing-member"
															checked
														/>
														<label htmlFor="existing-member">
															Existing Member
														</label>
													</span>
												</li>
											</ul>
										) */}

                    {showServiceDetails && bookingsubtype !== "group" && (
                      <SlideInAddBooking
                        id={id}
                        rider={assignMember}
                        horse={assignHorse}
                        bookingtype={bookingtype}
                        isChanged={this.state.isChanged}
                        onChange={this.onChangeRiderHorse}

                      />
                    )}

                    {showServiceDetails && (
                      <div className="form__form-group">
                        <label className="form__form-group-label">Notes</label>
                        <Field
                          name="booking_notes"
                          component="textarea"
                          type="text"
                        />
                      </div>
                    )}

                    <div className="slide-in-content-form-foot">
                      <p>
                        Price:
                        {showServiceDetails
                          ? " AED " + serviceData.data.price
                          : ""}
                      </p>
                      <Button
                        color="primary rounded"
                        disabled={pristine || !showServiceDetails || submitting}
                      >
                        Add Booking
                      </Button>
                    </div>
                  </form>
                </div>
              )}
              {/* new booking */}
            </div>
            {/* <div className="slide-in-footer">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <Button
                    color="primary"
                    size="sm"
                    className="rounded"
                    onClick={this.endSession}
                  >
                    Exit session
                  </Button>
                </li>
                <li className="list-inline-item">
                  <Button
                    color="danger"
                    size="sm"
                    className="rounded"
                    onClick={() => this.endSession(false)}
                    disabled={pristine || submitting}
                  >
                    Delete Session
                  </Button>
                </li>
              </ul>
            </div> */}
            {isEventSelected && <div>
             <Button
             tag={Link}
            color="primary" style={{marginRight: '8px', padding: '0.375rem 0.75rem', textTransform: 'uppercase', fontSize: '12px'}}
            to={`/booking/edit/${id}`}
            >
            Edit
          </Button>
          <Button
          tag={Link}
            color="primary" style={{marginRight: '8px', padding: '0.375rem 0.75rem', textTransform: 'uppercase', fontSize: '12px'}}
            to={`/booking/cancel/${id}`}
            >
            Cancel Booking
          </Button>
          <Button
          
            color="primary" style={{marginRight: '8px', padding: '0.375rem 0.75rem', textTransform: 'uppercase', fontSize: '12px'}}
            onClick={() => {
              if (
                window.confirm("Are you sure you wish to no show this item?")
              ) {
                this.props.noShowBooking(id, this.props.onChangeCalendar);
                this.props.closeModal();
              }
            }}
            >
            No Show
          </Button>
          <Button
            color="primary" style={{marginRight: '8px', padding: '0.375rem 0.75rem', textTransform: 'uppercase', fontSize: '12px'}}
            onClick={() => {
              if (
                window.confirm("Are you sure you wish to delete this item?")
              ) {
                this.props.deleteBooking(id, this.props.onChangeCalendar);
                this.props.closeModal();
              }
            }}
            >
            Delete
          </Button>
          </div>
  }
          </div>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector("booking_single");

const mapStateToProps = (state, ownProps) => {

  const bookingtype = selector(state, "bookingtype");
  const bookingsubtype = selector(state, "bookingsubtype");
  const bookingStartTime = selector(state, "booking_start_time");

  let bookingTypeSingle = selector(state, "bookingtype_single");
  let assignMember = selector(state, "asign_member");
  let assignHorse = selector(state, "asign_horse");

  if (!bookingTypeSingle) {
    bookingTypeSingle = {};
  }

  if (!assignMember) {
    assignMember = {};
  }

  if (!assignHorse) {
    assignHorse = {};
  }

  let initValues = state.booking.addFormData.data;
  if (initValues) {
    initValues.bookingtype = initValues.booking_type
      ? initValues.booking_type
      : null;
    initValues.bookingtype_single = initValues.service
      ? initValues.service
      : null;
    initValues.booking_date = initValues.date
      ? new Date(initValues.date)
      : null;
    initValues.booking_start_time = initValues.start_time
      ? moment(initValues.start_time, "HH:mm")
      : null;
    initValues.booking_end_time = initValues.end_time
      ? moment(initValues.end_time, "HH:mm")
      : null;
    initValues.booking_notes = initValues.notes ? initValues.notes : null;

    initValues.service_category = initValues.category
      ? initValues.category
      : null;
    initValues.service_duration = initValues.duration
      ? initValues.duration
      : null;
  } else {
    if (typeof initValues === "undefined") {
      initValues = {
        booking_start_time: moment(),
      };
    }
  }

  return {
    initialValues: initValues,
    bookingtype,
    bookingsubtype,
    bookingStartTime,
    assignMember,
    assignHorse,
    bookingTypeSingle,
    groupData: state.booking.groupFormData,
  };
};

const actionCreators = {
  getBooking: bookingActions.getBookingAction,
  addUpdateBooking: bookingActions.addUpdateBookingAction,
  clearBookingForm: bookingActions.clearBookingForm,
  addGroupBooking: bookingActions.addGroupBookingAction,
  clearGroupBooking: bookingActions.clearGroupBookingForm,

  getBookingParticipants: bookingActions.getBookingParticipantsAction,
  addBookingParticipant: bookingActions.addBookingParticipantAction,
  deleteBookingParticipant: bookingActions.deleteBookingParticipantAction,
  deleteBookingFrom: bookingActions.deleteBookingFromAction,
  deleteBooking: bookingActions.deleteBookingAction,
  noShowBooking: bookingActions.noShowBookingAction,
};

const createReduxForm = reduxForm({
  form: "booking_single",
});

export default connect(
  mapStateToProps,
  actionCreators
)(createReduxForm(SlideIn));
