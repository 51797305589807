import React, { PureComponent, Children } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import calendarActions from "../../../redux/actions/calendarActions";
import history from "../../../_helpers/history";
import bookingActions from "../../../redux/actions/bookingActions";
import SlideIn from "./SlideIn";
import clonedeep from "lodash.clonedeep";
import API from "../../../api/API";

const localizer = momentLocalizer(moment);

const tooltip = () => {
  return "This is a ToolTip";
};
class CalendarComponent extends PureComponent {
  static propTypes = {
    getCalendarTrainers: PropTypes.func.isRequired,
    trainercalender: PropTypes.arrayOf().isRequired,
    small: PropTypes.bool,
  };

  static defaultProps = {
    small: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      selectedSlot: null,
      selectedEvent: false,
      redirect: false,
      redirectEdit: false,
    };
    props.getCalendarTrainers();
  }

  moveEvent = ({ event, start, end }) => {
    const { events } = this.state;

    const idx = events.indexOf(event);
    const updatedEvent = { ...event, start, end };

    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);

    this.setState({
      events: nextEvents,
      redirect: false,
      redirectEdit: false,
    });
  };

  eventStyleGetter = (event) => {
    let color;

    switch (event.priority) {
      case "high":
        color = "#A5DFF8";
        break;

      case "family":
        color = "#A5DFF8";
        break;

      case "non":
        color = "#A5DFF8";
        break;
      default:
        color = "#A5DFF8";
        break;
    }

    const style = {
      backgroundColor: color,
      border: "none",
    };

    return {
      style,
    };
  };

  renderRedirect = () => {
    const { redirect, redirectEdit, selectedSlot } = this.state;
    let comp = null;
    if (redirect) {
      history.push(`/trainer-calendar`);
      comp = (
        <Redirect
          to={{
            pathname: "/booking/add/",
            selected: selectedSlot,
            from: "trainer-calendar",
          }}
        />
      );
    } else if (redirectEdit) {
      history.push(`/trainer-calendar`);
      comp = (
        <Redirect
          to={{
            pathname: "/booking/edit/",
            selected: selectedSlot,
            from: "trainer-calendar",
          }}
        />
      );
    }

    return comp;
  };

  closeModal = (update = false) => {
    const { props } = this;
    this.setState({
      openModal: false,
      selectedEvent: false,
      selectedSlot: false,
    });
    if (typeof update === "boolean" && update) {
      // props.onChange();
      props.getCalendarTrainers();
    }
  };

  updateModal = (event) => {
    const { props } = this;

    this.setState({
      openModal: false,
      selectedEvent: false,
      selectedSlot: false,
    });

    // props.onChange();
    props.getCalendarTrainers();
    this.onSelectEventHandler(event);
  };

  onSelectEventHandler = (event) => {
    const { props } = this;
    const eventData = clonedeep(event);
    API.getBooking(event.bid).then(
      (response) => {
        if (response.data) {
          props.getGroupBooking(event.bid);
          eventData.bookingData = response.data.data;
          this.setState({
            openModal: true,
            selectedEvent: eventData,
            selectedSlot: false,
          });
        }
      },
      () => {
        // Handle errors.
      }
    );
  };

  render() {
    const { small, trainercalender } = this.props;
    const { events, resources } = trainercalender;
    const { openModal, selectedSlot, selectedEvent } = this.state;

    const mapToRBCFormat = (e) =>
      Object.assign({}, e, {
        start: new Date(e.start),
        end: new Date(e.end),
      });
    const minTime = new Date();
    minTime.setHours(8, 30, 0);
    const maxTime = new Date();
    maxTime.setHours(20, 30, 0);
    return (
      <div className={`calendar${small ? " calendar--small" : ""}`}>
        {/* {this.renderRedirect()} */}
        {events && (
          <Calendar
            localizer={localizer}
            selectable
            events={events.map(mapToRBCFormat)}
            resources={resources}
            resourceIdAccessor="resourceId"
            resourceTitleAccessor="resourceTitle"
            views={["week", "day"]}
            min={minTime}
            max={maxTime}
            defaultView="day"
            popup
            step={15}
            timeslots={4}
            onEventDrop={this.moveEvent}
            // defaultDate={new Date(2019, 8, 1)}
            eventPropGetter={this.eventStyleGetter}
            tooltipAccessor={tooltip}
            // onSelectEvent={(event) => {
            //   this.setState({
            //     redirectEdit: true,
            //   });
            // }}
            onSelectEvent={this.onSelectEventHandler}
            onSelectSlot={(slotInfo) => {
              if(!moment(slotInfo.start).isBefore() || moment(slotInfo.start).isSame(moment(), 'day')){
              let selectedResource = resources.filter((k, i) => {
                if (k.resourceId == slotInfo.resourceId) {
                  return k;
                }
              });
              slotInfo.resourceTitle = selectedResource[0].resourceTitle;
              this.setState({
                selectedSlot: slotInfo,
                // selectedSlot: {
                //   start: slotInfo.start.toLocaleString(),
                //   end: slotInfo.end.toLocaleString(),
                //   action: slotInfo.action,
                //   resourceId: slotInfo.resourceId,
                //   resourceTitle: selectedResource[0].resourceTitle,
                // },
                openModal: true,
                // redirect: true,
              });
            }
            }}
            messages={{
              previous: <span className="lnr lnr-chevron-left" />,
              next: <span className="lnr lnr-chevron-right" />,
              today: <span className="lnr lnr-calendar-full" />,
            }}
            components={{
              dateCellWrapper: ColoredDateCellWrapper,
              timeSlotWrapper: ColoredTimeCellWrapper,
             }}
          />
        )}
        <SlideIn
          openModal={openModal}
          selectedSlot={selectedSlot}
          selectedEvent={selectedEvent}
          closeModal={this.closeModal}
          updateModal={this.updateModal}
          onChangeCalendar={this.props.getCalendarTrainers}
        />
      </div>
    );
  }
}

function mapState(state) {
  return {
    trainercalender: state.calendar.trainercalender,
  };
}

const ColoredDateCellWrapper = (props) =>{
  const {children, value, range} = props;
const now = new Date();
 now.setHours(0,0,0,0);
   return React.cloneElement(Children.only(children), {
       style: {
           ...children.style,
           backgroundColor: value < now ? 'rgb(232, 232, 232)' : '',
       },
   });

 }

 const ColoredTimeCellWrapper = (props) =>{
   const {children, value, range, resource} = props;
   const now = new Date();
   now.setHours(0,0,0,0);
    return React.cloneElement(Children.only(children), {
        style: {
            ...children.style,
            backgroundColor: value < now && typeof resource != 'undefined' ? 'rgb(232, 232, 232)' : '',
        },
    });

  }

const actionCreators = {
  getCalendarTrainers: calendarActions.getCalendarTrainersAction,
  getGroupBooking: bookingActions.getGroupBookingAction,
};

export default connect(mapState, actionCreators)(CalendarComponent);
