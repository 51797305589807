import React from 'react';
import {
  Container, Row
} from 'reactstrap';

import DataTable from './components/DataTable';
import ServiceCommonHeader from '../common/ServiceCommonHeader';


const ServiceSingleSeasonListing = props => (
  <Container className="dashboard">
    {/* <Row>
      <Col md={9}>
        <h3 className="page-title">Services</h3>
      </Col>
      <Col md={3} className="text-right">
        <UncontrolledDropdown>
          <DropdownToggle className="icon icon--right" color="primary">
            <p>Add New <ChevronDownIcon /></p>
          </DropdownToggle>
          <DropdownMenu className="dropdown__menu">
            <DropdownItem>
              <Link to="/services/add-service">New Single Session</Link>
            </DropdownItem>
            <DropdownItem>
              <Link to="/services/add-package">New Package</Link>
            </DropdownItem>
            <DropdownItem>
              <Link to="/services/add-activity">New Acitivity</Link>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Col>
    </Row> */}
    <ServiceCommonHeader title="Services"/>
    <Row>
      <DataTable {...props} />
    </Row>
  </Container>
);


export default ServiceSingleSeasonListing;
