import { reset } from "redux-form";
import API from "../../api/API";
import { serviceConstants } from "../../_constants/dashboard.constants";
import history from "../../_helpers/history";
import alertActions from "./alertActions";
import preloaderActions from "./preloaderActions";
import { constructUrlParams } from "../../_helpers/CommonFunctions";
import moment from "moment";
import { change } from "redux-form";

function getCategoryListAction(filters) {
  let custFilter = filters;

  function success(data) {
    return {
      type: serviceConstants.CATEGORY_LIST,
      data,
      filter: {
        currentPage: custFilter.currentPage,
        search: custFilter.search,
      },
    };
  }

  return (dispatch, getState) => {
    dispatch(preloaderActions.show());
    const { service } = getState();
    const { categories } = service;

    if (typeof filters === "undefined") {
      custFilter = categories.filter;
    }

    if (custFilter.currentPage > 1) {
      custFilter.currentPage =
        custFilter.currentPage > categories.last_page
          ? categories.last_page
          : custFilter.currentPage;
    }

    const urlParams = constructUrlParams({
      page: custFilter.currentPage,
      search: custFilter.search,
    });

    API.getCategories(urlParams).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function deleteCategoryAction(id) {
  function success(data) {
    return {
      type: serviceConstants.CATEGORY_DELETE,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.deleteCategory(id).then(
      (response) => {
        if (response.data) {
          dispatch(alertActions.success(response.data.message));
          dispatch(success(response.data));
          dispatch(getCategoryListAction());
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function addUpdateCategoryAction(params, id) {
  function success(data) {
    return {
      type: id
        ? serviceConstants.CATEGORY_UPDATE
        : serviceConstants.CATEGORY_ADD,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.addUpdateCategory(params, id).then(
      (response) => {
        if (response.data) {
          // dispatch(change('service_category_add_edit', 'member-documents', ''));
          dispatch(reset("service_category_add_edit"));
          history.push("/services/categories");
          if (!id) {
            dispatch(success(response.data));
          }
          dispatch(alertActions.success(response.data.message));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function getCategoryAction(id) {
  function success(data) {
    return {
      type: serviceConstants.CATEGORY_GET,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.getCategory(id).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function getServiceListAction(type, params) {
  let custFilter = params;

  function success(data) {
    return {
      type: serviceConstants.SERVICE_LIST,
      serviceType: type,
      data,
      filter: {
        currentPage: custFilter.currentPage,
        search: custFilter.search,
      },
    };
  }

  return (dispatch, getState) => {
    dispatch(preloaderActions.show());
    const { service } = getState();
    // const { categories } = service;

    if (typeof params === "undefined") {
      custFilter = service[type].filter;
    }

    if (custFilter.currentPage > 1) {
      custFilter.currentPage =
        custFilter.currentPage > service[type].last_page
          ? service[type].last_page
          : custFilter.currentPage;
    }

    const urlParams = constructUrlParams({
      page: custFilter.currentPage,
      search: custFilter.search,
    });

    API.getServices(type, urlParams).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function deleteServiceAction(type, id) {
  function success(data) {
    return {
      type: serviceConstants.SERVICE_DELETE,
      serviceType: type,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.deleteService(type, id).then(
      (response) => {
        if (response.data) {
          dispatch(alertActions.success(response.data.message));
          dispatch(success(response.data));
          dispatch(getCategoryListAction());
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function addUpdateServiceAction(type, params, id) {
  function success(data) {
    return {
      type: id ? serviceConstants.SERVICE_UPDATE : serviceConstants.SERVICE_ADD,
      serviceType: type,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.addUpdateService(type, params, id).then(
      (response) => {
        // console.log('addUpdateService', response)
        if (response.data) {
          if (type === "singleSession") {
            history.push(`/services/single/edit/${response.data.data.id}`);
          } else {
            history.push(`/services/${type}/edit/${response.data.data.id}`);
          }
          if (!id) {
            dispatch(success(response.data));
          }
          dispatch(alertActions.success(response.data.message));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function getServiceAction(type, id, endtimeUpdate) {
  function success(data) {
    return {
      type: serviceConstants.SERVICE_GET,
      serviceType: type,
      data,
    };
  }

  return (dispatch) => {
    dispatch(preloaderActions.show());
    API.getService(type, id).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
          if (type == "all") {
            if (!endtimeUpdate) {
              let duration = response.data.data.duration;
              const hours = moment(duration, "HH:mm:ss").hours();
              const minutes = moment(duration, "HH:mm:ss").minutes();

              dispatch(
                change(
                  "booking_single",
                  "booking_end_time",
                  moment()
                    .add(hours, "hours")
                    .add(minutes, "minutes")
                )
              );
            }
          }
        }

        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

function clearServiceFromAction() {
  return {
    type: serviceConstants.SERVICE_GET_CLEAR,
  };
}

const serviceActions = {
  getCategoryListAction,
  deleteCategoryAction,
  addUpdateCategoryAction,
  getCategoryAction,

  getServiceListAction,
  deleteServiceAction,
  addUpdateServiceAction,
  getServiceAction,
  clearServiceFromAction,
};

export default serviceActions;
