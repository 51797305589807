import React, { PureComponent } from "react";
import { Card, CardBody, Col, Row, Button, ButtonToolbar } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import renderSelectField from "../../../../shared/components/form/Select";
import trainerActions from "../../../../redux/actions/trainerActions";
// import validate from './validate';

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: "",
  meta: null,
  type: "text",
};

class VerticalForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    id: PropTypes.string,
    formDefaults: PropTypes.shape({}),
    // commission_type: {},
    // trainer_type: {},
    // horse_gender: {}
  };

  static defaultProps = {
    id: null,
    formDefaults: {},
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  componentDidMount() {
    const { props } = this;
    if (props.id) {
      props.getTrainer(props.id);
    }
  }

  componentWillUnmount() {
    // console.log('componentWillUnmount');
    const { props } = this;
    props.clearTrainerForm();
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  getValues = (values) => {
    const { props } = this;
    const data = values;
    data.trainer_type = values.trainer_type ? values.trainer_type.value : "";
    data.commission_type = values.commission_type
      ? values.commission_type.value
      : "";
    data.gender = values.genderdata ? values.genderdata.value : "";

    props.addUpdateTrainer(JSON.stringify(data), props.id);
  };

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      id,
      formDefaults,
    } = this.props;
    // const { showPassword } = this.state;
    return (
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody>
            <form className="form" onSubmit={handleSubmit(this.getValues)}>
              <div className="formInner">
                <Row>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Name</span>
                      <span style={{ color: "red" }}>*</span>
                      <div className="form__form-group-field">
                        <Field
                          name="name"
                          component={renderField}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">E-mail</span>
                      <span style={{ color: "red" }}>*</span>
                      <div className="form__form-group-field">
                        <Field
                          name="email"
                          component={renderField}
                          type="email"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Password</span>
                      <div className="form__form-group-field">
                        <Field
                          name="password"
                          component={renderField}
                          type="password"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Mobile</span>
                      <span style={{ color: "red" }}>*</span>
                      <div className="form__form-group-field">
                        <Field
                          name="mobile"
                          component={renderField}
                          type="tel"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Gender</span>
                      <span style={{ color: "red" }}>*</span>
                      <div className="form__form-group-field">
                        <Field
                          name="genderdata"
                          component={renderSelectField}
                          type="text"
                          options={[
                            { value: "Male", label: "Male" },
                            { value: "Female", label: "Female" },
                          ]}
                        />
                      </div>
                    </div>
                  </Col>
                  {/* <Col md={6}></Col> */}
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="form__form-group">
                      <h4>Trainer Type</h4>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Trainer type
                      </span>
                      <span style={{ color: "red" }}>*</span>
                      <div className="form__form-group-field">
                        <Field
                          name="trainer_type"
                          component={renderSelectField}
                          type="text"
                          placeholder="Trainer Type"
                          options={formDefaults.trainer_type}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Commission in %
                      </span>
                      <div className="form__form-group-field form__form-group-field-with-select">
                        <Field
                          name="commission"
                          component={renderField}
                          type="number"
                          placeholder="10"
                        />
                        <Field
                          name="commission_type"
                          component={renderSelectField}
                          type="text"
                          placeholder="Per"
                          options={formDefaults.commission_type}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                  <Col md={6}></Col>
                  <Col md={6}></Col>
                </Row> */}
                <ButtonToolbar className="form__button-toolbar">
                  {/* <Button
                        color="primary"
                        tag={Link}
                        to={{ pathname: '/trainer/listing', search: '?action=edit' }}
                      >Submit
                      </Button>
                      <Button type="button" onClick={reset} disabled={pristine || submitting}>
                        Cancel
                      </Button> */}
                  <Button
                    className="rounded"
                    type="submit"
                    color="primary"
                    disabled={pristine || submitting}
                  >
                    {id ? "Update" : "Add"}
                  </Button>
                  {/* {id ? (
                    <Button
                      className="rounded"
                      type="button"
                      onClick={reset}
                      disabled={pristine || submitting}
                    >
                      Cancel
                    </Button>
                  ) : ( */}
                  <Button
                    className="rounded"
                    tag={Link}
                    outline
                    // color="primary"
                    to="/trainer/listing"
                  >
                    Cancel
                  </Button>
                  {/* )} */}
                </ButtonToolbar>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

// const initialValues = {

//     gender: { value: 'Male', label: 'Male' },
//     firstname: 'Alexander',
//     lastname: 'Doe',
//     email: 'john@gmail.com',
//     mobile: '12345578',
// };

// export default connect(() => initialValues)(reduxForm({
//   form: 'edit_trainer', // a unique identifier for this form
//   // validate,
// })(withTranslation('common')(VerticalForm)));

// const mapState = (state) => {
//   return {
//     initialValues: state.member.corporate.addFormData.data
//   };
// }
const mapState = (state) => {
  return {
    initialValues: state.trainer.addFormData.data,
    formDefaults: state.common.formDefaults,
  };
};

const actionCreators = {
  getTrainer: trainerActions.getTrainerAction,
  addUpdateTrainer: trainerActions.addUpdateTrainerAction,
  clearTrainerForm: trainerActions.clearTrainerForm,
};
//

// You have to connect() to any reducers that you wish to connect to yourself
export default connect(
  mapState,
  actionCreators // bind account loading action creator
)(
  reduxForm({
    form: "trainer_add_edit", // a unique identifier for this form
    // validate
  })(withTranslation("common")(VerticalForm))
);
