import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import IcomoonReact from 'icomoon-react';
import iconSet from '../../../icomoon/selection.json';
import TopbarSidebarButton from "./TopbarSidebarButton";
import TopbarProfile from "./TopbarProfile";
// import TopbarLanguage from './TopbarLanguage';
import { connect } from "react-redux";

class Topbar extends PureComponent {
	static propTypes = {
		changeMobileSidebarVisibility: PropTypes.func.isRequired,
		changeSidebarVisibility: PropTypes.func.isRequired
	};

	render() {
		const {
			changeMobileSidebarVisibility,
			changeSidebarVisibility
		} = this.props;
		const {props} = this;
		let isMember =
      props.authData.user &&
      props.authData.user.userrole &&
      props.authData.user.userrole.includes("member")
        ? true
        : false;

		return (
			<div className='topbar'>
				<div className='topbar__wrapper'>
					<div className='topbar__left'>
						<TopbarSidebarButton
							changeMobileSidebarVisibility={changeMobileSidebarVisibility}
							changeSidebarVisibility={changeSidebarVisibility}
						/>
						<Link
							className='topbar__logo'
							title='Horse Listing'
							to='/dashboard'
						>
							Nashmi.io
						</Link>
					</div>
					<div className='topbar__right'>
						{!isMember && <Link className='site-search' title='Search' to='/search'>
							<IcomoonReact iconSet={iconSet} color="#576880" size={20} icon="search" />
						</Link> }
						<TopbarProfile />
						{/* <TopbarLanguage /> */}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({ authData: state.authentication });

export default connect(mapStateToProps)(Topbar);