import React, { PureComponent } from 'react';
import {
  Button, ButtonGroup, ButtonToolbar, Col
} from 'reactstrap';
// import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector,reset, change } from 'redux-form';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import MagnifyIcon from "mdi-react/MagnifyIcon";
// import EyeIcon from 'mdi-react/EyeIcon';
// import { withTranslation } from 'react-i18next';
// import renderSelectField from '../form/Select';
import renderSelectAsyncField from '../form/AsyncSelect';
// import validate from './validate';
import CloseCircleIcon from "mdi-react/CloseCircleIcon";

const renderField = ({
  input, placeholder, type, meta: { touched, error }
}) => (
    <div className="form__form-group-input-wrap">
      <input {...input} placeholder={placeholder} type={type} />
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
  );

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
};

// let componentLoaded = false;

class FilterForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    searchText: PropTypes.string,
    onFilterChange: PropTypes.func.isRequired,
    selectedLayout: PropTypes.string,
    category: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    search: PropTypes.string,
    // initialValues: PropTypes.shape({
    //   layout: PropTypes.string.isRequired,
    // }).isRequired,
    // selectedLayout: PropTypes.string,
    // layout: PropTypes.string.isRequired
    // reset: PropTypes.func.isRequired,
    // pristine: PropTypes.bool.isRequired,
    // submitting: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    searchText: '',
    // selectedLayout: 'horse',
    selectedLayout: 'horse',
    category: {
      label: 'All',
      value: ''
    },
    search: ''
  }

  constructor(props) {
    super(props);
    this.state = {
      // showPassword: false,
      inputtext: null,
    };
  }

  // componentDidMount() {
  //   console.log('componentDidMount');
  // }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  handleClick = (e) => {
    e.preventDefault();
    // props.onSearch(input.value);
  };

  handleButton = (type) => {
    // console.log('on button change');
    const { props } = this;
    const { category, search } = props;
    props.change("layout", type);
    
    props.onFilterChange({ layout: type, category, search });
    // console.log(type);
  }

  handleSelectChange = (event, newValue) => {
    const { props } = this;
    // console.log(newValue);
    props.onFilterChange({ layout: props.selectedLayout, search: props.search, category: newValue });
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { onFilterChange, selectedLayout, category, search } = this.props;
    onFilterChange({ layout: selectedLayout, category, search });
  };

  render() {
    const { searchText, selectedLayout } = this.props;
    // console.log(category, search);
    return (
      <form className="form row justify-content-end" onSubmit={this.onSubmit}>
        {/* <div className=""> */}
        <Col md={9}>
          <ul className="list-inline filter-widget">
            <li className="list-inline-item">
              <div className="form__form-group">
                <span className="form__form-group-label">Filter by Category</span>
                <div className="form__form-group-field">
                  {/* <Field
                    name="category"
                    onChange={this.handleSelectChange}
                    component={renderSelectField}
                    type="text"
                    placeholder="All"
                    options={[
                      { value: 'All', label: 'All' },
                      { value: 'DPEC', label: 'DPEC' },
                      { value: 'Livery', label: 'Livery' },
                      { value: 'Member', label: 'Member' },
                    ]}
                  /> */}

                  <Field
                    onChange={this.handleSelectChange}
                    clearable="true"
                    name="category"
                    component={renderSelectAsyncField}
                    api="categorylist/1"
                    showAll
                  />
                </div>
              </div>
            </li>
            <li className="list-inline-item">
              <ButtonToolbar>
                <ButtonGroup>
                  <Button className="rounded" outline={(selectedLayout === 'owner')} color="primary" type="button"
                    onClick={() => this.handleButton('horse')}>
                    By Horse
                    </Button>
                  <Button className="rounded" outline={(selectedLayout === 'horse')} color="primary" type="button"
                    onClick={() => this.handleButton('owner')}>
                    By Owner
                    </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </li>
          </ul>
        </Col>
        <Col md={3} className='text-right'>
          <div className='form__form-group'>
            <span className="form__form-group-label">&nbsp;</span>
            <div className='form__form-group-field'>
              <Field
                name='search'
                component={renderField}
                type='text'
                placeholder='Search...'
                {...(searchText ? { value: searchText } : {})}
                onChange={(event, newValue, previousValue, name)=>{
                  this.setState({inputtext: newValue !== '' ? newValue : null});
              }}
              />
              {this.state.inputtext != null && <button type="button" onClick={(e)=>{
                            this.setState({inputtext: null});
                            // input.value = '';
                            this.props.dispatch(change('horse_listing_filter', 'search', ''));
                            const { onFilterChange, selectedLayout, category, search } = this.props;
    onFilterChange({ layout: selectedLayout, category, search: '' });
                        }} className="form__form-group-icon" style={{position: 'absolute', right: '35px', backgroundColor: 'transparent', borderColor: 'transparent', zIndex: '100'}}>
                            <CloseCircleIcon />
                        </button>}
              <button
                type="submit"
                className="form__form-group-icon">
                <MagnifyIcon />
              </button>
            </div>
          </div>
        </Col>
      </form>
    );
  }
}

// export default reduxForm({
//   form: 'horse_listing_filter', // a unique identifier for this form
// })(withTranslation('common')(FilterForm));

// // Decorate with reduxForm(). It will read the initialValues prop provided by connect()
// let something = reduxForm({
//   form: 'horse_listing_filter' // a unique identifier for this form
// })(FilterForm)

// // You have to connect() to any reducers that you wish to connect to yourself
// something = connect(
//   state => ({
//     initialValues: state.account.data // pull initial values from account reducer
//   }),
//   { load: loadAccount } // bind account loading action creator
// )(FilterForm)

// // export default FilterForm

const selector = formValueSelector('horse_listing_filter');

const mapState = (state) => {

  const selectedLayout = selector(state, 'layout');
  const search = selector(state, 'search');
  const category = selector(state, 'category');

  return {
    selectedLayout,
    search,
    category,
    initialValues: state.horse.filter
  };
}

const actionCreators = {

};
// 

// You have to connect() to any reducers that you wish to connect to yourself
export default connect(
  mapState,
  actionCreators// bind account loading action creator
)(reduxForm({
  form: 'horse_listing_filter', // a unique identifier for this form
})(FilterForm))