/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import reportHorseByOwnerActions from "../../../../../redux/actions/reportHorseByOwnerActions";
import ServerSideDataTable from "../../../../../shared/components/table/ServerSideDataTable";

class DataTable extends PureComponent {
  static propTypes = {
    getReportHorseByOwner: PropTypes.func.isRequired,
    horseByOwnerReport: PropTypes.shape({
      totalResults: PropTypes.number,
      filter: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        search: PropTypes.string,
        st_date: PropTypes.string,
        ed_date: PropTypes.string,
      }),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          email: PropTypes.string.isRequired,
          mobile: PropTypes.string.isRequired,
          gender: PropTypes.string.isRequired,
          expiry_date: PropTypes.string,
          address_line1: PropTypes.string.isRequired,
          address_line2: PropTypes.string.isRequired,
          post_code: PropTypes.string,
          date_of_birth: PropTypes.string,
          horses_count: PropTypes.number,
          genderdata: PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
          }),
        })
      ),
    }),
  };

  static defaultProps = {
    horseByOwnerReport: {
      search: "",
      totalResults: null,
    },
  };

  constructor(props) {
    super(props);
    // props.setDefaults();
    props.getReportHorseByOwner({
      st_date: props.st_date,
      ed_date: props.ed_date,
    });
  }

  // componentDidMount() {
  //   console.log("componentDidMount");
  // }
  componentDidUpdate(prevProps) {
    if (
      prevProps.st_date != this.props.st_date ||
      prevProps.ed_date != this.props.ed_date
    ) {
      this.props.getReportHorseByOwner({
        st_date: this.props.st_date,
        ed_date: this.props.ed_date,
      });
    }
  }

  handleTableChange = (type, { searchText, page }) => {
    const { props } = this;
    props.getReportHorseByOwner({
      currentPage: page,
      search: searchText,
      st_date: props.st_date,
      ed_date: props.ed_date,
    });
  };


  getStyle = (cell, row, rowIndex, colIndex) => {
    return { maxWidth: '140px', whiteSpace: 'normal', wordWrap: 'break-word'};
  };

  render() {
    const { props } = this;
    const { permissions, horseByOwnerReport } = props;

    const columns = [
      {
        dataField: "id",
        text: "ID",
        hidden: true,
      },
      {
        dataField: "name",
        text: "Name",
        style: this.getStyle,
      },
      {
        dataField: "email",
        text: "Email",
        style: this.getStyle,
      
      },
      {
        dataField: "mobile",
        text: "Mobile",
      },
      {
        dataField: "gender",
        text: "Gender",
      },
      {
        dataField: "expiry_date",
        text: "Expiry Date",
      },
      {
        dataField: "address_line1",
        text: "Address Line 1",
        style: this.getStyle,
      },
      {
        dataField: "address_line2",
        text: "Address Line 2",
        style: this.getStyle,
      },
      {
        dataField: "post_code",
        text: "Post Code",
        style: this.getStyle,
      },
      {
        dataField: "date_of_birth",
        text: "Date of Birth",
      },
      {
        dataField: "horses_count",
        text: "Horses Count",
      },
    ];

    return (
      <Col md={12} lg={12}>
        <Card>
          {horseByOwnerReport.list && (
            <CardBody>
              <ServerSideDataTable
                data={horseByOwnerReport.list}
                columns={columns}
                onTableChange={this.handleTableChange}
                page={horseByOwnerReport.filter.currentPage}
                sizePerPage={props.sizePerPage}
                totalSize={horseByOwnerReport.totalResults}
                searchText={horseByOwnerReport.filter.search}
              />
            </CardBody>
          )}
        </Card>
      </Col>
    );
  }
}

function mapState(state) {
  const authData = state.authentication;
  const permissions =
    typeof authData.user !== "undefined" &&
    Array.isArray(authData.user.permissions)
      ? authData.user.permissions
      : [];
  return {
    permissions,
    sizePerPage: state.horseByOwnerReport.sizePerPage,
    horseByOwnerReport: state.horseByOwnerReport,
  };
}

const actionCreators = {
  getReportHorseByOwner: reportHorseByOwnerActions.getReportHorseByOwnerAction,
  // setDefaults: memberActions.setCorporateDefaultsAction
};

export default connect(mapState, actionCreators)(DataTable);
