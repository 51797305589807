import React from "react";
import { Col, Container, Row, Button } from "reactstrap";
import { Link } from "react-router-dom";
import CustomCalendar from "./components/TrainerCalendar";
// import EventLabels from './components/EventLabels';

const TrainerCalendar = () => (
  <Container>
    <Row>
      <Col md={9}>
        <h3 className="page-title">Trainer Calendar</h3>
      </Col>
      <Col md={3} className="text-right">
        <Button tag={Link} to="/calendar" color="primary" className="rounded">
          By Sessions
        </Button>
      </Col>
    </Row>
    <Row>
      <CustomCalendar />
    </Row>
  </Container>
);

export default TrainerCalendar;
