import React, { PureComponent } from "react";
import { Card, CardBody, Col, Row, Button, ButtonToolbar } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import EyeIcon from 'mdi-react/EyeIcon';
import { withTranslation } from "react-i18next";
import horseActions from "../../../../redux/actions/horseActions";
// import validate from "./validate";

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: "",
  meta: null,
  type: "text",
};

class VerticalForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    id: PropTypes.string,
  };

  static defaultProps = {
    id: null,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  componentDidMount() {
    const { props } = this;
    if (props.id) {
      props.getHorseLocation(props.id);
    }
  }

  componentWillUnmount() {
    // console.log('componentWillUnmount');
    const { props } = this;
    props.clearHorseLocationFrom();
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  getValues = (values) => {
    const { props } = this;
    // eslint-disable-next-line no-param-reassign
    values.description = "Description Unavailable";
    props.addUpdateHorseLocation(JSON.stringify(values), props.id);
  };

  render() {
    const { handleSubmit, pristine, reset, submitting, id } = this.props;
    // const { showPassword } = this.state;

    return (
      <Col md={12} lg={12} xl={12}>
        <form className="form" onSubmit={handleSubmit(this.getValues)}>
          <div className="formInner">
            <Card>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Location Name
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="location_name"
                          component={renderField}
                          type="text"
                          placeholder="Location Name"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Maximum Horses
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="max_horses"
                          component={renderField}
                          type="number"
                          placeholder="0"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <ButtonToolbar className="form__button-toolbar">
                  {/* <Button
										tag={Link}
										to={{
											pathname: "/horse/locations",
											search: "?action=edit"
										}}
										color='primary'
									>
										Submit
									</Button>
									<Button
										type='button'
										onClick={reset}
										disabled={pristine || submitting}
									>
										Cancel
									</Button> */}
                  <Button
                    className="rounded"
                    type="submit"
                    color="primary"
                    disabled={pristine || submitting}
                  >
                    {id ? "Update" : "Add"}
                  </Button>
                  {/* {id ? (
                    <Button
                      className="rounded"
                      type="button"
                      onClick={reset}
                      disabled={pristine || submitting}
                    >
                      Cancel
                    </Button>
                  ) : ( */}
                  <Button
                    className="rounded"
                    tag={Link}
                    outline
                    // color="primary"
                    to="/horse/locations"
                  >
                    Cancel
                  </Button>
                  {/* )} */}
                </ButtonToolbar>
              </CardBody>
            </Card>
          </div>
        </form>
      </Col>
    );
  }
}

// const initialValues = {
// 	initialValues: {
// 		location: "Location B",
// 		max_horses: "10"
// 	}
// };

// const mapState = (state) => {
// 	return {
// 		initialValues: state.member.HorseLocation.addFormData.data
// 	};
// }

const mapState = (state) => {
  return {
    // initialValues
    initialValues: state.horse.location.addFormData.data,
  };
};

const actionCreators = {
  getHorseLocation: horseActions.getHorseLocationAction,
  addUpdateHorseLocation: horseActions.addUpdateHorseLocationAction,
  clearHorseLocationFrom: horseActions.clearHorseLocationFrom,
};

export default connect(
  mapState,
  actionCreators // bind account loading action creator
)(
  reduxForm({
    form: "location_add_edit", // a unique identifier for this form
    // validate
  })(withTranslation("common")(VerticalForm))
);
// export default connect(() => initialValues)(
// 	reduxForm({
// 		form: "location_add_edit", // a unique identifier for this form
// 	})(withTranslation("common")(VerticalForm))
// );
