import React, { PureComponent } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  ButtonToolbar,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import classnames from "classnames";
import { Field, reduxForm, formValueSelector, change, reset } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import moment from "moment";
import clonedeep from "lodash.clonedeep";

// import EyeIcon from 'mdi-react/EyeIcon';
// import { withTranslation } from 'react-i18next';
import Recurrence from "./Recurrence";
import AddBooking from "./AddBooking";
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
// import validate from './validate';
import renderDatePickerField from "../../../../../shared/components/form/DatePicker";
import renderTimePickerField from "../../../../../shared/components/form/TimePicker";
import bookingActions from "../../../../../redux/actions/bookingActions";
import serviceActions from "../../../../../redux/actions/serviceActions";
import renderSelectAsyncField from "../../../../../shared/components/form/AsyncSelect";

// import history from '../../../../_helpers/history';
// import ActionsTable from './ActionsTable';
// import { SingleLessons, PackageLessons, Activities } from './data';

const RadioField = ({ name, onChange, options, isDisabled }) => {
  return (
    <ul className="list-inline">
      {options.map((option) => {
        const id = `${name}-${option.value}`;

        return (
          <li className="list-inline-item" key={id}>
            <span className="cs-radio">
              <Field
                name={name}
                component="input"
                type="radio"
                id={id}
                value={option.value.toString()}
                onChange={onChange}
                disabled={isDisabled}
              />
              <label htmlFor={id}>{option.label}</label>
            </span>
          </li>
        );
      })}
    </ul>
  );
};

class VerticalForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    // addBookingParticipant: PropTypes.func.isRequired,
    // deleteBookingParticipant: PropTypes.func.isRequired,
    bookingtype: PropTypes.string,
    assignMember: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    assignHorse: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    bookingTypeSingle: PropTypes.shape({}),
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    id: PropTypes.string,
    participants: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    bookingtype: "",
    bookingsubtype: "",
    assignMember: {},
    assignHorse: {},
    id: null,
    participants: [],
    bookingTypeSingle: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      toggleContent: {
        service: false,
      },
    };
  }

  componentDidMount() {
    const { props } = this;
    const { dispatch } = props;
    if (props.selectedSlot) {
      dispatch(
        change("booking_single", "trainer", {
          label: props.selectedSlot.resourceTitle,
          value: props.selectedSlot.resourceId,
        })
      );
    }

    if (props.id) {
      props.getBooking(props.id);
      props.getBookingParticipants(props.id);
      this.toggleContent("service");
    } else {
      const { search } = window.location;
      const params = new URLSearchParams(search);
      const start = params.get("start");

      if (!this.hasCalendarTime()) {
        return;
      }
      // if (!start) {
      //   return;
      // }

      const date = moment(this.hasCalendarTime());

      const startDate = new Date(start);
      dispatch(change("booking_single", "booking_date", new Date(date)));
      dispatch(
        change(
          "booking_single",
          "booking_start_time",
          moment(startDate, "HH:mm")
        )
      );
      dispatch(change("booking_single", "booking_end_time", ""));
    }
  }

  componentWillUnmount() {
    this.endSession();
  }

  toggleContent = (key) => {
    const { state } = this;
    const newState = clonedeep(state);
    newState.toggleContent[key] = !newState.toggleContent[key];

    this.setState(newState);
  };

  addRow = (assignMember, assignHorse) => {
    const { props } = this;
    if (!assignMember.label || !assignHorse.label) {
      return;
    }

    const data = {
      booking_id: props.id,
      horse_id: assignHorse.value,
      user_id: assignMember.value,
    };

    props.addBookingParticipant(JSON.stringify(data));
  };

  removeRow = (id) => {
    const { props } = this;
    props.deleteBookingParticipant(id);
  };

  getValues = (values) => {
    const { props } = this;

    const data = {};

    if (values) {
      data.recurrence = values.period ? values.period : "once";
      data.service_id = values.bookingtype_single
        ? values.bookingtype_single.value
        : null;
      data.date = moment(values.booking_date).format("YYYY-MM-DD");

      // Now, get the start and end time according to the given date.
      const startTime = moment(values.booking_start_time, "HH:mm");
      const endTime = moment(values.booking_end_time, "HH:mm");

      data.start_time = moment(values.booking_date)
        .add(startTime.hours(), "hours")
        .add(startTime.minutes(), "minutes")
        .format("YYYY-MM-DD HH:mm:ss");
      data.end_time = moment(values.booking_date)
        .add(endTime.hours(), "hours")
        .add(endTime.minutes(), "minutes")
        .format("YYYY-MM-DD HH:mm:ss");

      data.trainer_id = values.trainer ? values.trainer.value : null;
      data.arena_id = values.arena ? values.arena.value : null;
      data.user_id = values.asign_member ? values.asign_member.value : null;
      data.horse_id = values.asign_horse ? values.asign_horse.value : null;
      data.notes = values.booking_notes;

      data.weekdays = "";
      if (data.recurrence !== "once") {
        if (data.recurrence === "weekly") {
          let days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
          let byweekday = [];
          if ("days" in values && values.days.length > 0) {
            values.days.forEach((day, index) => {
              if (day) {
                byweekday.push(days[index]);
              }
            });
          }
          if (byweekday.length === 0) {
            byweekday = ["MO"];
          }
          data.weekdays = byweekday.join(",");
        }
      }
    }

    props.addUpdateBooking(JSON.stringify(data), props.id, {}, props.from);

    const groupBookingData = {};
    if (
      values &&
      typeof props.id !== "undefined" &&
      "asign_member" in values &&
      "asign_horse" in values
    ) {
      groupBookingData.user_id = values.asign_member.value;
      groupBookingData.horse_id = values.asign_horse.value;

      props.addGroupBooking(JSON.stringify(groupBookingData), props.id);
    }
  };

  getServiceDetails = (e) => {
    const { props } = this;
    if (e && "value" in e) {
      props.getService("all", e.value);
    }
    this.toggleContent("service");

    const { dispatch } = props;
    dispatch(change("booking_single", "booking_start_time", moment()));
  };

  hasCalendarTime = () => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    return params.get("start");
  };

  clearDateTime = (dispatch) => {
    if (!this.hasCalendarTime()) {
      dispatch(change("booking_single", "booking_start_time", ""));
      dispatch(change("booking_single", "booking_end_time", ""));
    }
  };

  calculateEndTime = (e, type) => {
    const { props } = this;
    const { bookingTypeSingle, dispatch, bookingStartTime } = props;
    if (!e && type === "bookingType") {
      return;
    }

    if (e && !e.duration && !bookingTypeSingle.duration) {
      return;
    }

    let duration;

    switch (type) {
      case "startTime":
        // eslint-disable-next-line prefer-destructuring
        duration = bookingTypeSingle.duration;
        break;
      case "bookingType":
        // eslint-disable-next-line prefer-destructuring
        duration = e.duration;
        break;
      default:
        // eslint-disable-next-line prefer-destructuring
        duration = bookingTypeSingle.duration;
        break;
    }

    const hours = moment(duration, "HH:mm:ss").hours();
    const minutes = moment(duration, "HH:mm:ss").minutes();
    let originTime = null;
    if (!e) {
      if (type === "startTime") {
        originTime = moment().startOf("day");
      }
    } else {
      originTime = e.duration ? bookingStartTime : e;
    }

    dispatch(
      change(
        "booking_single",
        "booking_end_time",
        moment(originTime)
          .add(hours, "hours")
          .add(minutes, "minutes")
      )
    );
  };

  endSession = () => {
    const { dispatch, clearBookingForm, clearGroupBooking } = this.props;
    dispatch(reset("booking_single"));
    clearBookingForm();
    clearGroupBooking();
  };

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      bookingtype,
      bookingsubtype,
      assignMember,
      assignHorse,
      id,
      groupData,
      participants,
      dispatch,
      bookingTypeSingle,
      serviceData,
      period,
      weekdays,
    } = this.props;
    const { toggleContent } = this.state;

    const isSessionSelected = bookingtype.length > 0;
    let isBookingSelected = isSessionSelected;
    if (bookingtype !== "2") {
      isBookingSelected = isSessionSelected && bookingsubtype.length > 0;
    }
    const isServiceSelected = isBookingSelected && "value" in bookingTypeSingle;
    const showServiceDetails =
      isServiceSelected && Object.keys(serviceData).length > 0;
    let bookingTypeTitle = "";
    if (isBookingSelected) {
      bookingTypeTitle = "Service";
      if (bookingtype === "2") {
        bookingTypeTitle = "Activity";
      } else if (bookingtype === "3") {
        bookingTypeTitle = "Package";
      }
    }

    return (
      <Col md={12} lg={12} xl={12}>
        <form className="form" onSubmit={handleSubmit(this.getValues)}>
          <div className="formInner">
            <div className="form__field-group">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <div className="form__form-group">
                        <h3>Choose a booking type</h3>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form__form-group">
                        <label className="form__form-group-label mb-2">
                          Session type
                        </label>
                        <span style={{ color: "red" }}>*</span>
                        <RadioField
                          name="bookingtype"
                          onChange={(e) => {
                            let period = "once";
                            if (e.target.value === "3") {
                              period = "daily";
                            }
                            dispatch(
                              change("booking_single", "period", period)
                            );
                            dispatch(
                              change("booking_single", "bookingsubtype", "")
                            );
                            dispatch(
                              change("booking_single", "bookingtype_single", "")
                            );
                          }}
                          isDisabled={!!id}
                          options={[
                            {
                              value: 1,
                              label: "Single Session",
                            },
                            {
                              value: 3,
                              label: "Packages",
                            },
                            {
                              value: 2,
                              label: "Activity",
                            },
                          ]}
                        />
                      </div>
                    </Col>
                  </Row>
                  {isSessionSelected && bookingtype !== "2" && (
                    <Row>
                      <Col md={12}>
                        <div className="form__form-group">
                          <label className="form__form-group-label mb-2">
                            Booking type
                          </label>
                          <span style={{ color: "red" }}>*</span>
                          <RadioField
                            name="bookingsubtype"
                            onChange={() => {
                              dispatch(
                                change(
                                  "booking_single",
                                  "bookingtype_single",
                                  ""
                                )
                              );
                            }}
                            isDisabled={!!id}
                            options={[
                              {
                                value: "private",
                                label: "Private",
                              },
                              {
                                value: "group",
                                label: "Group Session",
                              },
                            ]}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                  {isBookingSelected && (
                    <Row>
                      <Col sm="12" lg="6">
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Select {bookingTypeTitle}
                          </span>
                          <span style={{ color: "red" }}>*</span>
                          <div className="form__form-group-field">
                            <Field
                              isDisabled={!!id}
                              key={bookingsubtype}
                              name="bookingtype_single"
                              placeholder={`Select ${bookingTypeTitle}`}
                              component={renderSelectAsyncField}
                              api={`packagelist/${bookingtype}`}
                              group={bookingsubtype}
                              onChange={this.getServiceDetails}
                            />
                          </div>

                          {showServiceDetails && (
                            <div
                              className={classnames({
                                "cs-accordion": true,
                                open: toggleContent.service,
                              })}
                            >
                              <h4
                                className="cs-accordion-title"
                                onClick={() => this.toggleContent("service")}
                              >
                                <strong>DETAILS</strong>
                                <span className="lnr lnr-chevron-down" />
                              </h4>
                              <div className="cs-accordion-content">
                                <ul className="slide-in-content-item-list">
                                  <li className="col-half">
                                    <span>
                                      <strong>Duration:</strong>
                                    </span>
                                    <span>{serviceData.data.duration}</span>
                                  </li>
                                  <li className="col-half">
                                    <span>
                                      <strong>Price:</strong>
                                    </span>
                                    <span>{serviceData.data.price}</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </div>
            {showServiceDetails && (
              <div className="form__field-group">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <div className="form__form-group">
                          <h3>Booking Details</h3>
                        </div>
                      </Col>
                    </Row>

                    {bookingtype === "3" ? (
                      <Recurrence
                        id={id}
                        period={period}
                        weekdays={weekdays}
                        dispatch={dispatch}
                        onStartTimeChange={(e) => {
                          this.calculateEndTime(e, "startTime");
                        }}
                      />
                    ) : (
                      <>
                        <Row>
                          <Col md={12}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                Date
                              </span>
                              <span style={{ color: "red" }}>*</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="booking_date"
                                  component={renderDatePickerField}
                                  placeholder="12/12/1222"
                                  disabled
                                />
                                <div className="form__form-group-icon">
                                  <CalendarBlankIcon />
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                Start Time
                              </span>
                              <span style={{ color: "red" }}>*</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="booking_start_time"
                                  onChange={(e) => {
                                    this.calculateEndTime(e, "startTime");
                                  }}
                                  component={renderTimePickerField}
                                  placeholder=""
                                  disabled
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                End Time
                              </span>
                              <span style={{ color: "red" }}>*</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="booking_end_time"
                                  component={renderTimePickerField}
                                  placeholder=""
                                  disabled
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}

                    <Row>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Trainer
                          </span>
                          <span style={{ color: "red" }}>*</span>
                          <div className="form__form-group-field">
                            <Field
                              name="trainer"
                              placeholder="Select Trainer"
                              component={renderSelectAsyncField}
                              api="trainerlist"
                              isDisabled
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">Arena</span>
                          <span style={{ color: "red" }}>*</span>
                          <div className="form__form-group-field">
                            <Field
                              name="arena"
                              placeholder="Select Arena"
                              component={renderSelectAsyncField}
                              api="arenaslist"
                              isDisabled
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">Notes</span>
                          <div className="form__form-group-field">
                            <Field
                              name="booking_notes"
                              component="textarea"
                              type="text"
                              disabled
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            )}
            {showServiceDetails &&
              (id || bookingsubtype !== "group") &&
              (bookingtype === "3" || bookingtype === "1") && (
                <div className="form__field-group">
                  <Card>
                    <CardBody>
                      {bookingsubtype === "group" &&
                        id &&
                        groupData &&
                        "data" in groupData &&
                        groupData.data.total_participant > 0 && (
                          <Row>
                            <Col md={12}>
                              <div className="slide-in-content-item">
                                <div className="slide-in-content-item-head">
                                  <h3>
                                    Bookings ({groupData.data.total_participant}
                                    /{bookingTypeSingle.max_riders})
                                  </h3>
                                </div>
                                <Table responsive hover>
                                  <thead>
                                    <tr>
                                      <th>Order ID</th>
                                      <th>Rider</th>
                                      <th>Horse</th>
                                      {bookingTypeSingle.is_group === 1 && (
                                        <th>Actions</th>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {groupData.data.participants.map(
                                      (groupItem) => {
                                        return (
                                          <tr key={groupItem.bid}>
                                            <td>{groupItem.bid}</td>
                                            <td>{groupItem.name}</td>
                                            <td>{groupItem.horse_name}</td>
                                            {bookingTypeSingle.is_group ===
                                              1 && (
                                              <td>
                                                <Button
                                                  color="danger"
                                                  size="sm"
                                                  className="rounded"
                                                >
                                                  Delete
                                                </Button>
                                              </td>
                                            )}
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            </Col>
                          </Row>
                        )}
                      <Row>
                        {bookingsubtype !== "group" && participants.length > 0 && (
                          <>
                            <Col md={12}>
                              <div className="form__form-group">
                                <h3>Booking</h3>
                              </div>
                            </Col>
                            <Col md={12}>
                              <Table responsive hover>
                                <thead>
                                  <tr>
                                    <th>Order ID</th>
                                    <th>Rider</th>
                                    <th>Horse</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {participants.map((entry, index) => (
                                    <tr key={index}>
                                      <td data-index={index}>{id}</td>
                                      <td data-index={index}>
                                        {entry.user && entry.user.label}
                                      </td>
                                      <td data-index={index}>
                                        {entry.horse.label}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </Col>
                          </>
                        )}
                        {(participants.length === 0 ||
                          bookingsubtype === "group") && (
                          <>
                            <Col md={12}>
                              <div className="form__form-group">
                                <h3>
                                  {bookingsubtype !== "group"
                                    ? "Assign Riders and Horses"
                                    : "Add a Booking"}
                                </h3>
                              </div>
                            </Col>

                            <AddBooking
                              id={id}
                              rider={assignMember}
                              horse={assignHorse}
                            />
                          </>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              )}
          </div>
        </form>
      </Col>
    );
  }
}

const selector = formValueSelector("booking_single");

const mapState = (state, ownProps) => {
  const bookingtype = selector(state, "bookingtype");
  const bookingsubtype = selector(state, "bookingsubtype");
  const bookingStartTime = selector(state, "booking_start_time");
  const period = selector(state, "period");
  let weekdays = [];

  let bookingTypeSingle = selector(state, "bookingtype_single");
  let assignMember = selector(state, "asign_member");
  let assignHorse = selector(state, "asign_horse");

  let start = new Date();
  start.setHours(0, 0, 0, 0);

  if (!bookingTypeSingle) {
    bookingTypeSingle = {};
  }

  if (!assignMember) {
    assignMember = {};
  }

  if (!assignHorse) {
    assignHorse = {};
  }

  let initValues = state.booking.addFormData.data
    ? state.booking.addFormData.data
    : null;

  if (initValues) {
    initValues.bookingtype = initValues.booking_type
      ? initValues.booking_type.value.toString()
      : "";
    initValues.bookingsubtype = initValues.service
      ? initValues.service.is_group === 1
        ? "group"
        : "private"
      : "";
    initValues.bookingtype_single = initValues.service
      ? initValues.service
      : null;
    initValues.booking_date = initValues.date
      ? new Date(initValues.date)
      : null;
    initValues.booking_start_time = initValues.start_time
      ? moment(initValues.start_time, "HH:mm")
      : null;
    initValues.booking_end_time = initValues.end_time
      ? moment(initValues.end_time, "HH:mm")
      : null;
    initValues.trainer = "trainer" in initValues ? initValues.trainer : null;
    initValues.arena = "arena" in initValues ? initValues.arena : null;
    initValues.booking_notes = initValues.notes ? initValues.notes : null;
    initValues.period = initValues.recurrence ? initValues.recurrence : "";
    if (typeof initValues.weekdays !== "undefined") {
      let days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
      let currentWeekDays = initValues.weekdays.split(",");
      days.forEach((day) => {
        weekdays.push(currentWeekDays.includes(day));
      });
    }
    /*
		initValues.service_category = initValues.category
			? initValues.category
			: null;
		initValues.service_duration = initValues.duration
			? initValues.duration
			: null;
    */
    initValues.asign_member = initValues.user;
    initValues.asign_horse = initValues.horse;
  }
  if (!ownProps.id && !initValues) {
    initValues = {};
    initValues.booking_date = start;
  }

  return {
    initialValues: initValues,
    bookingtype,
    bookingsubtype,
    bookingStartTime,
    assignMember,
    assignHorse,
    bookingTypeSingle,
    period,
    weekdays,
    participants: state.booking.participantData,
    serviceData: state.service.all.addFormData,
    groupData: state.booking.groupFormData,
  };
};

const actionCreators = {
  getBooking: bookingActions.getBookingAction,
  addUpdateBooking: bookingActions.addUpdateBookingAction,
  clearBookingForm: bookingActions.clearBookingForm,
  addGroupBooking: bookingActions.addGroupBookingAction,
  clearGroupBooking: bookingActions.clearGroupBookingForm,
  // Service Actions.
  getService: serviceActions.getServiceAction,
  // Participant Actions.
  getBookingParticipants: bookingActions.getBookingParticipantsAction,
  addBookingParticipant: bookingActions.addBookingParticipantAction,
  deleteBookingParticipant: bookingActions.deleteBookingParticipantAction,
};

export default connect(
  mapState,
  actionCreators
)(
  reduxForm({
    form: "booking_single",
  })(VerticalForm)
);
