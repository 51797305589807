/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Button, ButtonToolbar } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import { formValueSelector } from 'redux-form';
import PropTypes from "prop-types";
import bookingActions from "../../../../redux/actions/bookingActions";
import ServerSideDataTable from "../../../../shared/components/table/ServerSideDataTable";
// import horseActions from '../../../../redux/actions/horseActions';
import Permission from "../../../Permission/components/Permission";


class BookingList extends PureComponent {
  static propTypes = {
    // getCorporateList: PropTypes.func.isRequired,
    getUserBookingList: PropTypes.func.isRequired,
    ownerId: PropTypes.string.isRequired,
    // ownerId: PropTypes.string.isRequired,
    // setDefaults: PropTypes.func.isRequired,
    bookings: PropTypes.shape({
      totalResults: PropTypes.number,
      currentPage: PropTypes.number,
      search: PropTypes.string,
      list: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string,
          category: PropTypes.string,
          price: PropTypes.string,
          location: PropTypes.string,
        })
      ),
    }),
    // sizePerPage: PropTypes.number.isRequired
  };

  static defaultProps = {
    bookings: {
      currentPage: 1,
      search: "",
      totalResults: "",
      list: [],
    },
  };

  constructor(props) {
    super(props);
    // props.setDefaults();
    // props.getCorporateList();
    if (props.ownerId) {
      props.getUserBookingList({ id: props.ownerId });
    }
  }

  componentDidMount() {
    const { props } = this;
    if (props.ownerId) {
      props.getUserBookingList({ id: props.ownerId });
    }
  }

  handleTableChange = (type, { searchText, page }) => {
    const { props } = this;
    // props.getCorporateList(page, searchText);
    props.getUserBookingList({
      currentPage: page,
      search: searchText,
      id: props.ownerId,
    });
  };

  //   handleFilterChange = (filters) => {
  //     const {props} = this;

  //     console.log(filters);
  //     props.getUserBookingList(filters);
  //     // props.getUserBookingList(page, searchText);

  //     // const { props } = this;
  //     // console.log('handleFilterChange', props);
  //   }

  customActions = (cell, row, rowIndex, custom) => {
    return (
      <ButtonToolbar className="table-actions">
        <Permission id="edit-bookings">
          <Button
            tag={Link}
            to={`/booking/edit/${row.id}`}
            color="primary"
            size="sm"
            className="rounded"
          >
            Edit
          </Button>
        </Permission>

        <Permission id="delete-bookings">
          <Button
            color="danger"
            size="sm"
            className="rounded"
            onClick={() => {
              if (
                window.confirm("Are you sure you wish to delete this item?")
              ) {
                custom(row.id);
              }
            }}
          >
            Delete
          </Button>
        </Permission>
        <Button
          tag={Link}
          to={`/booking/cancel/${row.id}`}
          color="secondary"
          size="sm"
          className="rounded"
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          size="sm"
          className="rounded"
          onClick={() => {
            if (
              window.confirm("Are you sure you wish to no show this item?")
            ) {
              this.props.noShowBooking(row.id);
            }
          }}
        >
          No Show
        </Button>
      </ButtonToolbar>
    );
  };

  render() {
    const { props } = this;
    const { bookings } = props;
    // const { corporate, bookings } = props;
    // const { corporate } = props;

    const columns = [
      {
        dataField: "service_name",
        text: "Lesson Type",
      },
      {
        dataField: "id",
        text: "ID",
        hidden: true,
      },
      {
        dataField: "date",
        text: "Date",
      },
      {
        dataField: "start_time",
        text: "Start Time",
      },
      {
        dataField: "end_time",
        text: "End Time",
      },
      {
        dataField: "trainer_name",
        text: "Trainer",
      },
      {
        dataField: "arena_name",
        text: "Arena",
      },
      {
        dataField: "created_user",
        text: "Created User",
      },
      // {
      //   dataField: 'notes',
      //   text: 'Notes',
      // },
      {
        dataField: "actions",
        text: "Actions",
        formatter: this.customActions,
        formatExtraData: props.deleteBooking,
        headerStyle: (colum, colIndex) => {
          return { width: '275px'};
        }
      },
    ];

    return (
      bookings.list && bookings.list.length > 0 ? (
        <ServerSideDataTable
          data={bookings.list}
          columns={columns}
          onTableChange={this.handleTableChange}
          page={bookings.currentPage}
          sizePerPage={props.sizePerPage}
          totalSize={bookings.totalResults}
          searchText={bookings.search}
          // onFilterChange={this.handleFilterChange}
          hideSearchFilter={false}
        />
      ) : <div>No bookings added </div>
      //   <Col md={12} lg={12}>
      //     <Card>
      //       {corporate.list && <CardBody>

      //       </CardBody>}
      //     </Card>
      //   </Col>
    );
  }
}

function mapState(state) {
  // console.log(state)
  // const filterForm = formValueSelector('horse_listing_filter');
  return {
    sizePerPage: state.booking.userBookings.sizePerPage,
    // corporate: state.booking.userBookings.corporate,
    bookings: state.booking.userBookings,
    // search: filterForm(state, 'search'),
    // layout: filterForm(state, 'layout'),
    // category: filterForm(state, 'category')
  };
}

const actionCreators = {
  deleteBooking: bookingActions.deleteBookingAction,
  getUserBookingList: bookingActions.getUserBookingListAction,
  noShowBooking: bookingActions.noShowBookingAction,

};

export default connect(mapState, actionCreators)(BookingList);
