import React, { Component } from "react";
import PropTypes from "prop-types";

import Permission from "../../Permission/components/Permission";
import PermissionGroup from "../../Permission/components/PermissionGroup";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";

import { connect } from "react-redux";

class SidebarContent extends Component {
  static propTypes = {
    // changeToDark: PropTypes.func.isRequired,
    // changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { props } = this;
    let isSuperAdmin =
      props.authData.user &&
      props.authData.user.userrole &&
      props.authData.user.userrole.includes("superadmin")
        ? true
        : false;
    return (
      <div className="sidebar__content">
        {/* <ul className="sidebar__block">
          <SidebarLink title="Log In" icon="exit" route="/log_in" onClick={this.hideSidebar} />
          <SidebarCategory title="Layout" icon="layers">
            <button type="button" className="sidebar__link" onClick={changeToLight}>
              <p className="sidebar__link-title">Light Theme</p>
            </button>
            <button type="button" className="sidebar__link" onClick={changeToDark}>
              <p className="sidebar__link-title">Dark Theme</p>
            </button>
          </SidebarCategory>
        </ul> */}
        <ul className="sidebar__block">
          <Permission id="manage-dashboard">
            <SidebarLink
              title="Dashboard"
              icon="dashboard"
              route="/dashboard"
              onClick={this.hideSidebar}
            />
          </Permission>

          <PermissionGroup name="bookings">
            <SidebarLink
              title="Calendar"
              icon="calendar-full"
              route="/calendar"
              onClick={this.hideSidebar}
            />
            <SidebarCategory title="Bookings" icon="book">
              <Permission id="view-bookings">
                <SidebarLink
                  title="Bookings"
                  route="/booking/listing"
                  onClick={this.hideSidebar}
                />
              </Permission>
              {/* {isSuperAdmin && ( */}
                <Permission id="view-bookings">
                  <SidebarLink
                    title="Bookings Cancelled"
                    route="/booking/cancelled-listing"
                    onClick={this.hideSidebar}
                  />
                </Permission>
              {/* )} */}
              <Permission id="view-bookings">
                  <SidebarLink
                    title="Bookings No-shows"
                    route="/booking/noshow-listing"
                    onClick={this.hideSidebar}
                  />
                </Permission>

              <Permission id="edit-bookings">
                <SidebarLink
                  title="Add Booking"
                  route="/booking/add"
                  onClick={this.hideSidebar}
                />
              </Permission>
            </SidebarCategory>
          </PermissionGroup>

          <PermissionGroup name="members">
            <SidebarCategory title="Members" icon="user">
              <Permission id="view-members">
                <SidebarLink
                  title="Member Listing"
                  route="/member/listing"
                  onClick={this.hideSidebar}
                />
              </Permission>

              <Permission id="edit-members">
                <SidebarLink
                  title="Add Member"
                  route="/member/add"
                  onClick={this.hideSidebar}
                />
              </Permission>

              <Permission id="view-members">
                <SidebarLink
                  title="Corporate"
                  route="/member/corporate"
                  onClick={this.hideSidebar}
                />
              </Permission>
            </SidebarCategory>
          </PermissionGroup>

          <PermissionGroup name="managers">
            <SidebarCategory title="Managers" icon="users">
              <Permission id="view-managers">
                <SidebarLink
                  title="Manager Listing"
                  route="/manager/listing"
                  onClick={this.hideSidebar}
                />
              </Permission>

              <Permission id="edit-managers">
                <SidebarLink
                  title="Add Manager"
                  route="/manager/add"
                  onClick={this.hideSidebar}
                />
              </Permission>
            </SidebarCategory>
          </PermissionGroup>

          {/* <Permission id="manage-roles">
						<SidebarCategory title='Roles' icon='lock'>
							<SidebarLink
								title='User Roles'
								route='/roles/users'
								onClick={this.hideSidebar}
							/>
							<SidebarLink
								title='Permissions'
								route='/roles/permissions'
								onClick={this.hideSidebar}
							/>
						</SidebarCategory>
					</Permission> */}

          <PermissionGroup name="horses">
            <SidebarCategory title="Horses" icon="horse">
              <Permission id="view-horses">
                <SidebarLink
                  title="Horse Listing"
                  route="/horse/listing"
                  onClick={this.hideSidebar}
                />
              </Permission>

              <Permission id="add-horses|edit-horses">
                <SidebarLink
                  title="Add Horse"
                  route="/horse/add"
                  onClick={this.hideSidebar}
                />
              </Permission>

              <Permission id="view-horses">
                <SidebarLink
                  title="Locations"
                  route="/horse/locations"
                  onClick={this.hideSidebar}
                />
              </Permission>
            </SidebarCategory>
          </PermissionGroup>

          <PermissionGroup name="trainers">
            <SidebarCategory title="Trainers" icon="rider">
              <Permission id="view-trainers">
                <SidebarLink
                  title="Trainer Listing"
                  route="/trainer/listing"
                  onClick={this.hideSidebar}
                />
              </Permission>

              <Permission id="edit-trainers">
                <SidebarLink
                  title="Add Trainer"
                  route="/trainer/add"
                  onClick={this.hideSidebar}
                />
              </Permission>
            </SidebarCategory>
          </PermissionGroup>

          <PermissionGroup name="services">
            <SidebarCategory title="Services" icon="clipboard-list">
              <SidebarLink
                title="Single Session"
                route="/services/single"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Packages"
                route="/services/packages"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Activity"
                route="/services/activity"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Activity Category"
                route="/services/categories"
                onClick={this.hideSidebar}
              />
            </SidebarCategory>
          </PermissionGroup>

          <Permission id="manage-reports">
            <SidebarCategory title="Reports" icon="stats-dots">
              <SidebarLink
                title="Analytics"
                route="/reports/analytics"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Reports"
                route="/reports/list"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Change Log"
                route="/reports/change-log"
                onClick={this.hideSidebar}
              />
            </SidebarCategory>
          </Permission>
        </ul>
      </div>
    );
  }
}

SidebarContent.propTypes = {
  authData: PropTypes.objectOf(PropTypes.any),
};
SidebarContent.defaultProps = {
  authData: {},
};

const mapStateToProps = (state) => ({ authData: state.authentication });

export default connect(mapStateToProps)(SidebarContent);
