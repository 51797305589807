import React, { PureComponent } from "react";
// import AsyncSelect from 'react-select';
import PropTypes from "prop-types";
// import Async, { makeAsyncSelect } from 'react-select/async';
import AsyncSelect from "react-select/lib/Async";

import API from "../../../api/API";
import GlobalConstans from "../../../_constants/global.constants";
import { constructUrlParams } from "../../../_helpers/CommonFunctions";

class SelectAsyncField extends PureComponent {
	static propTypes = {
		onChange: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
		placeholder: PropTypes.string,
		// options: PropTypes.arrayOf(PropTypes.shape({
		//   value: PropTypes.string,
		//   label: PropTypes.string,
		// })),
		isDisabled: PropTypes.bool,
		value: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				label: PropTypes.string,
			}),
		]).isRequired,
		api: PropTypes.string,
		clearable: PropTypes.bool,
	};

	static defaultProps = {
		placeholder: "",
		// options: [],
		isDisabled: false,
		clearable: false,
		api: "",
	};

	constructor(props) {
		super(props);
		this.state = { api: props.api, defaultOptions: [] };
	}

	componentWillMount() {
		const { props } = this;
		const { api } = this.state;
		const url = `${GlobalConstans.base_url}${api}`;
		API.getDropDown(url, true)
			.then((response) => {
				let resData = response.data.data;
				if (props.group.length > 0) {
					const groupIndex = resData.findIndex(
						(option) => option.label === props.group
					);
					if (groupIndex !== -1) {
						resData = [resData[groupIndex]];
					}
				}
				this.setState({
					defaultOptions: resData,
				});
			})
			.catch(() => {
				this.setState({
					defaultOptions: [],
				});
			});
	}

	handleChange = (selectedOption) => {
		const { onChange } = this.props;
		onChange(selectedOption);
	};

	loadOptions = (inputValue, callback) => {
		const { props } = this;
		// console.log(props);
		const { api } = this.state;
		// if (!inputValue) {
		//   return;
		// }

		const urlParams = constructUrlParams({
			s: inputValue,
		});
		const url = `${GlobalConstans.base_url}${api}${urlParams}`;
		API.getDropDown(url)
			.then((response) => {
				let list = [{ label: "All", value: "" }];
				if (props.showAll) {
					list = list.concat(response.data.data);
					callback(list);
				}
			})
			.catch(() => {
				callback();
			});
	};

	render() {
		const { value, name, placeholder, isDisabled, clearable } = this.props;

		const { defaultOptions } = this.state;

		return (
			<AsyncSelect
				loadOptions={this.loadOptions}
				defaultOptions={defaultOptions}
				onInputChange={this.handleInputChange}
				name={name}
				value={value}
				onChange={this.handleChange}
				// options={options}
				isClearable={clearable}
				className="react-select"
				placeholder={placeholder}
				classNamePrefix="react-select"
				isDisabled={isDisabled}
			/>
		);
	}
}

const renderSelectAsyncField = (props) => {
	const { input, meta, options, group, placeholder, isDisabled, api } = props;

	return (
		<div className="form__form-group-input-wrap">
			<SelectAsyncField
				{...input}
				options={options}
				placeholder={placeholder}
				isDisabled={isDisabled}
				api={api}
				group={group}
				showAll
				clearable
			/>
			{meta.touched && meta.error && (
				<span className="form__form-group-error">{meta.error}</span>
			)}
		</div>
	);
};

renderSelectAsyncField.propTypes = {
	input: PropTypes.shape({
		onChange: PropTypes.func,
		name: PropTypes.string,
	}).isRequired,
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string,
	}),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
		})
	),
	group: PropTypes.string,
	placeholder: PropTypes.string,
	isDisabled: PropTypes.bool,
	api: PropTypes.string.isRequired,
	showAll: PropTypes.bool,
	clearable: PropTypes.bool,
};

renderSelectAsyncField.defaultProps = {
	meta: null,
	options: [],
	group: "",
	placeholder: "",
	isDisabled: false,
	showAll: false,
	clearable: false,
};

export default renderSelectAsyncField;
