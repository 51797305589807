import API from "../../api/API";
import { reportConstants } from "../../_constants/dashboard.constants";
import history from "../../_helpers/history";
import alertActions from "./alertActions";
import preloaderActions from "./preloaderActions";

function getAnalyticsStatAction(params) {
  function success(data) {
    return {
      type: reportConstants.REPORT_ANALYTICS_STAT,
      data,
    };
  }

  return (dispatch, getState) => {
    dispatch(preloaderActions.show());

    API.getAnalyticsStat(params).then(
      (response) => {
        if (response.data) {
          dispatch(success(response.data));
        }
        dispatch(preloaderActions.hide());
      },
      (error) => {
        dispatch(preloaderActions.hide());
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(alertActions.error(error.response.data.error.message));
        }
      }
    );
  };
}

const reportAnalyticsStatActions = {
  getAnalyticsStatAction,
};

export default reportAnalyticsStatActions;
