import React, { PureComponent } from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import moment from "moment";

class DatePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      startDate: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      startDate: Date.parse(nextProps.value),
    };
  }

  handleChange(date) {
    const { onChange } = this.props;
    this.setState({
      startDate: date,
    });
    onChange(date);
  }

  render() {
    const { startDate } = this.state;
    const { isDisabled, filterDate } = this.props;

    return (
      <div className="date-picker">
        <DatePicker
          className="form__form-group-datepicker"
          selected={startDate}
          dateFormat="dd/MM/yyyy"
          onChange={this.handleChange}
          dropDownMode="select"
          disabled={isDisabled}
          filterDate={filterDate}
          minDate={moment().toDate()}
        />
      </div>
    );
  }
}

const renderDatePickerField = (props) => {
  const { input, disabled, filterDate } = props;
  return <DatePickerField {...input} isDisabled={disabled} filterDate={filterDate} />;
};

renderDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  filterDate: PropTypes.func
};

renderDatePickerField.defaultProps = {
  disabled: false,
  filterDate: null,
};

export default renderDatePickerField;
