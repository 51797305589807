import React, { PureComponent, Children } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import SlideIn from "./SlideIn";
import clonedeep from "lodash.clonedeep";
import API from "../../../api/API";
import bookingActions from "../../../redux/actions/bookingActions";
// import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
// import { Redirect } from "react-router-dom";

const localizer = momentLocalizer(moment);

// const DragAndDropCalendar = withDragAndDrop(Calendar);

const tooltip = () => {
  return "This is a ToolTip";
};

class CalendarComponent extends PureComponent {
  static propTypes = {
    events: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        allDay: PropTypes.bool,
        start: PropTypes.instanceOf(Date),
        end: PropTypes.instanceOf(Date),
        priority: PropTypes.string,
      })
    ).isRequired,
    small: PropTypes.bool,
  };

  static defaultProps = {
    small: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      selectedSlot: false,
      selectedEvent: false,
      // events: props.events,
      // selectedSlot: null,
      // redirect: false,
      // redirectEdit: false
    };
  }

  closeModal = (update = false) => {
    const { props } = this;
    this.setState({
      openModal: false,
      selectedEvent: false,
      selectedSlot: false,
    });
    if (typeof update === "boolean" && update) {
      props.onChange();
    }
  };

  updateModal = (event) => {
    const { props } = this;

    this.setState({
      openModal: false,
      selectedEvent: false,
      selectedSlot: false,
    });

    props.onChange();
    this.onSelectEventHandler(event);
  };

  moveEvent = ({ event, start, end }) => {
    const { events } = this.state;

    const idx = events.indexOf(event);
    const updatedEvent = { ...event, start, end };

    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);

    // this.setState({
    // 	events: nextEvents,
    // 	redirect: false,
    // 	redirectEdit: false
    // });
  };

  eventStyleGetter = (event) => {
    let color;

    switch (event.priority) {
      case "high":
        color = "#A5DFF8";
        break;

      case "family":
        color = "#A5DFF8";
        break;

      case "non":
        color = "#A5DFF8";
        break;
      default:
        color = "#A5DFF8";
        break;
    }

    const style = {
      backgroundColor: color,
      border: "none",
    };

    return {
      style,
    };
  };

  onSelectEventHandler = (event) => {
    const { props } = this;

    const eventData = clonedeep(event);
    API.getBooking(event.resourceId).then(
      (response) => {
        if (response.data) {
          props.getGroupBooking(event.resourceId);
          eventData.bookingData = response.data.data;
          this.setState({
            openModal: true,
            selectedEvent: eventData,
            selectedSlot: false,
          });
        }
      },
      () => {
        // Handle errors.
      }
    );
  };
  

  render() {
    const { small, events } = this.props;
    const { openModal, selectedSlot, selectedEvent } = this.state;
    return (
      <div className={`calendar${small ? " calendar--small" : ""}`}>
        <Calendar
          localizer={localizer}
          selectable
          events={events}
          views={["month", "week", "day"]}
          // defaultView="month"
          popup
          step={15}
          timeslots={4}
          onEventDrop={this.moveEvent}
          // defaultDate={new Date(2019, 8, 1)}
          eventPropGetter={this.eventStyleGetter}
          tooltipAccessor={tooltip}
          onSelectEvent={this.onSelectEventHandler}
          onSelectSlot={(slotInfo) => {
            if(!moment(slotInfo.start).isBefore() || moment(slotInfo.start).isSame(moment(), 'day')){
              this.setState({ selectedSlot: slotInfo, openModal: true });
            }
          }}
          onNavigate={this.props.onNavigate}
          messages={{
            previous: <span className="lnr lnr-chevron-left" />,
            next: <span className="lnr lnr-chevron-right" />,
            today: <span className="lnr lnr-calendar-full" />,
          }}
          components={{
            dateCellWrapper: ColoredDateCellWrapper,
            timeSlotWrapper: ColoredTimeCellWrapper,
           }}
        />
        <SlideIn
          openModal={openModal}
          selectedSlot={selectedSlot}
          selectedEvent={selectedEvent}
          closeModal={this.closeModal}
          updateModal={this.updateModal}
          onChangeCalendar={this.props.onChange}
        />
      </div>
    );
  }
}

 const ColoredDateCellWrapper = (props) =>{
   const {children, value, range} = props;
 const now = new Date();
  now.setHours(0,0,0,0);
    return React.cloneElement(Children.only(children), {
        style: {
            ...children.style,
            backgroundColor: value < now ? 'rgb(232, 232, 232)' : '',
        },
    });

  }

  const ColoredTimeCellWrapper = (props) =>{
    const {children, value, range, resource} = props;
    const now = new Date();
    now.setHours(0,0,0,0);
     return React.cloneElement(Children.only(children), {
         style: {
             ...children.style,
             backgroundColor: value < now && typeof resource != 'undefined' ? 'rgb(232, 232, 232)' : '',
         },
     });
 
   }
 

const actionCreators = {
  getGroupBooking: bookingActions.getGroupBookingAction,
};

export default connect(null, actionCreators)(CalendarComponent);
